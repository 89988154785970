import LockStake from "../abi/v2/xWinLockStake.json";
import appGlobalConfig from '../configs/56/appGlobalConfig';
import { getGasPrice, convertToWei, convertFromWei } from "../utils/helper";
import xWinLib from "../xWinLib";
import { getTokenInfo } from "./erc20Interactor";
import { getMasterChefStakingAPR } from "./masterChefInteractor";
import { ethers } from "ethers";

export async function GetUserLockStakeInfo(web3, account) {

  try {
    const contract = new web3.eth.Contract(LockStake, appGlobalConfig.mainnetLockStake);
    
    const promiseData = await Promise.all([
      contract.methods.userInfo(account).call(),
      contract.methods.getFavor(account).call(),
      contract.methods.getUserPosition(account).call(),
      contract.methods.getUserLockingBonusAPR(account).call(),
    ]);
    let userInfo = promiseData[0]
    let discount = promiseData[1]
    let userPosition = promiseData[2]
    let userBonusAPR = promiseData[3]
    
    
    return {
      "amount": convertFromWei(userInfo.amount),
      "rewards": convertFromWei(userPosition.rewardAmount),
      "currentxwinAmount": convertFromWei(userPosition.xwinAmount),
      "startTimestamp": userInfo.startTimestamp,
      "endPeriodTimestamp": userInfo.endPeriodTimestamp,
      "discountPerformance": discount / 100,
      "userBonusAPR" : userBonusAPR / 100,
      "duration" : xWinLib.numWeeksBetween(userInfo.startTimestamp, userInfo.endPeriodTimestamp)
    };
    
  } catch (error) {
    console.log(error)
    return {
      "amount": 0,
      "rewards": 0,
      "currentxwinAmount": 0,
      "startTimestamp": Date.today,
      "endPeriodTimestamp": Date.today,
      "discountPerformance": 0,
      "userBonusAPR" : 0,
      "duration" : 0
    };
  }

  
  
}

export async function GetPoolLockStakeInfo(web3) {

  const contract = new web3.eth.Contract(LockStake, appGlobalConfig.mainnetLockStake);
    
  const promiseData = await Promise.all([
    contract.methods.totalXWINBalance().call(),
    contract.methods.lockedRewardsVault().call(),
  ]);
  let totalShares = promiseData[0]
  let totalLockedShares = promiseData[1]
  
  return {
    "totalShares": convertFromWei(totalShares),
    "totalLockedShares": convertFromWei(totalLockedShares)
  };
  
}


export async function GetAPRInfo(web3) {

  let aprXWINPool = await getMasterChefStakingAPR(web3, 0)
  
  return {
    "aprXWINPool": aprXWINPool,
  };
  
}


export async function GetEstimatedDepositAPY(web3, duration, depositAmount) {

  const contract = new web3.eth.Contract(LockStake, appGlobalConfig.mainnetLockStake);
  if(Number(depositAmount) === 0) {
    return {
      "estimatedDailyRate": 0,
      "estimatedBonusApr": 0,
    };
  }
  const subsAmtInWei = convertToWei((depositAmount === 0 || depositAmount === undefined) ? 1: depositAmount);
  const promiseData = await Promise.all([
    contract.methods.getEstimatedDepositAPY(subsAmtInWei, duration).call(),
  ]);
  let aprs = promiseData[0]
  return {
    "estimatedDailyRate": convertFromWei(aprs.estimatedDailyRate),
    "estimatedBonusApr": aprs.estimatedBonusApr / 100,
  };
}


export function GetEstimatePerformanceDiscount(period, amount) {
    let diff = period * 7;
    let tmp = amount / 1 * diff * diff;
    tmp = (Math.sqrt(tmp) * 1) / 81600 * 50;
    let favor = tmp / 1;
    return favor > 50 ? 50 : favor;
}




export function GetBonusWeight(period) {
  return (1 * (period + 50)) / 51;
}

export async function DepositLockStake(signer, account, subsAmt, duration) {

  return new Promise((resolve, reject) => {    
    
    const contract = new ethers.Contract(appGlobalConfig.mainnetLockStake, LockStake, signer);
    const gasParam = getGasPrice(signer);
    const subsAmtInWei = convertToWei(subsAmt);
    contract.deposit(subsAmtInWei, duration, {
      from: account,
      value: 0,
      ...gasParam
    })
    .then(txn => {
      txn.wait().then(receipt => {
        console.log(receipt);
        resolve(receipt.transactionHash);
      })
    })
    .catch((err) => {
      console.log('error: %j', err.message);
      reject(err.message);
    });
  });
}

export async function WithdrawLockStake(signer, account) {
  
  return new Promise((resolve, reject) => {
    
    const contract = new ethers.Contract(appGlobalConfig.mainnetLockStake, LockStake, signer);
    const gasParam = getGasPrice(signer);
    contract.withdraw({
      from: account,
      value: 0,
      ...gasParam
    })
    .then(txn => {
      txn.wait().then(receipt => {
        console.log(receipt);
        resolve(receipt.transactionHash);
      })
    })
    .catch((err) => {
      console.log('error: %j', err.message);
      reject(err.message);
    });
  });
}


export async function GetUserPerformanceFee(web3, account, fundPerformanceFee, appGlobalConfig) {

  if(appGlobalConfig === undefined) return 0;
  const contract = new web3.eth.Contract(LockStake, appGlobalConfig.mainnetLockStake);
  const promiseData = await Promise.all([
    contract.methods.getFavor(account).call(),
  ]);
  let discount = promiseData[0]
  return  fundPerformanceFee * (1 - (discount / 10000))
  
}


      
