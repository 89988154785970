const appGlobalConfig = {
  MAXALLOWANCE: '10000000000000000000000000000000000000000000000',
  mainnetRiskFreeAsset: '0x8a0Fa3B0010c76099E935E2C8909d5441390fF62',
  mainnetRiskFreeProxy: '0x8a0Fa3B0010c76099E935E2C8909d5441390fF62',
  mainnetLockStake: '0x0000000000000000000000000000000000000000',
  mainnetMasterChef: '0x0000000000000000000000000000000000000000',
  mainnetBuddyChef: '0x0000000000000000000000000000000000000000',
  mainnetFundFactory: '0x6cC1CaD8b330731e6FF469EaEBf19961ea1DFc03',
  mainnetxWInPriceMaster: '0x8a3c24716447992C85a86231606759931f83c667',
  mainnetXWIN: '0x5834542ee7B6FE644Cd568E3ad48A5B0D58EC8ba',
  mainnetBurn: '0x000000000000000000000000000000000000dead',
  mainnetAddressZero: '0x0000000000000000000000000000000000000000',
  mainnetWBNB: '0x20865e63B111B2649ef829EC220536c82C58ad7B',
  mainnetBTC: '0x2f2a2543B76A4166549F7aaB2e75Bef0aefC5B0f',
  mainnetETH: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  mainnetUSDT: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
  mainnetUSDC: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  defaultVault: "0x5b0D58149CbD7f041a8833f8EfE4b2DdADAc47D2",
  mainnetCreateFundCost: "1",
  mainnetPrivateVaultPayIn: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  mainnetUSDTStakingFund: '0x0000000000000000000000000000000000000000',
  mainnetXWINBNBLPPool: '0x0000000000000000000000000000000000000000',
  mainnetXWINUSDTLPPool: '0x0000000000000000000000000000000000000000',
  mainnetXWINUSDTAPELPPool: '0x0000000000000000000000000000000000000000',
  mainnetXWINBNBLPPoolV2: '0x0000000000000000000000000000000000000000',
  mainnetXWINBNBLPPoolApeSwap: '0x0000000000000000000000000000000000000000',
  mainnetxWinUSDTStaking: '0x0000000000000000000000000000000000000000',
  mainnetxWinPrivateUSDT: '0x0000000000000000000000000000000000000000',
  mainnetxWINMaster: '0x0000000000000000000000000000000000000000', 
  apiGetWinStats: 'https://us-central1-xwininvestment.cloudfunctions.net/getXWINStats',
  apiGetTVL: 'https://us-central1-xwininvestment.cloudfunctions.net/getTotalLockValueV2',
  apiGetJPYUSD: 'https://us-central1-xwininvestment.cloudfunctions.net/getJpyUsdExchangeRate',
  apiOptimization: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/allocation',
  apiBasicAllocation: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/basicAllocation',
  apiGetPrivateFundsByContractAddress: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getPrivateFundsByContractAddress',
  apiGetPrivateFundsBySearchKey: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getPrivateFundsBySearchKey',
  apiGetLatestPrivateFunds: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getLatestPrivateFunds',
  apiGetFundActivitiesByWalletOrContractAddr: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getFundActivitiesByWalletOrContractAddr',
  apiGetPrivateFunds: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getPrivateFunds',
  apiGetTATradingSignal: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getTATradingSignal',
  apiGetTokenPrices: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getTokenPrices',
  apiGetMarketTrends: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getMarketTrends',
  apiGetSharpeRatio: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getSharpeRatio',
  apiGetPerformanceCompare: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getPerformanceReturns',
  apiGetMasterFarmTotalValueByUser: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getMasterFarmTotalValueByUser',
  apiGetBuddyFarmTotalValueByUser: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getBuddyFarmTotalValueByUser',
  apiGetVaultTotalValueByUser: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getVaultTotalValueByUser',
  apiGetStrategyTotalValueByUser: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getStrategyTotalValueByUser',
  apiGetStrategyEstimateOut: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getStrategyEstimateOut',
  apiGetFundv2EstimateOut: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getFundv2EstimatedOutput',
  apiGetTVLv2: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getV2TVLPlus',
  apiGetUserPnL: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getUserPnL',
  apiGetFundv2Details: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getFundv2Details',
  apiGetStrategyDetails: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getStrategyDetails',
  apiGetLeaderBoardByTVL: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getLeaderBoardByTVL',
  apiGetConfigV2: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getV2Config',
  apiGetAccessToken: 'https://us-central1-xwinfinancev2.cloudfunctions.net/accessToken',
  apiRegisterReferral: 'https://us-central1-xwinfinancev2.cloudfunctions.net/ReferralCode',
  apiGetSplitFeeAddress: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/GetSplitFeeAddress',
  apiGetVenusData: 'https://api.venus.io/api/governance/venus',
  apiGetArbTVLPlus: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getArbTVLPlus',
  apiGetPolygonTVLPlus: 'https://us-central1-xwinfinance-polygon.cloudfunctions.net/getPolygonTVLPlus',
  apiGetTotalLockValue: 'https://us-central1-xwinfinance-main.cloudfunctions.net/getTotalLockValue',
  apiGetHistoryTVL: 'https://us-central1-xwinfinance-main.cloudfunctions.net/getTotalTVLHistory',
  apiGetTotalVault: 'https://us-central1-xwinfinance-main.cloudfunctions.net/getTotalVault',
  apiGetRecommendedVault: 'https://us-central1-xwinfinance-main.cloudfunctions.net/getRecommendVaults?chain=arb',
  tokenv2: 'https://s3.ap-northeast-1.amazonaws.com/download.xwin.finance/v2-config/arb/tokensv2.json',
  xwinfundv2: 'https://s3.ap-northeast-1.amazonaws.com/download.xwin.finance/v2-config/arb/xwinfundv2.json',
  xwinstrategies: 'https://s3.ap-northeast-1.amazonaws.com/download.xwin.finance/v2-config/arb/xwinstrategies.json',
  xwinstakingv2: 'https://s3.ap-northeast-1.amazonaws.com/download.xwin.finance/v2-config/arb/xwinstakingv2.json',
  xwinbuddystakingv2: 'https://s3.ap-northeast-1.amazonaws.com/download.xwin.finance/v2-config/arb/xwinbuddystakingv2.json',
  apiGetVaultInfo: 'https://us-central1-xwinfinance-main.cloudfunctions.net/getVaultInformation?chain=arbvaults',
  apiGetExposure: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getDrillDownExposure?useraddr=',
  gasPrice: '100000000',  // for Main Net
  blockPerDay: 7140,  // for Main Net
  env: 'arb',  // for Main Net
  chainId: 42161
};

export default appGlobalConfig;

