import React, { useState, useEffect, useContext } from 'react'
import {
    Grid,
    Container,
    Tabs,
    Tab,
    Box,
    Typography,
} from '@material-ui/core'
import useStyles from './styles'
import RegisterReferralDetail from './components/registerReferralDetail'
import { useParams } from 'react-router-dom'
import IamReferral from './components/IamReferral'
import Widget from '../../components/Widget/Widget'
import PieChartIcon from '@material-ui/icons/PieChart'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Diversity2Icon from '@mui/icons-material/Diversity2';
import GroupIcon from '@mui/icons-material/Group';
import vaultfaqconfig from '../vaultfaq/faq/referralFAQ'
import VaultFaq from '../vaultfaq/VaultFaq'
import { AppContext } from '../../context/AppProvider'
import MyEarning from './components/MyEarning'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const RegisterReferral = () => {
    const classes = useStyles()
    const { id } = useParams()
    const { t, i18n } = useTranslation('fundDetails')
    const [value, setValue] = React.useState(0)
    const [refCode, setRefCode] = React.useState(id)
    const [faqs, setFaqs] = useState([])
    const {
        appGlobalConfig
    } = useContext(AppContext)
    

    useEffect(() => {
        init()
    }, [appGlobalConfig])

    const init = async () => {
        let fqs = vaultfaqconfig.faqs.filter(x=>x.env === appGlobalConfig?.chainId);
        setFaqs(fqs)    
    }
    
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid
                    item
                    lg={9}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight}
                >
                <Widget
                        title={t('referral.registercode-input')}
                        disableWidgetMenu
                        inheritHeight
                        enableWalletDisplay
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            // aria-label="basic tabs example"
                        >
                            <Tab
                                className={classes.contentText}
                                icon={<Diversity2Icon />}
                                iconPosition="start"
                                label={t('referral.register')}
                                {...a11yProps(0)}
                            />
                            <Tab
                                className={classes.contentText}
                                icon={<GroupIcon />}
                                iconPosition="start"
                                label={t('referral.my-code')}
                                {...a11yProps(1)}
                            />
                             <Tab
                                className={classes.contentText}
                                icon={<MonetizationOnIcon />}
                                iconPosition="start"
                                label={t('referral.my-earning')}
                                {...a11yProps(2)}
                            />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <br />
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    className={classes.hoverHighlight}
                                >
                                    <RegisterReferralDetail refCode={refCode}/>
                                </Grid>
                               
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <br />
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    className={classes.hoverHighlight}
                                >
                                    <IamReferral />
                                </Grid>
                               
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <br />
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    className={classes.hoverHighlight}
                                >
                                    <MyEarning />
                                </Grid>
                               
                            </Grid>
                        </TabPanel>
                </Widget>
                <VaultFaq faqs={faqs} />
                </Grid>
                
            </Grid>
            
        </Container>
    )
    
    
}

export default RegisterReferral
