const buddychefFAQ = {
    faqs: [
        {
            symbol: 'buddychef',
            env: 56,
            question: 'buddychef.question-1',
            defaultExpanded: true,
            desc: 'buddychef.desc-1',
        },
        {
            symbol: 'buddychef',
            env: 56,
            question: 'buddychef.question-2',
            desc: 'buddychef.desc-2',
        },
        {
            symbol: 'buddychef',
            env: 56,
            question: 'buddychef.question-3',
            desc: 'buddychef.desc-3',
        },
        {
            symbol: 'buddychef',
            env: 56,
            question: 'buddychef.question-4',
            desc: 'buddychef.desc-4',
        },
    ],
}

export default buddychefFAQ
