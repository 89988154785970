import React from "react";
import { Box } from "@material-ui/core";
import useStyles from '../styles'

const Footer = ({ children }) => {
  var classes = useStyles()
  return (
    <Box
      mt={15}
      mb={3}
      width={"100%"}
    >
      {children}
    </Box>
  );
};

export default Footer;
