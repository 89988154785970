import { Grid, Box, CardContent, Card } from '@material-ui/core'
import { Typography } from '../../../components/Wrappers/Wrappers'
import React, { useState, useEffect, useContext } from 'react'
import useStyles from '../styles'
import ApexLineChart from './ApexLineChart'
import { AppContext } from '../../../context/AppProvider'
import xWinLib from '../../../xWinLib'
import { GetTokenPrices } from '../../../utils/firestoreDataInteractor'
import clsx from 'clsx'
import Dot from '../../../components/Sidebar/components/Dot'
import format from 'format-number'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

const Vault = props => {
    const classes = useStyles()
    const { appGlobalConfig } = useContext(AppContext)
    const [mainData, setMainData] = React.useState([])

    const fetchTokenPrice = async () => {
        const promiseData = await Promise.all([
            GetTokenPrices(props?.props.id, appGlobalConfig),
        ])
        let priceData = promiseData[0]
        setMainData(priceData)
    }

    useEffect(() => {
        fetchTokenPrice()
    }, [props?.props.id])

    return (
        <Card>
            <CardContent>
                <Box
                    style={{
                        marginBottom: 4,
                    }}
                >
                    <Typography
                        className={classes.primaryHeading}
                        style={{
                            marginRight: 5,
                            textAlign: 'center',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    >
                        {props?.props?.fundName}
                    </Typography>
                </Box>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid item>
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            TVL
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont, 'p-2')}
                            >
                                {xWinLib.displayBigNumber(props?.props?.tvl)}
                            </Typography>
                        </Box>
                    </Grid>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Grid item>
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            Return (7D)
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(
                                    props?.props?.return >= 0
                                        ? classes.positiveNum
                                        : classes.negativeNum,
                                    classes.displayFont,
                                    'p-2'
                                )}
                            >
                                {props?.props?.return >= 0 ? (
                                    <ArrowUpwardIcon />
                                ) : (
                                    <ArrowDownwardIcon />
                                )}
                                {format({ prefix: '', suffix: '%' })(
                                    xWinLib.roundTo(props?.props?.return, 2)
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </div>
                <Grid>
                    <Grid item lg={12}>
                        <ApexLineChart
                            returns={props?.props?.return}
                            mainData={mainData}
                            period={8}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Vault
