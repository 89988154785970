import React, { useState, useEffect } from 'react'
import xWinLib from '../../../xWinLib'
import format from 'format-number'
import xWinUtils from '../../../xWinUtils'
import PropTypes from 'prop-types'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import {
    InfoRounded as InfoIcon,
    MoneySharp as MoneyIcon,
} from '@material-ui/icons'
import moment from 'moment'

import { Grid, Box, Tabs, Tab, Accordion, AccordionSummary, AccordionDetails, Card, CardContent, Avatar, Tooltip } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

// styles
import useStyles from '../styles'
import Widget from '../../../components/Widget/Widget'
import { Typography } from '../../../components/Wrappers/Wrappers'
import Dot from '../../../components/Sidebar/components/Dot'
import { GetSharpeRatio } from '../../../utils/firestoreDataInteractor'
import appGlobalConfig from '../../../configs/56/appGlobalConfig'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { GetUserPerformanceFee } from '../../../utils/autoLockInteractor'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useTranslation } from 'react-i18next'
import { getUnderlyingFee } from '../../../utils/fundV2Interactor'
import clsx from 'clsx'
import { getExplorerScan } from '../../../utils/helper'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function UnitPrice(props) {
    var classes = useStyles()
    const theme = useTheme()
    const { fundData, loading, port, underlyingNames, account, web3static, xwinstrategies, explorerScan, appGlobalConfig } = props
    const [value, setValue] = React.useState(0)
    const [returns, setReturns] = useState([])
    const [statistic, setStatistic] = useState({
        portReturns: 0,
        portVol: 0,
        portSR: 0,
    })
    const [dropdown, setDropdown] = React.useState('optimization-6m')
    const [userPerformanceFee, setUserPerformanceFee] = useState(
        fundData.performanceFee
    )
    const [underlyingFee, setUnderlyingFee] = useState(
      {
        "performanceFee": 0,
        "strategyFee": 0
      }
    )
    const [marketCap, setMarketCap] = useState(
        {
          "valueInMilify": 0,
          "value": 0
        }
      )
    const { t, i18n } = useTranslation('fundDetails')

    useEffect(() => {
        init('optimization-6m')
    }, [fundData])

    useEffect(() => {
        initUnderlyingFee()
    }, [underlyingNames])

    useEffect(() => {
        initUserPerformanceFee()
    }, [account])

    useEffect(() => {
        calcStatistic()
    }, [returns, dropdown])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const init = async (model) => {
        const yyyymm = moment(new Date())
            .endOf('month')
            .format('YYYYMM')

        const promiseData = await Promise.all([GetSharpeRatio(model, yyyymm, appGlobalConfig)])
        let o12m = promiseData[0]
        setReturns(o12m)
        await initUserPerformanceFee()

        const marCapInMilify = xWinLib.getMarketCap(fundData, !xWinLib.getIsJPYC(fundData?.baseCcy))
        const marCap = xWinLib.getMarketCapNoMilify(fundData, !xWinLib.getIsJPYC(fundData?.baseCcy))
        setMarketCap({
            "valueInMilify": marCapInMilify,
            "value": marCap
        })
    }

    const initUnderlyingFee = async () =>{
    
      if(fundData.contractaddress === undefined) return;
      if(underlyingNames === undefined) return;
        
      let pfee = 0;
      let sfee = 0;
      underlyingNames.forEach(o => {
        pfee = pfee + (Number(o.performanceFee) * Number(o.targetweight) / 10000)
        sfee = sfee + (Number(o.managerFee) * Number(o.targetweight) / 10000)
      });
      setUnderlyingFee({
        "performanceFee" : pfee,
        "strategyFee" : sfee,
      })  
    }
  
    const initUserPerformanceFee = async () => {
        if(appGlobalConfig?.chainId !== 56){
            setUserPerformanceFee(fundData.performanceFee)
            return
        }
        if (account !== undefined) {
            let newFee = await GetUserPerformanceFee(
                web3static,
                account,
                fundData.performanceFee || 0,
                appGlobalConfig
            )
            setUserPerformanceFee(newFee)
        } else {
            setUserPerformanceFee(fundData.performanceFee)
        }
    }

    const calcStatistic = () => {
        
        if (fundData.tokenNames === undefined) return
        
        let totalReturns = 0
        let totalVol = 0
        let totalSR = 0
        const proxyAssets = xwinstrategies.filter(
            x => x.env === appGlobalConfig.env
        )
        
        fundData.tokenNames.forEach(p => {
            let r = returns.find(
                x => x.asset.toLowerCase() === p.address.toLowerCase()
            )
            if (r === undefined && p.key !== 999) {
                let proxy = proxyAssets.find(
                    x =>
                        x.contractaddress.toLowerCase() ===
                        p.address.toLowerCase()
                )

                if (proxy !== undefined) {
                    if (
                        proxy.underlying.toLowerCase() ===
                        appGlobalConfig.mainnetRiskFreeAsset.toLowerCase()
                    ) {
                        r = {
                            return: 0.0,
                            volatility: 0,
                            sharpeRatio: 1,
                        }
                    } else {
                        r = returns.find(
                            x =>
                                x.asset.toLowerCase() ===
                                proxy.underlying.toLowerCase()
                        )
                    }
                }
            }
            if (r !== undefined && p.key !== 999) {
                totalReturns += (p.targetweight / 100) * r.return
                totalVol += (p.targetweight / 100) * r.volatility
                totalSR += ((p.targetweight / 100) * r.sharpeRatio) / 100
            }
        })

        let portReturns = Math.pow(1 + totalReturns / 100, 12) - 1
        let portVol = (totalVol / 100) * Math.sqrt(12)
        setStatistic({
            portReturns: portReturns * 100,
            portVol: portVol * 100,
            portSR: (portReturns / portVol) * 100,
        })
    }

    const handleChangeDropdown = async event => {
        event.preventDefault()
        await init(event.target.value)
        setDropdown(event.target.value)
    }

    const displayDiscount = () => {
        if (
            userPerformanceFee !== undefined &&
            userPerformanceFee !== fundData.performanceFee
        ) {
            return (
                <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        {xWinUtils.displayToolTip(
                            'You got performance fee discount because you staked and lock xWIN Tokens. Thank you!'
                        )}
                        <Typography
                            size="md"
                            weight={'medium'}
                            style={{ marginLeft: 5 }}
                            className={clsx(classes.displayFont,"p-2")}
                            color="secondary"
                        >
                            <s>
                                {format({ prefix: '', suffix: '%' })(
                                    xWinLib.roundTo(fundData.performanceFee, 2)
                                )}
                            </s>
                        </Typography>
                        &rarr;
                        <Typography
                            size="md"
                            weight={'medium'}
                            style={{ marginLeft: 5 }}
                            className={clsx(classes.displayFont,"p-2")}
                            color="primary"
                        >
                            {format({ prefix: '', suffix: '%' })(
                                xWinLib.roundTo(userPerformanceFee, 2)
                            )}
                        </Typography>
                    </Box>
                </Grid>
            )
        } else {
            return (
                <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <Dot size="medium" color="primary" />
                        <Typography
                            size="md"
                            weight={'medium'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {format({ prefix: '', suffix: '%' })(
                                xWinLib.roundTo(fundData.performanceFee, 2)
                            )}
                        </Typography>
                    </Box>
                </Grid>
            )
        }
    }

    const getStrategyFeePerDay = (fee) => {
        return xWinLib.roundTo(fee / 365, 5) 
    }

    const getPlatformFeePerDay = () => {
        return xWinLib.roundTo(fundData.platformFee / 365, 5) 
    }

    const displayCcyIcon = (baseCcy) => {

        return (
            <Tooltip arrow title={baseCcy}>
                <Avatar
                    variant={'rounded'}
                    className={classes.smallavatar}
                    src={xWinLib.getIcon(
                        baseCcy?.toLowerCase() +
                            '.png'
                    )}
                    sx={{marginLeft: 20}}
                />
            </Tooltip>
        )
    }

    return (
        <Widget
            // title="Vault Info"
            disableWidgetMenu
            bodyClass={classes.fullHeightBody}
            className={classes.card}
        >
            <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons={true}
                aria-label="scrollable force tabs example"
                value={value}
                textColor="primary"
                indicatorColor="primary"
                onChange={handleChange}
            >
                <Tab
                    className={classes.contentText}
                    fullWidth={false}
                    icon={<InfoIcon />}
                    iconPosition="start"
                    label={`${t('unitPrice.vault-info')}`}
                    {...a11yProps(0)}
                />
                <Tab
                    className={classes.contentText}
                    fullWidth={false}
                    icon={<AnalyticsIcon />}
                    iconPosition="start"
                    label={`${t('unitPrice.ex-ante')}`}
                    {...a11yProps(1)}
                />
                <Tab
                    className={classes.contentText}
                    icon={<MoneyIcon />}
                    iconPosition="start"
                    label={`${t('unitPrice.fee')}`}
                    {...a11yProps(2)}
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <br />
                <>
                    <Grid container spacing={3}>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginLeft: 5 }}
                                className={'p-2'}
                            >
                                {t('unitPrice.symbol')}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Box
                                sx={{ p: 2, border: '1px dashed grey' }}
                                display="flex"
                                alignItems="center"
                                justifyContent={'right'}
                            >
                                <Dot size="medium" color="primary" />
                                <Typography
                                    size="md"
                                    weight={'medium'}
                                    style={{ marginLeft: 5 }}
                                    className={clsx(classes.displayFont, 'p-2')}
                                >
                                    {port?.symbol}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginLeft: 5, display: 'flex' }}
                                className={'p-2'}
                            >
                                {t('unitPrice.unit-price')}{' '}
                                
                            </Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'right'}
                            >
                                {displayCcyIcon(fundData?.stablecoinUSD)}
                                <Typography
                                    size="md"
                                    weight={'medium'}
                                    style={{ marginLeft: 5 }}
                                    className={clsx(classes.displayFont, 'p-2')}
                                >
                                    {format({ prefix: '', suffix: '' })(
                                        xWinLib.roundTo(
                                            fundData.unipriceInUSD,
                                            2
                                        )
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginLeft: 5, display:'flex', }}
                                className={'p-2'}
                            >
                                {t('unitPrice.unit-price')}&nbsp;
                                {
                                    xWinLib.getIsJPYC(fundData?.baseCcy)?
                                    (
                                        xWinUtils.displayToolTip(
                                        `${t(
                                            'unitPrice.jpycjpy-unitprice-explain'
                                        )}`
                                    )) : ("")
                                }
                            </Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'right'}
                            >
                                
                                {displayCcyIcon(fundData?.baseCcy)}
                                <Typography
                                    size="md"
                                    weight={'medium'}
                                    style={{ marginLeft: 5 }}
                                    className={clsx(classes.displayFont, 'p-2')}
                                >
                                    {format({
                                        prefix: '',
                                        suffix: '',
                                    })(xWinLib.roundTo(fundData.uniprice, 4))}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginLeft: 5 }}
                                className={'p-2'}
                            >
                                {t('unitPrice.market-cap')}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'right'}
                            >
                                {displayCcyIcon(xWinLib.getIsJPYC(fundData?.baseCcy) ? fundData?.baseCcy : fundData?.stablecoinUSD)}
                                <Tooltip arrow title={marketCap.value}>
                                <Typography
                                    size="md"
                                    weight={'medium'}
                                    style={{ marginLeft: 5 }}
                                    className={clsx(classes.displayFont, 'p-2')}
                                >
                                    {marketCap.valueInMilify}
                                </Typography>
                                </Tooltip>
                                
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginLeft: 5 }}
                                className={'p-2'}
                            >
                                {t('unitPrice.total-supply')}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'right'}
                            >
                                <Dot size="medium" color="primary" />
                                <Typography
                                    size="md"
                                    weight={'medium'}
                                    style={{ marginLeft: 5 }}
                                    className={clsx(classes.displayFont, 'p-2')}
                                >
                                    {format({ prefix: '', suffix: '' })(
                                        xWinLib.roundTo(fundData.totalSupply, 4)
                                    )}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <br />
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.risk-model')}
                        </Typography>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Box
                            sx={{ p: 2, border: '1px dashed grey' }}
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <FormControl
                                sx={{ minWidth: 50 }}
                                size="small"
                                variant="standard"
                            >
                                <Select
                                    value={dropdown}
                                    label="months"
                                    onChange={handleChangeDropdown}
                                    sx={{
                                        color:
                                            theme.palette.type === 'dark' &&
                                            'white',
                                        backgroundColor:
                                            theme.palette.type === 'dark' &&
                                            '#2f2f36',
                                        paddingLeft: 1,
                                    }}
                                    className={clsx(classes.displayFont, 'p-2')}
                                >
                                    <MenuItem value="optimization-3m">
                                        {t('unitPrice.3-month')}
                                    </MenuItem>
                                    <MenuItem value="optimization-6m">
                                        {t('unitPrice.6-month')}
                                    </MenuItem>
                                    {/* <MenuItem value="optimization-12m">
                                        {t('unitPrice.12-month')}
                                    </MenuItem> */}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.ante-returns')}
                        </Typography>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Box
                            sx={{ p: 2, border: '1px dashed grey' }}
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont, 'p-2')}
                            >
                                {format({ prefix: '', suffix: '%' })(
                                    xWinLib.roundTo(statistic.portReturns, 2)
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.volatility')}
                        </Typography>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont, 'p-2')}
                            >
                                {format({ prefix: '', suffix: '%' })(
                                    xWinLib.roundTo(statistic.portVol, 2)
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.sharpe-ratio')}
                        </Typography>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont, 'p-2')}
                            >
                                {format({ prefix: '', suffix: '' })(
                                    xWinLib.roundTo(statistic.portSR / 100, 2)
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <br />

                <Grid container spacing={3}>
                    <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {xWinUtils.displayToolTip(
                                `${t('unitPrice.strategyFee-explain', {
                                    strategyFee: xWinLib.roundTo(
                                        fundData.managerFee,
                                        2
                                    ),
                                    strategyFeePerDay: getStrategyFeePerDay(
                                        fundData.managerFee
                                    ),
                                })}`
                            )}
                            {t('unitPrice.strategy-fee')}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont, 'p-2')}
                            >
                                {format({ prefix: '', suffix: '%' })(
                                    xWinLib.roundTo(fundData.managerFee, 2)
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {xWinUtils.displayToolTip(
                                `${t('unitPrice.platformFee-explain', {
                                    platformFee: xWinLib.roundTo(
                                        fundData.platformFee,
                                        2
                                    ),
                                    platformFeePerDay: getPlatformFeePerDay(),
                                })}`
                            )}
                            {t('unitPrice.platform-fee')}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont, 'p-2')}
                            >
                                {format({ prefix: '', suffix: '%' })(
                                    xWinLib.roundTo(fundData.platformFee, 2)
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {xWinUtils.displayToolTip(
                                `${t(
                                    'unitPrice.performanceFee-bookcosst-explain'
                                )}`
                            )}
                            {t('unitPrice.performance-fee')}
                        </Typography>
                    </Grid>
                    {displayDiscount()}
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.manager-wallet')}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            {fundData.fundmanager && (
                                <Jazzicon
                                    diameter={20}
                                    seed={jsNumberForAddress(
                                        fundData.fundmanager
                                    )}
                                />
                            )}
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont, 'p-2')}
                            >
                                <a
                                    className={classes.link}
                                    href={getExplorerScan(
                                        fundData.fundmanager,
                                        'address',
                                        explorerScan
                                    )}
                                    target="blank"
                                >
                                    {xWinUtils.getAddressShort(
                                        fundData.fundmanager
                                    )}
                                </a>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </TabPanel>
        </Widget>
    )
}
