import React from 'react'
import xWinLib from '../../../xWinLib'
import format from 'format-number'
import xWinUtils from '../../../xWinUtils'
import clsx from 'clsx'

import {
    Box,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableContainer,
} from '@material-ui/core'

// styles
import useStyles from '../styles'
import Widget from '../../../components/Widget/Widget'
import { Typography, Avatar } from '../../../components/Wrappers/Wrappers'
import Dot from '../../../components/Sidebar/components/Dot'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import { useTranslation } from 'react-i18next'

export default function TradingSignal(props) {
    var classes = useStyles()
    const { t, i18n } = useTranslation('dashboard')
    const { signals } = props
    const displayTradingSignals = () => {
        if (signals?.length > 0) {
            return signals.map(s => (
                <TableRow hover>
                    <TableCell width={'30%'}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'left'}
                        >
                            <Dot
                                size="medium"
                                color={
                                    s.ordertype?.toLowerCase() === 'long'
                                        ? 'success'
                                        : 'secondary'
                                }
                            />
                            <Typography
                                variant={'caption'}
                                style={{ marginLeft: 8 }}
                            >
                                {xWinLib.FormatDate(s.datetime, 'lll')}
                            </Typography>
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'left'}
                        >
                            {s.ordertype?.toLowerCase() === 'long' ? (
                                <>
                                    {'Buy '}
                                    <TrendingUpIcon color="primary" />
                                </>
                            ) : (
                                <>
                                    {'Sell '}
                                    <TrendingDownIcon color="secondary" />
                                </>
                            )}
                        </Box>
                    </TableCell>
                    <TableCell width={'15%'}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'left'}
                        >
                            <Dot
                                size="medium"
                                color={
                                    s.ordertype?.toLowerCase() === 'long'
                                        ? 'success'
                                        : 'secondary'
                                }
                            />
                            <Typography
                                variant={'caption'}
                                style={{ marginLeft: 8 }}
                            >
                                {s.symbol}
                            </Typography>
                        </Box>
                    </TableCell>
                    <TableCell
                        className={clsx(
                            classes.contentText,
                            classes.columnHide
                        )}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'left'}
                        >
                            <Dot
                                size="medium"
                                color={
                                    s.ordertype?.toLowerCase() === 'long'
                                        ? 'success'
                                        : 'secondary'
                                }
                            />
                            <Typography
                                variant={'caption'}
                                style={{ marginLeft: 8 }}
                            >
                                {s.strategy}
                            </Typography>
                        </Box>
                    </TableCell>

                    <TableCell align="right">
                        {xWinUtils.displayBalance(
                            false,
                            format({ prefix: '', suffix: '' })(
                                xWinLib.roundTo(s.price, 2)
                            )
                        )}
                    </TableCell>
                </TableRow>
            ))
        } else {
            return (
                <TableRow hover>
                    <TableCell width={'100%'} colSpan="5">
                        {xWinUtils.getProgressLinear(true)}
                    </TableCell>
                </TableRow>
            )
        }
    }
    return (
        <Widget
            bodyClass={classes.mainChartBody}
            title={t('tradingSignals.title')}
            disableWidgetMenu
        >
            <TableContainer>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className={clsx(classes.contentText)}
                                width={'25%'}
                            >
                                {t('tradingSignals.date')}
                            </TableCell>
                            <TableCell
                                className={clsx(classes.contentText)}
                                width={'15%'}
                            >
                                {t('tradingSignals.buy-sell')}
                            </TableCell>
                            <TableCell
                                className={clsx(classes.contentText)}
                                width={'15%'}
                            >
                                {t('tradingSignals.symbol')}
                            </TableCell>
                            <TableCell
                                className={clsx(
                                    classes.contentText,
                                    classes.columnHide
                                )}
                                width={'25%'}
                            >
                                {t('tradingSignals.strategy')}
                            </TableCell>
                            <TableCell
                                className={clsx(classes.contentText)}
                                width={'20%'}
                            >
                                {t('tradingSignals.price')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {displayTradingSignals()}
                </Table>
            </TableContainer>
        </Widget>
    )
}
