import React, { forwardRef, useImperativeHandle  } from "react";
import xWinLib from '../../xWinLib';
// components
import { Typography } from "../../components/Wrappers/Wrappers";
import { Alert } from '@material-ui/lab';
import xWinUtils from '../../xWinUtils';

import {
  Slide,
  IconButton,
  Collapse
} from "@material-ui/core";
import useStyles from "./styles";
// import { IconButton, Button, Collapse } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";


function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

const MessageBar = forwardRef((props, ref) => {
  const { className } = props;
  const classes = useStyles();
  const [msgStatus, setMsgStatus] = React.useState("info");
  const [responseMsg, setResponseMsg] = React.useState("");
  const [open, setOpen] = React.useState(true);
	
  
  const handleOpen = (msgStatus, responseMsg) => {
    
    setMsgStatus(msgStatus)
    setResponseMsg(responseMsg)
    setOpen(true);
    // setPosition(_position)
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen,
      hideMessageTxn: hideMessageTxn
    };
  });

  const hideMessageTxn = () => {
    setOpen(false);
  };

  const getMessage = (responseMsg) => {

    return(
      <Typography color="text" colorBrightness={"hint"} className={classes.positiveNum}>
        {responseMsg}
      </Typography>
    )
  }
  
  return (

				<Alert
          variant="outlined"
          color={msgStatus}
        >
				{getMessage(responseMsg)}
				</Alert>
    );
});


export default MessageBar;
