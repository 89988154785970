import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    totalNumber: {
      fontSize: '1.842rem',
      fontWeight: 100,
      color: theme.palette.primary.dark,
    },
    refreshButton: {
      display: 'inline-flex',
      [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      padding: theme.spacing(2),
      display: 'flex !important',
    },
  },
  }
))