import React, { useState, useEffect, useContext } from 'react'
import StrategieslistV2 from './components/StrategieslistV2'
import { AppContext } from '../../context/AppProvider'
import useStyles from './styles'
import {
    Search as SearchIcon,
} from '@material-ui/icons'

import {
    Grid,
    Container,
    TextField as Input,
    InputAdornment,
    Box,
} from '@material-ui/core'  
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// components
import TradinglistV2 from '../tradingsV2/components/TradinglistV2'
import Widget from '../../components/Widget/Widget'
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/vaultFAQ'
import xWinUtils from '../../xWinUtils'
import { useTranslation } from 'react-i18next'
import { GetVenusData } from '../../utils/strategyV2Interactor'
import { useAccount } from 'wagmi/dist'

export default function StrategiesV2(props) {
    const [tabValue, setTabValue] = React.useState(0);
    const classes = useStyles()
    const { portfoliofundv2, portfolios, tokensMaster, xwinstrategies, fundtype } = props
    const [venusData, setVenusData] = useState([])
    const [filterPortfolios, setFilterPortfolios] = useState([])
    const [
        filterPortfoliosByCategory,
        setFilterPortfoliosByCategory,
    ] = useState([])
    const {
        ETHBalance,
        theWeb3,
        web3static,
        refreshAccount,
        appGlobalConfig
    } = useContext(AppContext)
    const [loading, setLoading] = React.useState(false)
    const [faqs, setFaqs] = useState([])
    const { t, i18n } = useTranslation('tradings')
    const { address, isConnecting, isDisconnected, isConnected } = useAccount()
    
    useEffect(() => {
        loadWeb3()
    }, [portfolios, fundtype, address])

    useEffect(() => {
        setTabValue(1)
    }, [appGlobalConfig])

    const loadWeb3 = async () => {
        
        if(appGlobalConfig === undefined) return
        setLoading(true)
        let unfilterPort = portfolios.concat(portfoliofundv2)
        let filterPort = [];

        if(fundtype === "v2trading"){
            filterPort = unfilterPort.filter(
                x =>x.category !== "partner" && x.category === "allocation" && x.active === true
            )

        } else {
            filterPort = unfilterPort.filter(
                x =>x.category === "partner" && x.active === true
            )
        }

        setFilterPortfolios(filterPort)
        setFilterPortfoliosByCategory(filterPort)

        if(appGlobalConfig?.chainId === 56){
            let venusDt = await GetVenusData()
            setVenusData(venusDt)
        }
        setLoading(false)
    }

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        
        let unfilterPort = portfolios.concat(portfoliofundv2)
        let filterPort = [];

        if(fundtype === "v2trading"){
            filterPort = unfilterPort.filter(
                x =>x.category !== "partner"
            )

        } else {
            filterPort = unfilterPort.filter(
                x =>x.category === "partner"
            )
        }

        let fundFilter;
        if (newValue !== 0){
            if (newValue === 1){
                fundFilter = "allocation"
            }
            else if (newValue === 2){
                fundFilter = "autotrading"
            }
            else if (newValue === 3){
                fundFilter = "sca"
            }
            else if (newValue === 4){
                fundFilter = "dca"
            }
            else if (newValue === 5){
                fundFilter = "rdca"
            }
            else if (newValue === 6){
                fundFilter = "singleasset"
            }
            else if (newValue === 7){
                fundFilter = "other"
            }
            let filterbyTabPort = [];
            filterbyTabPort = filterPort.filter(x =>x.category === fundFilter)
            setFilterPortfoliosByCategory(filterbyTabPort)
        }
        else {
            setFilterPortfoliosByCategory(filterPort)
        }
      };
    
    const displayTabs = () => {
        if(fundtype === "v2singlebase") return
        return <Tabs 
                textColor={"inherit"}
                value={tabValue} 
                onChange={handleChange} 
                aria-label="Filter Tab For Strategies"
                variant="scrollable"
                scrollButtons="auto"
            >
                <Tab label="All" {...a11yProps(0)} />
                <Tab label="Allocation" {...a11yProps(1)} />
                {TabDisable("Technical Analysis", 2)}
                <Tab label="SCA" {...a11yProps(3)} />
                <Tab label="DCA" {...a11yProps(4)} />
                <Tab label="RDCA" {...a11yProps(5)} />
                <Tab label="Lending" {...a11yProps(6)} />
                {TabDisable("Other", 7)}
            </Tabs>
    }


    const diplayVault = (funds, port) => {
        if (port === undefined) return
        if (port.fundtype !== 'v2index'){
            return <StrategieslistV2
                showBalWhenSmall={false}
                port={port}
                portfolios={funds}
                tokensMaster={tokensMaster}
                globalWeb3={theWeb3}
                web3static={web3static}
                selectedWallet={address}
                ETHBalance={ETHBalance}
                refreshAccount={refreshAccount}
                venusData={venusData}
                xwinstrategies={xwinstrategies}
            />
        }
        else {
            return <TradinglistV2
                showBalWhenSmall={false}
                port={port}
                portfolios={funds}
                tokensMaster={tokensMaster}
                globalWeb3={theWeb3}
                web3static={web3static}
                selectedWallet={address}
                fundtype={"v2index"}
                ETHBalance={ETHBalance}
                refreshAccount={refreshAccount}
        />
        }
    }
    const displayPortfolios = () => {
        if (loading) return ''
        if (filterPortfoliosByCategory === undefined) return ''

        let funds = filterPortfoliosByCategory

        if (funds.length > 0) {
            return funds.map(port =>
                <Grid
                    item
                    lg={6}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight}
                >
                    {diplayVault(funds, port)}
                </Grid>
            )}
        else {
            return (
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'center'}
                    >
                        <img
                            style={{ maxWidth: '250px' }}
                            alt={'no data'}
                            src={'/images/background/nodata.png'}
                        />
                    </Box>
                </Grid>
            )
        }
    }

    const getTitle = () => {
        let name = ''
        if (fundtype === 'v2index') name = `${t('v2index')}`
        else if (fundtype === 'v2trading') name = `${t('v2trading')}`
        else if (fundtype === 'v2tradingmanual')
            name = `${t('v2tradingmanual')}`
        else if (fundtype === 'v2single') name = `${t('v2single')}`
        else if (fundtype === 'v2singlebase') name = `${t('v2singlebase')}`
        else if (fundtype === 'v2private') name = `${t('v2private')}`
        else if (fundtype === 'v2allprivate') name = `${t('v2allprivate')}`

        return name
    }

    const handleOnchange = async event => {
        event.preventDefault()

        const value = event.target.value
        let filterport = filterPortfolios.filter(x =>
            x.symbol.toLowerCase().includes(value.toLowerCase())
        )

        if (filterport.length === 0) {
            filterport = filterPortfolios.filter(x =>
                x.name.toLowerCase().includes(value.toLowerCase())
            )
        }
        setTabValue(0)
        setFilterPortfoliosByCategory(filterport)
    }

    const displaySearchBar = () => {
        
        return (
            <Input
                placeholder={`${t('searchBar.symbol-vault')}`}
                id="search-field"
                fullWidth
                onChange={handleOnchange}
                className={classes.textField}
                margin="dense"
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon className={classes.searchIcon} />
                        </InputAdornment>
                    ),
                }}
            />
        )
    
    }

    const TabDisable = (Text, TabNumber) => {
        if (appGlobalConfig?.chainId === 42161 || appGlobalConfig?.chainId === 137) {
            return <Tab label={Text} {...a11yProps(TabNumber)} disabled />
        }
        else{
            return <Tab label={Text} {...a11yProps(TabNumber)}/>
        }
    }

    return (
        <Container>
            {xWinUtils.getProgress(loading)}
            <Grid container spacing={2}>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight2}
                >
                    <Widget
                        spacing={0}
                        className={classes.newsHeader}
                        title={getTitle()}
                        disableWidgetMenu
                        inheritHeight
                        enableWalletDisplay
                        selectedWallet={address}
                        globalWeb3={web3static}
                    >
                        {displaySearchBar()}
                    </Widget>
                </Grid>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight2}
                    style={{ justifyContent: 'center' }}
                >
                    {displayTabs()}
                </Grid>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight2}
                >
                    <Grid container spacing={2}>
                        {displayPortfolios()}
                    </Grid>
                </Grid>
            </Grid>
            <VaultFaq faqs={faqs} />
        </Container>
    )
}
