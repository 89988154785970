const masterchefFAQ = {
    faqs: [
        {
            symbol: 'masterchef',
            env: 56,
            question: 'masterchef.question-1',
            defaultExpanded: true,
            desc: 'masterchef.desc-1',
        },
        {
            symbol: 'masterchef',
            env: 56,
            question: 'masterchef.question-2',
            desc: 'masterchef.desc-2',
        },
        {
            symbol: 'masterchef',
            env: 56,
            question: 'masterchef.question-3',
            desc: 'masterchef.desc-3',
        },
        {
            symbol: 'masterchef',
            env: 56,
            question: 'masterchef.question-4',
            desc: 'masterchef.desc-4',
        },
        {
            symbol: 'masterchef',
            env: 56,
            question: 'masterchef.question-5',
            desc: 'masterchef.desc-5',
        },
    ],
}

export default masterchefFAQ
