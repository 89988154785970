export const systemConfigMainnet = {
  chainId: 1, 
  rpc : "https://ethereum-mainnet.core.chainstack.com/ca215da03f7ab200dcc61c4ad99a511b",
  blockExplorerUrl: "	https://etherscan.io",
};

export const systemConfig = {
  chainId: 56, 
  rpc : "https://bsc-dataseed.binance.org/",
  blockExplorerUrl: "https://bscscan.com",
};

export const systemConfigArb = {
  chainId: 42161, 
  rpc : "https://nd-031-626-743.p2pify.com/b71d43cfe2c6619230f4667c67e33373",
  blockExplorerUrl: "https://arbiscan.io/",
};

export const systemConfigPolygon = {
  chainId: 137, 
  rpc : "https://polygon-rpc.com/",
  blockExplorerUrl: "https://polygonscan.com/",
};

export const systemConfigLocal = {
  chainId: 31337, 
  rpc : "http://localhost:8545",
  blockExplorerUrl: "https://polygonscan.com/",
};
