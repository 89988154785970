import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTheme } from '@material-ui/styles'

export default function ApexPieChart(props) {
    var theme = useTheme()
    const { tokenNames, tokenWeight } = props

    return (
        <ReactApexChart
            options={themeOptions(theme, tokenNames)}
            series={tokenWeight}
            type="donut"
            height="350"
            stroke={''}
        />
    )
}
function themeOptions(theme, tokenNames) {
    return {
        labels: tokenNames,
        tooltip: {
            enabled: false,
        },
        chart: {
            tools: {
                download: false,
                selection: false,
            },
            foreColor: '#ccc',
            toolbar: {
                show: false,
            },
        },
        legend: {
            position: 'bottom',
            fontSize: '15px',
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            },
        },
    }
}
