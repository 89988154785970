import { ethers } from "ethers";
import xWinFund from "../abi/v2/fundV2.json";
import xWinStrategy from "../abi/v2/xWinStrategies.json";
import xWinAllocation from "../abi/v2/xWinAllocation.json";
import { convertToWei, convertFromWei, getLayer1BlockNumber } from "../utils/helper";
import appGlobalConfig from '../configs/56/appGlobalConfig';


export async function GetVenusData() {

    try {
      let output = await (await fetch(appGlobalConfig.apiGetVenusData)).json();
      return output;          
    } catch (error) {
      return
    }

}

export async function getWBETHAPR(){
  try {
    let output = await (await fetch(appGlobalConfig.apiGetWBETHAPR)).json();
    return output.data.apr;          
  } catch (error) {
    return
  }
}

export async function getTargetedWeight (web3, contractaddress, targetaddress) {
  const contract = new web3.eth.Contract(xWinFund, contractaddress);
  const promiseData =  contract.methods.TargetWeight(targetaddress).call()

  return promiseData
  
}


export async function getFundExtraInfo(web3, contractaddress, targetaddress) {
	const contract = new web3.eth.Contract(xWinFund, contractaddress);
	
  const promiseData = await Promise.all([
    contract.methods.TargetWeight(targetaddress).call(),
    contract.methods.getTokenValues(targetaddress).call(),
    contract.methods.getBalance(targetaddress).call(),
    contract.methods.decimals().call()
  ]);
  let targetweight = promiseData[0]
  let fundTokenValue = promiseData[1]
  let AllBal = promiseData[2]
  let decimals = promiseData[3]
  
  return {
    "targetweight": targetweight,
    "fundTokenValue": convertFromWei(fundTokenValue || 0, decimals),
    "AllBal": convertFromWei(AllBal || 0, decimals),
  };
}

export async function getUnderlyingFee(web3, contractaddress) {
	
  const contract = new web3.eth.Contract(xWinStrategy, contractaddress);
	let performanceFee = await contract.methods.performanceFee().call();
	let strategyFee = await contract.methods.managerFee().call();
	return {
    "performanceFee": performanceFee || 0,
    "strategyFee": strategyFee || 0
  };
}

export async function getTargetNamesAddress(web3, contractaddress) {
	
  const contract = new web3.eth.Contract(xWinAllocation, contractaddress);
	let targetNamesAddress = await contract.methods.getTargetNamesAddress().call();
	return targetNamesAddress
}

export async function getMyPorts(web3, portfolios, account) {
  
  let myPorts = []
  
  const promises = portfolios.map(async port => {
    
    const data = await getPerformanceData(web3, port.contractaddress, account);
    if(parseFloat(data.shares) > 0){
      const funddata = await GetFundDataAll(web3, port.contractaddress);
      return {
        ...port,
        ...funddata,
        "shares" : data.shares,
        "avgPrice" : data.avgPrice
      }
    }
  });
  let ports = await Promise.all(promises);
  ports.forEach(p => {
    if(p !== undefined){
      myPorts.push(p);
    }
  });
  return myPorts;

}

export async function getEstimateShares(web3, contractaddress, subAmount, theSigner) {
  
  const subsAmtInWei = convertToWei(subAmount);
  const contract = new ethers.Contract(
    contractaddress,
    xWinFund,
    theSigner
  )
  let estimateShares = await contract.callStatic.deposit(subsAmtInWei);
  return convertFromWei(estimateShares);
}

export async function getPerformanceData(web3, contractaddress, account, amount, theSigner) {
	
  const contractE = new ethers.Contract(
    contractaddress,
    xWinFund,
    theSigner
  )
  const outAmtInWei = convertToWei(amount);  
  let estimateReceived = await contractE.callStatic.withdraw(outAmtInWei);
	return {
    "estimateReceived" : convertFromWei(estimateReceived || 0),
  };
}

export async function GetStrategiesDataAll(web3, contractaddress, xwinstrategies) {
  const contract = new web3.eth.Contract(xWinStrategy, contractaddress);

  try {
    const promiseData = await Promise.all([
      contract.methods.GetStrategyData().call(),
      contract.methods.decimals().call(),
      contract.methods.collectionBlock().call(),
    ]);
    
    let currentBlock
    if(await web3.eth.getChainId() === 42161){
      currentBlock = await getLayer1BlockNumber();
    }else{
      currentBlock = await web3.eth.getBlockNumber();
    }
    let myFundData = promiseData[0]
    let decimals = promiseData[1]
    let collectionBlock = promiseData[2]
    
    const s = xwinstrategies.find(x=>x.contractaddress.toLowerCase() === contractaddress.toLowerCase())
    let fundmanager = myFundData.mAddr; //_managerAddr
    let totalSupply = convertFromWei(myFundData.totalUnitB4, decimals); //totalUnitB4
    let uniprice = convertFromWei(myFundData.unitprice, decimals); //unitprice
    let fundvalue = convertFromWei(myFundData.fundvalue, decimals); //fundvalue
    let fundvalueUSD = convertFromWei(myFundData.fundvalueUSD, decimals); //fundvalueUSD
    let fundName = s?.name; //fundName
    let symbol = s?.symbol; //symbolName
    let unipriceInUSD = convertFromWei(myFundData.unitpriceUSD, decimals);
    let baseToken = myFundData.baseCcy; //baseToken 
    let managerFee = myFundData.managementFee; //managementFee
    let performanceFee = myFundData.performFee; // perfomanceFee
    let highWaterMarkPrice = convertFromWei(myFundData.highWaterMarkPrice, s?.basedecimal || 18); //fundvalueUSD
    
    return {
      fundmanager,
      totalSupply,
      uniprice,
      fundvalue,
      fundvalueUSD,
      fundName,
      symbol,
      managerFee,
      performanceFee,
      unipriceInUSD,
      baseToken,
      highWaterMarkPrice,
      collectionBlock,
      currentBlock
    };
  } catch (error) {
    console.log(contractaddress, "contractaddress")
    console.log(error)
  }
  
}

export async function validInvestor(web3, xfundaddress, account){

  const contract = new web3.eth.Contract(xWinFund, xfundaddress);
  const promiseData = await Promise.all([
    contract.methods.validInvestors(account).call(),
    contract.methods.openForPublic().call(),
  ]);
  let isValid = promiseData[0]
  let openForPublic = promiseData[1]
  if(openForPublic) return true;
  if(!openForPublic && isValid) return true;
  return false;
}

export async function validCheckRebManager(web3, contractaddress, account) {
	
  const contract = new web3.eth.Contract(xWinAllocation, contractaddress);
	let valid = await contract.methods.executors(account).call();
	return valid
}

