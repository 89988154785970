import React, { useState, useEffect, useContext } from "react";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { Drawer, IconButton, List } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar
} from "../../context/LayoutContext";
import { AppContext } from "../../context/AppProvider";

function Sidebar({ location, structure }) {
  var classes = useStyles();
  var theme = useTheme();
  const [existingStructure, setExistingStructure] = useState(structure)
  const [newStructure, setNewStructure] = useState([])
  const toggleDrawer = value => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (value && !isPermanent) toggleSidebar(layoutDispatch);
  };

  const { appGlobalConfig } = useContext(AppContext)

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
    
  });

  useEffect(() => {
      init()
  }, [appGlobalConfig])

  const init = async () => {
       
    if(appGlobalConfig === undefined){
      setNewStructure(structure)
      setExistingStructure(structure)
      return
    }
    // sidebar.title.staking
    if(appGlobalConfig?.chainId === 42161){
      let newArr = existingStructure.filter(x=>x.label !== "sidebar.title.staking")  
      setNewStructure(newArr)
    }else if(appGlobalConfig?.chainId === 137){
      let newArr = existingStructure.filter(x=>x.label !== "sidebar.title.staking" && x.label !== "sidebar.title.public-vaults")  
      setNewStructure(newArr)    
    }else{
      setNewStructure(existingStructure)
    }
  }

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: !isPermanent ? !isSidebarOpened : isSidebarOpened,
        [classes.drawerClose]: !isPermanent ? isSidebarOpened : !isSidebarOpened
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: !isPermanent
            ? !isSidebarOpened
            : isSidebarOpened,
          [classes.drawerClose]: !isPermanent
            ? isSidebarOpened
            : !isSidebarOpened
        })
      }}
      open={!isPermanent ? !isSidebarOpened : isSidebarOpened}
      onClose={toggleDrawer(true)}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse)
            }}
          />
        </IconButton>
      </div>
      <List
        className={classes.sidebarList}
        classes={{ padding: classes.padding }}
      >
        {newStructure.map(link => (
          <SidebarLink
            defaultExpand={link.defaultExpand ? true: false}
            key={link.id}
            location={location}
            isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
            {...link}
            toggleDrawer={toggleDrawer(true)}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
