import React from 'react'
import ApexCharts from 'react-apexcharts'
import { useTheme } from '@material-ui/styles'

export default function ApexLineChart(props) {
    var theme = useTheme()
    const { mainData, period, returns } = props

    const getDateFormat = _date => {
        let year = _date.substring(0, 4)
        let month = _date.substring(4, 6)
        let day = _date.substring(6, 8)
        return new Date(year + '-' + month + '-' + day)
    }

    let dateArr = []
    let dataArr = []
    let finalUPData = []
    if (mainData !== undefined) {
        const date = new Date()
        date.setDate(date.getDate() - period)
        Object.keys(mainData).forEach(function(key) {
            if (key !== 'env') {
                const historydate = getDateFormat(key)
                if (historydate > date) {
                    dataArr.push(mainData[key])
                    dateArr.push(getDateFormat(key).toString('yyyy/mm/dd'))
                }
            }
        })
    }
    finalUPData.push({
        name: 'Unit Price',
        data: dataArr,
    })

    return (
        <div style={{}}>
            <ApexCharts
                options={themeOptions(theme, dateArr, returns)}
                series={finalUPData}
                type="area"
                height={200}
            />
        </div>
    )
}

// ############################################################
function themeOptions(theme, dateArr, returns) {
    return {
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            categories: dateArr,
            type: 'datetime',
            lines: {
                show: false,
            },
            labels: {
                show: false, // This will hide the x-axis labels
            },
            axisBorder: {
                show: false, // This will hide the X-axis line
            },
            axisTicks: {
                show: false, // This hides the X-axis tick marks
            },
        },
        yaxis: {
            lines: {
                show: false,
            },
            labels: {
                show: false, // This will hide the x-axis labels
            },
        },
        grid: {
            show: false, // This will hide the grid lines
        },
        crosshairs: {
            show: false,
        },
        tooltip: {
            theme: false,
            enabledOnSeries: undefined,
            x: {
                show: false,
                
            },
            y: {
                show: true,
                formatter: value => {
                    return Number(value).toFixed(5)
                },
            },
        },
        fill: {
            colors:
                returns >= 0
                    ? [theme.palette.success.light, theme.palette.success.light]
                    : [
                          theme.palette.secondary.light,
                          theme.palette.secondary.light,
                      ],
        },
        colors:
            returns >= 0
                ? [theme.palette.success.main, theme.palette.success.light]
                : [theme.palette.secondary.main, theme.palette.secondary.light],
        chart: {
            foreColor: '#ccc',
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: true,
        },
    }
}
