import xwinSplitFeeWalletAbi from "../abi/v2/xwinSplitFeeWallet.json"
import ERC20 from "../abi/BEP20.json"
import { getGasPrice, convertToWei, convertFromWei } from "../utils/helper";
import { getTokenInfo, getTokenNameSymbol } from "./erc20Interactor";
import { ethers } from "ethers";
import { getUnitPriceInUSD } from "./fundV2Interactor";
import { getPrice } from "./xWinPriceMasterInteractor";
import appGlobalConfig from "../configs/56/appGlobalConfig";

// function distributeFees() external returns (bool) {
//   uint periodToUse = (block.number - startBlock) / period;
//   if (periodToUse > managers[0].ratio.length - 1) {
//       periodToUse = managers[0].ratio.length - 1;
//   }

//   for (uint i = 0; i < tokens.length; i++) {
//       uint tokenBalance = IERC20Upgradeable(tokens[i]).balanceOf(
//           address(this)
//       );
//       for (uint j = 0; j < managers.length; j++) {
//           IERC20Upgradeable(tokens[i]).safeTransfer(
//               managers[j].managerAddress,
//               (tokenBalance * managers[j].ratio[periodToUse]) / 10000
//           );
//       }
//       emitEvent.feeTransfered(tokens[i], tokenBalance, address(this));
//   }

//   return true;
// }

export async function getManagerRatio(web3, address) {
  
  const currentBlock = await web3.eth.getBlockNumber();
  const contract = new web3.eth.Contract(xwinSplitFeeWalletAbi, address);
  const promiseData = await Promise.all([
    contract.methods.startBlock().call(),
    contract.methods.period().call(),
    contract.methods.managerAll().call(),
  ]);
  let startBlock = promiseData[0]
  let period = promiseData[1]
  let managerAll = promiseData[2]
  const periodToUse = Math.floor((currentBlock - startBlock) / period);
  return{
    periodToUse,
    managerAll
  }
}


export async function getSplitWalletTokens(web3, address, appGlobalConfig, tokensv2) {
  
  
  try {
    const contract = new web3.eth.Contract(xwinSplitFeeWalletAbi, address);
    const promiseData = await Promise.all([
      contract.methods.tokenLength().call(),
    ]);
    let tokenLen = promiseData[0]
  
    let arr = []
    for(let i=0; i<tokenLen; i++){
      const tokenAddr = await contract.methods.tokens(i).call()
      const data = await getTokenNameSymbol(web3, tokenAddr)
      const balData = await getTokenInfo(web3, tokenAddr, address)
      let foundToken = tokensv2.find(x=>x.address.toLowerCase() === tokenAddr.toLowerCase())
      let unitPriceUSD = 0
      if(foundToken){
        unitPriceUSD = await getPrice(web3, tokenAddr, appGlobalConfig.mainnetUSDT)
        unitPriceUSD = convertFromWei(unitPriceUSD)
      }else{
        const data = await getUnitPriceInUSD(web3, tokenAddr)
        unitPriceUSD = data.unitPriceInUSD
      }    
      
      arr.push({
        address : tokenAddr,
        balance : Number(balData.targetBal),
        unitPriceUSD : Number(unitPriceUSD),
        data
      })
    }
    return arr
  } catch (error) {
    return []
  }
  
}

export async function DistributeFeeAsync(signer, splitAddr, account) {
  
  return new Promise((resolve, reject) => {    
    const contract = new ethers.Contract(splitAddr, xwinSplitFeeWalletAbi, signer);
    const gasParam = getGasPrice(signer);
    
    contract.distributeFees({
      from: account,
      value: 0,
      ...gasParam
    })
    .then(txn => {
      txn.wait().then(receipt => {
        resolve(receipt.transactionHash);
      })
    })
    .catch(err => {
      reject(err.message);
    })
  
    
  });
}


