import React, { forwardRef, useContext, useImperativeHandle  } from "react";
import xWinLib from '../../xWinLib';
// components
import { Typography } from "../../components/Wrappers/Wrappers";
import { Alert } from '@material-ui/lab';
import xWinUtils from '../../xWinUtils';
import { AppContext } from '../../context/AppProvider'

import {
  Slide,
  Snackbar,
} from "@material-ui/core";
import useStyles from "./styles";
import { getExplorerScan } from "../../utils/helper";

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

const SnackbarMessage = forwardRef((props, ref) => {
  const { className } = props;
  const classes = useStyles();
  const { appGlobalConfig, explorerScan } = useContext(AppContext)

  const [msgStatus, setMsgStatus] = React.useState("success");
  const [responseMsg, setResponseMsg] = React.useState("");
  const [openMsgTxn, setOpenMsgTxn] = React.useState("");
  const [position, setPosition] = React.useState("bottom");
  
  
  const handleOpen = (msgStatus, responseMsg, _position = "bottom") => {
    
    setMsgStatus(msgStatus)
    setResponseMsg(responseMsg)
    setOpenMsgTxn(true);
    setPosition(_position)
  }

  useImperativeHandle(ref, () => {
    return {
      handleOpen: handleOpen,
      hideMessageTxn: hideMessageTxn
    };
  });

  const hideMessageTxn = () => {
    setOpenMsgTxn(false);
  };

  const getMessage = (msgStatus, responseMsg) => {

    if(msgStatus==="error"){
      return(
        <Typography variant={'body2'} className={classes.positiveNum}>
          {responseMsg}
        </Typography>
      )
    }else if(msgStatus==="success") {
      return (
        <Typography variant={'body2'} className={classes.positiveNum}>
            Transaction is Done! Check in Explorer {<a className={classes.link} href={getExplorerScan(responseMsg, "tx", explorerScan)} target="blank">{xWinUtils.getAddressShort(responseMsg)}</a>} 
        </Typography>
      )
    }else if(msgStatus==="info") {
      return (
        <Typography variant={'body2'} className={classes.positiveNum}>
            {responseMsg}
        </Typography>
      )
    }
    return ""
  }
  
  return (
    <Snackbar 
        open={openMsgTxn} 
        anchorOrigin={{
          vertical: position,
          horizontal: 'center',
        }}
        TransitionComponent={TransitionRight}
        autoHideDuration={12000} 
        onClose={() => setOpenMsgTxn(false)}
        >
        <Alert 
          onClose={hideMessageTxn} severity={msgStatus}>
            <span id="message-id2">
                    {msgStatus.toUpperCase()}
                <Typography
                  size="md"
                  weight={"medium"}
                  style={{ marginRight: 8 }}
                >
                  {getMessage(msgStatus, responseMsg)}
                </Typography>
              </span>
        </Alert>            
      </Snackbar>
    );
});


export default SnackbarMessage;
