import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    expansion: {
        backgroundColor: theme.palette.primary.light,
        color: 'white ',
    },
    newsHeader: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: '#536DFE',
    },
    subTitle: {
        color: '#ffc247',
    },
    avatar: {
        height: 50,
        width: 50,
    },
    columnHide: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    notificationItem: {
        marginTop: theme.spacing(2),
    },
    snackbarWarning: {
        backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
        backgroundColor: theme.palette.success.main,
        color: 'black',
    },
    positiveNum: {
        color: theme.palette.success.dark,
        fontSize: '1.042rem',
        [theme.breakpoints.down('xs')]: {
          fontSize: '0.75rem',
        }    
    },
    negativeNum: {
        color: theme.palette.secondary.dark,
        fontSize: '1.042rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.75rem',
        }
    },
    contentText: {
        fontSize: '1.042rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.75rem',
        }
      },
}))