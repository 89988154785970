import appGlobalConfig from '../configs/56/appGlobalConfig';
// import httpService from './httpService';


export async function GetTokenPrices(contractaddress, appGlobalConfig) {
  if(appGlobalConfig === undefined) return [];  
  let api = appGlobalConfig.apiGetTokenPrices + "?contractaddress=" +contractaddress
  let output = await (await fetch(api)).json();
  return output.message;
}

export async function GetVaultInfo(appGlobalConfig) {
  if(appGlobalConfig === undefined) return [];  
  let api = appGlobalConfig.apiGetVaultInfo
  let output = await (await fetch(api)).json();
  return output;
}

export async function GetTATradingSignal() {

  let api = appGlobalConfig.apiGetTATradingSignal
  let output = await (await fetch(api)).json();
  return output.message;

}

export async function GetPerformanceCompare(targetAddr, bmAddr, appGlobalConfig) {

  if(appGlobalConfig === undefined) return [];  
  let api = appGlobalConfig.apiGetPerformanceCompare+ "?contractaddress=" +targetAddr + "&bmaddress=" + bmAddr
  let output = await (await fetch(api)).json();
  return output.message;

}


export async function GetWinStats() {

  let api = appGlobalConfig.apiGetWinStats
  let output = await (await fetch(api)).json();
  return output;

}

export async function GetTVLv1() {

  let api = appGlobalConfig.apiGetTVL
  let output = await (await fetch(api)).json();
  return output;

}

export async function GetTVLv2(appGlobalConfig) {

  if(appGlobalConfig === undefined) return
  let api = appGlobalConfig.apiGetTVLv2
  let output = await (await fetch(api)).json();
  return output;

}

export async function GetHistoryTVL(appGlobalConfig){
  if(appGlobalConfig === undefined) return
  let api = appGlobalConfig.apiGetHistoryTVL
  let output = await (await fetch(api)).json();
  return output;
}

export async function GetRecommendedVaults (appGlobalConfig){
  if(appGlobalConfig === undefined) return
  let api = appGlobalConfig.apiGetRecommendedVault
  let output = await (await fetch(api)).json();
  return output;
  
}

export async function GetTotalVault(appGlobalConfig){
  if(appGlobalConfig === undefined) return
  let api = appGlobalConfig.apiGetTotalVault
  let output = await (await fetch(api)).json();
  return output;
}


export async function GetTVLArb(appGlobalConfig) {

  if(appGlobalConfig === undefined) return
  let api = appGlobalConfig.apiGetArbTVLPlus
  let output = await (await fetch(api)).json();
  return output;

}

export async function GetAllTvl(appGlobalConfig) {

  if(appGlobalConfig === undefined) return
  let api = appGlobalConfig.apiGetTotalLockValue
  let output = await (await fetch(api)).json();
  return output;

}

export async function GetRecommendedVault(appGlobalConfig) {

  if(appGlobalConfig === undefined) return
  let api = appGlobalConfig.apiGetRecommendedVault
  let output = await (await fetch(api)).json();
  return output;

}

export async function GetTVLPolygon(appGlobalConfig) {

  if(appGlobalConfig === undefined) return
  let api = appGlobalConfig.apiGetPolygonTVLPlus
  let output = await (await fetch(api)).json();
  return output;

}

export async function GetMarketTrends(month) {
  try {
    let api = appGlobalConfig.apiGetMarketTrends + "?month="+month
    let output = await (await fetch(api)).json();
    return output.message;
  } catch (error) {
    console.log(error)
    return []    
  }
}

export async function GetSharpeRatio(model, month, appGlobalConfig) {
  try {
    if(appGlobalConfig === undefined) return [];
    let api = appGlobalConfig.apiGetSharpeRatio + "?month="+month+"&model="+model
    let output = await (await fetch(api)).json();
    return output.message;
  } catch (error) {
    console.log(error)
    return []    
  }
}

export async function GetVaultTotalValueByUser(useraddress, appGlobalConfig) {
  try {
    let api = appGlobalConfig.apiGetVaultTotalValueByUser + "?useraddress="+useraddress
    let output = await (await fetch(api)).json();
    return output;
  } catch (error) {
    console.log(error)
    return []    
  }
}

export async function GetStrategyTotalValueByUser(useraddress, appGlobalConfig) {
  try {
    let api = appGlobalConfig.apiGetStrategyTotalValueByUser + "?useraddress="+useraddress
    let output = await (await fetch(api)).json();
    return output;
  } catch (error) {
    console.log(error)
    return []    
  }
}

export async function GetBuddyFarmTotalValueByUser(useraddress) {
  try {
    let api = appGlobalConfig.apiGetBuddyFarmTotalValueByUser + "?useraddress="+useraddress
    let output = await (await fetch(api)).json();
    return output;
  } catch (error) {
    console.log(error)
    return []    
  }
}

export async function GetMasterFarmTotalValueByUser(useraddress) {
  try {
    let api = appGlobalConfig.apiGetMasterFarmTotalValueByUser + "?useraddress="+useraddress
    let output = await (await fetch(api)).json();
    return output;
  } catch (error) {
    console.log(error)
    return []    
  }
}

export async function GetStrategyEstimatedOutput(useraddress, contractaddress, shares, appGlobalConfig) {

  try {
    let api = appGlobalConfig.apiGetStrategyEstimateOut + "?useraddress="+useraddress+ "&contractaddress="+contractaddress+ "&shares="+shares
    let output = await (await fetch(api)).json();
    return output;
      
  } catch (error) {
    console.log(error)
    return []    
  }

}

export async function GetFundV2EstimatedOutput(useraddress, contractaddress, shares, appGlobalConfig) {

  try {
    let api = appGlobalConfig.apiGetFundv2EstimateOut + "?useraddress="+useraddress+ "&contractaddress="+contractaddress+ "&shares="+shares
    let output = await (await fetch(api)).json();
    return output;
  } catch (error) {
    console.log(error)
    return []    
  }

}

export async function GetUserPnL(useraddress, appGlobalConfig) {

  try {
    let api = appGlobalConfig.apiGetUserPnL + "?useraddr="+useraddress
    let output = await (await fetch(api)).json();
    return output;
  } catch (error) {
    console.log(error)
    return []    
  }
}

export async function GetFundv2Details(contractaddress, useraddress) {

  try {
    let api = appGlobalConfig.apiGetFundv2Details + "?useraddress="+useraddress+"&contractaddress="+contractaddress
    let output = await (await fetch(api)).json();
    return output;
  } catch (error) {
    console.log(error)
    return []    
}
}

export async function GetStrategyDetails(contractaddress, useraddress) {

  try {
    let api = appGlobalConfig.apiGetStrategyDetails + "?useraddress="+useraddress+"&contractaddress="+contractaddress
    let output = await (await fetch(api)).json();
    return output;
  } catch (error) {
    console.log(error)
    return []    
  }

}

export async function GetConfigV2() {

  try {
    let output = await (await fetch(appGlobalConfig.apiGetConfigV2)).json();
    return output;
  } catch (error) {
    console.log(error)
    return []    
  }

}

export async function signinUser(body, appGlobalConfig) {
  
  try {
    let authEdpoint = appGlobalConfig.apiGetAccessToken
    const response = await fetch(authEdpoint, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    const result = await response.json();
    return {
      "accessToken": result.accessToken,
      "status": response.status == 200
    };
  } catch (error) {
    console.log(error)
    return {
      "accessToken": "",
      "status": false
    };
  }
  
}

export async function registerRefferal(accessToken, appGlobalConfig, body) {
  
  let authEdpoint = appGlobalConfig.apiRegisterReferral
  const response = await fetch(authEdpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        "access-token": accessToken
      }
    });
  const result = await response.json();
  
  if(response.status === 200){
    return {
      "message": result.message,
      "status": true
    }
  }
  return {
    "message": result.error,
    "status": false
  } 

}

export async function alreadyRegisterReferral(wallet, appGlobalConfig) {
  
  let body = {"WalletAddress": wallet}
  let authEdpoint = appGlobalConfig.apiGetSplitFeeAddress
  const response = await fetch(authEdpoint, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    });
  const result = await response.json();
  return result.SplitFeeAddress;
}
