import React, { useContext, useEffect, useRef, useState } from 'react'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
// import appGlobalConfig from '../../configs/56/appGlobalConfig';
import StrategyDetails from './components/StrategyDetails'
import SingleAsset from './components/SingleAsset'
import AutoTradingStrategy from './components/AutoTradingStrategy'
import { AppContext } from '../../context/AppProvider'
import { useTranslation } from 'react-i18next'
import { Box, Container, Grid, Select, MenuItem, Typography } from '@material-ui/core'
// styles
import useStyles from './styles'

// components
import Widget from '../../components/Widget/Widget'
import AllocationStrategy from './components/AllocationStrategy'
import SingleAssetAAVE from './components/SingleAssetAAVE';

const Strategies = props => {
    const { tokensMaster, relatedStrategyData, xwinstrategies, myFundData } = props
    const classes = useStyles()
    const [contractChoice, setContractChoice] = React.useState(
        relatedStrategyData[0]?.address || appGlobalConfig?.mainnetAddressZero
    )
    const [strategyTypes, setStrategyTypes] = React.useState([])
    const { t, i18n } = useTranslation('fundDetails')

    const {
        web3static,
        appGlobalConfig
    } = useContext(AppContext)

    const refsnackbar = useRef(null)

    useEffect(() => {
        init()
    }, [relatedStrategyData])

    const init = async () => {
        if (web3static === undefined) return
        const options = getOptions(relatedStrategyData)
        setStrategyTypes(options)
    }

    const handleChoice = event => {
        setContractChoice(event.target.value)
    }

    const displayStrategies = () => {
        if (relatedStrategyData == undefined) return
        const choice = contractChoice === appGlobalConfig?.mainnetAddressZero ? relatedStrategyData[0]?.address || contractChoice : contractChoice
        if (choice === undefined) return
        let strategy = xwinstrategies.find(
            x => x.contractaddress.toLowerCase() === choice.toLowerCase()
        )
        if (strategy === undefined) return
        if (strategy.template.toLowerCase() === 'allocation') {
            return (
                <AllocationStrategy
                    contractaddress={contractChoice}
                    strategy={strategy}
                    tokensMaster={tokensMaster}
                    myFundData={myFundData}
                    xwinstrategies={xwinstrategies}
                />
            )
        }else if (strategy.template.toLowerCase() === 'autotrading') {
            return (
                <AutoTradingStrategy
                    contractaddress={contractChoice}
                    strategy={strategy}
                    tokensMaster={tokensMaster}
                />
            )
        }else if (
            strategy.template.toLowerCase() === 'dca' ||
            strategy.template?.toLowerCase() === 'sca'
        ) {
            return (
                <StrategyDetails
                    strategy={strategy}
                    tokensMaster={tokensMaster}
                    myFundData={myFundData}
                />
            )
        } else if (strategy.template.toLowerCase() === 'single') {
            return (
                <SingleAsset
                    singleaddress={contractChoice}
                    underlyingaddress={strategy.underlying}
                    tokensMaster={tokensMaster}
                    xwinstrategies={xwinstrategies}
                />
            )
        } else if (strategy.template.toLowerCase() === "singleassetaave") {
            return (
                <SingleAssetAAVE
                    singleaddress={contractChoice}
                    underlyingaddress={strategy.underlying}
                    tokensMaster={tokensMaster}
                    xwinstrategies={xwinstrategies}
                />
            )
        }
    }

    const getOptions = (relatedStrategyData) => {
        const types = []
        relatedStrategyData.forEach(s => {
            types.push({
                name: s.name,
                address: s.address
            })
        });
        return types
    }

    return (
        <>
            <SnackbarMessage ref={refsnackbar} />
            <Widget
                className={classes.widgetLayout}
                title=""
                disableWidgetMenu
            >
                <Grid container spacing={3}>
                    <Grid item lg={12} sm={12} xs={12}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="left"
                        >
                            <Typography className={classes.displayFont}>
                                {t('strategies.select-strategy')}: &nbsp;&nbsp;
                            </Typography> 
                            <Select
                                classes={{ root: classes.selectPadding }}
                                id="contractChoice"
                                value={contractChoice}
                                onChange={handleChoice}
                                label="contractChoice"
                            >
                                {strategyTypes?.map(type => {
                                    return (
                                        <MenuItem value={type.address}>
                                            <Typography className={classes.displayFont}>{type.name}</Typography>
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </Box>
                        <br />
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="left"
                        >
                            {displayStrategies()}
                        </Box>
                    </Grid>
                </Grid>
            </Widget>
        </>
    )
}

export default Strategies
