import React, { useState, useEffect, useRef, useContext } from 'react'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import format from 'format-number'
import 'react-toastify/dist/ReactToastify.css'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import {
    CreateFundAsync,
    getCreationCost,
    IsWhiteListed,
    CreateFundWithBaseCcyAsync,
} from '../../utils/fundV2FactoryInteractor'
import { Typography, Button } from '../../components/Wrappers/Wrappers'
import CancelIcon from '@material-ui/icons/Cancel'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { canRebalance, getEstimateAsync, RebalanceAsync } from '../../utils/fundV2Interactor'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import Autocomplete from '@material-ui/lab/Autocomplete'
import EditIcon from '@material-ui/icons/Edit'
import TextRotationNoneTwoToneIcon from '@material-ui/icons/TextRotationNoneTwoTone'
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'

import {
    TableRow,
    Table,
    TableCell,
    TableBody,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    ButtonGroup,
    TextField,
    MenuItem,
    IconButton,
    InputAdornment,
    Select,
    TableHead,
    Box,
    Grid,
} from '@material-ui/core'
// components
import useStyles from './styles'
import { AppContext } from '../../context/AppProvider'
import { useTheme } from '@material-ui/styles'
import {
    approveContract,
    getTokenInfo,
    IsTokenApproved,
} from '../../utils/erc20Interactor'
import { getPrivateFunds } from '../../utils/moralisInteractor'
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/createFundFAQ'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi/dist'
import { alreadyRegisterReferral } from '../../utils/firestoreDataInteractor'

const CreatePortV2 = props => {
    const {
        openModal,
        allocatedports,
        tokensMaster,
        returns,
        proxyAssets,
        reset,
    } = props

    var theme = useTheme()
    const classes = useStyles()
    const [loading, setLoading] = React.useState(false)
    const [positions, setPositions] = useState([])
    const [newPort, setNewPort] = React.useState('new')
    const [newPortname, setNewPortname] = React.useState('')
    const [newPortSymbol, setNewPortSymbol] = React.useState('')
    const [selectedPortAddress, setselectedPortAddress] = React.useState('')
    const [selectedPortName, setselectedPortName] = React.useState('')
    const [isApproval, setIsApproval] = useState(false)
    const [privateVaults, setPrivateVaults] = React.useState([])
    const [alreadyRegister, setAlreadyRegister] = React.useState(false)
    const [referralCode, setReferralCode] = React.useState('')
    const [originalport, setOriginalport] = React.useState(allocatedports)
    const [errMsg, setErrmsg] = React.useState('')
    const [faqs, setFaqs] = useState([])
    const [whiteListed, setWhiteListed] = React.useState(false)
    const [creationCost, setCreationCost] = React.useState(0)
    const [creationTokenBal, setCreationTokenBal] = React.useState(0)
    const { t, i18n } = useTranslation('roboAdvisor')
    const [slippage, setSlippage] = React.useState(1)
    const [selectedBaseToken, setBaseToken] = React.useState()
    const [baseTokenList, setBaseTokenList] = React.useState([])
    const [statistic, setStatistic] = useState({
        portReturns: 0,
        portVol: 0,
        portSR: 0,
    })

    const [ticker, setTicker] = useState({
        symbol: '',
        weight: 100,
    })

    const { theSigner, web3static, networkName, appGlobalConfig } = useContext(
        AppContext
    )
    const { address } = useAccount()
    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    useEffect(() => {
        initCheckReferral()
    }, [address])

    const initCheckReferral = async () => {
        if (appGlobalConfig == undefined) return
        if (address == undefined) return
        const output = await alreadyRegisterReferral(address, appGlobalConfig)
        const already = output !== ''
        setAlreadyRegister(already)
        setReferralCode(output?.ReferralCode || '')
    }

    useEffect(() => {
        init()
    }, [allocatedports, address, appGlobalConfig])

    const init = async () => {
        if (allocatedports === undefined) return
        if (appGlobalConfig === undefined) return
        if (address === undefined) return

        const cost = await getCreationCost(web3static, appGlobalConfig)
        const tokenInfo = await getTokenInfo(
            web3static,
            appGlobalConfig?.mainnetPrivateVaultPayIn,
            address
        )
        setCreationTokenBal(tokenInfo.targetBal)
        setCreationCost(cost)
        setOriginalport(allocatedports)
        let p = setupPosition()
        setPositions(p)
        // calcStatistic(p)
        let filterport = await getPrivateFunds(address, appGlobalConfig)
        setPrivateVaults(filterport)
        setselectedPortAddress(filterport[0]?.contractaddress)
        setselectedPortName(filterport[0]?.name)
        let baseTokens = baseTokenOptions()
        setBaseTokenList(baseTokens)
        setBaseToken(baseTokens[0])
        if (address !== undefined) {
            if (Number(cost) === 0) {
                setIsApproval(false)
            } else {
                const isApp = await IsTokenApproved(
                    web3static,
                    cost,
                    address,
                    appGlobalConfig.mainnetPrivateVaultPayIn,
                    appGlobalConfig.mainnetFundFactory
                )
                setIsApproval(isApp)
                let isWL = await IsWhiteListed(
                    web3static,
                    address,
                    appGlobalConfig
                )
                setWhiteListed(isWL)
            }
        }
        let fqs = vaultfaqconfig.faqs.filter(
            x => x.env === appGlobalConfig.chainId
        )
        setFaqs(fqs)

        let lastWeight = getLastPositionWeight(p)
        setTicker({
            ...ticker,
            weight: lastWeight,
        })
    }

    const calcStatistic = positions => {
        let totalReturns = 0
        let totalVol = 0
        let totalSR = 0

        positions.forEach(p => {
            let r = returns.find(
                x => x.asset.toLowerCase() === p.taddress.toLowerCase()
            )
            if (r === undefined) {
                let proxy = proxyAssets.find(
                    x =>
                        x.contractaddress.toLowerCase() ===
                        p.taddress.toLowerCase()
                )
                if (proxy !== undefined) {
                    r = returns.find(
                        x =>
                            x.asset.toLowerCase() ===
                            proxy.underlying.toLowerCase()
                    )
                }
            }
            // console.log(r)
            if (r) {
                totalReturns += p.weight * r.return
                totalVol += p.weight * r.volatility
                totalSR += (p.weight * r.sharpeRatio) / 100
            }
        })

        let portReturns = Math.pow(1 + totalReturns / 100, 12) - 1
        let portVol = (totalVol / 100) * Math.sqrt(12)

        setStatistic({
            portReturns: portReturns * 100,
            portVol: portVol * 100,
            portSR: (portReturns / portVol) * 100,
        })
    }

    const setupPosition = () => {
        let p = []
        let sum = 0
        let count = 0
        allocatedports.forEach(port => {
            let wgt = xWinLib.roundTo(port.ratio, 2)

            if (count == allocatedports.length - 1) {
                let remain = xWinLib.roundTo(100 - sum, 2)
                wgt = Number(remain)
            }
            p.push({
                symbol: port.name,
                weight: wgt,
                taddress: port.contractaddress,
            })
            sum = sum + Number(wgt)
            count++
        })
        return p
    }

    const getLastPositionWeight = pos => {
        let lastWeight = 0
        let total = 0
        pos.forEach(p => {
            total = total + parseFloat(p.weight)
        })
        let currentTotal = xWinLib.roundTo(total, 3)
        if (currentTotal < 100) {
            lastWeight = xWinLib.roundTo(100 - Number(currentTotal), 2)
        }
        return lastWeight
    }

    const alignPosition = () => {
        let currentTotal = getTotalWeight()
        if (currentTotal !== 100) {
            let lastitem = positions.slice(-1).pop()
            let lastWeight = xWinLib.roundTo(
                100 - Number(currentTotal) + Number(lastitem.weight),
                2
            )
            const filteredItems = positions.filter(
                t => t.symbol !== lastitem.symbol
            )
            filteredItems.push({
                symbol: lastitem.symbol,
                weight: lastWeight,
                taddress: lastitem.taddress,
            })
            setPositions(filteredItems)
        }
    }

    const handleClickApprove = async event => {
        event.preventDefault()

        setLoading(true)
        approveContract(
            theSigner,
            appGlobalConfig.mainnetPrivateVaultPayIn,
            appGlobalConfig.mainnetFundFactory,
            address
        )
            .then(res => {
                setLoading(false)
                setIsApproval(true)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                setIsApproval(false)
            })
    }

    const handleClickOriginal = async event => {
        event.preventDefault()
        let p = setupPosition()
        setPositions(p)
    }

    const handleClickAlign = async event => {
        event.preventDefault()

        alignPosition()
    }

    const getTotalWeight = () => {
        let total = 0
        positions.forEach(p => {
            total = total + parseFloat(p.weight)
        })
        return xWinLib.roundTo(total, 3)
    }

    const handleNameChange = () => event => {
        event.preventDefault()
        setNewPortname(event.target.value)
    }

    const handleSymbolChange = () => event => {
        event.preventDefault()
        setNewPortSymbol(event.target.value)
    }

    const handleCodeChange = () => event => {
        event.preventDefault()
        setReferralCode(event.target.value)
    }

    const handleSelectedPortChange = event => {
        setselectedPortAddress(event.target.value)
        setselectedPortName(getSelectedFundName(event.target.value))
    }

    const handleSlippageChange = () => async event => {
        if (event.target.value === '') return

        const val = parseFloat(event.target.value)
        if (val >= 0 && val !== undefined) {
            setSlippage(val)
        } else {
            setSlippage(1)
        }
    }

    const handleBaseTokenChange = () => async event => {
        if (event.target.value === '') return
        if (event.target.value === undefined) return
        if (event.target.value === 0) return
        setBaseToken(event.target.value)
    }

    const baseTokenOptions = () => {
        if (appGlobalConfig?.env === 'arb') {
            return ['USDC']
        } else if (appGlobalConfig?.env === 'v2bscmain') {
            return ['USDT']
        } else if (appGlobalConfig?.env === 'polygon') {
            return ['JPYC','USDC']
        }
        return ['USDT']
    }

    const getSetTarget = () => {
        return (
            <DialogContent>
                <Grid item lg={12} sm={12} xs={12}>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'center'}
                    >
                        <ToggleButtonGroup
                            color="primary"
                            value={newPort}
                            exclusive
                            size="small"
                            onChange={handleNewPort}
                            aria-label="NewPort"
                        >
                            <ToggleButton
                                value="new"
                                style={{
                                    outlineColor: theme.palette.primary.main,
                                    outlineWidth: '1px',
                                    outlineStyle: 'solid',
                                    margin: '2px',
                                }}
                            >
                                {t('createPortV2.setTarget.new')}
                            </ToggleButton>
                            <ToggleButton
                                value="update"
                                style={{
                                    outlineColor: theme.palette.primary.main,
                                    outlineWidth: '1px',
                                    outlineStyle: 'solid',
                                    margin: '2px',
                                }}
                            >
                                {t('createPortV2.setTarget.rebalance')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </Grid>
                <br />
                {newPort === 'new' && (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            lg={3}
                            sm={12}
                            xs={12}
                            className={classes.hoverHighlight2}
                        >
                            <div className="mb-1">
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                    noWrap
                                >
                                    {t('createPortV2.setTarget.portfolio-name')}
                                </Typography>
                            </div>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'left'}
                                marginTop={'8px'}
                            >
                                <TextField
                                    placeholder={t(
                                        'createPortV2.textfield-label-1'
                                    )}
                                    size="small"
                                    margin="dense"
                                    name="portname"
                                    onChange={handleNameChange()}
                                    required
                                    // variant="outlined"
                                    value={newPortname}
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={12}
                            xs={12}
                            className={classes.hoverHighlight2}
                        >
                            <div className="mb-1">
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                    noWrap
                                >
                                    {t('createPortV2.setTarget.symbol')}
                                </Typography>
                            </div>
                            <Box
                                marginTop={'8px'}
                                display="flex"
                                alignItems="center"
                                justifyContent={'left'}
                            >
                                <TextField
                                    size="small"
                                    margin="dense"
                                    name="portsymbol"
                                    placeholder={t(
                                        'createPortV2.textfield-label-2'
                                    )}
                                    onChange={handleSymbolChange()}
                                    required
                                    // variant="outlined"
                                    value={newPortSymbol}
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={12}
                            xs={12}
                            className={classes.hoverHighlight2}
                        >
                            <div className="mb-1">
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                    noWrap
                                >
                                    {t('createPortV2.setTarget.baseToken')}
                                </Typography>
                            </div>
                            <Box
                                marginTop={'13px'}
                                display="flex"
                                alignItems="center"
                                justifyContent={'left'}
                            >
                                <Select
                                    fullWidth
                                    value={selectedBaseToken}
                                    onClick={handleBaseTokenChange()}
                                >
                                    {baseTokenList.map(p => (
                                        <MenuItem value={p}>{p}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            sm={12}
                            xs={12}
                            className={classes.hoverHighlight2}
                        >
                            <div className="mb-1">
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                    noWrap
                                >
                                    {t('createPortV2.setTarget.referralCode')}
                                    &nbsp;
                                    {xWinUtils.displayToolTip(
                                        `${t(
                                            'createPortV2.setTarget.referralCode-tips'
                                        )}`
                                    )}
                                </Typography>
                            </div>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'left'}
                            >
                                <TextField
                                    disabled={alreadyRegister}
                                    size="small"
                                    margin="dense"
                                    name="referralCode"
                                    placeholder={t(
                                        'createPortV2.textfield-enter-referralCode'
                                    )}
                                    onChange={handleCodeChange()}
                                    value={referralCode}
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}
                {newPort === 'update' && (
                    <Grid container spacing={2}>
                        <Grid
                            item
                            lg={6}
                            sm={6}
                            xs={6}
                            className={classes.hoverHighlight2}
                        >
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                                noWrap
                            >
                                {t(
                                    'createPortV2.setTarget.exisiting-portfolio'
                                )}
                            </Typography>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'left'}
                            >
                                <Select
                                    label={t(
                                        'createPortV2.setTarget.exisiting-portfolio'
                                    )}
                                    fullWidth
                                    labelId="demo-simple-select-label"
                                    id="selectedPortAddress"
                                    value={selectedPortAddress}
                                    // label="selectedPortAddress"
                                    onChange={handleSelectedPortChange}
                                >
                                    {privateVaults.map(p => (
                                        <MenuItem value={p.contractaddress}>
                                            {p.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            sm={6}
                            xs={6}
                            className={classes.hoverHighlight2}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'left'}
                            >
                                <TextField
                                    label="Slippage"
                                    type="number"
                                    margin="dense"
                                    name="slippage"
                                    onChange={handleSlippageChange()}
                                    required
                                    // variant="outlined"
                                    value={slippage}
                                    fullWidth
                                    InputProps={{
                                        classes: {
                                            input: classes.positiveNum,
                                        },
                                        style: { textAlign: 'right' },
                                        step: 0.1,
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <Typography
                                                    className={
                                                        classes.displayFont
                                                    }
                                                >
                                                    %
                                                </Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                )}
                <br />
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight2}
                >
                    <Table size="small">
                        <TableBody>
                            {positions.map((p, i) => (
                                <TableRow hover>
                                    <TableCell
                                        className={clsx(
                                            classes.displayFont,
                                            classes.columnHide
                                        )}
                                        width="20%"
                                    >
                                        {xWinUtils.getIcons(p.symbol)}
                                    </TableCell>
                                    <TableCell
                                        className={classes.displayFont}
                                        width="20%"
                                    >
                                        {p.symbol}
                                    </TableCell>
                                    <TableCell
                                        className={classes.displayFont}
                                        width="20%"
                                        align="right"
                                    >
                                        <Typography
                                            color="primary"
                                            className={classes.displayFont}
                                        >
                                            {format({
                                                prefix: '',
                                                suffix: '%',
                                            })(p.weight)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        className={classes.displayFont}
                                        width="40%"
                                    >
                                        <ButtonGroup
                                            size="small"
                                            color="secondary"
                                            aria-label="small outlined primary button group"
                                        >
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="small"
                                                // className={classes.customButton}
                                                // startIcon={<DeleteIcon />}
                                                onClick={handleClickDelete(
                                                    p.symbol
                                                )}
                                            >
                                                <Typography
                                                    className={
                                                        classes.displayFont
                                                    }
                                                >
                                                    Delete
                                                </Typography>
                                            </Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            ))}

                            <TableRow className={classes.tableRow}>
                                <TableCell
                                    width="20%"
                                    className={clsx(
                                        classes.displayFont,
                                        classes.columnHide
                                    )}
                                >
                                    {xWinUtils.getIcons(ticker.symbol)}
                                </TableCell>
                                <TableCell width="20%">
                                    <Autocomplete
                                        id="symbol"
                                        style={{ width: '100%' }}
                                        name="symbol"
                                        className={classes.displayFont}
                                        defaultValue={xWinLib.getTokenName(
                                            tokensMaster,
                                            appGlobalConfig?.mainnetBTC
                                        )}
                                        options={xWinUtils.getSupportedSymbols(
                                            tokensMaster,
                                            networkName,
                                            privateVaults[0]
                                        )}
                                        getOptionLabel={option => option.symbol}
                                        onChange={(event, newValue) => {
                                            setTicker({
                                                // ...ticker,
                                                weight: getLastPositionWeight(
                                                    positions
                                                ),
                                                symbol:
                                                    newValue == null
                                                        ? ''
                                                        : newValue.symbol,
                                            })
                                        }}
                                        renderInput={params => (
                                            <Typography
                                                className={classes.displayFont}
                                            >
                                                <TextField
                                                    {...params}
                                                    label={t(
                                                        'createPortV2.setTarget.token'
                                                    )}
                                                    margin="dense"
                                                    // variant="outlined"
                                                    size="small"
                                                    className={
                                                        classes.displayFont
                                                    }
                                                />
                                            </Typography>
                                        )}
                                    />
                                </TableCell>
                                <TableCell width="40%" align="right">
                                    <TextField
                                        type="number"
                                        size="small"
                                        margin="dense"
                                        name="weight"
                                        value={ticker?.weight}
                                        onChange={handleChange('weight')}
                                        required
                                        fullWidth
                                        // variant="outlined"
                                        InputProps={{
                                            style: { textAlign: 'right' },
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    %
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </TableCell>
                                <TableCell width="20%">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        // className={classes.customButton}
                                        // startIcon={<AddCircleIcon />}
                                        onClick={handleClickAdd}
                                    >
                                        <Typography
                                            className={classes.displayFont}
                                        >
                                            Add
                                        </Typography>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <VaultFaq faqs={faqs} />
            </DialogContent>
        )
    }

    const handleNewPort = (event, newP) => {
        if (newP !== null) {
            setNewPort(newP)
        }
    }

    const handleClickCreateFund = async event => {
        event.preventDefault()

        let totalweight = getTotalWeight()
        if (parseFloat(totalweight) !== 100) {
            showSnackBar('error', `${t('createPortV2.error-2')}`)
            return
        }
        if (newPortSymbol.trim() === '' || newPortname.trim() === '' || selectedBaseToken.trim() === ''){
            showSnackBar('error', `${t('createPortV2.error-symbol')}`)
            return
        }

        const stopCont = await validateContinueWithoutReferral()
        if (!stopCont) {
            props.history.push('/app/referral')
            return
        }

        setLoading(true)
        await createPrivateFund()
            .then(res => {
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
    }

    const createPrivateFund = async () => {
        if (appGlobalConfig.env === 'arb' || appGlobalConfig.env === 'v2bscmain') {
            CreateFundAsync(
                theSigner,
                newPortname,
                newPortSymbol,
                address,
                positions,
                appGlobalConfig
            )
        } else {
            const tokenFound = tokensMaster.find(x=>x.name.toLowerCase() === selectedBaseToken.toLowerCase())
            CreateFundWithBaseCcyAsync(
                theSigner,
                newPortname,
                newPortSymbol,
                address,
                positions,
                appGlobalConfig,
                tokenFound.address
            )
        }
    }

    const validateContinueWithoutReferral = async () => {
        
        if (!alreadyRegister && referralCode.trim() === '') {
            const msg = t('createPortV2.confirm-no-referral')
            return window.confirm(msg) === true
        }
        return true
    }

    const handleClickRebalance = async event => {
        event.preventDefault()

        let totalweight = getTotalWeight()
        if (parseFloat(totalweight) !== 100) {
            showSnackBar('error', `${t('createPortV2.error-2')}`)
            return
        }

        if (!canRebalance(web3static, selectedPortAddress)) {
            showSnackBar('error', `${t('createPortV2.cannot-rebalance')}`)
            return
        }

        const outputEst = await getEstimateAsync(theSigner, selectedPortAddress, positions, slippage)
        if(!outputEst.status){
            showSnackBar('error', outputEst.message)
            setLoading(false)
            return;
        }

        setLoading(true)
        RebalanceAsync(theSigner, selectedPortAddress, address, positions, slippage, outputEst.estimation)
            .then(res => {
                setLoading(false)
                showSnackBar('success', res)
                // props.parentCallback(true);
            })
            .catch(err => {
                setLoading(false)
                showSnackBar('error', err)
                // props.parentCallback(false);
            })
    }

    const getSelectedFundName = address => {
        let selected = privateVaults.find(x => x.contractaddress === address)
        return selected?.name || ''
    }

    const handleClickDelete = symbol => event => {
        event.preventDefault()
        const filteredItems = positions.filter(t => t.symbol !== symbol)
        setPositions(filteredItems)
        let lastWeight = getLastPositionWeight(filteredItems)
        setTicker({
            ...ticker,
            weight: lastWeight,
        })
        // calcStatistic(filteredItems)
    }

    const handleClickAdd = event => {
        event.preventDefault()

        if (ticker.symbol === '') return
        if (Number(ticker.weight) === 0 || ticker.weight == '') return

        let result = positions.filter(t => t.symbol === ticker.symbol)
        if (result.length > 0) {
            return
        }
        setErrmsg('')
        let existingWgt = getTotalWeight()
        let totalweight = parseFloat(existingWgt) + parseFloat(ticker.weight)
        if (totalweight > 100) {
            setErrmsg(`${t('createPortV2.error')}`)
            return
        }

        let p = []
        positions.forEach(element => {
            p.push({
                symbol: element.symbol,
                weight: element.weight,
                taddress: element.taddress,
            })
        })

        let tokenaddress = xWinLib.getTokenAddress(tokensMaster, ticker.symbol)

        p.push({
            symbol: ticker.symbol,
            weight: ticker.weight,
            taddress: tokenaddress,
        })
        setPositions(p)
        let lastWeight = getLastPositionWeight(p)
        setTicker({
            ...ticker,
            weight: lastWeight,
        })
        // calcStatistic(p)
    }

    const handleChange = name => event => {
        setTicker({
            ...ticker,
            weight: event.target.value,
        })
    }

    return (
        <Dialog
            open={openModal}
            aria-labelledby="form-dialog-title"
            // fullWidth={true}
            fullScreen={true}
            maxWidth={'lg'}
        >
            <SnackbarMessage ref={refsnackbar} />
            <CardHeader
                className={classes.expansion}
                action={
                    <IconButton
                        className={classes.title}
                        onClick={reset}
                        aria-label="settings"
                    >
                        <CancelIcon />
                    </IconButton>
                }
                title={t('createPortV2.title')}
            />
            {xWinUtils.getProgress(loading)}

            {getSetTarget()}

            <DialogActions className={classes.boxAmount}>
                <Typography className={classes.negativeNum}>
                    {errMsg}
                </Typography>
                <ButtonGroup
                    size="small"
                    color="primary"
                    aria-label="small outlined primary button group"
                >
                    <Button
                        // className={classes.customButton}
                        onClick={handleClickOriginal}
                        color="primary"
                        variant="outlined"
                        startIcon={<EditIcon />}
                    >
                        <Typography className={classes.displayFont}>
                            {t('createPortV2.reset')}
                        </Typography>
                    </Button>
                    <Button
                        onClick={handleClickAlign}
                        color="primary"
                        variant="outlined"
                        startIcon={<EditIcon />}
                    >
                        <Typography className={classes.displayFont}>
                            {t('createPortV2.align')}
                        </Typography>
                    </Button>
                    {isApproval ? (
                        <Button
                            onClick={handleClickApprove}
                            color="secondary"
                            variant="contained"
                            startIcon={<TextRotationNoneTwoToneIcon />}
                        >
                            <Typography className={classes.displayFont}>
                                {t('createPortV2.approve')}
                            </Typography>
                        </Button>
                    ) : (
                        <Button
                            onClick={
                                newPort === 'new'
                                    ? handleClickCreateFund
                                    : handleClickRebalance
                            }
                            color="primary"
                            variant="contained"
                            startIcon={<AddCircleIcon />}
                        >
                            {newPort === 'new'
                                ? `${t('createPortV2.build')}`
                                : `${t('createPortV2.rebalance')}`}
                        </Button>
                    )}
                </ButtonGroup>
            </DialogActions>
            {newPort === 'new' && (
                <DialogActions>
                    <Box
                        display="flex"
                        alignItems="left"
                        justifyContent={'left'}
                    >
                        <Typography
                            color="text"
                            variant={'small'}
                            // style={{ marginRight: 5 }}
                            className={classes.subTitle}
                            noWrap
                        >
                            {!whiteListed ? (
                                <>
                                    {`${t('createPortV2.create-new-fund', {
                                        xwinToken: creationCost,
                                        paidToken: xWinLib.getTokenName(
                                            tokensMaster,
                                            appGlobalConfig?.mainnetPrivateVaultPayIn
                                        ),
                                    })}`}
                                </>
                            ) : (
                                <>{t('createPortV2.congratulations')}</>
                            )}
                        </Typography>
                    </Box>
                </DialogActions>
            )}
            {newPort === 'update' && (
                <DialogActions>
                    <Box
                        display="flex"
                        alignItems="left"
                        justifyContent={'left'}
                    >
                        <Typography
                            color="text"
                            variant={'small'}
                            className={classes.subTitle}
                            noWrap
                        >
                            {/* {'You will overite ' +
                                selectedPortName +
                                ' weight and rebalance the fund'} */}
                            {`${t('createPortV2.text-1', {
                                selectedPortname: selectedPortName,
                            })}`}
                        </Typography>
                    </Box>
                </DialogActions>
            )}
        </Dialog>
    )
}
export default withRouter(CreatePortV2)
// export default CreatePortV2
