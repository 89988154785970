import useStyles from './styles'
import React from 'react'
import {
    AppBar,
    Toolbar,
    IconButton,
    Tooltip,
    Button,
    Typography,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
    Divider,
    Select,
    FormControl,
    InputLabel,
} from '@material-ui/core'
import { Collapse, Alert } from '@mui/material'
import xWinUtils from '../../xWinUtils'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import { useAlertBannerDispatch } from '../../context/AlertBannerContext'
import { useTranslation } from 'react-i18next'





export default function Banners() {
    const classes = useStyles()
    const [open, setOpen] = React.useState(true)
    const isDark = localStorage.getItem('theme')
    const alertBannerDispatch = useAlertBannerDispatch()
    const { t, i18n } = useTranslation('dashboard')

    const toggleAlert = () => {
        alertBannerDispatch(false)
        setOpen(false)
    }

    return (
        <>
            {/* <Collapse in={open}> */}
            <Collapse in={open}>
                <Alert
                    onClose={toggleAlert}
                    icon={false}
                    sx={{
                        backgroundColor:
                            isDark === 'dark' ? '#1fc7d4' : '#D5DBFF',
                        position: 'relative',
                        height: '3rem',
                    }}
                    // className={classes.main}
                    // className={classes.main}
                >
                    <div className={classes.flex}>
                        <div className={classes.logo}>
                            {xWinUtils.getIcons('xwin')}
                        </div>
                        <div
                            className={
                                isDark === 'dark'
                                    ? classes.message
                                    : classes.messageLight
                            }
                        >
                            <ReportProblemIcon
                                sx={{ color: 'red', marginLeft: 2 }}
                            />
                            <div
                                style={{
                                    marginLeft: 15,
                                    fontWeight: 'bold',
                                    color: '#ffff00',
                                    opacity: 1,
                                    textShadow:
                                        '1px 1px 1px #e60000,-1px 1px 1px #e60000,-1px -1px 0 #e60000,1px -1px 0 #e60000',
                                }}
                            >
                                {t('banner.warning-title')}:
                            </div>
                            <div className={classes.message2}>
                                {t('banner.warning-desc')}
                            </div>
                        </div>
                    </div>
                </Alert>
            </Collapse>
        </>
    )
}
