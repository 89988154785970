import React, { useState, useEffect, useContext, useRef } from 'react'
import {
    Grid,
    Container,
    Box,
    TextField,
    Slider,
    Typography,
    Button,
    InputAdornment,
    Chip,
    IconButton,
    ExpansionPanelActions,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core'
import Dot from '../../components/Sidebar/components/Dot'
import format from 'format-number'
import { AppContext } from '../../context/AppProvider'
import Widget from '../../components/Widget'
import useStyles from './styles'
import { Link, Avatar } from '../../components/Wrappers/Wrappers'
// import appGlobalConfig from '../../configs/56/appGlobalConfig';
import xWinUtils from '../../xWinUtils'
import {
    DepositLockStake,
    GetAPRInfo,
    GetBonusWeight,
    GetEstimatedDepositAPY,
    GetEstimatePerformanceDiscount,
    GetPoolLockStakeInfo,
    GetUserLockStakeInfo,
    WithdrawLockStake,
} from '../../utils/autoLockInteractor'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { approveContract, getTokenInfo, IsTokenApproved } from '../../utils/erc20Interactor'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import xWinLib from '../../xWinLib'
import moment from 'moment'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import clsx from 'clsx'
import { getMasterChefStakingAPR } from '../../utils/masterChefInteractor'
import millify from 'millify'
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/autoLockFAQ'
import ReactInterval from 'react-interval'
import { getAllPrices } from '../../utils/xWinPriceMasterInteractor'
import TextRotationNoneTwoToneIcon from '@material-ui/icons/TextRotationNoneTwoTone'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi/dist'
import { useNetwork } from 'wagmi'
import { withRouter } from 'react-router-dom'

const autoLockStake = props => {
    const { showNewPosition } = props
    const { chain} = useNetwork()
    const {
        XWINBalance,
        theSigner,
        web3static,
        refreshAccount,
        appGlobalConfig
    } = useContext(AppContext)
    const { address, isConnecting, isDisconnected, isConnected } = useAccount()
    var classes = useStyles()
    const [isApproval, setIsApproval] = useState(false)
    const [loading, setLoading] = React.useState(false)
    const [duration, setDuration] = React.useState(26)
    const [bonusWeight, setBonusWeight] = React.useState(1)
    const [performanceFeeDiscount, setPerformanceFeeDiscount] = React.useState(
        0
    )
    const [newEndDate, setNewEndDate] = React.useState(Date.now())
    const [depositAmount, setDepositAmount] = React.useState()
    const [myBal, setMyBal] = React.useState()
    const refsnackbar = useRef(null)
    const { t, i18n } = useTranslation('autoLockStake')
    const [userInfo, setUserInfo] = React.useState({
        discountPerformance: '0',
        amount: 0,
        startTimestamp: '',
        endPeriodTimestamp: '',
        rewards: 0,
    })
    const [poolInfo, setPoolInfo] = React.useState({
        bonusAPR: '0',
        mainAPR: '0',
        totalShares: 0,
        totalLockedShares: 0,
    })
    const [faqs, setFaqs] = useState([])
    const [prices, setPrices] = useState([])
    
    useEffect(() => {
        loadData()
    }, [address, appGlobalConfig])

    const loadData = async () => {
        
        if(appGlobalConfig === undefined) return

        if(appGlobalConfig.chainId !== 56){
          return  props.history.push('/app/dashboard')
        }

        let newEndDate = xWinLib.getEndDate(duration)
        setNewEndDate(newEndDate)
        let fqs = vaultfaqconfig.faqs.filter(x=>x.env === appGlobalConfig.chainId);
        setFaqs(fqs)

        setLoading(true)
        let allprices = await getAllPrices(web3static)
        setPrices(allprices)

        let bonus = GetBonusWeight(duration)
        let pool = await GetPoolLockStakeInfo(web3static)
        const mainapr = await getMasterChefStakingAPR(web3static, 0)
        let annRate = xWinLib.APRToAPYNoFormat(mainapr / 100)
        setBonusWeight(bonus)

        let aprs = await GetEstimatedDepositAPY(
            web3static,
            duration,
            depositAmount
        )
        setPoolInfo({
            bonusAPR: aprs.estimatedBonusApr,
            mainAPR: annRate,
            totalShares: pool.totalShares,
            totalLockedShares: pool.totalLockedShares,
        })
        if (address !== undefined) {
            // refreshAccount()
            const info = await getTokenInfo(web3static, appGlobalConfig.mainnetXWIN, address)
            const isApp = await IsTokenApproved(
                web3static,
                info.targetBal,
                address,
                appGlobalConfig.mainnetXWIN,
                appGlobalConfig.mainnetLockStake
            )
            setIsApproval(isApp)
            setDepositAmount(info.targetBal)    
            setMyBal(info.targetBal)    
            await loadUserData()

            let feeDisc = GetEstimatePerformanceDiscount(
                duration,
                info.targetBal
            )
            setPerformanceFeeDiscount(feeDisc)
        } else {
            setUserInfo({
                discountPerformance: 0,
                amount: 0,
                startTimestamp: '',
                endPeriodTimestamp: '',
                rewards: 0,
            })
        }
        setLoading(false)
    }

    const loadUserData = async () => {
        if (address !== undefined) {
            let user = await GetUserLockStakeInfo(web3static, address)
            setUserInfo(user)
        }
    }

    const valuetext = value => {
        return `${value}`
    }

    const handleChange = async (event, newValue) => {
        setDuration(newValue)
        let bonus = GetBonusWeight(newValue)
        setBonusWeight(bonus)
        let feeDisc = GetEstimatePerformanceDiscount(newValue, depositAmount)
        setPerformanceFeeDiscount(feeDisc)
        let newEndDate = xWinLib.getEndDate(newValue * 7)
        setNewEndDate(newEndDate)
        let aprs = await GetEstimatedDepositAPY(
            web3static,
            newValue,
            depositAmount
        )
        setPoolInfo({
            ...poolInfo,
            bonusAPR: aprs.estimatedBonusApr,
        })
    }

    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    const handleClickWithdraw = async () => {
        setLoading(true)
        WithdrawLockStake(theSigner, address)
            .then(res => {
                console.log(res)
                setLoading(false)
                showSnackBar('success', res)
                loadData()
                // refreshAccount
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                showSnackBar('error', err)
                // parentCallback(false);
            })
    }

    const validDuration = () => {
        if (userInfo.amount == 0) return true

        let newEndDate = xWinLib.getEndDate(duration * 7)
        let currentEndDate = new Date(
            xWinLib.FormatUnixDate(userInfo?.endPeriodTimestamp)
        )
        return currentEndDate <= newEndDate
    }

    const handleClickStake = async () => {
        if (!validDuration()) {
            showSnackBar('error', `${t('error.duration')}`)
            return
        }
        if (depositAmount <= 0) {
            showSnackBar('error', `${t('error.amount')}`)
            return
        }
        setLoading(true)

        DepositLockStake(theSigner, address, depositAmount, duration)
            .then(res => {
                console.log(res)
                setLoading(false)
                showSnackBar('success', res)
                loadData()
                // refreshAccount
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                showSnackBar('error', err)
                // parentCallback(false);
            })
    }

    const handleClickApprove = async event => {
        event.preventDefault()

        setLoading(true)
        approveContract(
            theSigner,
            appGlobalConfig.mainnetXWIN,
            appGlobalConfig.mainnetLockStake,
            address
        )
            .then(res => {
                setLoading(false)
                setIsApproval(true)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                setIsApproval(false)
            })
    }

    const handleAmtDepositChange = async event => {
        event.preventDefault()
        const amount = parseFloat(event.target.value)
        if (amount >= 0 && amount !== undefined && amount !== '') {
            setDepositAmount(parseFloat(amount))
            let feeDisc = GetEstimatePerformanceDiscount(duration, amount)
            setPerformanceFeeDiscount(feeDisc)
            let aprs = await GetEstimatedDepositAPY(
                web3static,
                duration,
                amount
            )
            setPoolInfo({
                ...poolInfo,
                bonusAPR: aprs.estimatedBonusApr,
            })
        } else {
            setDepositAmount('')
        }
    }

    const handleMaxDepositAmt = async event => {
        setDepositAmount(myBal)
        let feeDisc = GetEstimatePerformanceDiscount(duration, depositAmount)
        setPerformanceFeeDiscount(feeDisc)
        let aprs = await GetEstimatedDepositAPY(
            web3static,
            duration,
            depositAmount
        )
        setPoolInfo({
            ...poolInfo,
            bonusAPR: aprs.estimatedBonusApr,
        })
    }

    const IsAlreadyLocked = () => {
        return userInfo?.amount > 0
    }

    const canWithdrawExtend = () => {
        let today = Date.now()
        let end = xWinLib.FormatUnixDate(userInfo?.endPeriodTimestamp)
        return today / 1000 >= userInfo?.endPeriodTimestamp
    }

    const displayMyPosition = () => {
        if (IsAlreadyLocked()) {
            return (
                <Accordion defaultExpanded={true} rounded={true}>
                    <Typography
                        size="md"
                        weight={'medium'}
                        style={{ marginLeft: 8 }}
                        className={classes.newsHeader}
                    >
                        {t('displayMyPosition.title')}
                    </Typography>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{ expanded: classes.expanded }}
                    >
                        <div className={clsx(classes.column1)}>
                            {xWinUtils.getIcons('xwin')}
                        </div>
                        <div className={clsx(classes.column5, classes.helper)}>
                            <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('displayMyPosition.perf-fee')}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    className={classes.subTitle}
                                >
                                    {format({ prefix: '', suffix: '%' })(
                                        xWinLib.roundTo(
                                            userInfo?.discountPerformance,
                                            2
                                        )
                                    )}
                                </Typography>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('auto-apy')}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    className={classes.subTitle}
                                >
                                    {format({ prefix: ' ', suffix: '%' })(
                                        millify(poolInfo.mainAPR || 0)
                                    )}
                                </Typography>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('bonus-apr')}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    className={classes.subTitle}
                                >
                                    {format({ prefix: ' ', suffix: '%' })(
                                        millify(userInfo?.userBonusAPR || 0, {
                                            precision: 2,
                                            lowercase: true,
                                        })
                                    )}
                                </Typography>
                            </Grid>
                        </div>
                        <div className={clsx(classes.column3, classes.helper)}>
                            <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('unlocked-date')}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    className={classes.subTitle}
                                >
                                    {xWinLib.FormatUnixDate(
                                        userInfo?.endPeriodTimestamp
                                    )}
                                </Typography>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('displayMyPosition.duration')}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    className={classes.subSuccess}
                                >
                                    {format({
                                        prefix: '',
                                        suffix: `${t(
                                            'displayMyPosition.week'
                                        )}`,
                                    })(userInfo?.duration)}
                                </Typography>
                            </Grid>
                        </div>
                        <div className={clsx(classes.column5, classes.helper)}>
                            <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('displayMyPosition.current-position')}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    className={classes.subTitle}
                                >
                                    {format({ prefix: '', suffix: '' })(
                                        xWinLib.roundTo(
                                            userInfo?.currentxwinAmount,
                                            2
                                        )
                                    )}
                                </Typography>
                                <Box
                                    display="flex"
                                    alignItems="right"
                                    justifyContent={'right'}
                                >
                                    <Typography
                                        // color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                    >
                                        {format({ prefix: ' ~$', suffix: ' ' })(
                                            xWinLib.roundTo(
                                                userInfo?.currentxwinAmount *
                                                    prices.xwinPriceInUSD,
                                                1
                                            )
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('displayMyPosition.original-staked')}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    className={classes.subTitle}
                                >
                                    {format({ prefix: '', suffix: '' })(
                                        xWinLib.roundTo(userInfo?.amount, 2)
                                    )}
                                </Typography>
                                <Box
                                    display="flex"
                                    alignItems="right"
                                    justifyContent={'right'}
                                >
                                    <Typography
                                        // color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                    >
                                        {format({ prefix: ' ~$', suffix: ' ' })(
                                            xWinLib.roundTo(
                                                userInfo?.amount *
                                                    prices.xwinPriceInUSD,
                                                1
                                            )
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('displayMyPosition.bonus')}
                                </Typography>
                                <Typography
                                    variant={'body2'}
                                    className={classes.subTitle}
                                >
                                    {format({ prefix: '', suffix: '' })(
                                        xWinLib.roundTo(userInfo?.rewards, 2)
                                    )}
                                </Typography>
                                <Box
                                    display="flex"
                                    alignItems="right"
                                    justifyContent={'right'}
                                >
                                    <Typography
                                        // color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                    >
                                        {format({ prefix: ' ~$', suffix: ' ' })(
                                            xWinLib.roundTo(
                                                userInfo?.rewards *
                                                    prices.xwinPriceInUSD,
                                                1
                                            )
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                        </div>
                    </AccordionSummary>
                    <ExpansionPanelActions>
                        <Button
                            onClick={handleClickWithdraw}
                            color="secondary"
                            variant="contained"
                            fullWidth
                            disabled={!canWithdrawExtend()}
                            startIcon={<AddCircleIcon />}
                        ><Typography className={classes.displayFont}>
                            {t('button.withdraw')}
                            </Typography>
                        </Button>
                    </ExpansionPanelActions>
                </Accordion>
            )
        }
    }

    const displayDepositButton = () => {
        if (!isApproval) {
            return (
                <Button
                    onClick={handleClickApprove}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    startIcon={<TextRotationNoneTwoToneIcon />}
                >
                    <Typography className={classes.displayFont}>
                    {t('button.approve')}
                    </Typography>
                </Button>
            )
        } else {
            return (
                <Button
                    onClick={handleClickStake}
                    color="primary"
                    variant="contained"
                    fullWidth
                    startIcon={<AddCircleIcon />}
                >
                    <Typography className={classes.displayFont}>
                    {IsAlreadyLocked()
                        ? `${t('button.extend')}`
                        : `${t('button.stake-lock')}`}
                        </Typography>
                </Button>
            )
        }
    }

    return (
        <Container>
            {xWinUtils.getProgress(loading)}
            <SnackbarMessage ref={refsnackbar} />
            <ReactInterval
                timeout={30000}
                enabled={true}
                callback={() => loadUserData()}
            />
            <Grid container spacing={3}>
                <Grid item lg={9} sm={12} xs={12}>
                    {displayMyPosition()}
                    {showNewPosition && (
                        <Widget
                            spacing={0}
                            className={classes.newsHeader}
                            // title={getTitle()}
                            disableWidgetMenu
                            inheritHeight
                            enableWalletDisplay
                            selectedWallet={address}
                            globalWeb3={web3static}
                            // className={classes.widgetLayout}
                            title={t('title')}
                            // disableWidgetMenu
                            // enableWalletDisplay
                        >
                            <Grid container spacing={3}>
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    alignItems="left"
                                >
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        value={depositAmount}
                                        fullWidth
                                        onChange={handleAmtDepositChange}
                                        InputProps={{
                                            classes: {
                                                input: classes.positiveNum,
                                            },
                                            style: { textAlign: 'right' },
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {xWinUtils.getIcons('xwin')}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle max"
                                                        onClick={
                                                            handleMaxDepositAmt
                                                        }
                                                    >
                                                        <Typography
                                                            color={'primary'}
                                                            variant={'caption'}
                                                        >
                                                            {t('max')}
                                                        </Typography>
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        className={classes.contentText}
                                        style={{ marginRight: 5 }}
                                        noWrap
                                    >
                                        {t('duration')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    alignItems="left"
                                >
                                    {xWinUtils.displayToolTip(
                                        `${t('tooltip-1')}`
                                    )}
                                    <Slider
                                        aria-label="Duration"
                                        defaultValue={26}
                                        valueLabelDisplay="on"
                                        getAriaValueText={valuetext}
                                        value={duration}
                                        step={1}
                                        marks
                                        min={1}
                                        max={52}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    xs={6}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="text"
                                        className={classes.contentText}
                                        // size={'sm'} variant={'body1'}
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                        noWrap
                                    >
                                        {xWinUtils.displayToolTip(
                                            `${t('tooltip-2')}`
                                        )}
                                        {t('auto-apy')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    xs={6}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="primary"
                                        className={classes.contentText}
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                        noWrap
                                    >
                                        {format({ prefix: ' ', suffix: '%' })(
                                            millify(poolInfo.mainAPR || 0, {
                                                precision: 2,
                                                lowercase: true,
                                            })
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    xs={6}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="text"
                                        className={classes.contentText}
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                        noWrap
                                    >
                                        {xWinUtils.displayToolTip(
                                            `${t('tooltip-3')}`
                                        )}
                                        {t('bonus-apr')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    xs={6}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="primary"
                                        className={classes.contentText}
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                        noWrap
                                    >
                                        {format({ prefix: ' ', suffix: '%' })(
                                            millify(poolInfo.bonusAPR || 0, {
                                                precision: 2,
                                                lowercase: true,
                                            })
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    xs={6}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="text"
                                        className={classes.contentText}
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                        noWrap
                                    >
                                        {xWinUtils.displayToolTip(
                                            `${t('tooltip-4')}`
                                        )}
                                        {t('est-perf')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    xs={6}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="primary"
                                        className={classes.contentText}
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                        noWrap
                                    >
                                        {format({ prefix: ' ', suffix: '%' })(
                                            xWinLib.roundTo(
                                                performanceFeeDiscount,
                                                2
                                            )
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    xs={6}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="text"
                                        className={classes.contentText}
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                        noWrap
                                    >
                                        {xWinUtils.displayToolTip(
                                            `${t('tooltip-5')}`
                                        )}
                                        {t('unlocked-date')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    xs={6}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="primary"
                                        className={classes.contentText}
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                        noWrap
                                    >
                                        {moment(newEndDate).format(
                                            'YYYY-MM-DD HH:mm:ss'
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={6}
                                    sm={6}
                                    xs={6}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="text"
                                        className={classes.contentText}
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                        noWrap
                                    >
                                        {xWinUtils.getIcons('xwin')}
                                        {t('total-locked')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={3}
                                    sm={3}
                                    xs={3}
                                    alignItems="left"
                                >
                                    <Typography
                                        color="primary"
                                        className={classes.contentText}
                                        style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                        }}
                                        noWrap
                                    >
                                        {format({ prefix: ' ', suffix: '' })(
                                            xWinLib.roundTo(
                                                poolInfo.totalShares,
                                                2
                                            )
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={3}
                                    sm={3}
                                    xs={3}
                                    alignItems="left"
                                >
                                    <Typography
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                    >
                                        {format({ prefix: ' ~$', suffix: ' ' })(
                                            xWinLib.roundTo(
                                                poolInfo.totalShares *
                                                    prices.xwinPriceInUSD,
                                                1
                                            )
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    sm={12}
                                    xs={12}
                                    alignItems="left"
                                >
                                    {displayDepositButton()}
                                </Grid>
                            </Grid>
                        </Widget>
                    )}
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item lg={9} sm={12} xs={12}>
                    <VaultFaq faqs={faqs} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default withRouter(autoLockStake)
