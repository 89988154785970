import React, { useState, useEffect, useContext } from 'react'
import { AppContext } from '../../context/AppProvider'
import { withRouter } from 'react-router-dom'
// import ReactInterval from 'react-interval'
import { Grid, Container, Tabs, Tab, Box } from '@material-ui/core'
import VaultPerformance from '../vaultPerformance'
import MyVaultValue from './components/MyVaultValue'
import TradinglistV2 from '../tradingsV2/components/TradinglistV2'
import HistoryIcon from '@material-ui/icons/History'
import PieChartIcon from '@material-ui/icons/PieChart'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// styles
import useStyles from './styles'

// components
import Widget from '../../components/Widget'
import { Typography } from '../../components/Wrappers'
import { getAllPrices } from '../../utils/xWinPriceMasterInteractor'
import HistoryEvents from '../historyDetails/HistoryDetailV2'
import { getPrivateFunds } from '../../utils/moralisInteractor'
import { GetVaultTotalValueByUser } from '../../utils/firestoreDataInteractor'
import MyFarms from './components/MyFarm'
import MyStrategies from './components/MyStrategies'
import { useAccount } from 'wagmi/dist'
import { useNetwork } from 'wagmi'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}


const MyMenu = props => {
    const { portfolios, stakingPools, stakingBuddyPools, tokensMaster, xwinstrategies } = props

    var classes = useStyles()
    const [filterportPrivate, setFilterportPrivate] = useState([])
    const [vaultCollections, setVaultCollections] = useState([])
    const [vaultTotalValues, setVaultTotalValues] = useState('0')
    const { chain } = useNetwork()
  

    const [loading, setLoading] = React.useState(false)
    const [prices, setPrices] = useState([])
    const { t, i18n } = useTranslation('myMenu')

    const {
        web3static,
        refreshAccount,
        appGlobalConfig
    } = useContext(AppContext)
    const { address, isConnecting, isDisconnected, isConnected } = useAccount()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        loadWeb3()
    }, [portfolios, address, chain])

    const loadWeb3 = async () => {
        try {
            if (address === undefined) {
              return  props.history.push('/app/dashboard')
            }
            // if (!theWeb3) return

            setLoading(true)
            let filterportPrivate = await getPrivateFunds(address, appGlobalConfig)
            
            //get xwin price in USD
            const promiseData = await Promise.all([
                getAllPrices(web3static),
            ])
            let allprices = promiseData[0]
            setPrices(allprices)
            setFilterportPrivate(filterportPrivate)

            if (address !== undefined) {                
                const userVaults = await GetVaultTotalValueByUser(address, appGlobalConfig)
                let output = getVaultsData(userVaults, filterportPrivate);
                setVaultCollections(output.vaults || [])
                setVaultTotalValues(output.totalAmount)

            } else {
                setVaultCollections([])
                setVaultTotalValues('0')
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const getVaultsData = (userVaults, filterportPrivate) => {
        
        let vaults=[]
        userVaults.fundOwned.forEach(v => {
            let selected = portfolios.find(
                x =>
                    x.contractaddress.toLowerCase() ===
                    v.contractaddress.toLowerCase()
            )
            if (selected === undefined) {
                selected = filterportPrivate.find(
                    x =>
                        x.contractaddress.toLowerCase() ===
                        v.contractaddress.toLowerCase()
                )
            }
            vaults.push({
                ...selected,
                ...v
            })
        });
        return {
            vaults,
            totalAmount : userVaults.totalAmount
        }
            
    }

    const handleCallback = refresh => {
        if (refresh) loadWeb3()
    }

    const displayTradingPortfolios = () => {
        if (vaultCollections.length == 0) return
        if (vaultCollections.length > 0) {
            return vaultCollections.map(port => (
                <>
                    <TradinglistV2
                        port={port}
                        fundtype={port?.fundtype}
                        tokensMaster={tokensMaster}
                        // globalWeb3={theWeb3}
                        web3static={web3static}
                        selectedWallet={address}
                        refreshAccount={refreshAccount}
                        parentCallback={handleCallback}
                    />
                    <br />
                </>
            ))
        } else {
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                >
                    <img
                        style={{ maxWidth: '250px' }}
                        alt={'no data'}
                        src={'/images/background/nodata.png'}
                    />
                </Box>
            )
        }
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight}
                >
                    <Widget
                        title={t('title')}
                        disableWidgetMenu
                        inheritHeight
                        enableWalletDisplay
                        selectedWallet={address}
                    >
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            aria-label="basic tabs example"
                        >
                            <Tab
                                className={classes.contentText}
                                icon={<PieChartIcon />}
                                iconPosition="start"
                                label={t('tabs.vaults')}
                                {...a11yProps(0)}
                            />
                            <Tab
                                className={classes.contentText}
                                icon={<PieChartIcon />}
                                iconPosition="start"
                                label={t('tabs.strategies')}
                                {...a11yProps(1)}
                            />
                            <Tab
                                className={classes.contentText}
                                icon={<FileCopyIcon />}
                                iconPosition="start"
                                label={t('tabs.staking')}
                                {...a11yProps(2)}
                            />
                            <Tab
                                className={classes.contentText}
                                icon={<AttachMoneyIcon />}
                                iconPosition="start"
                                label={t('tabs.p/l')}
                                {...a11yProps(3)}
                            />
                            <Tab
                                className={classes.contentText}
                                icon={<HistoryIcon />}
                                iconPosition="start"
                                label={t('tabs.history')}
                                {...a11yProps(4)}
                            />
                        </Tabs>

                        <TabPanel value={value} index={0}>
                            <br />
                            <Grid container spacing={2}>
                                <Grid
                                    item
                                    lg={
                                        vaultCollections?.length == 0
                                            ? 12
                                            : vaultCollections?.length > 4
                                            ? 12
                                            : 6
                                    }
                                    sm={12}
                                    xs={12}
                                    className={classes.hoverHighlight}
                                >
                                    <MyVaultValue
                                        vaultTotalValues={vaultTotalValues || 0}
                                        vaultCollections={
                                            vaultCollections || []
                                        }
                                        loading={loading}
                                    />
                                </Grid>
                                {vaultCollections?.length > 0 && (
                                    <Grid
                                        item
                                        lg={
                                            vaultCollections?.length > 4
                                                ? 12
                                                : 6
                                        }
                                        sm={12}
                                        xs={12}
                                        className={classes.hoverHighlight}
                                    >
                                        {displayTradingPortfolios()}
                                    </Grid>
                                )}
                            </Grid>
                        </TabPanel>
                        
                            <TabPanel value={value} index={2}>
                                <br />
                                {appGlobalConfig?.chainId === 56 && (
                                <MyFarms 
                                    prices={prices}
                                    stakingBuddyPools={stakingBuddyPools}
                                    stakingPools={stakingPools}
                                />
                                )}
                            </TabPanel>
                        
                        <TabPanel value={value} index={1}>
                            <br />
                            <MyStrategies 
                                prices={prices}
                                stakingBuddyPools={stakingBuddyPools}
                                stakingPools={stakingPools}
                                portfolios={portfolios}
                                tokensMaster={tokensMaster}
                                xwinstrategies={xwinstrategies}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <VaultPerformance
                                account={address}
                                portfolios={portfolios}
                                xwinstrategies={xwinstrategies}
                                vaultCollections={vaultCollections || []}
                                tokensMaster={tokensMaster}
                                appGlobalConfig={appGlobalConfig}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <HistoryEvents account={address} showAll={false} />
                        </TabPanel>
                    </Widget>
                </Grid>
            </Grid>
        </Container>
    )
}

export default withRouter(MyMenu)
