import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { useTheme } from '@material-ui/styles'

export default function ApexChart(props) {
    var theme = useTheme()
    const { mainData } = props

    let filterData = []
    for (let index = 0; index < mainData.length; index++) {
        let temp = {
            x: mainData[index].Symbol,
            y: parseFloat(mainData[index].Amount).toFixed(4),
        }
        filterData.push(temp)
    }

    return (
        <ReactApexChart
            options={themeOptions(theme)}
            series={[
                {
                    data: filterData,
                },
            ]}
            type="bar"
            height="350"
        />
    )
}

function themeOptions(theme) {
    return {
        tooltip: {
            enabled: true,
            theme: false,
            enabledOnSeries: undefined,
            x: {
                show: false,
            },
            y: {
                show: true,
                formatter: function(val) {
                    return '$' + Number(val.toFixed(2))
                },
                title: {
                    formatter: function(seriesName) {
                        return ''
                    },
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        yaxis: {
            labels: {
                show: true,
                align: 'right',
                style: {
                    fontSize: '14px',
                    color: '#fff',
                    background: '#00E396',
                },
                formatter: value => {
                    return parseFloat(value).toFixed(2)
                },
            },
        },
        fill: {
            colors: [theme.palette.primary.light, theme.palette.success.light],
        },
        colors: [theme.palette.primary.main, theme.palette.success.main],
        chart: {
            foreColor: '#ccc',
            toolbar: {
                show: false,
            },
        },
    }
}
