import React, { useEffect, useState,useContext } from 'react'
import Widget from '../../../components/Widget/Widget'
import useStyles from '../styles'
import clsx from 'clsx'
import {
    Tooltip,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableContainer,
    Box,
} from '@material-ui/core'
import { Typography } from '../../../components/Wrappers'
import xwinStrategy from '../../../abi/v2/xWinStrategies.json'

import xWinLib from '../../../xWinLib'
import xWinUtils from '../../../xWinUtils'
import format from 'format-number'
import { convertFromWei } from '../../../utils/helper'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../../context/AppProvider'
const _ = require("lodash");


const StrategyTVL = (props) => {
    const classes = useStyles()
    const { web3static, xwinstrategies } = props
    const [loading, setLoading] = useState(false)
    const [newStrategiesArr, setNewStrategiesArr] = useState([])
    const { t, i18n } = useTranslation('dashboard')
    const {
        appGlobalConfig,
    } = useContext(AppContext)

    const loadStrategies = async () => {
        try {
            if(xwinstrategies.length === 0){
                setLoading(false)
                setNewStrategiesArr([])
                return
            } 
            if(web3static == undefined){
                setLoading(false)
                setNewStrategiesArr([])
            }
            setLoading(true)
            const arr = []
            xwinstrategies.map(async strategy => {
                const contract = new web3static.eth.Contract(xwinStrategy, strategy.contractaddress);
                const promiseData = await Promise.all([
                    contract.methods.getUnitPriceInUSD().call(),
                    contract.methods.getVaultValuesInUSD().call(),
                ]);
                let data = promiseData[0]
                let data2 = promiseData[1]
                let price = convertFromWei(data.toString())
                let tvl = convertFromWei(data2.toString())

                price = parseFloat(price).toFixed(4)
                tvl = parseFloat(tvl).toFixed(2)
                const newData = {
                    ...strategy,
                    price: price,
                    tvl: tvl,
                }
                arr.push(newData)
            })
            arr.sort(xWinLib.compareValues('type', 'desc'))
            setNewStrategiesArr(arr)
            
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
        setLoading(false)
    }

    useEffect(() => {
        loadStrategies()
    }, [xwinstrategies])

    function CustomTableRow({ to, ...props }) {
        return (
            <TableRow
                component={Link}
                to={to}
                sx={{ textDecoration: 'none' }}
                {...props}
                hover
            />
        )
    }
    const DisplayTVL = () => {
        
        if(newStrategiesArr.length > 0){

            return newStrategiesArr.map(strategy => (
                <CustomTableRow to={`/app/strategydetailv2/${strategy.contractaddress}/chainId=${appGlobalConfig?.chainId}`}>
                    <TableCell>
                        <Tooltip title={strategy?.strategyname} arrow={true}>
                            {xWinUtils.getIcons(strategy.symbol)}
                        </Tooltip>
                        <br />
                        <Typography
                            className={classes.columnShow}
                            color="text"
                            colorBrightness={'hint'}
                        >
                            {strategy.symbol}
                        </Typography>
                    </TableCell>
                    <TableCell
                        className={clsx(classes.contentText, classes.columnHide)}
                    >
                        {strategy.name}
                    </TableCell>
                    <TableCell>
                        <Typography className={classes.positiveNum}>
                            {format({ prefix: '$', suffix: '' })(
                                xWinLib.roundTo(strategy.price, 3)
                            )}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography className={classes.positiveNum}>
                            {format({ prefix: '$', suffix: '' })(
                                xWinLib.roundTo(strategy.tvl, 2)
                            )}
                        </Typography>
                    </TableCell>
                </CustomTableRow>
            ))
        }else{

            return (
                <TableRow hover>
                    <TableCell width={'100%'} colSpan="4">
                        <Box display="flex" alignItems="center" justifyContent={"center"}>
                            <img style={{maxWidth:"250px"}} alt={"no data"} src={"/images/background/nodata.png"} />
                        </Box>
                    </TableCell>
                </TableRow>
            )

        }
    }    

    return (
        <Widget
            title={t('strategiesTvl.title')}
            disableWidgetMenu
        >
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell width={'15%'}></TableCell>
                            <TableCell
                                width={'40%'}
                                className={clsx(
                                    classes.contentText,
                                    classes.columnHide
                                )}
                            >
                                {t('strategiesTvl.strategy')}
                            </TableCell>
                            <TableCell
                                width={'30%'}
                                className={classes.positiveNum}
                            >
                                {t('strategiesTvl.unit-price')}
                            </TableCell>
                            <TableCell
                                width={'30%'}
                                className={classes.positiveNum}
                            >
                                {t('strategiesTvl.tvl')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {DisplayTVL()}
                </Table>
            </TableContainer>
        </Widget>
    )
}
export default StrategyTVL
