const appGlobalConfig = {
    MAXALLOWANCE: '10000000000000000000000000000000000000000000000',
    mainnetRiskFreeAsset: '0x55d398326f99059fF775485246999027B3197955',
    mainnetRiskFreeProxy: '0x8B7fcACB99124F009c8470FDa6f5fcF60277BDB2',
    mainnetLockStake: '0xa4AE0DCC89Af9855946C0b2ad4A10FF27125a9Fc',
    mainnetMasterChef: '0xD09774e3d5Dc02fa969896c53D3Cbb5bC8900A60',
    mainnetBuddyChef: '0x4B87a60fC5a94e5ac886867977e29c9711C2E903',
    mainnetFundFactory: '0x9ab3c504De0fDa0087D378123bDC318AADbC60a0',
    mainnetxWInPriceMaster: '0xB1233713FeA0984fff84c7456d2cCed43e5e48E2',
    mainnetXWIN: '0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28',
    mainnetBurn: '0x000000000000000000000000000000000000dead',
    mainnetAddressZero: '0x0000000000000000000000000000000000000000',
    mainnetWBNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    mainnetBTC: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    mainnetETH: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    mainnetUSDT: '0x55d398326f99059fF775485246999027B3197955',
    mainnetUSDC: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    mainnetPrivateVaultPayIn: '0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28',
    mainnetUSDTStakingFund: '0x6439f73dA37Bc8EB83Ac05c615Eb8BAb9fA2a701',
    mainnetXWINBNBLPPool: '0x2D74b7DbF2835aCadd8d4eF75B841c01E1a68383',
    mainnetXWINUSDTLPPool: '0x2E5946E39687FdE415aFEa24fF7272231F69adAF',
    mainnetXWINUSDTAPELPPool: '0x4F263c2F03d8dcd7Dea928de0224e34cEbD9F03c',
    mainnetXWINBNBLPPoolV2: '0xD4A3Dcf47887636B19eD1b54AAb722Bd620e5fb4',
    mainnetXWINBNBLPPoolApeSwap: '0x9f1762b15e740704f40a3606b31dccfbe5318d8b',
    mainnetxWinProtocol: '0x1Bf7fe7568211ecfF68B6bC7CCAd31eCd8fe8092',
    mainnetxWinUSDTStaking: '0x95088e705BC6bE78642f2F20A3286190af000685',
    mainnetxWinPrivateUSDT: '0xd2DC56F4BcAb9377FBfC689ED57777F2aC3DFd36',
    mainnetxWINMaster: '0x3e3EA5dA8eD28be5cdb9D5E95ad74B9A6cbc2196',
    defaultVault: "0x0A0817454710102F2bcB2215D616cBe3aFf495e5",
    mainnetCreateFundCost: "10000000000000000000",
    apiGetWinStats: 'https://us-central1-xwininvestment.cloudfunctions.net/getXWINStats',
    apiGetTVL: 'https://us-central1-xwininvestment.cloudfunctions.net/getTotalLockValueV2',
    apiGetJPYUSD: 'https://us-central1-xwininvestment.cloudfunctions.net/getJpyUsdExchangeRate',
    apiOptimization: 'https://us-central1-xwinfinancev2.cloudfunctions.net/allocation',
    apiBasicAllocation: 'https://us-central1-xwinfinancev2.cloudfunctions.net/basicAllocation',
    apiGetPrivateFundsByContractAddress: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getPrivateFundsByContractAddress',
    apiGetPrivateFundsBySearchKey: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getPrivateFundsBySearchKey',
    apiGetLatestPrivateFunds: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getLatestPrivateFunds',
    apiGetFundActivitiesByWalletOrContractAddr: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getFundActivitiesByWalletOrContractAddr',
    apiGetPrivateFunds: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getPrivateFunds',
    apiGetTATradingSignal: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getTATradingSignal',
    apiGetTokenPrices: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getTokenPrices',
    apiGetMarketTrends: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getMarketTrends',
    apiGetSharpeRatio: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getSharpeRatio',
    apiGetPerformanceCompare: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getPerformanceReturns',
    apiGetMasterFarmTotalValueByUser: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getMasterFarmTotalValueByUser',
    apiGetBuddyFarmTotalValueByUser: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getBuddyFarmTotalValueByUser',
    apiGetVaultTotalValueByUser: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getVaultTotalValueByUser',
    apiGetStrategyTotalValueByUser: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getStrategyTotalValueByUser',
    apiGetStrategyEstimateOut: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getStrategyEstimateOut',
    apiGetFundv2EstimateOut: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getFundv2EstimatedOutput',
    apiGetTVLv2: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getV2TVLPlus',
    apiGetUserPnL: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getUserPnL',
    apiGetFundv2Details: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getFundv2Details',
    apiGetStrategyDetails: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getStrategyDetails',
    apiGetLeaderBoardByTVL: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getLeaderBoardByTVL',
    apiGetConfigV2: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getV2Config',
    apiGetArbTVLPlus: 'https://us-central1-xwinfinance-arb.cloudfunctions.net/getArbTVLPlus',
    apiGetPolygonTVLPlus: 'https://us-central1-xwinfinance-polygon.cloudfunctions.net/getPolygonTVLPlus',
    apiGetTotalLockValue: 'https://us-central1-xwinfinance-main.cloudfunctions.net/getTotalLockValue',
    apiGetHistoryTVL: 'https://us-central1-xwinfinance-main.cloudfunctions.net/getTotalTVLHistory',
    apiGetTotalVault: 'https://us-central1-xwinfinance-main.cloudfunctions.net/getTotalVault',
    apiGetVenusData: 'https://api.venus.io/api/governance/venus',
    apiGetAccessToken: 'https://us-central1-xwinfinancev2.cloudfunctions.net/accessToken',
    apiRegisterReferral: 'https://us-central1-xwinfinancev2.cloudfunctions.net/ReferralCode',
    apiGetSplitFeeAddress: 'https://us-central1-xwinfinancev2.cloudfunctions.net/GetSplitFeeAddress',
    apiGetRecommendedVault: 'https://us-central1-xwinfinance-main.cloudfunctions.net/getRecommendVaults?chain=bsc',
    tokenv2: 'https://s3.ap-northeast-1.amazonaws.com/download.xwin.finance/v2-config/bsc/tokensv2.json',
    xwinfundv2: 'https://s3.ap-northeast-1.amazonaws.com/download.xwin.finance/v2-config/bsc/xwinfundv2.json',
    xwinstrategies: 'https://s3.ap-northeast-1.amazonaws.com/download.xwin.finance/v2-config/bsc/xwinstrategies.json',
    xwinstakingv2: 'https://s3.ap-northeast-1.amazonaws.com/download.xwin.finance/v2-config/bsc/xwinstakingv2.json',
    xwinbuddystakingv2: 'https://s3.ap-northeast-1.amazonaws.com/download.xwin.finance/v2-config/bsc/xwinbuddystakingv2.json',
    apiGetVaultInfo: 'https://us-central1-xwinfinance-main.cloudfunctions.net/getVaultInformation?chain=bscvaults',
    apiGetWBETHAPR: 'https://www.binance.com/bapi/earn/v1/public/pos/cftoken/project/exchange-rate/get',
    apiGetExposure: 'https://us-central1-xwinfinancev2.cloudfunctions.net/getDrillDownExposure?useraddr=',
    gasPrice: '5000000000', // for Main Net
    blockPerDay: 28800, // for Main Net
    env: 'v2bscmain', // for Main Net
    chainId: 56
};

export default appGlobalConfig;