import React from 'react'
import ApexCharts from 'react-apexcharts'
import { useTheme } from '@material-ui/styles'
import xWinLib from '../../../xWinLib'

export default function ApexLineChart(props) {
    var theme = useTheme()
    const { mainData, period } = props

    const getDateFormat = _date => {
        let year = _date.substring(0, 4)
        let month = _date.substring(4, 6)
        let day = _date.substring(6, 8)
        return new Date(year + '-' + month + '-' + day)
    }

    let dateArr = []
    let dataArr = []
    let finalUPData = []
    if (mainData !== undefined) {
        const date = new Date()
        date.setDate(date.getDate() - period)
        Object.keys(mainData).forEach(function(key) {
            if (key !== 'env') {
                const historydate = getDateFormat(key)
                if (historydate > date) {
                    dataArr.push(xWinLib.roundTo(mainData[key], 0))
                    dateArr.push(getDateFormat(key).toString('yyyy/mm/dd'))
                }
            }
        })
    }
    finalUPData.push({
        name: 'TVL USD',
        data: dataArr,
    })

    return (
        <ApexCharts
            options={themeOptions(theme, dateArr, dataArr)}
            series={finalUPData}
            type="area"
            height={400}
        />
    )
}

// ############################################################
function themeOptions(theme, dateArr, dataArr) {
    let minY = 0
    minY = Math.min(...dataArr) * 0.99
    let maxY = 0
    maxY = Math.max(...dataArr) * 1.03
    return {
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            type: 'datetime',
            categories: dateArr,
        },
        yaxis: {
            tickAmount: 10,
            labels: {
                show: true,
                align: 'right',
                style: {
                    fontSize: '14px',
                    color: '#fff',
                    background: '#00E396',
                },
                formatter: value => {
                    return (value / 1000000).toString() + 'M'
                },
            },
        },
        crosshairs: {
            show: false,
        },
        tooltip: {
            theme: false,
            enabledOnSeries: undefined,
            x: {
                show: false,
            },
            y: {
                show: true,
                formatter: value => {
                    return value.toLocaleString()
                },
            },
            fixed: {
                enabled: true,
                position: 'topRight',
                offsetY: -15,
            },
            style: { fontSize: '20px' },
        },
        fill: {
            colors: [theme.palette.primary.light, theme.palette.success.light],
        },
        colors: [theme.palette.primary.main, theme.palette.success.main],
        chart: {
            foreColor: '#ccc',
            toolbar: {
                show: false,
            },
        },
    }
}
