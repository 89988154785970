import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    hoverHighlight: {
      "&:hover": {
        boxShadow: theme.customShadows.widgetWide,
        borderRadius: "20px"
      },
    },
    div: {
      padding: theme.spacing(1),
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(0, 50, 0, 50)
      }
    },
    expanded: { backgroundColor: theme.palette.background.default },
    expansion: {
      backgroundColor: theme.palette.primary.light,
      color: 'white '
    },
    column1: {
      flexBasis: '15.00%',
      verticalAlign: 'center',
    },
    column2: {
      flexBasis: '20.00%',
      verticalAlign: 'center',
    },
    column3: {
      flexBasis: '40.00%',
      verticalAlign: 'center',
    },
    column4: {
      flexBasis: '20.00%',
      verticalAlign: 'right',
      alignItems: "right"
    },
    column5: {
      flexBasis: '25.00%',
      verticalAlign: 'bottom',
    },
    column6: {
      flexBasis: '40.00%',
      verticalAlign: 'bottom',
    },
    columnHide: {
      [theme.breakpoints.down('xs')]: {
        display: "none"
      }
    },
    columnShow: {
      display: "none",
      [theme.breakpoints.down('xs')]: {
        display: "inline"
      }
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    table: {
      padding: theme.spacing(30),
      [theme.breakpoints.up('xs')]: {
        padding: theme.spacing(1)
      }
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
    },
    subTitle: {
      color: theme.palette.primary.main,
      fontSize: '1.042rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      }
    },
    subSuccess: {
      color: theme.palette.warning.dark,
      fontSize: '1.042rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      }
    },
    avatar: {
      height: 50,
      width: 50,
      [theme.breakpoints.down('xs')]: {
        height: 25,
        width: 25,
      }
    },
    avatarSmall: {
      height: 20,
      width: 20,
      [theme.breakpoints.down('xs')]: {
        height: 15,
        width: 15,
      }
    },
    snackbarWarning: {
      backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
      color: "black"
    },

    icon1: {
      height: "25px",
      width: "25px",
      [theme.breakpoints.down('xs')]: {
        height: 20,
        width: 20,
      },
      zIndex: "6",
      borderRadius: "50px",
      justifyContent: "center",
      // background: "#FFFFFF",
      alignItems: "center",
      marginRight: "0px",
      marginTop: theme.spacing(1),
    },
    icon2: {
      height: "45px",
      width: "45px",
      [theme.breakpoints.down('xs')]: {
        height: 32,
        width: 32,
      },
      zIndex: "1",
      borderRadius: "50px",
      justifyContent: "center",
      // background: "#FFFFFF",
      alignItems: "center",
      marginRight: "-15px",
    },
  }
))