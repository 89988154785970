const autoLockFAQ = {
    faqs: [
        {
            symbol: 'autolock',
            env: 56,
            question: 'autoLockFaq.question-1',
            defaultExpanded: true,
            desc: `autoLockFaq.desc-1`,
        },
        {
            symbol: 'autolock',
            env: 56,
            question: 'autoLockFaq.question-2',
            desc: 'autoLockFaq.desc-2',
        },
        {
            symbol: 'autolock',
            env: 56,
            question: 'autoLockFaq.question-3',
            desc: 'autoLockFaq.desc-3',
        },
        {
            symbol: 'autolock',
            env: 56,
            question: 'autoLockFaq.question-4',
            desc: 'autoLockFaq.desc-4',
        },
        {
            symbol: 'autolock',
            env: 56,
            question: 'autoLockFaq.question-5',
            desc: 'autoLockFaq.desc-5',
        },
    ],
}

export default autoLockFAQ
