import React, { useState, useEffect } from 'react'
import xWinLib from '../../../xWinLib'
import format from 'format-number'
import xWinUtils from '../../../xWinUtils'
import PropTypes from 'prop-types'
import {
    InfoRounded as InfoIcon,
    MoneySharp as MoneyIcon,
} from '@material-ui/icons'
import { Grid, Box, Tabs, Tab, Tooltip, Avatar} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

// styles
import useStyles from '../styles'
import Widget from '../../../components/Widget/Widget'
import { Typography } from '../../../components/Wrappers/Wrappers'
import Dot from '../../../components/Sidebar/components/Dot'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { getExplorerScan, getLayer1ExplorerScan } from '../../../utils/helper'
import appGlobalConfig from '../../../configs/56/appGlobalConfig'
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

export default function UnitPrice(props) {
    var classes = useStyles()
    const theme = useTheme()
    const { fundData, loading, port, explorerScan, web3static, xwinstrategies, appGlobalConfig } = props
    const [value, setValue] = React.useState(0)
    const [returns, setReturns] = useState([])
    const [statistic, setStatistic] = useState({
        portReturns: 0,
        portVol: 0,
        portSR: 0,
    })
    const [dropdown, setDropdown] = React.useState('optimization-6m')
    const [userPerformanceFee, setUserPerformanceFee] = useState(
        fundData.performanceFee
    )
    
    const { t, i18n } = useTranslation('fundDetails')

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const displayDiscount = () => {
        if (
            userPerformanceFee !== undefined &&
            userPerformanceFee !== fundData.performanceFee
        ) {
            return (
                <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        {xWinUtils.displayToolTip(
                            'You got performance fee discount because you staked and lock xWIN Tokens. Thank you!'
                        )}
                        <Typography
                            style={{ marginLeft: 5 }}
                            className={clsx(classes.displayFont,"p-2")}
                            color="secondary"
                        >
                            <s>
                                {format({ prefix: '', suffix: '%' })(
                                    xWinLib.roundTo(fundData.performanceFee, 2)
                                )}
                            </s>
                        </Typography>
                        &rarr;
                        <Typography
                            style={{ marginLeft: 5 }}
                            className={clsx(classes.displayFont,"p-2")}
                            color="primary"
                        >
                            {format({ prefix: '', suffix: '%' })(
                                xWinLib.roundTo(userPerformanceFee, 2)
                            )}
                        </Typography>
                    </Box>
                </Grid>
            )
        } else {
            return (
                <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <Dot size="medium" color="primary" />
                        <Typography
                            style={{ marginLeft: 5 }}
                            className={clsx(classes.displayFont,"p-2")}
                        >
                            {format({ prefix: '', suffix: '%' })(
                                xWinLib.roundTo(fundData.performanceFee, 2)
                            )}
                        </Typography>
                    </Box>
                </Grid>
            )
        }
    }

    const getStrategyFeePerDay = () => {
        return xWinLib.roundTo(fundData.managerFee / 365, 5) 
    }

    const getPlatformFeePerDay = () => {
        return xWinLib.roundTo(fundData.platformFee / 365, 5) 
    }

    const chooseExplorer = (url) => {

        let getExplorer = getExplorerScan(url, 'block/countdown', explorerScan)  
        if(appGlobalConfig.chainId === 42161){
            getExplorer = getLayer1ExplorerScan(url, 'block/countdown')
        }
        return getExplorer
    }

    const displayCcyIcon = (baseCcy) => {

        return (
            <Tooltip arrow title={baseCcy}>
                <Avatar
                    variant={'rounded'}
                    className={classes.smallavatar}
                    src={xWinLib.getIcon(
                        baseCcy?.toLowerCase() +
                            '.png'
                    )}
                    sx={{marginLeft: 20}}
                />
            </Tooltip>
        )
    }

    return (
        <Widget
            // title="Vault Info"
            disableWidgetMenu
            bodyClass={classes.fullHeightBody}
            className={classes.card}
        >
            {xWinUtils.getProgress(loading)}
            <Tabs
                allowScrollButtonsMobile
                variant="scrollable"
                scrollButtons={true}
                aria-label="scrollable force tabs example"
                value={value}
                textColor="primary"
                indicatorColor="primary"
                onChange={handleChange}
            >
                <Tab
                    className={classes.contentText}
                    fullWidth={false}
                    icon={<InfoIcon />}
                    iconPosition="start"
                    label={`${t('unitPrice.vault-info')}`}
                    {...a11yProps(0)}
                />
                <Tab
                    className={classes.contentText}
                    icon={<MoneyIcon />}
                    iconPosition="start"
                    label={`${t('unitPrice.fee')}`}
                    {...a11yProps(1)}
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <br />
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.symbol')}
                        </Typography>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Box
                            sx={{ p: 2, border: '1px dashed grey' }}
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont,"p-2")}
                            >
                                {port?.symbol}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.unit-price')} 
                            {/* ({appGlobalConfig?.chainId === 56 ? "USDT" : "USDC"}) */}
                        </Typography>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            {displayCcyIcon(appGlobalConfig?.chainId === 56 ? "USDT" : "USDC")}
                                
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont,"p-2")}
                            >
                                {format({ prefix: '', suffix: '' })(
                                    xWinLib.roundTo(fundData.unipriceInUSD, 2)
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.unit-price')} ({fundData.baseCcy})
                        </Typography>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            {displayCcyIcon(fundData.baseCcy)}
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont,"p-2")}
                            >
                                {format({
                                    prefix: "",
                                    suffix: '',
                                })(xWinLib.roundTo(fundData.uniprice, 4))}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.market-cap')}
                        </Typography>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont,"p-2")}
                            >
                                {xWinLib.getMarketCap(fundData)}
                                {/* {format({prefix: fundData.baseCcy, suffix: ''})(xWinLib.roundTo(fundData.fundvalue,2))} */}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.total-supply')}
                        </Typography>
                    </Grid>
                    <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont,"p-2")}
                            >
                                {format({ prefix: '', suffix: '' })(
                                    xWinLib.roundTo(fundData.totalSupply, 4)
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <br />
                <Grid container spacing={3}>
                    <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                             {xWinUtils.displayToolTip(`${t('unitPrice.strategyFee-explain', {
                                    strategyFee: xWinLib.roundTo(fundData.managerFee, 2),
                                    strategyFeePerDay: getStrategyFeePerDay(),
                                })}`)}

                            {t('unitPrice.strategy-fee')}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont,"p-2")}
                            >
                                {format({ prefix: '', suffix: '%' })(
                                    xWinLib.roundTo(fundData.managerFee, 2)
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {xWinUtils.displayToolTip(`${t('unitPrice.platformFee-explain', {
                                    platformFee: xWinLib.roundTo(fundData.platformFee, 2),
                                    platformFeePerDay: getPlatformFeePerDay(),
                                })}`)}
                            {t('unitPrice.platform-fee')}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="primary" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont,"p-2")}
                            >
                                {format({ prefix: '', suffix: '%' })(
                                    xWinLib.roundTo(fundData.platformFee, 2)
                                )}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                             {xWinUtils.displayToolTip(`${t('unitPrice.performanceFee-hwm-explain')}`)}
                            {t('unitPrice.highwatermark-fee')}
                        </Typography>
                    </Grid>
                    {displayDiscount()}
                </Grid>
                {fundData.performanceFee > 0 && (
                    <>
                    <Grid container spacing={3}>
                         <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginLeft: 5 }}
                                className={'p-2'}
                            >
                                
                                {xWinUtils.displayToolTip(`${t('unitPrice.performanceFee-hwm-price-explain', {
                                    highwatermark: xWinLib.roundTo(fundData.highWaterMarkPrice, 4),
                                    baseCcy: fundData.baseCcy,
                                })}`)}
                                {t('unitPrice.watermark')} ({fundData.baseCcy})
                            </Typography>
                        </Grid>
                        <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'right'}
                            >
                                <Dot size="medium" color="primary" />
                                <Typography
                                    size="md"
                                    weight={'medium'}
                                    style={{ marginLeft: 5 }}
                                    className={clsx(classes.displayFont,"p-2")}
                                >
                                    {format({
                                        prefix: '',
                                        suffix: '',
                                    })(xWinLib.roundTo(fundData.highWaterMarkPrice, 4))}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                         <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginLeft: 5 }}
                                className={'p-2'}
                            >
                                
                                {xWinUtils.displayToolTip(`${t('unitPrice.performanceFee-hwm-nextCollection-explain', {
                                    highwatermark: xWinLib.roundTo(fundData.highWaterMarkPrice, 4),
                                    nextCollection: fundData.collectionBlock,
                                })}`)}
                                {t('unitPrice.nextCollection')}
                            </Typography>
                        </Grid>
                        <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'right'}
                                >
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginLeft: 5 }}
                                    className={clsx(classes.displayFont,"p-2")}
                                >
                                    <a className={classes.link} href={chooseExplorer(fundData.collectionBlock)} target='_blank'>
                                    {fundData.collectionBlock}
                                    </a>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    </>
                )}
                
                <Grid container spacing={3}>
                <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginLeft: 5 }}
                            className={'p-2'}
                        >
                            {t('unitPrice.manager-wallet')}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            {fundData.fundmanager && (
                                <Jazzicon
                                    diameter={20}
                                    seed={jsNumberForAddress(
                                        fundData.fundmanager
                                    )}
                                />
                            )}
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginLeft: 5 }}
                                className={clsx(classes.displayFont,"p-2")}
                            >
                                <a
                                    className={classes.link}
                                    href={getExplorerScan(
                                        fundData.fundmanager,
                                        'address',
                                        explorerScan
                                    )}
                                    target="blank"
                                >
                                    {xWinUtils.getAddressShort(
                                        fundData.fundmanager
                                    )}
                                </a>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </TabPanel>
        </Widget>
    )
}
