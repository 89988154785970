import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
    main: {
        width: '100%',
        display: 'flex',
        zIndex: 1000,
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    flex: {
        display: 'flex',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        left: '50%',
        top: '50%',
        float: 'right',
        zIndex: 1000,
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            fontSize: 12,
        },
        [theme.breakpoints.down('xs')]: {
            position: 'relative',
            fontSize: 10,
        },
    },
    message: {
        background: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '13px',
        display: 'flex',
        paddingTop: '5px',
        paddingBottom: '5px',
        width: '100%',
        alignItems: 'center',
        marginLeft: '10px',
    },
    messageLight: {
        background: 'rgba(89, 89, 89, 0.9)',
        borderRadius: '13px',
        display: 'flex',
        paddingTop: '5px',
        paddingBottom: '5px',
        width: '100%',
        alignItems: 'center',
        marginLeft: '10px',
    },
    message2: {
        color: '#c6ff1a',
        marginLeft: '5px',
        paddingRight: '10px',
    },
    logo: {},
}))
