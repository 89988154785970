const vaultFAQ = {
    faqs: [
        {
            symbol: 'v2index',
            env: 56,
            question: 'vaultFaq.question-1',
            defaultExpanded: true,
            desc: 'vaultFaq.desc-1',
        },
        {
            symbol: 'v2index',
            env: 56,
            question: 'vaultFaq.question-2',
            desc: 'vaultFaq.desc-2',
        },
        {
            symbol: 'v2index',
            env: 56,
            question: 'vaultFaq.question-3',
            desc: 'vaultFaq.desc-3',
        },
        
        {
            symbol: 'v2trading',
            env: 56,
            question: 'vaultFaq.question-5',
            defaultExpanded: true,
            desc: 'vaultFaq.desc-5',
        },
        {
            symbol: 'v2singlebase',
            env: 56,
            question: 'vaultFaq.question-4',
            desc: 'vaultFaq.desc-4',
        },
        {
            symbol: 'v2trading',
            env: 56,
            question: 'vaultFaq.question-6',
            desc: 'vaultFaq.desc-6',
        },
        {
            symbol: 'v2trading',
            env: 56,
            question: 'vaultFaq.question-7',
            desc: 'vaultFaq.desc-7',
        },
        {
            symbol: 'v2singlebase',
            env: 56,
            question: 'vaultFaq.question-8',
            desc: 'vaultFaq.desc-8',
        },
        {
            symbol: 'v2singlebase',
            env: 56,
            question: 'vaultFaq.question-9',
            desc: 'vaultFaq.desc-9',
        },
        {
            symbol: 'v2singlebase',
            env: 56,
            question: 'vaultFaq.question-10',
            desc: 'vaultFaq.desc-10',
        },
        {
            symbol: 'v2singlebase',
            env: 56,
            question: 'vaultFaq.question-11',
            desc: 'vaultFaq.desc-11',
        },
        {
            symbol: 'v2allprivate',
            env: 56,
            question: 'vaultFaq.question-13',
            defaultExpanded: true,
            desc: 'vaultFaq.desc-13',
        },
        {
            symbol: 'v2allprivate',
            env: 56,
            question: 'vaultFaq.question-14',
            desc: 'vaultFaq.desc-14',
        },
        {
            symbol: 'v2allprivate',
            env: 56,
            question: 'vaultFaq.question-15',
            desc: 'vaultFaq.desc-15',
        },
        {
            symbol: 'v2allprivate',
            env: 56,
            question: 'vaultFaq.question-16',
            desc: 'vaultFaq.desc-16',
        },
        {
            symbol: 'v2singlebase',
            env: 56,
            question: 'vaultFaq.question-17',
            desc: 'vaultFaq.desc-17',
        },
        {
            symbol: 'v2singlebase',
            env: 56,
            question: 'vaultFaq.question-18',
            desc: 'vaultFaq.desc-18',
        },
        {
            symbol: 'v2index',
            env: 56,
            question: 'vaultFaq.question-19',
            desc: 'vaultFaq.desc-19',
        },
        {
            symbol: 'v2index',
            env: 56,
            question: 'vaultFaq.question-20',
            desc: 'vaultFaq.desc-20',
        },
        {
            symbol: 'v2index',
            env: 56,
            question: 'vaultFaq.question-21',
            desc: 'vaultFaq.desc-21',
        },
        {
            symbol: 'v2index',
            env: 42161,
            question: 'vaultFaq.question-1',
            defaultExpanded: true,
            desc: 'vaultFaq.desc-1',
        },
        {
            symbol: 'v2index',
            env: 42161,
            question: 'vaultFaq.question-2-arb',
            desc: 'vaultFaq.desc-2-arb',
        },
        {
            symbol: 'v2index',
            env: 42161,
            question: 'vaultFaq.question-3-arb',
            desc: 'vaultFaq.desc-3-arb',
        },
        {
            symbol: 'v2index',
            env: 42161,
            question: 'vaultFaq.question-22-arb',
            desc: 'vaultFaq.desc-22-arb',
        },
        {
            symbol: 'v2allprivate',
            env: 42161,
            question: 'vaultFaq.question-13',
            defaultExpanded: true,
            desc: 'vaultFaq.desc-13',
        },
        {
            symbol: 'v2allprivate',
            env: 42161,
            question: 'vaultFaq.question-14',
            desc: 'vaultFaq.desc-14',
        },
        {
            symbol: 'v2allprivate',
            env: 42161,
            question: 'vaultFaq.question-15',
            desc: 'vaultFaq.desc-15',
        },
        {
            symbol: 'v2allprivate',
            env: 42161,
            question: 'vaultFaq.question-16',
            desc: 'vaultFaq.desc-16',
        },
        {
            symbol: 'v2trading',
            env: 42161,
            question: 'vaultFaq.question-5-arb',
            defaultExpanded: true,
            desc: 'vaultFaq.desc-5-arb',
        },
        {
            symbol: 'v2trading',
            env: 42161,
            question: 'vaultFaq.question-6-arb',
            desc: 'vaultFaq.desc-6-arb',
        },
        {
            symbol: 'v2trading',
            env: 42161,
            question: 'vaultFaq.question-23-arb',
            desc: 'vaultFaq.desc-23-arb',
        },
        {
            symbol: 'v2trading',
            env: 42161,
            question: 'vaultFaq.question-24-arb',
            desc: 'vaultFaq.desc-24-arb',
        }
    ],
}

export default vaultFAQ
