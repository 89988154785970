import { makeStyles } from '@material-ui/styles';
import { alpha } from '@mui/material';

export default makeStyles(theme => ({
    link: {
        display: 'flex',
        alignItems: 'center',
        //color: theme.palette.primary.main,
        fontWeight: 'bold',
        color: theme.palette.success.main,
        visited: {
            color: theme.palette.success.main,
        }
    },
    hoverHighlight: {
        boxShadow: theme.customShadows.widgetWide,
        //minHeight: "20vh",
        "&:hover": {
            boxShadow: theme.customShadows.widgetWide
        },
    },
    expanded: { backgroundColor: theme.palette.background.default },
    expansion: {
        backgroundColor: theme.palette.primary.light,
        color: 'white '
    },
    column1: {
        flexBasis: '10.00%',
        verticalAlign: 'center',
    },
    column2: {
        flexBasis: '20.00%',
        verticalAlign: 'center',
    },
    column3: {
        flexBasis: '25.00%',
        verticalAlign: 'center',
    },
    column4: {
        flexBasis: '20.00%',
        verticalAlign: 'center',
    },
    column5: {
        flexBasis: '25.00%',
        verticalAlign: 'bottom',
    },
    column6: {
        flexBasis: '50.00%',
        verticalAlign: 'bottom',
    },
    columnHide: {
        [theme.breakpoints.down('xs')]: {
            display: "none"
        }
    },
    columnShow: {
        [theme.breakpoints.up('xs')]: {
            display: "none"
        }
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    table: {
        padding: theme.spacing(30),
        [theme.breakpoints.up('xs')]: {
            padding: theme.spacing(1)
        }
    },
    newsHeader: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: '#536DFE',
    },
    subTitle: {
        //textDecoration: 'none',
        //textTransform: 'uppercase',
        fontWeight: 100,
        color: '#536DFE',
    },
    primaryHeading: {
        fontSize: '1.64rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.95rem',
        }
    },
    displayFont: {
        fontSize: '1.042rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.75rem',
        }
    },
    avatar: {
        height: 85,
        width: 85,
        [theme.breakpoints.down('xs')]: {
            height: 40,
            width: 40,
        }
    },
    smallavatar: {
        height: 25,
        width: 25,
        marginLeft: 5,
        [theme.breakpoints.down('xs')]: {
            height: 20,
            width: 20,
        }
    },
    positiveNum: {
        fontWeight: 100,
        color: theme.palette.success.dark,
    },
    negativeNum: {
        fontWeight: 100,
        color: theme.palette.secondary.light,
    },
    cardpie: {
        alignItems: "center",
        minHeight: "50vh"
    },
    contentText: {
        fontSize: '1.042rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.80rem',
        }
    },
    cards: {
        padding: theme.spacing(2),
        height: "300px",
    },
    customButton: {
        fontSize: '1.042rem',
        padding: "none",
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.8rem',
            // padding: theme.spacing(2),
        }
    },
    ShareIcon: {

        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.5),
            opacity: 0.5,
        },
    }

}))