const leaderboardFAQ = {
    faqs: [
        {
            symbol: 'leaderboard',
            env: 56,
            question: 'leaderboard.question-1',
            defaultExpanded: true,
            desc: 'leaderboard.desc-1',
        },
        {
            symbol: 'leaderboard',
            env: 56,
            question: 'leaderboard.question-2',
            desc: 'leaderboard.desc-2',
        },
    ],
}

export default leaderboardFAQ
