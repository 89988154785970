import React, { useState, useContext, useRef } from 'react'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import format from 'format-number'
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone'
import TextRotationNoneTwoToneIcon from '@material-ui/icons/TextRotationNoneTwoTone'
import { Typography, Button, Avatar } from '../../components/Wrappers/Wrappers'
import CancelIcon from '@material-ui/icons/Cancel'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import { useTranslation } from 'react-i18next'

import {
    CardHeader,
    IconButton,
    Box,
    Dialog,
    DialogContent,
    DialogActions,
    ButtonGroup,
    TextField,
    InputAdornment,
    Card,
} from '@material-ui/core'

import useStyles from './styles'
// import appGlobalConfig from '../../configs/56/appGlobalConfig';
import { AppContext } from '../../context/AppProvider'
import { DepositFarm, DepositFarmEther } from '../../utils/masterChefInteractor'
import { DepositBuddyFarm } from '../../utils/buddyChefInteractor'
import { approveContract, IsTokenApproved } from '../../utils/erc20Interactor'

const subscribefarmV2 = ({
    userData,
    openModal,
    port,
    selectedWallet,
    parentCallback,
    reset,
    buddyfarm,
    refreshAccountLocal,
    theSigner
}) => {
    const { appGlobalConfig, web3static } = useContext(AppContext)
    const { t, i18n } = useTranslation('farmingV2')
    const classes = useStyles()
    const [loading, setLoading] = React.useState(false)
    const [subsAmt, setsubsAmt] = useState(0)
    const [isApproval, setIsApproval] = useState(false)
    const [isBuddyFarm, setIsBuddyFarm] = useState(buddyfarm)
    const refsnackbar = useRef(null)
    
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    const handleClickSubs = async event => {
        event.preventDefault()

        if (subsAmt <= 0) {
            showSnackBar('error', `${t('subscribeFarmV2.error.amount')}`)
            return
        }

        setLoading(true)
        await depositChefFarm()
    }

    const depositChefFarm = async () => {
        if (isBuddyFarm) {
            DepositBuddyFarm(theSigner, port.pid, selectedWallet, subsAmt)
                .then(res => {
                    console.log(res)
                    setLoading(false)
                    showSnackBar('success', res)
                    // reset
                    parentCallback(true)
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                    showSnackBar('error', err)
                    parentCallback(false)
                })
        } else {
            DepositFarm(theSigner, port.pid, selectedWallet, subsAmt)
                .then(res => {
                    console.log(res)
                    setLoading(false)
                    showSnackBar('success', res)
                    // reset
                    parentCallback(true)
                })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                    showSnackBar('error', err)
                    parentCallback(false)
                })
        }
    }

    const handleClickApprove = async event => {
        event.preventDefault()

        setLoading(true)
        approveContract(
            theSigner,
            port?.contractaddress,
            getChefContract(),
            selectedWallet
        )
            .then(res => {
                setLoading(false)
                setIsApproval(true)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                setIsApproval(false)
            })
    }

    const handleClickClearAmt = () => event => {
        event.preventDefault()
        setsubsAmt(0)
    }

    const getChefContract = () => {
        return isBuddyFarm
            ? appGlobalConfig.mainnetBuddyChef
            : appGlobalConfig.mainnetMasterChef
    }

    const handleAmtChange = async event => {
        event.preventDefault()
        const subsAmount = parseFloat(event.target.value)
        if (subsAmount >= 0 && subsAmount !== undefined) {
            setsubsAmt(subsAmount)
            const isApp = await IsTokenApproved(
                web3static,
                subsAmount,
                selectedWallet,
                port.contractaddress,
                getChefContract()
            )
            setIsApproval(isApp)
        } else {
            setsubsAmt('')
        }
    }

    const handleClickAmt = ratio => async event => {
        event.preventDefault()

        let subsAmount
        if (ratio == 1) {
            subsAmount = userData.userfundbal
            setsubsAmt(subsAmount)
        } else {
            subsAmount = xWinLib.roundDown(ratio * userData.userfundbal, 8)
            setsubsAmt(subsAmount)
        }
        const isApp = await IsTokenApproved(
            web3static,
            subsAmount,
            selectedWallet,
            port.contractaddress,
            getChefContract()
        )
        setIsApproval(isApp)
    }

    const getSubHeader = () => {
        return (
            <Typography className={classes.displayFont}>
                {format({
                    prefix: `${t('subscribeFarmV2.available')}`,
                    suffix: '',
                })(xWinLib.roundTo(userData?.userfundbal, 10))}
            </Typography>
        )
    }

    return (
        <Dialog
            open={openModal}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            fullScreen={false}
        >
            <SnackbarMessage ref={refsnackbar} />
            <Card className={classes.expansion}>
            <CardHeader
                action={
                    <IconButton
                        className={classes.positiveNum}
                        onClick={reset}
                        aria-label="settings"
                    >
                        <CancelIcon />
                    </IconButton>
                }
                avatar={
                    <Avatar variant="round">
                        {
                            <img
                                alt={port.symbol}
                                src={xWinLib.getIcon(
                                    port.logo2 ? port.logo2 : port.logo
                                )}
                                className={classes.avatar}
                            />
                        }
                    </Avatar>
                }
                subheader={getSubHeader()}
            />
            <DialogContent>
                {xWinUtils.getProgress(loading)}

                <Box className={classes.boxAmount}>
                    <ButtonGroup size="small" color="primary" aria-label="">
                        <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.25)}
                        >
                            <Typography className={classes.displayFont}>
                                {'25%'}
                            </Typography>
                        </Button>
                        <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.5)}
                        >
                            <Typography className={classes.displayFont}>
                                {'50%'}
                            </Typography>
                        </Button>
                        <Button
                            className={classes.columnHide}
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(0.75)}
                        >
                            <Typography className={classes.displayFont}>
                            {'75%'}
                            </Typography>
                        </Button>
                        <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(1)}
                        >
                            <Typography className={classes.displayFont}>
                                {'100%'}
                            </Typography>
                        </Button>
                        <Button
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickClearAmt()}
                        >
                            <Typography className={classes.displayFont}>
                                {t('subscribeFarmV2.clear')}
                            </Typography>
                        </Button>
                    </ButtonGroup>
                </Box>
            </DialogContent>
            <DialogContent>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                >
                    <TextField
                        type="number"
                        className={classes.text}
                        margin="dense"
                        name="weight"
                        onChange={handleAmtChange}
                        required
                        // variant="outlined"
                        value={subsAmt}
                        fullWidth
                        InputProps={{
                            classes: {
                                input: classes.positiveNum,
                            },
                            style: { textAlign: 'right' },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Typography className={classes.displayFont}>{port.symbol}</Typography>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <ButtonGroup
                    size="small"
                    color="primary"
                    aria-label="large outlined primary button group"
                >
                    <Button
                        disabled={isApproval}
                        onClick={handleClickApprove}
                        color="secondary"
                        variant="contained"
                        startIcon={<TextRotationNoneTwoToneIcon />}
                    >
                        <Typography className={classes.displayFont}>
                            {t('subscribeFarmV2.approve')}
                        </Typography>
                    </Button>
                    <Button
                        disabled={!isApproval}
                        onClick={handleClickSubs}
                        color="primary"
                        variant="contained"
                        startIcon={<AddCircleTwoToneIcon />}
                    >
                        <Typography className={classes.displayFont}>
                            {t('subscribeFarmV2.stake')}
                        </Typography>
                    </Button>
                </ButtonGroup>
            </DialogActions>
            </Card>
        </Dialog>
    )
}

export default subscribefarmV2
