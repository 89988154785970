import Icon from '@mdi/react';
import React, { useEffect, useState, useRef, useContext } from 'react';
import classnames from 'classnames';
import {
  Fab,
  IconButton,
} from '@material-ui/core';
import {
  mdiFacebookBox as FacebookIcon,
  mdiGithubBox as GithubIcon,
  mdiMedium as MediumIcon,
  mdiSettings as SettingsIcon,
  mdiTelegram as TelegramIcon,
  mdiTwitterBox as TwitterIcon,
} from '@mdi/js';
import { Route, Switch, withRouter } from 'react-router-dom';

import ColorChangeThemePopper from './components/ColorChangeThemePopper';
import Dashboard from '../../pages/dashboard';
import MyMenu from '../../pages/mymenu';
import FarmingPageV2 from '../../pages/farmingV2';
import FarmingBuddyPageV2 from '../../pages/farmingBuddyV2';
import Footer from '../Footer';
import FundDetailPageV2 from '../../pages/funddetailV2/FundDetailV2';
import StrategyDetailPageV2 from '../../pages/strategyDetailV2/StrategyDetailV2';
import Header from '../Header';
import Sidebar from '../Sidebar';
import FundV2Page from '../../pages/tradingsV2';
import StrategiesV2Page from '../../pages/strategiesV2';
import AssetAllocation from '../../pages/assetAllocation';
import AutoLockStake from '../../pages/autoLockStake';
import structure from '../Sidebar/SidebarStructure';
import useStyles from './styles';
import { Link, Typography } from '../Wrappers';
import { useLayoutState } from '../../context/LayoutContext';
import Leaderboard from '../../pages/leaderboard/Leaderboard';
import MessageBar from '../../pages/messagebar/MessageBar';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/AppProvider';
import RegisterReferral from '../../pages/referralRegister/registerReferral';
import { useAlertBannerState } from '../../context/AlertBannerContext';
import MainMenu from '../../pages/mainMenuV2/MainMenu'
import Exposure from '../../pages/exposure/exposure';

function Layout(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { tokensv2, xwinfundv2, xwinstrategies, xwinstakingv2, xwinbuddystakingv2 } = useContext(AppContext)
  const open = Boolean(anchorEl);
  const id = open ? 'add-section-popover' : undefined;
  const { t, i18n } = useTranslation('termcondition')
  const isAlertOpen = useAlertBannerState()

  const handleClick = (event) => {
    setAnchorEl(open ? null : event.currentTarget);
  };

  const refsnackbar = useRef(null);
	const showMessageBar = (status, msg) => {
		refsnackbar.current.handleOpen(status, msg, "bottom");
	}

  useEffect(function() {
		showMessageBar("success", t('footerWarning'))
	});

  // global
  const layoutState = useLayoutState();
  const [portfoliosV2, setPortfoliosV2] = useState([]);
  const [strategiesV2, setStrategiesV2] = useState([]);
  const [stakingPools, setStakingPools] = useState([]);
  const [stakingBuddyPools, setStakingBuddyPools] = useState([]);
  const [tokensMasterV2, setTokensMasterV2] = useState([]);

  useEffect(() => {
    setTokensMasterV2(tokensv2 || []);
  }, [tokensv2]);

  useEffect(() => {
    setPortfoliosV2(xwinfundv2 || []);
  }, [xwinfundv2]);

  useEffect(() => {
    setStrategiesV2(xwinstrategies || []);
  }, [xwinstrategies]);

  useEffect(() => {
    setStakingPools(xwinstakingv2 || []);
  }, [xwinstakingv2]);

  useEffect(() => {
    setStakingBuddyPools(xwinbuddystakingv2 || []);
  }, [xwinbuddystakingv2]);

  return (
    <>
    <div className={classes.root}>
      <Header history={props.history} />
      <Sidebar structure={structure} />
      
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          }, isAlertOpen && classes.bannerAlert)}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/markets" render={() => <Dashboard xwinstrategies={strategiesV2} tokensMaster={tokensMasterV2} portfolios={portfoliosV2} />} />
            <Route path="/app/dashboard" render={() => <MainMenu xwinstrategies={strategiesV2} tokensMaster={tokensMasterV2} portfolios={portfoliosV2} />} />
            <Route path="/app/mymenu" render={() => <MyMenu 
              tokensMaster={tokensMasterV2} 
              portfolios={portfoliosV2} 
              stakingPools={stakingPools}
              stakingBuddyPools={stakingBuddyPools}
              xwinstrategies={strategiesV2}
              />} />
            <Route path="/app/leaderboard" render={()=><Leaderboard portfolios={portfoliosV2}/>}/>
            <Route path="/app/allocation" render={() => <AssetAllocation xwinstrategies={strategiesV2} portfolios={portfoliosV2} tokensMaster={tokensMasterV2}/>} />
            <Route path="/app/referral/:id" render={() => <RegisterReferral />} />
            <Route path="/app/referral" render={() => <RegisterReferral />} />
            <Route path="/app/autolockstake" render={() => <AutoLockStake showNewPosition={true}/>} />
            <Route path="/app/strategies/trading" component={StrategiesV2Page}>
              <StrategiesV2Page
                tokensMaster={tokensMasterV2}
                portfoliofundv2={xwinfundv2}
                portfolios={strategiesV2}
                xwinstrategies={strategiesV2}
                fundtype="v2trading"
              />
            </Route>
            <Route path="/app/strategies/singlebase" component={StrategiesV2Page}>
              <StrategiesV2Page
                tokensMaster={tokensMasterV2}
                portfoliofundv2={xwinfundv2}
                portfolios={strategiesV2}
                xwinstrategies={strategiesV2}
                fundtype="v2singlebase"
              />
            </Route>
            <Route path="/app/vaults/privatevault" component={FundV2Page}>
              <FundV2Page
                tokensMaster={tokensMasterV2}
                portfolios={portfoliosV2}
                fundtype="v2private"
                fundtypeparam="v2private"
              />
            </Route>
            <Route path="/app/exposure" component={Exposure}>
              <Exposure/>
            </Route>
            <Route path="/app/vaults/allprivatevault" component={FundV2Page}>
              <FundV2Page
                tokensMaster={tokensMasterV2}
                portfolios={portfoliosV2}
                fundtype="v2allprivate"
                fundtypeparam="v2allprivate"
              />
            </Route>
            <Route path="/app/farmingv2/:id" component={FarmingPageV2}>
              <FarmingPageV2
                tokensMaster={tokensMasterV2}
                portfolios={stakingPools}
                fundtype="v2staking"
                category="all"
              />
            </Route>
            <Route path="/app/farmingbuddy" component={FarmingBuddyPageV2}>
              <FarmingBuddyPageV2
                tokensMaster={tokensMasterV2}
                portfolios={stakingBuddyPools}
                fundtype="v2buddystaking"
                category="all"
              />
            </Route>
            <Route path="/app/funddetailv2/:id/:chainId?" component={FundDetailPageV2}>
              <FundDetailPageV2 portfolios={portfoliosV2} tokensMaster={tokensMasterV2}/>
            </Route>
            <Route path="/app/strategydetailv2/:id/:chainId?" component={StrategyDetailPageV2}>
              <StrategyDetailPageV2 portfolios={strategiesV2} tokensMaster={tokensMasterV2}/>
            </Route>
          </Switch>
          <Fab
            color="primary"
            aria-label="settings"
            onClick={(e) => handleClick(e)}
            className={classes.changeThemeFab}
            style={{ zIndex: 100 }}
          >
            <Icon path={SettingsIcon} size={1} color="#fff" />
          </Fab>
          <ColorChangeThemePopper
            id={id}
            open={open}
            anchorEl={anchorEl}
          />
          <Footer>
            <div className={classes.background}>
              <Link
                href="https://www.t.me/xwinfinance"
                target="_blank"
              >
                <IconButton aria-label="telegram">
                  <Icon
                    path={TelegramIcon}
                    size={1}
                    color="#6E6E99"
                  />
                </IconButton>
              </Link>
              <Link
                href="https://twitter.com/xwinfinance"
                target="_blank"
              >
                <IconButton aria-label="twitter">
                  <Icon
                    path={TwitterIcon}
                    size={1}
                    color="#6E6E99"
                  />
                </IconButton>
              </Link>
              <Link
                href="https://github.com/xwinfinance/xwin-finance-v2"
                target="_blank"
              >
                <IconButton aria-label="github">
                  <Icon
                    path={GithubIcon}
                    size={1}
                    color="#6E6E99"
                  />
                </IconButton>
              </Link>
              <Link
                href="https://xwin.medium.com/"
                target="_blank"
              >
                <IconButton aria-label="github">
                  <Icon
                    path={MediumIcon}
                    size={1}
                    color="#6E6E99"
                  />
                </IconButton>
              </Link>
            </div>
            <div className={classes.background}>
              <Typography
                color="text"
                colorBrightness="hint"
                variant="caption"
              >
                Powered by &copy; xWIN Technology Sdn. Bhd
                {' '}
                {new Date().getFullYear()}
              </Typography>
              <br/>
            </div>
          </Footer>
          <MessageBar ref={refsnackbar} />
        </div>
      
    </div>
    </>       
  );
}

export default withRouter(Layout);
