import React, { useState, useEffect, useContext } from 'react'
import xWinLib from '../../../xWinLib'
import format from 'format-number'
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
// styles
import useStyles from '../styles'
import { Typography, Button, Link } from '../../../components/Wrappers/Wrappers'
import xWinUtils from '../../../xWinUtils'
import { Link as RouterLink } from 'react-router-dom'
import {
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    Badge,
} from '@material-ui/core'
import { getTokenInfo } from '../../../utils/erc20Interactor'
import { useTranslation } from 'react-i18next'
import { GetStrategiesDataAll, getWBETHAPR } from '../../../utils/strategyV2Interactor'
import { getAAVESupplyLendingRates, getSupplyLendingRates } from '../../../utils/xWinSingleAssetInteractor'
import millify from 'millify'
import { AppContext } from '../../../context/AppProvider'

const StrategieslistV2 = props => {
    var classes = useStyles()
    const {
        appGlobalConfig,
    } = useContext(AppContext)
    const {
        port,
        selectedWallet,
        web3static,
        tokensMaster,
        venusData,
        xwinstrategies
    } = props
    let tmp = []
    const [fundData, setFundData] = useState({
        fundvalue: 0,
        tokenNames: tmp,
        uniprice: 0,
        unipriceInUSD: 0,
        totalSupply: 0,
        fundETHbalance: 0,
        networkName: 'bscmain',
        baseCcy: 'USDT',
    })

    const [loading, setLoading] = React.useState(false)
    const [userData, setUserData] = useState({
        userfundbal: 0,
    })
    const { t, i18n } = useTranslation('tradings')

    useEffect(() => {
        loadFundData()
    }, [port.contractaddress, selectedWallet])

    const loadAPR = async () => {
        
        // let venusD = venusData?.data?.markets.find(x=>x.address.toLowerCase() === port.venusAddr?.toLowerCase())
        // if(venusD){
        //     return venusD?.supplyApy
        // }else 
        if(port.source === "aave"){
            const supplyLendingRates = await getAAVESupplyLendingRates(
                web3static,
                port.contractaddress
            )
            return  xWinLib.APRToAPY(Number(supplyLendingRates.supplyRate))
        }else{
            const supplyLendingRates = await getSupplyLendingRates(
                web3static,
                port.contractaddress
            )
            const proceeds = supplyLendingRates.supplyRatePerBlock * port.blockPerYear
            return  xWinLib.APRToAPY(Number(proceeds))
        }
    }

    const loadFundData = async () => {
        
        try {
            
            if (port.contractaddress === undefined) return
            setLoading(true)

            let supplyApy = 0
            if(port.fundtype === "v2singlebase" && port.template !== "other"){
                supplyApy = await loadAPR() 
            } else if (port.fundtype === "v2singlebase" && port.template === "other"){
                supplyApy = Number(await loadAPR()) + (await getWBETHAPR() * 100)
            }
            let myFundData = await GetStrategiesDataAll(web3static, port.contractaddress, xwinstrategies)
            if (selectedWallet !== undefined) {
                const promiseData = await Promise.all([
                    getTokenInfo(web3static, port.contractaddress, selectedWallet),
                    getTokenInfo(web3static, myFundData.baseToken, selectedWallet),
                ])
                let fundHolderData = promiseData[0]
                let myBaseCcyData = promiseData[1]

                setUserData({
                    userfundbal: fundHolderData.targetBal,
                    userbasebal: myBaseCcyData.targetBal,
                })
            } else {
                setUserData({
                    userfundbal: '0',
                    userbasebal: '0',
                })
            }

            const selected = tokensMaster.find(
                x => x.address.toLowerCase() === myFundData.baseToken?.toLowerCase()
            )
            const baseCcy = selected?.name
            const decimal = selected?.decimal
            setFundData({
                ...fundData,
                source: port.source,
                basedecimal: port.basedecimal,
                contractaddress: port.contractaddress,
                fundvalue: myFundData?.fundvalue?.toString(), 
                uniprice: myFundData?.uniprice.toString(), 
                unipriceInUSD: myFundData?.unipriceInUSD.toString(), 
                totalSupply: myFundData?.totalSupply.toString(), 
                baseToken: myFundData.baseToken,
                venusAPY : supplyApy || 0,
                baseCcy: baseCcy,
                decimal: decimal,
            })
        } catch (error) {
            setLoading(false)
        }

        setLoading(false)
    }

    const checkForImage = () => {
        if(port.fundtype === "v2singlebase" && port.template === "other"){
            return <Badge></Badge>
        }
        else {
            return <Badge
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            color="primary"
            overlap="rectangle"
        >
            <img
                alt={port.symbol.toUpperCase()}
                src={xWinLib.getIcon(port.source + ".png")}
                // className={classes.avatar}
                className={classes.icon1}
            />
        </Badge>
        }
    }

    const displayAPRBatch = () => {
        if (parseFloat(fundData.venusAPY) > 0) {
            return (
                <>
                <Badge
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    badgeContent={format({ prefix: '', suffix: '%' })(
                        xWinLib.roundTo(
                            fundData.venusAPY,
                            2
                        )
                    )}
                    color="secondary"
                    overlap="rectangle"
                >
                </Badge>
                {checkForImage()}
                </>
            )
        }
    }


    const displayBatch = () => {
        if (userData.userfundbal > 0) {
            return (
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    badgeContent={t('tradingList.deposit')}
                    color="primary"
                    overlap="rectangle"
                ></Badge>
            )
        }
    }

    return (
        <div>
            {xWinUtils.getProgressLinear(loading)}
            <ExpansionPanel className={classes.hoverHighlight}>
                <Link
                    underline="none"
                    component={RouterLink}
                    to={{
                        pathname: `/app/strategydetailv2/${port.contractaddress}/chainId=${appGlobalConfig?.chainId}`,
                        state: {
                            port: port,
                            portparam: port,
                            tokensMaster: tokensMaster,
                            fundtype: port?.fundtype,
                            fundtypeparam: port?.fundtype,
                        },
                    }}
                >
                    <ExpansionPanelSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{ expanded: classes.expanded }}
                    >
                        <div className={clsx(classes.column2)}>
                            {displayBatch()}
                            <br />
                            <img
                                alt={port.symbol.toUpperCase()}
                                src={xWinLib.getIcon(port.logo)}
                                className={classes.avatar}
                                // className={classes.icon2}
                            />
                            {/* <img
                                alt={port.symbol.toUpperCase()}
                                src={xWinLib.getIcon(port.source + ".png")}
                                // className={classes.avatar}
                                className={classes.icon1}
                            /> */}
                            <Typography
                                className={clsx(classes.subTitle, classes.columnShow)}
                                color="text"
                                colorBrightness={'hint'}
                                // variant={'caption'}
                                style={{ marginRight: 5 }}
                            >
                                <br />
                                {port.symbol}
                            </Typography>
                        </div>

                        <div
                            className={clsx(
                                classes.column3,
                                classes.columnHide,
                                classes.helper
                            )}
                        >
                            <Typography
                                color="text"
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                                colorBrightness={'hint'}
                                >
                                {port.name}
                            </Typography>
                            <Typography
                                className={clsx(classes.subTitle)}
                                colorBrightness={'hint'}
                                style={{ marginRight: 5 }}
                            >
                                {port.symbol}
                            </Typography>
                        </div>

                        <div className={clsx(classes.column6, classes.helper)}>
                            <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('tradingList.unit-price')}
                                </Typography>
                                <Typography
                                    // variant={'body2'}
                                    className={classes.subTitle}
                                >
                                    {xWinUtils.displayBalance(
                                        loading,
                                        format({ prefix: ' $', suffix: '' })(
                                            xWinLib.roundTo(
                                                fundData.unipriceInUSD,
                                                2
                                            )
                                        )
                                    )}
                                </Typography>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('tradingList.tvl')}
                                </Typography>
                                <Typography
                                    // variant={'body2'}
                                    className={classes.subTitle}
                                >
                                    {xWinUtils.displayBalance(
                                        loading,
                                        xWinLib.getMarketCap(fundData)
                                    )}
                                </Typography>
                            </Grid>
                        </div>
                        <div className={clsx(classes.column6, classes.helper)}>
                            {/* <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('tradingList.balance')}
                                </Typography>
                                <Typography
                                    // variant={'body2'}
                                    className={
                                        userData.userfundbal > 0
                                            ? classes.subSuccess
                                            : classes.subTitle
                                    }
                                >
                                    <img
                                        src={xWinLib.getIcon(port.logo)}
                                        alt={port.logo}
                                        className={classes.avatarSmall}
                                    />
                                    {xWinUtils.displayBalance(
                                        loading,
                                        format({ prefix: ' ', suffix: ` ` })(
                                            xWinLib.roundTo(
                                                userData.userfundbal,
                                                4
                                            )
                                        )
                                    )}
                                </Typography>
                            </Grid> */}
                            <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('tradingList.available')}
                                </Typography>
                                <Typography
                                    // variant={'body2'}
                                    className={
                                        userData.userbasebal > 0
                                            ? classes.subSuccess
                                            : classes.subTitle
                                    }
                                >
                                    <img
                                        src={xWinLib.getIcon(
                                            fundData.baseCcy?.toLowerCase() +
                                                '.png'
                                        )}
                                        alt={fundData.baseCcy}
                                        className={classes.avatarSmall}
                                    />

                                    {xWinUtils.displayBalance(
                                        loading,
                                        format({ prefix: ' ', suffix: '' })(millify(userData.userbasebal || 0, {precision: 1, lowercase: false}))
                                    )}
                                </Typography>
                            </Grid>
                        </div>
                        {displayAPRBatch()}
                    </ExpansionPanelSummary>
                </Link>
            </ExpansionPanel>
        </div>
    )
}
export default withRouter(StrategieslistV2)
