import xWinStableAlpha from "../abi/v2/xWinStableAlpha.json";
import { convertFromWei } from "../utils/helper";
import { getDecimal } from "./erc20Interactor";

export async function getCompositionValues(web3, contractaddress, baseDecimal, targetDecimal) {

  try {
    const contract = new web3.eth.Contract(xWinStableAlpha, contractaddress);
    const promiseData = await Promise.all([
      contract.methods.getStableValues().call(),
      contract.methods.getTargetValues().call(),
    ]);
    let stableValues = promiseData[0]
    let targetValues = promiseData[1]
    
    return {
      "stableValues": convertFromWei(stableValues || 0, Number(baseDecimal)),
      "targetValues": convertFromWei(targetValues || 0, Number(targetDecimal)),
    };

      
  } catch (error) {
    console.log(error)
    return {
      "stableValues": 0,
      "targetValues": 0,
    };
  }
}

export async function getNextInvestmentAmount(web3, contractaddress, myFundData) {

  if(myFundData === undefined) {
    return {
      "nextInvestAmount": 0
    };
  } 
	const contract = new web3.eth.Contract(xWinStableAlpha, contractaddress);
	let amount = await contract.methods.getAmountToSwap().call();
  const nextInvestAmount = Number(convertFromWei(amount || 0)) * Number(myFundData.unipriceInUSD)
  return {
    "nextInvestAmount": nextInvestAmount || 0
  };
}

export async function getNextInvestmentBlock(web3, contractaddress) {

	const contract = new web3.eth.Contract(xWinStableAlpha, contractaddress);
	let blocks = await contract.methods.getNextInvestBlock().call();
  return blocks;
}

export async function getStableTargetName(web3, contractaddress, tokensMaster) {

	const contract = new web3.eth.Contract(xWinStableAlpha, contractaddress);
  const promiseData = await Promise.all([
    contract.methods._baseTokenStaking().call(),
    contract.methods.targetToken().call(),
    contract.methods.baseToken().call(),
  ]);
  let stableCoinStaking = promiseData[0]
  let targetToken = promiseData[1]
  let baseToken = promiseData[2]
  return {
    "stakingCcy": tokensMaster.find(x=>x.address.toLowerCase() === stableCoinStaking.toLowerCase())?.name || "",
    "targetCcy" : tokensMaster.find(x=>x.address.toLowerCase() === targetToken.toLowerCase())?.name || "",
    "baseCcy" : tokensMaster.find(x=>x.address.toLowerCase() === baseToken.toLowerCase())?.name || ""
  };
}

