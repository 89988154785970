import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    expansion: {
      backgroundColor: theme.palette.background.default,
      color: 'white '
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
    },
    subTitle: {
      color: "#ffc247",
    },
    avatar: {
      height: 50,
      width: 50
    },
    columnHide: {
      [theme.breakpoints.down('xs')]: {
        display: "none"
      }
    },
    displayFont: {
      fontSize: '1.042rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      }
    },
    notificationItem: {
      marginTop: theme.spacing(2),
    },
    snackbarWarning: {
      backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
      color: "black"
    },
    positiveNum: {
      textAlign: "right",
      fontSize: '1.042rem',
      color: theme.palette.success.dark,
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      }
    },
    negativeNum: {
      fontSize: '1.042rem',
      color: theme.palette.secondary.dark,
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      }
    },
    customButton: {
      fontSize: '1.042rem',
      padding: theme.spacing(0),
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
        padding: theme.spacing(0),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.5rem',
        padding: theme.spacing(0),
      },
    },
  }
))