import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => (
{
  root: {
    '& .red-cell': {
      color: theme.palette.secondary.dark,
    },
    '& .green-cell': {
      color: theme.palette.success.dark,
    },
    '& .bold-text': {
      fontWeight: 'bold',
    },
  },
  avatar: {
    height: 40,
    width: 40,
},
}))