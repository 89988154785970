import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link } from 'react-router-dom'
import {
    AppBar,
    Toolbar,
    IconButton,
    Tooltip,
    Button,
    Typography,
    MenuItem,
    Menu,
    ListItemIcon,
    ListItemText,
    Divider,
    Select,
    FormControl,
    InputLabel,
} from '@material-ui/core'
import xWinLib from '../../xWinLib'
import format from 'format-number'
import SnackbarMessage from '../../pages/snackbarmessage/SnackbarMessage'
import ReactInterval from 'react-interval'

import { useTheme } from '@material-ui/styles'
import {
    Menu as MenuIcon,
    ArrowBack as ArrowBackIcon,
    FlashOff as FlashOffIcon,
} from '@material-ui/icons'
import classNames from 'classnames'
import xWinUtils from '../../xWinUtils'
import { AppContext } from '../../context/AppProvider'
// styles
import useStyles from './styles'

// context
import {
    useLayoutState,
    useLayoutDispatch,
    toggleSidebar,
} from '../../context/LayoutContext'

// import { getAddressShort, getNetworkById } from '../../utils/helper'
import LanguageIcon from '@mui/icons-material/Language'
import { useTranslation } from 'react-i18next'
import { Web3Button, useWeb3Modal } from '@web3modal/react'
import { Web3NetworkSwitch } from '@web3modal/react'
import { useAccount, useDisconnect } from "wagmi";
import { useNetwork } from 'wagmi'
import Banners from '../Banners/Banners'
import {useDropdownChainIdDispatch} from '../../context/ThemeContext'
import { IsSupportedChain } from '../../utils/helper'


export default function Header(props) {
    const { address, isConnecting, isDisconnected, isConnected } = useAccount()
    const { chain} = useNetwork()
    const [dropdown, setDropdown] = React.useState(localStorage.getItem('chainId'))
    const [language, setLanguage] = React.useState()
     const {
        appGlobalConfig,
        web3static
    } = useContext(AppContext)
    //   const { chain, globalProvider, EthProvider } = useNetwork()

    var classes = useStyles()
    var theme = useTheme()
    // global
    var layoutState = useLayoutState()
    var layoutDispatch = useLayoutDispatch()
    const [isSmall, setSmall] = useState(false)
    const { switchChain } = useContext(AppContext)
    const refsnackbar = useRef(null)
    const { t, i18n } = useTranslation(['navigations'])
    // let storageChainId = localStorage.getItem('chainId')

    useEffect(() => {
        window.addEventListener("resize", handleWindowWidthChange);
        handleWindowWidthChange();
        return function cleanup() {
          window.removeEventListener("resize", handleWindowWidthChange);
        };
    }, [])

    useEffect(() => {
        if(isConnected)return
        if(appGlobalConfig?.chainId === undefined) return
        if(IsSupportedChain(Number(appGlobalConfig.chainId))){
            setDropdown(Number(appGlobalConfig.chainId))
            switchChain(Number(appGlobalConfig.chainId))    
        }
    },[appGlobalConfig])

  
    const handleWindowWidthChange = () => {
        
        var windowWidth = window.innerWidth;
        var breakpointWidth = theme.breakpoints.values.md;
        var isSmallScreen = windowWidth < breakpointWidth;
        setSmall(isSmallScreen)
    }

    const getWalletMessage = () => {
        if (isConnected) {
            return (
                <Web3NetworkSwitch  />   
            )
        } 
        else {
            return (
                <Web3Button icon="show" label="Connect" balance="show" />
            )
        }
    }

    const handleChangeLanguage = async event => {
        event.preventDefault()
        i18n.changeLanguage(event.target.value)
        setLanguage(event.target.value)
    }

    const languageSelect = () => {
        return (
            <FormControl
                    size="small"
                    variant="standard"
                    style={{minWidth: 50}}
                >
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        value={language}
                        fullWidth
                        defaultValue={"en"}
                        onChange={handleChangeLanguage}
                        sx={{
                            color: theme.palette.type === 'dark' && 'white',
                            backgroundColor:
                                theme.palette.type === 'dark' && '#2f2f36',
                        }}
                    >
                        <MenuItem value={"en"}>
                            EN
                        </MenuItem>
                        <MenuItem value={"ja"}>
                            JA
                        </MenuItem>
                    </Select>
                </FormControl>

        )
    }

    const handleChangeDropdown = async event => {
        event.preventDefault()
        setDropdown(event.target.value)
        // changeNetworkConfig(event.target.value)
        localStorage.setItem("chainId", event.target.value.toString());
        switchChain(event.target.value)
    }

    const languageChain = () => {
        
        if (isConnected) {
            return ""
        }else{
            return (
                <FormControl
                    size="small"
                    variant="standard"
                    style={{minWidth: 50}}
                >
                    <Select
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                        value={dropdown}
                        fullWidth
                        label="Chain"
                        defaultValue={dropdown}
                        onChange={handleChangeDropdown}
                        sx={{
                            color: theme.palette.type === 'dark' && 'white',
                            backgroundColor:
                                theme.palette.type === 'dark' && '#2f2f36',
                        }}
                    >
                        <MenuItem value={56}>
                            <img
                                src={xWinLib.getIcon("bnb.png")}
                                className={classes.smallavatar}
                            />
                        </MenuItem>
                        <MenuItem value={42161}>
                            <img
                                src={xWinLib.getIcon("arb.png")}
                                className={classes.smallavatar}
                            />
                        </MenuItem>
                        <MenuItem value={137}>
                            <img
                                src={xWinLib.getIcon("wmatic.png")}
                                className={classes.smallavatar}
                            />
                        </MenuItem>
                    </Select>
                </FormControl>
            )
        }
    }
    

    const handleClose2 = () => {
        setAnchorEl2(null)
    }


    const handleClick2 = event => {
        setAnchorEl2(event.currentTarget)
    }

    const closeBar = () => {
        refsnackbar.current.hideMessageTxn()
    }

    const displayxwinLogo = () => {
        
        if(isSmall){
            return xWinUtils.getIcons('xwin')
        }
        return (
            <img
                src={'../../../images/background/xWin Logo.png'}
                alt="logo"
                className={classes.profileLabel}
            />
        )
    }

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Banners />
            <Toolbar className={classes.toolbar}>
                <SnackbarMessage ref={refsnackbar} />
                <ReactInterval
                    timeout={5000}
                    enabled={true}
                    callback={() => closeBar()}
                />

                <IconButton
                    color="inherit"
                    onClick={() => toggleSidebar(layoutDispatch)}
                    className={classNames(
                        classes.headerMenuButton,
                        classes.headerMenuButtonCollapse
                    )}
                >
                    {(!layoutState.isSidebarOpened && isSmall) ||
                    (layoutState.isSidebarOpened && !isSmall) ? (
                        <ArrowBackIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse
                                ),
                            }}
                        />
                    ) : (
                        <MenuIcon
                            classes={{
                                root: classNames(
                                    classes.headerIcon,
                                    classes.headerIconCollapse
                                ),
                            }}
                        />
                    )}
                </IconButton>
                <Link to="/">
                    {displayxwinLogo()}
                </Link>
                <div className={classes.grow} />
                &nbsp;
                {languageSelect()}
                &nbsp;
                {languageChain()}
                &nbsp;
                {getWalletMessage()}
            </Toolbar>
        </AppBar>
    )
}
