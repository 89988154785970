import React, { useState, useEffect,useContext } from 'react'
import xWinLib from '../../../xWinLib'
import format from 'format-number'
import clsx from 'clsx'
import { withRouter } from 'react-router-dom'
// styles
import useStyles from '../styles'
import { Typography, Link } from '../../../components/Wrappers/Wrappers'
import xWinUtils from '../../../xWinUtils'
import { Link as RouterLink } from 'react-router-dom'
import {
    Grid,
    ExpansionPanel,
    ExpansionPanelSummary,
    Badge,
    Tooltip,
} from '@material-ui/core'
import { GetFundDataAll } from '../../../utils/fundV2Interactor'
import { getTokenInfo } from '../../../utils/erc20Interactor'
import { useTranslation } from 'react-i18next'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import { useTheme } from "@material-ui/styles";
import millify from 'millify'
import {AppContext} from '../../../context/AppProvider'

const TradinglistV2 = props => {
    
    var theme = useTheme()
    var classes = useStyles()
    const {
        appGlobalConfig,
    } = useContext(AppContext)
    const { fundtype, port, selectedWallet, web3static, tokensMaster } = props
    let tmp = []
    const [fundData, setFundData] = useState({
        fundvalue: 0,
        tokenNames: tmp,
        uniprice: 0,
        unipriceInUSD: 0,
        totalSupply: 0,
        fundETHbalance: 0,
        networkName: 'bscmain',
        baseCcy: 'USDT',
    })

    const [loading, setLoading] = React.useState(false)
    const [userData, setUserData] = useState({
        userfundbal: 0,
    })
    const { t, i18n } = useTranslation('tradings')

    useEffect(() => {
        loadFundData()
    }, [port, selectedWallet])

    const loadFundData = async () => {
        
        if(web3static === undefined) return

        setUserData({
            userfundbal: '0',
            userbasebal: '0',
        })

        if (port.contractaddress === undefined) return
        setLoading(true)
        let myFundData = await GetFundDataAll(web3static, port.contractaddress)
        if(myFundData == undefined) return
        
        if (selectedWallet !== undefined) {
            const promiseData = await Promise.all([
                getTokenInfo(web3static, port.contractaddress, selectedWallet),
                getTokenInfo(web3static, myFundData.baseToken, selectedWallet),
            ])
            let fundHolderData = promiseData[0]
            let myBaseCcyData = promiseData[1]

            setUserData({
                userfundbal: fundHolderData.targetBal,
                userbasebal: myBaseCcyData.targetBal,
            })
        }

        let tokenNames = []
        const selected = tokensMaster.find(
            x => x.address.toLowerCase() === myFundData.baseToken?.toLowerCase()
        )
        const baseCcy = selected?.name
        const decimal = selected?.decimal
        const stablecoinUSD = tokensMaster.find(
            x =>x.address.toLowerCase() === myFundData.stablecoinUSDAddr.toLowerCase()
        )?.name
        setFundData({
            ...fundData,
            contractaddress: port.contractaddress,
            fundvalue: myFundData?.fundvalue?.toString(), //fundvalue / PRECISION,
            tokenNames: tokenNames,
            uniprice: myFundData?.uniprice.toString(), //uniprice / PRECISION,
            unipriceInUSD: myFundData?.unipriceInUSD.toString(), //uniprice / PRECISION,
            totalSupply: myFundData?.totalSupply.toString(), // totalSupply / PRECISION,
            fundETHbalance: myFundData?.fundETHbalance.toString(), //fundETHbalance / PRECISION
            baseToken: myFundData?.baseToken,
            baseCcy: baseCcy,
            decimal: decimal,
            stablecoinUSD: stablecoinUSD,
        })

        setLoading(false)
    }

    const displayLogo = () => {
        if (fundtype === 'v2allprivate' || fundtype === 'v2private') {
            return (
                <Jazzicon
                    diameter={40}
                    seed={jsNumberForAddress(port.contractaddress)}
                />
            )
        } else {
            return (
                <img
                    alt={port.symbol.toUpperCase()}
                    src={xWinLib.getIcon(port.logo)}
                    className={classes.avatar}
                />
            )
        }
    }

    const displaySmallLogo = () => {
        if (fundtype === 'v2allprivate' || fundtype === 'v2private') {
            return (
                <Jazzicon
                    diameter={20}
                    seed={jsNumberForAddress(port.contractaddress)}
                />
            )
        } else {
            return (
                <img
                    alt={port.symbol.toUpperCase()}
                    src={xWinLib.getIcon(port.logo)}
                    className={classes.avatarSmall}
                />
            )
        }
    }

    const displayBatch = () => {
        if (userData.userfundbal > 0) {
            return (
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    badgeContent={t('tradingList.deposit')}
                    color="primary"
                    overlap="rectangle"
                ></Badge>
            )
        }
    }

    const displayCcyIcon = (baseCcy) => {

        return (
            <Tooltip arrow title={baseCcy}>
                <img
                    src={xWinLib.getIcon(
                        baseCcy?.toLowerCase() +
                            '.png'
                    )}
                    alt={baseCcy}
                    className={classes.avatarSmall}
                />
            </Tooltip>
        )
    }


    return (
        <div>
            {xWinUtils.getProgressLinear(loading)}
            <ExpansionPanel className={classes.hoverHighlight}>
                <Link
                    underline="none"
                    component={RouterLink}
                    to={{
                        pathname: `/app/funddetailV2/${port.contractaddress}/chainId=${appGlobalConfig.chainId}`,
                        state: {
                            port: port,
                            portparam: port,
                            tokensMaster: tokensMaster,
                            fundtype: fundtype,
                            fundtypeparam: fundtype,
                        },
                    }}
                >
                    <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        classes={{ expanded: classes.expanded }}
                    >
                        <div className={clsx(classes.column2)}>
                            {displayBatch()}
                            <br />
                            {displayLogo()}
                            <Typography
                                className={clsx(classes.columnShow, classes.subTitle)}
                                color="text"
                                colorBrightness={'hint'}
                                style={{ marginRight: 5 }}
                            >
                                <br />
                                {port.symbol}
                            </Typography>
                        </div>

                        <div
                            className={clsx(
                                classes.column3,
                                classes.columnHide,
                                classes.helper
                            )}
                        >
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                            >
                                {port.name}
                            </Typography>
                            <Typography
                                colorBrightness={'hint'}
                                className={clsx(classes.subTitle)}
                                style={{ marginRight: 5 }}
                            >
                                {port.symbol}
                            </Typography>
                        </div>

                        <div className={clsx(classes.column6, classes.helper)}>
                            <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('tradingList.unit-price')}
                                </Typography>
                                <Typography
                                    className={classes.subTitle}
                                >
                                    {xWinUtils.displayBalance(
                                        loading,
                                        format({ prefix: xWinLib.getIsJPYC(fundData?.baseCcy) ? "¥" : "$" , suffix: '' })(
                                            xWinLib.roundTo(
                                                xWinLib.getIsJPYC(fundData?.baseCcy) ? fundData.uniprice : fundData.unipriceInUSD,
                                                2
                                            )
                                        )
                                    )}
                                </Typography>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('tradingList.tvl')}
                                </Typography>
                                <Typography
                                    className={classes.subTitle}
                                >
                                    {xWinUtils.displayBalance(
                                        loading,
                                        xWinLib.getMarketCap(fundData, !xWinLib.getIsJPYC(fundData?.baseCcy))
                                    )}
                                </Typography>
                            </Grid>
                        </div>
                        <div className={clsx(classes.column6, classes.helper)}>
                            {/* <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                >
                                    {t('tradingList.balance')}
                                </Typography>
                                <Typography
                                    className={
                                        userData.userfundbal > 0
                                            ? classes.subSuccess
                                            : classes.subTitle
                                    }
                                >
                                    {displaySmallLogo()}
                                    { xWinUtils.displayBalance(
                                        loading,
                                        format({ prefix: ' ', suffix: '' })(millify(userData.userfundbal || 0, {precision: 1, lowercase: false}))
                                    )}
                                </Typography>
                            </Grid> */}
                            <Grid item>
                                <Typography
                                    color="text"
                                    colorBrightness={'hint'}
                                    variant={'caption'}
                                    style={{ marginRight: 5 }}
                                    className={classes.displayFont}
                                >
                                    {t('tradingList.available')}
                                </Typography>
                                <Typography
                                    className={
                                        userData.userbasebal > 0
                                            ? classes.subSuccess
                                            : classes.subTitle
                                    }
                                >
                                    {displayCcyIcon(fundData?.baseCcy)}
                                    {xWinUtils.displayBalance(
                                        loading,
                                        format({ prefix: ' ', suffix: '' })(millify(userData.userbasebal || 0, {precision: 1, lowercase: false}))
                                    )}
                                </Typography>
                            </Grid>
                        </div>
                    </ExpansionPanelSummary>
                </Link>
            </ExpansionPanel>
        </div>
    )
}
export default withRouter(TradinglistV2)
