import React, { useState, useEffect, useContext } from 'react'
import xWinLib from '../../../xWinLib'
import format from 'format-number'
import xWinUtils from '../../../xWinUtils'
import CountUp from 'react-countup'
import { Grid, Box, CardContent, Chip, Tooltip } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
// styles
import useStyles from '../styles'
import Widget from '../../../components/Widget/Widget'
import { Typography, Avatar } from '../../../components/Wrappers/Wrappers'
import Dot from '../../../components/Sidebar/components/Dot'
import { AppContext } from '../../../context/AppProvider'

export default function TokenStats(props) {
    var classes = useStyles()
    const { t, i18n } = useTranslation('dashboard')
    const { appGlobalConfig } = useContext(AppContext)
    const { xwinStats, overallStat } = props

    return (
        <Widget
            title={t('tokenStats.title')}
            disableWidgetMenu
            bodyClass={classes.fullHeightBody}
            className={classes.card}
        >
            <CardContent>
            
            {appGlobalConfig?.chainId === 56 && (
                <>
                <Grid container spacing={4}>
                <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                    <Typography
                        color="text"
                        colorBrightness={'hint'}
                        variant={'caption'}
                        style={{ marginTop: 7, marginRight: 5 }}
                    >
                        {t('tokenStats.price')}
                    </Typography>
                </Grid>
                <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <Typography
                            size="md"
                            weight={'medium'}
                            style={{ marginLeft: 8 }}
                        >
                            <Chip
                                size="large"
                                label={xWinUtils.displayBalance(
                                    false,
                                    format({ prefix: '$', suffix: '' })(
                                        xWinLib.roundTo(
                                            overallStat.xwinPrice,
                                            2
                                        )
                                    )
                                )}
                                avatar={
                                    <Avatar src="/images/icons/color/xwin.png" />
                                }
                            />
                        </Typography>
                    </Box>
                </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginRight: 5 }}
                        >
                            {t('tokenStats.rewards-supply')}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            {/* <Dot size="medium" color="primary" /> */}
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 8 }}
                            >
                                <CountUp
                                    separator=","
                                    decimals={0}
                                    decimal="."
                                    prefix=" "
                                    duration={1.75}
                                    end={xWinLib.roundTo(
                                        xwinStats?.rewardSupply,
                                        0
                                    )}
                                />
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginRight: 5 }}
                        >
                            {t('tokenStats.circulation-supply')}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            {/* <Dot size="medium" color="primary" /> */}
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 8 }}
                            >
                                <CountUp
                                    separator=","
                                    decimals={0}
                                    decimal="."
                                    prefix=" "
                                    duration={1.75}
                                    end={xWinLib.roundTo(
                                        xwinStats.publicCirculation,
                                        0
                                    )}
                                />
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginRight: 5 }}
                        >
                            {t('tokenStats.total-burned')}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Dot size="medium" color="warning" />
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 8 }}
                            >
                                <CountUp
                                    separator=","
                                    decimals={0}
                                    decimal="."
                                    prefix=" "
                                    duration={1.75}
                                    end={xWinLib.roundTo(
                                        overallStat.burnBal,
                                        0
                                    )}
                                />
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item lg={8} sm={8} xs={8} alignItems="flex-end">
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginRight: 5 }}
                        >
                            {t('tokenStats.total-market-cap')}
                        </Typography>
                    </Grid>
                    <Grid item lg={4} sm={4} xs={4} alignItems="flex-end">
                        <Tooltip arrow title={format({ prefix: '$', suffix: '' })(xWinLib.roundTo(xwinStats.publicCirculation * overallStat.xwinPrice,0))}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'right'}
                        >
                            <Typography
                                size="md"
                                weight={'medium'}
                                style={{ marginLeft: 8 }}
                            >
                                {xWinLib.displayBigNumber(
                                    xwinStats.publicCirculation *
                                        overallStat.xwinPrice
                                )}
                            </Typography>
                        </Box>
                        </Tooltip>
                    </Grid>
                </Grid>
                </>
                )}
        </CardContent>
    </Widget>
    )
}
