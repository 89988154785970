import React from "react";
import { useTheme } from "@material-ui/styles";
import xWinLib from '../../../xWinLib';
import ReactApexChart from "react-apexcharts";

// styles
import useStyles from "../styles";
import Widget from "../../../components/Widget/Widget";
import xWinUtils from "../../../xWinUtils";
import { Card, CardContent } from "@material-ui/core";


export default function TargetAllocation(props) {
  var classes = useStyles();
  var theme = useTheme();
  const { fundData, tokensMaster, networkName, loading} = props;

  let myPayLabel = []
  let seriesValue = []

  if(fundData.tokenNames !== undefined){
    fundData.tokenNames.forEach(t => {

      let tokenname = xWinLib.getTokenName(tokensMaster, t.address)
      let targetweight = xWinLib.roundTo(t.targetweight / 100,2) 
      myPayLabel.push(tokenname)
      seriesValue.push(parseFloat(targetweight))
        
    })
  }
  const values = {
    series2: seriesValue, //[144, 55, 41, 17, 15],
    // series2: [144, 55, 41, 17, 15],
  };

  const themeOptions = theme => {
    return {
      labels: myPayLabel, 
      maintainAspectRatio: false,
      legend: {
        display: true,
        position: 'bottom'
      },
      chart: {
        foreColor: '#ccc',
        toolbar: {
          show: false,
        },
      },
      responsive: [
        {
          // breakpoint: 480,
          // options: {
          //   chart: {
          //     width: 200
          //   },
          //   legend: {
          //     position: "bottom"
          //   }
          // }
        }
      ],
      colors: [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.warning.main,
        theme.palette.success.light,
        theme.palette.info.main
      ],
    };
  };

  return (
    <Widget
          disableWidgetMenu
          className={classes.card}
          bodyClass={classes.alignStandaloneElement}
          subtitle= {"Target Allocation"}
        >
          {fundData.tokenNames === undefined ? (
            <Card>  
              <CardContent className={classes.cards}>
                  {xWinUtils.getProgressLinear(true)}
              </CardContent>
          </Card>
          ):(
            <ReactApexChart
              options={themeOptions(theme)}
              series={values.series2}
              type="donut"
              height="350"
              stroke={""}
            />
          )}
          
          
    </Widget>
  );
}
