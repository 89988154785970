import React, { useState, useEffect, useContext } from 'react'
import { useTheme } from '@material-ui/styles'
import { MoneySharp } from '@material-ui/icons/'
import { Typography } from '../../../components/Wrappers/Wrappers'
import { Box, Grid, TextField, InputAdornment } from '@material-ui/core'
import useStyles from '../styles'
import { AppContext } from '../../../context/AppProvider'
import {
    getAAVESupplyLendingRates,
    getAccruedComp,
    getFundAndUnderlyingValue,
    getStrategyInfo,
    getStrategyInfoAAVE,
    getSupplyLendingRates,
} from '../../../utils/xWinSingleAssetInteractor'
// import appGlobalConfig from '../../../configs/56/appGlobalConfig';
import xWinUtils from '../../../xWinUtils'
import xWinLib from '../../../xWinLib'
import { useTranslation, Trans } from 'react-i18next'
import clsx from 'clsx';
import format from 'format-number';

export default function SingleAssetAAVE(props) {
    var classes = useStyles()
    var theme = useTheme()
    const { singleaddress, tokensMaster, xwinstrategies, underlyingaddress } = props
    const { t, i18n } = useTranslation('fundDetails', {
        transSupportBasicHtmlNodes: true,
    })
    const {
        isConnected,
        account,
        theWeb3,
        web3static,
        ETHBalance,
        refreshAccount,
        appGlobalConfig
    } = useContext(AppContext)
    const [fundData, setFundData] = useState({
        accruedComp: '0',
        supplyRate: '0',
        borrowRate: '0',
        fundValue: '0',
        underlyingValue: '0',
        targetaddress: appGlobalConfig.mainnetAddressZero,
        rewardaddress: appGlobalConfig.mainnetAddressZero,
        targetname: 'usdc.e',
        rewardname: 'aave',
    })

    const [values, setValues] = useState({
        series: [
            {
                data: [],
            },
        ],
    })

    useEffect(() => {
        init()
    }, [singleaddress])

    let myPayLabel = []
    let seriesValue = []
    let myBalDataValueOnly = []

    const init = async () => {

        const strategyMaster = xwinstrategies.find(x=>x.contractaddress === singleaddress)
        const strategyInfo = await getStrategyInfoAAVE(web3static, singleaddress)
        // const accruedComp = await getAccruedComp(web3static, singleaddress)
        const supplyLendingRate = await getAAVESupplyLendingRates(
            web3static,
            singleaddress
        )

        const fundValues = await getFundAndUnderlyingValue(
            web3static,
            singleaddress,
            strategyMaster.contractaddress,
            strategyMaster.underlying
        )
        let rewardToken = tokensMaster.find(
            x => x.address === strategyInfo.rewardaddress
        )
        let targetToken = tokensMaster.find(
            x => x.address === strategyInfo.targetaddress
        )
        let blockPerYear = xwinstrategies.find(
            x => x.contractaddress === singleaddress
        )?.blockPerYear
        
        setFundData({
            supplyRate: xWinLib.APRToAPY(Number(supplyLendingRate.supplyRate)),
            fundValue: fundValues.vaultValue,
            underlyingValue: fundValues.underlyingValue,
            targetaddress:
                strategyInfo?.targetaddress ||
                appGlobalConfig.mainnetAddressZero,
            rewardaddress:
                strategyInfo?.rewardaddress ||
                appGlobalConfig.mainnetAddressZero,
            targetname: targetToken?.name || '',
            rewardname: rewardToken?.name || '',
            blockPerYear: blockPerYear || 0,
        })
        seriesValue.push(fundValues.vaultValue)
        seriesValue.push(fundValues.underlyingValue)
        myPayLabel.push('Fund Values')
        myPayLabel.push('Underlying Values')

        myBalDataValueOnly.push(seriesValue)
        setValues({
            series: [
                {
                    data: myBalDataValueOnly,
                },
            ],
        })
    }

    const getAPY = () => {
        return format({ prefix: "", suffix: '' })(xWinLib.roundTo(fundData.supplyRate, 2))
    }

    return (
        <Grid container spacing={2}>
            <Grid item lg={6} sm={12} xs={12} className={classes.gridlite}>
                <Typography
                    color="text"
                    colorBrightness={'hint'}
                    className={classes.contentText}
                >
                    <Trans i18nKey="strategies.singleAsset.desc-arb">
                        {t('strategies.singleAsset.desc-arb', {
                            linkClass: classes.link,
                            targetname: fundData.targetname.toUpperCase(),
                            apy: getAPY(),
                        })}
                    </Trans>
                </Typography>
            </Grid>
            <Grid item lg={6} sm={12} xs={12} className={classes.gridlite}>
                <Grid item>
                    <Typography
                        className={clsx(classes.displayFont, "p-2")}
                        color="text"
                        colorBrightness={'hint'}
                    >
                        {t('strategies.singleAsset.vault-balance')}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <TextField
                            value={Number(fundData.fundValue).toFixed(2)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.positiveNum,
                                },
                                readOnly: true,
                                style: { textAlign: 'right' },
                                startAdornment: (
                                    <InputAdornment position="end">
                                        {xWinUtils.getIcons(
                                            fundData?.targetname.toLowerCase()
                                        )}

                                        {/* <img height="32px" width="32px" src={"/images/icons/color/"+ fundData.targetname.toLowerCase() +".png"} /> */}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.displayFont}>{fundData.targetname.toUpperCase()}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item>
                    <Typography
                        className={clsx(classes.displayFont, "p-2")}
                        color="text"
                        colorBrightness={'hint'}
                    >
                        {t('strategies.singleAsset.interest')}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <TextField
                            value={getAPY()}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.positiveNum,
                                },
                                readOnly: true,
                                style: { textAlign: 'right' },
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.displayFont}>{t('strategies.singleAsset.apy')}</Typography>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        %
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}
