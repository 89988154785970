import React, { createContext, useState } from "react";
import useNetwork from '../useNetwork';

// see also https://stackoverflow.com/questions/63007702

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  // appMsg is {variant, message}
  // variant is "default" | "error" | "success" | "warning" | "info"
  const [appMsg, setAppMsg_] = useState(["info", ""])
  const [{ xwinbuddystakingv2, xwinstakingv2, tokensv2, xwinfundv2, xwinstrategies, ETHBalance, XWINBalance, USDTBalance, tokenBalances, network, theWeb3, web3static, explorerScan, theSigner, isConnected, globalProvider, appGlobalConfig }, refreshAccount, switchChain, changeNetworkConfig, connectNetwork, disconnectNetwork] = useNetwork()

  const setAppMsg = (appMsg_) => {
    setAppMsg_(appMsg_)
  }

  const value = {
    appGlobalConfig, xwinbuddystakingv2, xwinstakingv2, tokensv2, xwinfundv2, xwinstrategies, globalProvider, ETHBalance, tokenBalances, XWINBalance, USDTBalance, network, explorerScan, theSigner, theWeb3, web3static, isConnected, appMsg,
    refreshAccount, connectNetwork, disconnectNetwork, changeNetworkConfig, switchChain, setAppMsg
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
