import React, { useState, useEffect, useRef, useContext } from 'react'
import TradinglistV2 from './components/TradinglistV2'
import { AppContext } from '../../context/AppProvider'
import useStyles from './styles'
import {
    Search as SearchIcon,
} from '@material-ui/icons'
import { Typography } from '../../components/Wrappers/Wrappers'

import {
    Grid,
    Container,
    TextField as Input,
    InputAdornment,
    Box,
    IconButton,
} from '@material-ui/core'

// components
import Widget from '../../components/Widget/Widget'
import {
    getLatestPrivateFunds,
    getPrivateFunds,
    getPrivateFundsBySearchKey,
} from '../../utils/moralisInteractor'
// import appGlobalConfig from '../../configs/56/appGlobalConfig';
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/vaultFAQ'
import xWinUtils from '../../xWinUtils'
import { useTranslation } from 'react-i18next'
import { useAccount, useDisconnect } from "wagmi";
import { useNetwork, useSwitchNetwork } from 'wagmi'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'

export default function TradingsV2(props) {
    const classes = useStyles()
    const { portfolios, tokensMaster, fundtype } = props
    const [filterPortfolios, setFilterPortfolios] = useState([])
    const [
        filterPortfoliosByCategory,
        setFilterPortfoliosByCategory,
    ] = useState([])
    const {
        ETHBalance,
        theWeb3,
        web3static,
        refreshAccount,
        appGlobalConfig,
    } = useContext(AppContext)
    const [loading, setLoading] = React.useState(false)
    const [faqs, setFaqs] = useState([])
    const [searchKey, setSearchKey] = React.useState('')
    const { t, i18n } = useTranslation('tradings')
    const { address, isConnecting, isDisconnected, isConnected } = useAccount()
    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }
    useEffect(() => {
        loadWeb3()
    }, [portfolios, address, appGlobalConfig, fundtype])

    const loadWeb3 = async () => {

        if(appGlobalConfig === undefined) return
        setLoading(true)
        let filterport = []
        if (fundtype === 'v2private' && address !== undefined) {
            filterport = await getPrivateFunds(address, appGlobalConfig)
        } else if (fundtype === 'v2allprivate') {
            filterport = await getLatestPrivateFunds(appGlobalConfig)
        } else {
            filterport = portfolios.filter(
                x =>x.fundtype === fundtype
            )
        }
        setFilterPortfolios(filterport)
        setFilterPortfoliosByCategory(filterport)
        let fqs = vaultfaqconfig.faqs.filter(x => x.symbol === fundtype && x.env === appGlobalConfig.chainId)
        setFaqs(fqs)

        setLoading(false)
    }

    const displayPortfolios = () => {
        if (loading) return ''
        if (filterPortfoliosByCategory === undefined) return ''

        let funds = filterPortfoliosByCategory

        if (funds.length > 0) {
            return funds.map(port => (
                <Grid
                    item
                    lg={6}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight}
                >
                    <TradinglistV2
                        showBalWhenSmall={false}
                        port={port}
                        portfolios={funds}
                        tokensMaster={tokensMaster}
                        globalWeb3={theWeb3}
                        web3static={web3static}
                        selectedWallet={address}
                        fundtype={fundtype}
                        ETHBalance={ETHBalance}
                        refreshAccount={refreshAccount}
                    />
                </Grid>
            ))
        } else {
            return (
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    // className={classes.hoverHighlight}
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'center'}
                    >
                        <img
                            style={{ maxWidth: '250px' }}
                            alt={'no data'}
                            src={'/images/background/nodata.png'}
                        />
                    </Box>
                </Grid>
            )
        }
    }

    const getTitle = () => {
        let name = ''
        if (fundtype === 'v2index') name = `${t('v2index')}`
        else if (fundtype === 'v2trading') name = `${t('v2trading')}`
        else if (fundtype === 'v2tradingmanual')
            name = `${t('v2tradingmanual')}`
        else if (fundtype === 'v2single') name = `${t('v2single')}`
        else if (fundtype === 'v2singlebase') name = `${t('v2singlebase')}`
        else if (fundtype === 'v2private') name = `${t('v2private')}`
        else if (fundtype === 'v2allprivate') name = `${t('v2allprivate')}`
        else if (fundtype === 'v2stablealpha') name = `${t('v2stablealpha')}`

        return name
    }

    const handleOnchange = async event => {
        event.preventDefault()

        const value = event.target.value
        let filterport = filterPortfolios.filter(x =>
            x.symbol.toLowerCase().includes(value.toLowerCase())
        )

        if (filterport.length === 0) {
            filterport = filterPortfolios.filter(x =>
                x.name.toLowerCase().includes(value.toLowerCase())
            )
        }
        setFilterPortfoliosByCategory(filterport)
    }

    const handleOnchangeSearchText = async event => {
        event.preventDefault()
        const value = event.target.value
        setSearchKey(value)
        if (value === '') {
            setFilterPortfoliosByCategory(filterPortfolios)
        }
        let filterport = filterPortfolios.filter(x =>
            x.symbol.toLowerCase().includes(value.toLowerCase())
        )
        if(filterport.length === 0 && value.length > 2){
            setLoading(true)
            filterport = await getPrivateFundsBySearchKey(value, appGlobalConfig)
            setLoading(false)
        }
        setFilterPortfoliosByCategory(filterport)
    }

    const handleOnchangeSearch = async event => {
        event.preventDefault()
        
        if(searchKey.trim() === ""){
            showSnackBar('error', `${t('searchBar.inputSearch')}`)
            return
        }

        setLoading(true)
        let filterport = await getPrivateFundsBySearchKey(searchKey, appGlobalConfig)
        setFilterPortfoliosByCategory(filterport)
        setLoading(false)
    }

    const handleOnchangeClear = async event => {
        event.preventDefault()
        setSearchKey('')
        setFilterPortfoliosByCategory(filterPortfolios)
    }

    const displaySearchBar = () => {
        if (fundtype === 'v2allprivate') {
            return (
                <Input
                    placeholder={`${t('searchBar.symbol-vault')}`}
                    id="search-field"
                    fullWidth
                    value={searchKey}
                    className={classes.textField}
                    onChange={handleOnchangeSearchText}
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle search"
                                    onClick={handleOnchangeSearch}
                                >
                                    <Typography
                                        color={'primary'}
                                        variant={'caption'}
                                    >
                                        {t('searchBar.search')}
                                    </Typography>
                                </IconButton>
                                <IconButton
                                    aria-label="toggle clear"
                                    onClick={handleOnchangeClear}
                                >
                                    <Typography
                                        color={'primary'}
                                        variant={'caption'}
                                    >
                                        {t('searchBar.clear')}
                                    </Typography>
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )
        } else {
            return (
                <Input
                    placeholder={`${t('searchBar.symbol-vault')}`}
                    id="search-field"
                    fullWidth
                    onChange={handleOnchange}
                    className={classes.textField}
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                        ),
                    }}
                />
            )
        }
    }

    return (
        <Container>
            {xWinUtils.getProgress(loading)}
            <SnackbarMessage ref={refsnackbar} />
            <Grid container spacing={2}>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight2}
                >
                    <Widget
                        spacing={0}
                        className={classes.newsHeader}
                        title={getTitle()}
                        disableWidgetMenu
                        inheritHeight
                        enableWalletDisplay
                        selectedWallet={address}
                        globalWeb3={web3static}
                    >
                        {displaySearchBar()}
                    </Widget>
                </Grid>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight2}
                >
                    <Grid container spacing={2}>
                        {displayPortfolios()}
                    </Grid>
                </Grid>
            </Grid>
            <VaultFaq faqs={faqs} />
        </Container>
    )
}
