import React, { useState ,useEffect, useContext} from 'react'
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import Widget from '../../components/Widget/Widget'
import axios from 'axios';
import xWinLib from '../../xWinLib';
import "./styles.js"
import useStyles from './styles'
import { useTranslation } from 'react-i18next'
import xWinUtils from '../../xWinUtils';
import { AppContext } from '../../context/AppProvider'
import vaultfaqconfig from '../vaultfaq/faq/leaderboardFAQ'
import VaultFaq from '../vaultfaq/VaultFaq';
import { Container } from '@material-ui/core';
import Jazzicon from 'react-jazzicon/dist/Jazzicon';
import { jsNumberForAddress } from 'react-jazzicon';


const Leaderboard = props => {
    const { tokensMaster, portfolios } = props
    
    const [rowsData , setRowsData] = useState([])
    const [loading , setLoading] = useState(false)
    const classes = useStyles();
    const { t, i18n } = useTranslation('leaderboard')
    const { appGlobalConfig } = useContext(AppContext)

    const [faqs, setFaqs] = useState([])
        
    useEffect(()=>{
        loadLeaderboard()
    },[appGlobalConfig])


    const loadLeaderboard = async() =>{
        
        try {
            setLoading(true)
            const apiData = await axios.get(appGlobalConfig.apiGetLeaderBoardByTVL)
            const {data} = apiData.data.message
            let fqs = vaultfaqconfig.faqs.filter(x=>x.env === appGlobalConfig.chainId);
            setFaqs(fqs)
            loopDatas(data)
            setLoading(false)                
        } catch (error) {
            setLoading(false)                            
        }
    }

    const loopDatas = (datas) =>{
        let allData=[]
        if(datas){
            datas.map((data,index) =>{
                if(data.fundvalueUSD!==0){
                    const newData = 
                    {   
                        id: data.contractaddress,
                        fundName: data.fundName,
                        fundvalueUSD: xWinLib.displayBigNumber(data.fundvalueUSD), 
                        daily: Math.round(data["24H"]*1000000)/10000,
                        weekly:Math.round(data["1W"]*1000000)/10000,
                        bianually: Math.round(data["6M"]*1000000)/10000,
                        yearly:Math.round(data["1Y"]*1000000)/10000,
                        contractaddress: data.contractaddress
                    }
                    allData.push(newData)
                }
            })
        }
        setRowsData(allData)
    }

    const displayLogo = (contractaddress) =>{
   
        const found = portfolios.find(x=>x.contractaddress.toLowerCase() === contractaddress)
        if(found === undefined){
            return(
                <Jazzicon diameter={38} seed={jsNumberForAddress(contractaddress)}/>
            )
        }
        return(
            <img src={xWinLib.getIcon(found.logo)} alt="Fund" className={classes.avatar} />
        )
    }

    const columns = [
        
        {field: 'id',
         headerName: ' ',
            width: 130 ,
          renderCell: (params) => (
            displayLogo(params.value.toLowerCase())
            ),
        },
        {field: 'fundName', headerName: `${t("funds")}`, width: 250 },
        { field: 'fundvalueUSD', headerName: `${t("fund-value")}`, width: 160, type: 'number'},
        {
          field: 'daily',
          headerName: '24H',
          width: 130,
          valueFormatter:({value})=>`${(value).toFixed(4)}%`,
          type: 'number'
        },
        {
            field: 'weekly',
            headerName: '1W',
            width: 130,
            valueFormatter:({value})=>`${(value).toFixed(4)}%`,
            type: 'number'
        },
        {
            field: 'bianually',
            headerName: '6M',
            width: 130,
            valueFormatter:({value})=>`${(value).toFixed(4)}%`,
            type: 'number'

        }, 
        {
            field: 'yearly',
            headerName: '1Y',
            width: 130,
            valueFormatter:({value})=>`${(value).toFixed(4)}%`,
            type: 'number'

        },   
   
        // {
        //   field: 'fullName',
        //   headerName: 'Full name',
        //   description: 'This column has a value getter and is not sortable.',
        //   sortable: false,
        //   width: 160,
        //   valueGetter: (params) =>
        //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
      ];

    const getCellClassName = (params) => {
        const check =parseFloat(params.value)
        if (params.colDef.field === 'daily' || params.colDef.field === 'weekly'||params.colDef.field === 'bianually'||params.colDef.field === 'yearly') {
            if(check<0){
                return 'red-cell';
            }
            if(check>0){
               return 'green-cell';
            }
            if(check===0){
                return '';
             }
            else{
                return 0;
            }   
        }
        if(params.colDef.field==='fundName' ||params.colDef.field==='fundvalueUSD'  ){
            return 'bold-text'
        }
    }

  return (
    <Container>
        <Widget
            bodyClass={classes.mainChartBody}
            title={t("leaderboard")}
            disableWidgetMenu
             >
            <div style={{ height: 700, width: '100%' }} className={classes.root}>
                {xWinUtils.getProgress(loading)}
                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    pageSize={11}
                    rowsPerPageOptions={[5]}
                    border
                    getCellClassName={getCellClassName}
                />
            </div>
           
        </Widget>
        <VaultFaq faqs={faqs} />
        </Container>
  )
}

export default Leaderboard;