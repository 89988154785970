const createfundFAQ = {
    faqs: [
        {
            symbol: 'createfund',
            env: 56,
            question: 'createFundFaq.question-1',
            defaultExpanded: true,
            desc: 'createFundFaq.desc-1',
        },
        {
            symbol: 'createfund',
            env: 56,
            question: 'createFundFaq.question-2',
            desc: 'createFundFaq.desc-2',
        },
        {
            symbol: 'createfund',
            env: 56,
            question: 'createFundFaq.question-3',
            desc: 'createFundFaq.desc-3',
        },
        {
            symbol: 'createfund',
            env: 56,
            question: 'createFundFaq.question-4',
            desc: 'createFundFaq.desc-4',
        },
        {
            symbol: 'createfund',
            env: 42161,
            question: 'createFundFaq.question-1',
            defaultExpanded: true,
            desc: 'createFundFaq.desc-1',
        },
        {
            symbol: 'createfund',
            env: 42161,
            question: 'createFundFaq.question-2',
            desc: 'createFundFaq.desc-2',
        },
        {
            symbol: 'createfund',
            env: 42161,
            question: 'createFundFaq.question-3',
            desc: 'createFundFaq.desc-3',
        },
        {
            symbol: 'createfund',
            env: 42161,
            question: 'createFundFaq.question-4',
            desc: 'createFundFaq.desc-4-e',
        },
    ],
}

export default createfundFAQ
