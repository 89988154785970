import React, { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Widget from '../../../components/Widget/Widget'
import './styles.js'
import useStyles from './styles'
import xWinLib from '../../../xWinLib.js'

const dataTable = props => {
    const { mainData } = props

    const [rowsData, setRowsData] = useState([])
    const classes = useStyles()

    useEffect(() => {
        loadData()
    }, [mainData])

    const loadData = async () => {
        if (mainData[0] !== undefined) {
            loopDatas()
        }
    }

    const loopDatas = () => {
        let allData = []
        for (let index = 0; index < mainData.length; index++) {
            if (mainData[index] !== undefined) {
                let temp = {
                    id: index,
                    Icon: xWinLib.getIcon(
                        mainData[index].Symbol.toLowerCase() + '.png'
                    ),
                    Tokens: mainData[index].Symbol,
                    USD: '$' + parseFloat(mainData[index].Amount).toFixed(2),
                }
                allData.push(temp)
            }
        }
        setRowsData(allData)
    }

    const columns = [
        {
            field: 'Icon',
            headerName: 'Icon',
            width: '30',
            sortable: false,
            renderCell: params => {
                return (
                    <>
                        <img src={params.row.Icon} className={classes.icon} />
                    </>
                )
            },
        },
        { field: 'Tokens', headerName: 'Tokens', width: 140 },
        {
            field: 'USD',
            headerName: 'USD',
            width: 110,
            align: 'right',
            type: 'number',
            sortOrder: 'desc',
        },
    ]

    return (
        <Widget disableWidgetMenu>
            <div style={{ height: 700, width: '100%' }}>
                <DataGrid
                    rows={rowsData}
                    columns={columns}
                    rowsPerPageOptions={[5]}
                    pageSize={10}
                    border
                />
            </div>
        </Widget>
    )
}

export default dataTable
