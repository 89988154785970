import React, { useEffect, useState, useRef, useContext } from 'react'
import xWinLib from '../../../xWinLib'
import GetAppTwoToneIcon from '@material-ui/icons/GetAppTwoTone'
import format from 'format-number'
import { HarvestAll } from '../../../utils/masterChefInteractor'
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import confetti from 'canvas-confetti'
import { useTranslation } from 'react-i18next'

import { Grid, Button } from '@material-ui/core'
import { Typography } from '../../../components/Wrappers/Wrappers'
import { AppContext } from '../../../context/AppProvider'

// styles
import useStyles from '../styles'
import xWinUtils from '../../../xWinUtils'
import { BuddyHarvestAll } from '../../../utils/buddyChefInteractor'

export default function MyBuddyRewardValue(props) {
    var classes = useStyles()
    const {
        loading,
        farmBuddyCollections,
        parentCallback,
        theSigner,
        ...rest
    } = props
    const [totalFarmEarn, setTotalFarmEarn] = useState('0')
    const { t, i18n } = useTranslation('myMenu')
    const [loadingTxn, setLoadingTxn] = React.useState(false)
    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    useEffect(() => {
        let activeFarm = farmBuddyCollections.filter(x => x.pending > 0)
        setTotalFarmEarn(activeFarm.length)
    }, [farmBuddyCollections])

    const handleClickHarvest = async event => {
        event.preventDefault()

        setLoadingTxn(true)

        BuddyHarvestAll(theSigner)
            .then(res => {
                console.log(res)
                setLoadingTxn(false)
                showSnackBar('success', res)
                confetti()
            })
            .catch(err => {
                setLoadingTxn(false)
                console.log(err)
                showSnackBar('error', err)
                parentCallback
            })
    }

    return (
        <>
            {xWinUtils.getProgress(loadingTxn)}
            <SnackbarMessage ref={refsnackbar} />
            <Grid item lg={6} sm={12} xs={12}>
                <Typography className={classes.positiveNum}>
                </Typography>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
                <Button
                    color="secondary"
                    variant="outlined"
                    fullWidth
                    onClick={handleClickHarvest}
                >
                    <GetAppTwoToneIcon />
                    {t('harvest-all')} ({totalFarmEarn})
                </Button>
            </Grid>
            <br />
        </>
    )
}
