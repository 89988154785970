import React, { useState, useEffect, useContext } from 'react'
import {
    Grid,
    Box,
    CardHeader,
    Card,
    CardContent,
    Table,
    TableRow,
    TableCell,
} from '@material-ui/core'
import useStyles from '../styles'
import xWinLib from '../../../xWinLib'
import xWinUtils from '../../../xWinUtils'
import format from 'format-number'
import Dot from '../../../components/Sidebar/components/Dot'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { Typography, Link, Avatar } from '../../../components/Wrappers/Wrappers'
import { useTranslation } from 'react-i18next'
import { jsNumberForAddress } from 'react-jazzicon'
import Jazzicon from 'react-jazzicon/dist/Jazzicon'

const UnrealizedPnL = props => {
    const classes = useStyles()
    const { port, tokensMaster, portfolios, xwinstrategies, appGlobalConfig } = props
    const { t, i18n } = useTranslation('myMenu')
    const [baseCcy, setBaseCcy] = useState()

    useEffect(() => {
        init()
    }, [port])

    const init = async () => {
        const baseccy = tokensMaster.find(
            x => x.address.toLowerCase() === port.baseCcy.toLowerCase()
        ).name
        setBaseCcy(baseccy)
    }

    const displayLogo = (port) =>{
   
        let found = portfolios.find(x=>x.contractaddress.toLowerCase() === port.contractaddress.toLowerCase())
        if(found === undefined){
            found = xwinstrategies.find(x=>x.contractaddress.toLowerCase() === port.contractaddress.toLowerCase())
        }
        if(found === undefined){
            return(
                <Jazzicon diameter={38} seed={jsNumberForAddress(port.contractaddress)}/>
            )
        }
        return(
            <Avatar
                variant={'rounded'}
                className={classes.avatar}
                src={xWinLib.getIcon(port.logo)}
            />
            // <img src={xWinLib.getIcon(found.logo)} alt="Fund" className={classes.avatar} />
        )
    }

    return (
        <>
            <br />
            <Card>
                <Link
                    underline="none"
                    component={RouterLink}
                    to={{
                        pathname: (port.isStartegy ? '/app/strategydetailv2/' : '/app/funddetailV2/') +  port.contractaddress + "/chainId=" + appGlobalConfig?.chainId,
                        state: {
                            port: port,
                            portparam: port,
                            tokensMaster: tokensMaster,
                            fundtypeparam: port.fundtype,
                        },
                    }}
                >
                    <CardHeader
                        title={
                            <Typography className={classes.primaryHeading}>
                                {port.name}
                            </Typography>
                        }
                        avatar={
                            displayLogo(port)
                        }
                        subheaderTypographyProps={{ variant: 'body2' }}
                    />
                </Link>
                <CardContent>
                    <Grid item>
                        <Table size="small">
                            <TableRow className={classes.columnHide}>
                                <TableCell>
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        {t('unrealizedPnL.average-price')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot size="medium" color="success" />
                                        <Typography
                                            size="md"
                                            weight={'medium'}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.avrPriceValue,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHightlightPositive}
                                >
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        Market Value
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot size="medium" color="success" />
                                        <Typography
                                            size="md"
                                            weight={'medium'}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.latestMktValue,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHightlightPositive}
                                >
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        Average Rate
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot size="medium" color="success" />
                                        <Typography
                                            size="md"
                                            weight={'medium'}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.avrrate,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        {t('unrealizedPnL.unit-price')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot size="medium" color="success" />
                                        <Typography
                                            className={classes.displayFont}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.unitPrice,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.columnHide}>
                                <TableCell
                                    className={classes.tableHightlightPositive}
                                >
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        {t('unrealizedPnL.est-pnl')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Avatar
                                            variant={'rounded'}
                                            className={classes.baseIcon}
                                            src={xWinLib.getIcon(
                                                baseCcy?.toLowerCase() + '.png'
                                            )}
                                        />
                                        <Typography
                                            className={
                                                port.unrealizedPnl >= 0
                                                    ? classes.positiveNum
                                                    : classes.negativeNum
                                            }
                                            // size="md"
                                            // weight={'medium'}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.unrealizedPnl,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>

                                        
                                    </Box>
                                </TableCell>

                                <TableCell
                                    className={classes.tableHightlightPositive}
                                >
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        {t('unrealizedPnL.pnl')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot
                                            size="medium"
                                            color={
                                                port.unrealizedPnlPercentage >=
                                                0
                                                    ? 'success'
                                                    : 'secondary'
                                            }
                                        />
                                        <Typography
                                            className={
                                                port.unrealizedPnlPercentage >=
                                                0
                                                    ? classes.positiveNum
                                                    : classes.negativeNum
                                            }
                                            // size="md"
                                            // weight={'medium'}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '%',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.unrealizedPnlPercentage * 100,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell colSpan={2}>
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        {t('unrealizedPnL.shares')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot size="medium" color="success" />
                                        <Typography
                                            className={classes.displayFont}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.totalAccShare,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.columnShow}>
                                <TableCell>
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        {t('unrealizedPnL.average-price')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot size="medium" color="success" />
                                        <Typography
                                            className={classes.displayFont}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.avrPriceValue,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHightlightPositive}
                                >
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        Market Value
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot size="medium" color="success" />
                                        <Typography
                                            className={classes.displayFont}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.latestMktValue,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.columnShow}>
                                <TableCell
                                    className={classes.tableHightlightPositive}
                                >
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        Average Rate
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot size="medium" color="success" />
                                        <Typography
                                            className={classes.displayFont}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.avrrate,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        {t('unrealizedPnL.unit-price')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot size="medium" color="success" />
                                        <Typography
                                            className={classes.displayFont}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.unitPrice,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.columnShow}>
                                <TableCell
                                    className={classes.tableHightlightPositive}
                                >
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        {t('unrealizedPnL.est-pnl')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Avatar
                                            variant={'rounded'}
                                            className={classes.baseIcon}
                                            src={xWinLib.getIcon(
                                                baseCcy?.toLowerCase() + '.png'
                                            )}
                                        />
                                        <Typography
                                            className={
                                                port.unrealizedPnl >= 0
                                                    ? classes.positiveNum
                                                    : classes.negativeNum
                                            }
                                            // size="md"
                                            // weight={'medium'}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.unrealizedPnl,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>

                                        
                                    </Box>
                                </TableCell>

                                <TableCell>
                                    <Typography
                                        color="text"
                                        colorBrightness={'hint'}
                                        variant={'caption'}
                                        style={{ marginRight: 5 }}
                                        //noWrap
                                    >
                                        {t('unrealizedPnL.pnl')}
                                    </Typography>
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent={'left'}
                                    >
                                        <Dot
                                            size="medium"
                                            color={
                                                port.unrealizedPnlPercentage >=
                                                0
                                                    ? 'success'
                                                    : 'secondary'
                                            }
                                        />
                                        <Typography
                                            className={
                                                port.unrealizedPnlPercentage >=
                                                0
                                                    ? classes.positiveNum
                                                    : classes.negativeNum
                                            }
                                            // size="md"
                                            // weight={'medium'}
                                            style={{ marginLeft: 8 }}
                                        >
                                            {xWinUtils.displayBalance(
                                                false,
                                                format({
                                                    prefix: '',
                                                    suffix: '%',
                                                })(
                                                    xWinLib.roundTo(
                                                        port.unrealizedPnlPercentage * 100,
                                                        4
                                                    )
                                                )
                                            )}
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}

export default withRouter(UnrealizedPnL)
