import React, { useState, useEffect, useRef, useContext } from 'react'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import format from 'format-number'
// components
import { Typography, Button, Avatar } from '../../components/Wrappers/Wrappers'
import CancelIcon from '@material-ui/icons/Cancel'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import {
    SubscribeDirectAsync,
    getEstimateGasDeposit,
    getEstimateShares,
    validInvestor,
} from '../../utils/fundV2Interactor'
import confetti from 'canvas-confetti'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'

import {
    Box,
    Grid,
    Slide,
    CardHeader,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    ButtonGroup,
    TextField,
    InputAdornment,
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableRow,
    TableCell,
    TableHead,
} from '@material-ui/core'
import useStyles from './styles'
import { AppContext } from '../../context/AppProvider'
import { approveContract, IsTokenApproved } from '../../utils/erc20Interactor'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi/dist'
import { convertToWei } from '../../utils/helper'

const DepositV2 = ({
    underlyingNames,
    fundData,
    openModal,
    port,
    isFundv2,
    userData,
    parentCallback,
    reset,
}) => {
    const classes = useStyles()
    const [loadingTxn, setLoadingTxn] = React.useState(false)
    const [loadingEst, setLoadingEst] = React.useState(false)
    const [slippage, setSlippage] = React.useState(1)
    const [subsAmt, setsubsAmt] = useState('')
    const [canDeposit, setCanDeposit] = React.useState(true)
    const [estimateShares, setEstimateShares] = useState(0)
    const [newUnderlying, setNewUnderlying] = useState([])
    const {
        web3static,
        theSigner,
        appGlobalConfig
    } = useContext(AppContext)
    const { address } = useAccount()

    const [isApproval, setIsApproval] = useState(false)
    const { t, i18n } = useTranslation('fundDetails')

    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    useEffect(() => {
        setSlippage(fundData?.baseCcy?.toLowerCase() === "jpyc" ? 3: 1)
        initUnderlyingFee()
    }, [underlyingNames])

    const initUnderlyingFee = async () =>{

        if(underlyingNames == undefined) return
        const filtered = underlyingNames.filter(x=>x.performanceFee > 0)
        setNewUnderlying(filtered)
      }

    const calcEstimateShares = async subAmount => {
        if (address !== undefined && fundData?.baseToken !== undefined) {
            
            setLoadingEst(true)
            const shares = await getEstimateShares(
                web3static,
                fundData.contractaddress,
                convertToWei(subAmount, fundData.baseDecimal), 
                theSigner,
                slippage
            )
            setLoadingEst(false)
            if(shares > 0){
                setCanDeposit(true)
                setEstimateShares(shares)
            }else{
                setCanDeposit(false)
                setEstimateShares(0)
                showSnackBar('error', `${t('redeemV2.error.no-estimate')}`)
            }
            return shares
        }
    }

    const handleClickSubs = async event => {
        event.preventDefault()
        if(isFundv2){
            let isValid = await validInvestor(
                web3static,
                port.contractaddress,
                address
            )
            if (!isValid) {
                showSnackBar('error', `${t('private-vault-error')}`)
                return
            }
        }

        if (subsAmt <= 0) {
            showSnackBar('error', `${t('subscribe-error')}`)
            return
        }
        if (subsAmt > userData?.userbasebal) {
            showSnackBar('error', `${t('deposit-error')}`)
            return
        }

        const shares = await calcEstimateShares(subsAmt);
        if(shares === 0){
            return
        }

        const subsAmtInWei = convertToWei(subsAmt, fundData.baseDecimal);
        const outputEst = await getEstimateGasDeposit(
            port?.contractaddress,
            subsAmtInWei,
            theSigner,
            slippage);
        if(!outputEst.status){
            showSnackBar('error', outputEst.message)
            return;
        }

        setLoadingTxn(true)
        SubscribeDirectAsync(
            theSigner,
            port?.contractaddress,
            address,
            subsAmt,
            fundData.baseDecimal,
            slippage,
            outputEst.estimation
        )
            .then(res => {
                // console.log(res)
                setsubsAmt("")
                setEstimateShares("")
                setLoadingTxn(false)
                showSnackBar('success', res)
                confetti()
                parentCallback(true)
            })
            .catch(err => {
                setLoadingTxn(false)
                console.log(err)
                showSnackBar('error', err)
                parentCallback(false)
            })
    }

    const handleClickApprove = async event => {
        event.preventDefault()

        setLoadingTxn(true)
        approveContract(
            theSigner,
            fundData.baseToken,
            port?.contractaddress,
            address
        )
            .then(res => {
                setLoadingTxn(false)
                setIsApproval(true)
                calcEstimateShares(subsAmt);
            })
            .catch(err => {
                setLoadingTxn(false)
                console.log(err)
                setIsApproval(false)
            })
    }

    const handleClickAmt = ratio => async event => {
        event.preventDefault()
        const subAmount = xWinLib.roundDown(ratio * userData?.userbasebal, 6)
        setsubsAmt(subAmount)
        const isApp = await IsTokenApproved(
            web3static,
            subAmount,
            address,
            fundData.baseToken,
            port?.contractaddress
        )
        setIsApproval(isApp)
        if(isApp) calcEstimateShares(subAmount);
    }

    const handleClickClearAmt = () => event => {
        event.preventDefault()
        setsubsAmt('')
    }

    const handleSlippageAmt = (slippage) => event =>{
        event.preventDefault()
        setSlippage(slippage)
    }

    const handleAmtChange = async event => {
        event.preventDefault()
        const subsAmount = parseFloat(event.target.value)
        if (subsAmount >= 0 && subsAmount !== undefined) {
            setsubsAmt(subsAmount)
            const isApp = await IsTokenApproved(
                web3static,
                subsAmount,
                address,
                fundData.baseToken,
                port?.contractaddress
            )
            setIsApproval(isApp)
            if(isApp) calcEstimateShares(subsAmount);
        } else {
            setsubsAmt('')
        }
    }

    const getSubHeader = () => {
        return (
            <Typography color="primary" className={classes.displayFont}>
                {format({ prefix: '', suffix: '' })(
                    xWinLib.roundTo(userData?.userbasebal, 10)
                )}
            </Typography>
        )
    }

    const displayFeeWarning = () => {

        if(!isFundv2)
        {
            if(Number(fundData.performanceFee) <= 0) return 
            const feeWarning = xWinLib.doShowFeeWarning(
                fundData.currentBlock, 
                fundData.collectionBlock, 
                Number(subsAmt), 
                Number(fundData.performanceFee), 
                Number(fundData.highWaterMarkPrice), 
                Number(fundData.uniprice),
                appGlobalConfig
                )

            if(feeWarning.display){
                return (
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={classes.hoverHighlight}
                        >
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                                // noWrap
                            >
                                <ErrorOutlineIcon/>
                                {" High Water Mark Fee"}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                color={"primary"}
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                                // noWrap
                            >
                                {"Reminder: The following underlying tokens HWM performance fee collection is approaching. Since the current unit price is higher than HWM price, you may be losing the fee if you are depositing it now."}
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20%" >
                                            {t('depositV2.token')}
                                        </TableCell>
                                        <TableCell width="20%">{t('depositV2.est-fee')}</TableCell>
                                        <TableCell width="20%" className={classes.columnHide}>
                                            {t('depositV2.hwm-fee')}
                                        </TableCell>
                                        <TableCell width="40%">{t('depositV2.day-to-fee')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHightlightPositive}>
                                        <Typography
                                            color="text"
                                            colorBrightness={'hint'}
                                            variant={'caption'}
                                            style={{ marginRight: 5 }}
                                            // noWrap
                                        >
                                            {port.symbol}
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography
                                            color="text"
                                            colorBrightness={'hint'}
                                            variant={'caption'}
                                            style={{ marginRight: 5 }}
                                            // noWrap
                                        >
                                            {format({ prefix: fundData.baseCcy, suffix: '' })(
                                                xWinLib.roundTo(feeWarning.estFee, 2)
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="20%" className={classes.columnHide}>
                                        <Typography
                                            color="text"
                                            colorBrightness={'hint'}
                                            variant={'caption'}
                                            style={{ marginRight: 5 }}
                                            // noWrap
                                        >
                                            {format({ prefix: '', suffix: '%' })(
                                                xWinLib.roundTo(fundData.performanceFee, 2)
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color="text"
                                            colorBrightness={'hint'}
                                            variant={'caption'}
                                            style={{ marginRight: 5 }}
                                            // noWrap
                                        >
                                            {format({ prefix: '', suffix: ' days' })(
                                                xWinLib.roundTo(feeWarning.days, 2)
                                            )}
                                        </Typography>
                                    </TableCell>
                                    
                                </TableRow>
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                )
            }
        }
    }

    const displayWithdrawWarning = () => {
        
        if(!isFundv2 && port?.type !== "allocation") return ""
        return (
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.hoverHighlight}
                >
                    <Typography
                        color="text"
                        colorBrightness={'hint'}
                        variant={'caption'}
                        style={{ marginRight: 5 }}
                        // noWrap
                    >
                        <ErrorOutlineIcon />
                        {t('redeemV2.slippage-title')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <Box className={classes.boxAmount2}>
                        <ButtonGroup size="small" color="primary">
                            <Button
                                className={classes.customButton2}
                                fullWidth
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={handleSlippageAmt(0.5)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'0.5%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.customButton2}
                                fullWidth
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={handleSlippageAmt(1.0)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'1.0%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.customButton2}
                                fullWidth
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={handleSlippageAmt(1.5)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'1.5%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.customButton2}
                                fullWidth
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={handleSlippageAmt(2.0)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'2.0%'}
                                </Typography>
                            </Button>
                            {/* <Button
                            className={classes.customButton}
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickAmt(1)}
                        >
                            <Typography className={classes.displayFont}>
                                {'100%'}
                            </Typography>
                        </Button> */}
                            {/* <Button
                            className={classes.customButton}
                            fullWidth
                            color="primary"
                            size="small"
                            variant="outlined"
                            onClick={handleClickClearAmt()}
                        >
                            <Typography  className={classes.displayFont}>
                                {t('redeemV2.clear')}
                            </Typography>
                        </Button> */}
                        </ButtonGroup>
                    </Box>
                    <TextField
                        type="number"
                        margin="dense"
                        name="slippage"
                        onChange={handleSlippageChange()}
                        required
                        // variant="outlined"
                        value={slippage}
                        fullWidth
                        InputProps={{
                            classes: {
                                input: classes.positiveNum,
                            },
                            style: { textAlign: 'right' },
                            step: 0.1,
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Typography className={classes.displayFont}>
                                        %
                                    </Typography>
                                </InputAdornment>
                            ),
                        }}
                    />
                </AccordionDetails>
                <AccordionDetails>
                    <Typography
                        color={'primary'}
                        colorBrightness={'hint'}
                        variant={'caption'}
                        style={{ marginRight: 5 }}
                    >
                        {t('depositV2.slippage-desc')}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        )
    }

    const handleSlippageChange = () => async event => {
        if (event.target.value === '') return

        const val = parseFloat(event.target.value)
        if (val >= 0 && val !== undefined) {
            setSlippage(val)
        } else {
            setSlippage(1)
        }
    }

    return (
        <Dialog
            open={openModal}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            fullScreen={false}
            className={classes.expanded}
            //maxWidth = {'sm'}
        >
            <SnackbarMessage ref={refsnackbar} />
            <Card className={classes.expansion}>
                <CardHeader
                    action={
                        <IconButton
                            className={classes.positiveNum}
                            onClick={reset}
                            aria-label="settings"
                        >
                            <CancelIcon />
                        </IconButton>
                    }
                    avatar={
                        <Avatar variant="round">
                            {
                                <img
                                    alt={fundData.baseCcy}
                                    src={xWinLib.getIcon(
                                        fundData.baseCcy?.toLowerCase() + '.png'
                                    )}
                                    className={classes.avatar}
                                />
                            }
                        </Avatar>
                    }
                    subheader={getSubHeader()}
                />

                <DialogContent>
                    {xWinUtils.getProgress(loadingTxn)}
                    <Box className={classes.boxAmount}>
                        <ButtonGroup size="small" color="primary">
                            <Button
                                className={classes.customButton}
                                fullWidth
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={handleClickAmt(0.25)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'25%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.customButton}
                                fullWidth
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={handleClickAmt(0.5)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'50%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.customButton}
                                fullWidth
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={handleClickAmt(0.75)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'75%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.customButton}
                                fullWidth
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={handleClickAmt(1)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'100%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.customButton}
                                fullWidth
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={handleClickClearAmt()}
                            >
                                <Typography className={classes.displayFont}>
                                    {t('redeemV2.clear')}
                                </Typography>
                            </Button>
                        </ButtonGroup>
                        
                    </Box>
                </DialogContent>
                <DialogContent>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'center'}
                    >
                        <TextField
                            type="number"
                            margin="dense"
                            name="subsAmt"
                            onChange={handleAmtChange}
                            required
                            // variant="outlined"
                            value={subsAmt}
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.positiveNum,
                                },
                                style: { textAlign: 'right' },
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography
                                            className={classes.displayFont}
                                        >
                                            {fundData.baseCcy}
                                        </Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </DialogContent>
                {displayWithdrawWarning()}
                {displayFeeWarning()}
                {isFundv2 && newUnderlying.length > 0 && (
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            className={classes.hoverHighlight}
                        >
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                                // noWrap
                            >
                                <ErrorOutlineIcon />
                                {' High Water Mark Fee'}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography
                                color={'primary'}
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                                // noWrap
                            >
                                {
                                    'Reminder: The following underlying tokens HWM performance fee collection is approaching. Since the current unit price is higher than HWM price, you may be losing some fees right after you deposited. You can either ignore this warning message or wait until the fee processing is over.'
                                }
                            </Typography>
                        </AccordionDetails>
                        <AccordionDetails>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20%">
                                            {t('depositV2.token')}
                                        </TableCell>
                                        <TableCell width="20%">
                                            {t('depositV2.est-fee')}
                                        </TableCell>
                                        <TableCell
                                            width="20%"
                                            className={classes.columnHide}
                                        >
                                            {t('depositV2.hwm-fee')}
                                        </TableCell>
                                        <TableCell width="40%">
                                            {t('depositV2.day-to-fee')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {newUnderlying.map(fundData => {
                                    const feeWarning = xWinLib.doShowFeeWarning(
                                        fundData?.currentBlock,
                                        fundData?.collectionBlock,
                                        subsAmt,
                                        Number(fundData?.performanceFee),
                                        Number(fundData?.highWaterMarkPrice),
                                        Number(fundData?.uniprice),
                                        appGlobalConfig
                                    )

                                    if (feeWarning.display) {
                                        return (
                                            <TableRow>
                                                <TableCell
                                                    className={
                                                        classes.tableHightlightPositive
                                                    }
                                                >
                                                    <Typography
                                                        color="text"
                                                        colorBrightness={'hint'}
                                                        variant={'caption'}
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                        // noWrap
                                                    >
                                                        {fundData?.symbol}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="text"
                                                        colorBrightness={'hint'}
                                                        variant={'caption'}
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                        // noWrap
                                                    >
                                                        {format({
                                                            prefix:
                                                                fundData?.baseCcy,
                                                            suffix: '',
                                                        })(
                                                            xWinLib.roundTo(
                                                                feeWarning.estFee,
                                                                2
                                                            )
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    width="20%"
                                                    className={
                                                        classes.columnHide
                                                    }
                                                >
                                                    <Typography
                                                        color="text"
                                                        colorBrightness={'hint'}
                                                        variant={'caption'}
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                        // noWrap
                                                    >
                                                        {format({
                                                            prefix: '',
                                                            suffix: '%',
                                                        })(
                                                            xWinLib.roundTo(
                                                                fundData?.performanceFee,
                                                                2
                                                            )
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography
                                                        color="text"
                                                        colorBrightness={'hint'}
                                                        variant={'caption'}
                                                        style={{
                                                            marginRight: 5,
                                                        }}
                                                        // noWrap
                                                    >
                                                        {format({
                                                            prefix: '',
                                                            suffix: ' days',
                                                        })(
                                                            xWinLib.roundTo(
                                                                feeWarning.days,
                                                                2
                                                            )
                                                        )}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                })}
                            </Table>
                        </AccordionDetails>
                    </Accordion>
                )}
                <DialogContent>
                    <Grid container spacing={0}>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                                // noWrap
                            >
                                {t('depositV2.est-shares')}
                            </Typography>
                        </Grid>
                        <Grid item lg={6} sm={6} xs={6} alignItems="flex-end">
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'right'}
                            >
                                {
                                    <img
                                        alt={port?.symbol}
                                        src={xWinLib.getIcon(port?.logo)}
                                        className={classes.avatarSmall}
                                    />
                                }
                                <Typography
                                    variant={'body2'}
                                    color={'primary'}
                                    // className={classes.subTitle}
                                    style={{ marginLeft: 5 }}
                                >
                                    {loadingEst
                                        ? xWinUtils.getProgressLinear(true)
                                        : format({ prefix: ' ', suffix: ' ' })(
                                              xWinLib.roundDown(
                                                  estimateShares,
                                                  4
                                              )
                                          )}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup
                        size="small"
                        color="primary"
                        aria-label="outlined primary button group"
                    >
                        <Button
                            disabled={isApproval}
                            onClick={handleClickApprove}
                            color="secondary"
                            variant="contained"
                            startIcon={<AddCircleIcon />}
                        >
                            <Typography className={classes.displayFont}>
                                {t('depositV2.approve')}
                            </Typography>
                        </Button>
                        <Button
                            disabled={!isApproval || !canDeposit}
                            onClick={handleClickSubs}
                            color="primary"
                            variant="contained"
                            startIcon={<AddCircleIcon />}
                        >
                            <Typography className={classes.displayFont}>
                                {t('depositV2.deposit')}
                            </Typography>
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Card>
        </Dialog>
    )
}

export default DepositV2
