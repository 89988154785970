// import Web3 from "web3";
import xWinAutoTrading from "../abi/v2/xWinBBMA.json";
import { convertFromWei } from "../utils/helper";
import { getPrice } from "./xWinPriceMasterInteractor";

export async function getStableTargetValues(web3, contractaddress) {

	const contract = new web3.eth.Contract(xWinAutoTrading, contractaddress);
  const promiseData = await Promise.all([
    contract.methods.getStableValues().call(),
    contract.methods.getTargetValues().call(),
    contract.methods.targetToken().call(),
    contract.methods.baseToken().call(),
    contract.methods.stopLossPrice().call(),
  ]);
  let stableValues = promiseData[0]
  let targetValues = promiseData[1]
  let targetToken = promiseData[2]
  let stablecoin = promiseData[3]
  let stopLossPrice = promiseData[4]
  
  return {
    "stableValues": convertFromWei(stableValues || 0),
    "targetValues": convertFromWei(targetValues || 0),
    "targetToken" : targetToken,
    "stablecoin" : stablecoin,
    "stopLossPrice": convertFromWei(stopLossPrice || 0),
  };
}
