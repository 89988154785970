import WalletConnectProvider from "@walletconnect/web3-provider";
import { useEffect, useState } from 'react';
import Web3 from 'web3';
import { ethers } from "ethers";
import { systemConfig, systemConfigArb, systemConfigLocal, systemConfigPolygon } from "./configs/systemConfig";
import { useSwitchNetwork,useNetwork, useConnect } from 'wagmi'
import { usePublicClient } from 'wagmi'
import { useWalletClient } from 'wagmi'
import { providers, utils } from "ethers";
import appGlobalConfigBSC from "./configs/56/appGlobalConfig";
import appGlobalConfigARB from "./configs/42161/appGlobalConfig";
import appGlobalConfigPolygon from "./configs/137/appGlobalConfig";
import appGlobalConfigLOCAL from "./configs/31337/appGlobalConfig";
// import {appGlobalConfig as appGlobalConfigArb} from "./configs/42161/appGlobalConfig";
import { switchNetwork } from '@wagmi/core'
import { useAccount } from "wagmi/dist";


function useNetworkContext() {
  const [wallet, setWallet] = useState("---")
  const [account, setAccount] = useState("---")
  const [theWeb3, setWeb3] = useState(new Web3(systemConfig.rpc))
  const [theSigner, setTheSigner] = useState([])
  const [tokensv2, setTokensv2] = useState([])
  const [xwinfundv2, setXwinfundv2] = useState([])
  const [xwinstrategies, setXwinstrategies] = useState([])
  const [xwinstakingv2, setXwinstakingv2] = useState([])
  const [xwinbuddystakingv2, setXwinbuddystakingv2] = useState([])
  const [appGlobalConfig, setAppGlobalConfig] = useState()
  const [web3static, setWeb3static] = useState(new Web3(systemConfig.rpc))
  const [explorerScan, setExplorerScan] = useState(systemConfig.blockExplorerUrl)
  const [ETHBalance, setETHBalance] = useState(0)
  const [USDTBalance, setUSDTBalance] = useState(0)
  const [USDCBalance, setUSDCBalance] = useState(0)
  const [XWINBalance, setXWINBalance] = useState(0)
  const [tokenBalances, setTokenBalances] = useState([])
  const { data: walletClient } = useWalletClient()
  const { data: publicClient } = usePublicClient()
  const { chain, globalProvider, EthProvider } = useNetwork()
  // const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork()
  const { address, isConnecting, isDisconnected, isConnected } = useAccount()
  
  useEffect(() => {
    init(chain, walletClient)
  }, [chain, walletClient])

  const init = async (chain, walletClient) => {

    if(chain != undefined){
      if(walletClient !== undefined){
        setWeb3static(new Web3(chain.rpcUrls.public.http[0]))
        const output = await walletClientToSigner(walletClient)
        setWeb3(new Web3(output.provider)) 
        setTheSigner(output.signer)
        changeNetworkConfig(chain.id)
      }
    }else{
      // default to BNB
      changeNetworkConfig(systemConfig.chainId)
    } 
  }

  const switchChain = async (chainId) => {  
    try {
      if(isConnected){
        await switchNetwork({
          chainId: chainId,
        })  
      }else{
        changeNetworkConfig(chainId)
      }
    } catch (error) {
      console.log(error)
    }

  }

  const  performGetFile = async (url) => { 
    let output = await (await fetch(url)).json();
    return output;
  }

  const walletClientToSigner = async (walletClient) => {
    const { account, chain, transport } = walletClient
    const network = {
      chainId: chain.id,
      name: chain.name,
      ensAddress: chain.contracts?.ensRegistry?.address,
    }
    const provider = new providers.Web3Provider(transport, network)
    const signer = provider.getSigner(account.address)
    return {signer, provider}
  }
  
  // const publicClientToProvider = async (publicClient) => {
  //   const { chain, transport } = publicClient
  //   const network = {
  //     chainId: chain.id,
  //     name: chain.name,
  //     ensAddress: chain.contracts?.ensRegistry?.address,
  //   }
  //   if (transport.type === 'fallback')
  //     return new providers.FallbackProvider(
  //       transport.transports.map(
  //         ({ value }) => new providers.JsonRpcProvider(value?.url, network),
  //       ),
  //     )
  //   return new providers.JsonRpcProvider(transport.url, network)
  // }
 
  // const getAppGlobalConfig = (chain) => {
  //   if(chain.id == 56) return appGlobalConfigBSC
  //   if(chain.id == 42161) return appGlobalConfigARB
  //   if(chain.id == 31337) return appGlobalConfigLOCAL
  //   return appGlobalConfigBSC
  // }

  const changeNetworkConfig = async (value) => {
    // default to BNB
    
    let tokens = []
    let funds = []
    let strategies = []
    let xwinstaking = [] 
    let xwinbuddystaking = []
    let config = appGlobalConfigBSC 
      
    if(value === 56){
      setWeb3static(new Web3(systemConfig.rpc))
      setWeb3(new Web3(systemConfig.rpc))
      setExplorerScan(systemConfig.blockExplorerUrl)
      tokens = await performGetFile(config.tokenv2);
      funds = await performGetFile(config.xwinfundv2);
      strategies = await performGetFile(config.xwinstrategies);
      xwinstaking = await performGetFile(config.xwinstakingv2);
      xwinbuddystaking = await performGetFile(config.xwinbuddystakingv2);  
    }else if(value === 42161){
      setWeb3static(new Web3(systemConfigArb.rpc))
      setWeb3(new Web3(systemConfigArb.rpc))
      setExplorerScan(systemConfigArb.blockExplorerUrl)
      config = appGlobalConfigARB 
      tokens = await performGetFile(config.tokenv2);
      funds = await performGetFile(config.xwinfundv2);
      strategies = await performGetFile(config.xwinstrategies);
      xwinstaking = await performGetFile(config.xwinstakingv2);
      xwinbuddystaking = await performGetFile(config.xwinbuddystakingv2);  
    }else if(value === 137){
      setWeb3static(new Web3(systemConfigPolygon.rpc))
      setWeb3(new Web3(systemConfigPolygon.rpc))
      setExplorerScan(systemConfigPolygon.blockExplorerUrl)
      config = appGlobalConfigPolygon 
      tokens = await performGetFile(config.tokenv2);
      funds = await performGetFile(config.xwinfundv2);
      strategies = await performGetFile(config.xwinstrategies);
      xwinstaking = await performGetFile(config.xwinstakingv2);
      xwinbuddystaking = await performGetFile(config.xwinbuddystakingv2);  
    }else if(value === 31337){
      setWeb3static(new Web3(systemConfigLocal.rpc))
      setWeb3(new Web3(systemConfigLocal.rpc))
      setExplorerScan(systemConfigLocal.blockExplorerUrl)
      config = appGlobalConfigLOCAL
      tokens = await performGetFile(config.tokenv2);
      funds = await performGetFile(config.xwinfundv2);
      strategies = await performGetFile(config.xwinstrategies);
      xwinstaking = await performGetFile(config.xwinstakingv2);
      xwinbuddystaking = await performGetFile(config.xwinbuddystakingv2);  
    }
    setAppGlobalConfig(config)
    setTokensv2(tokens)
    setXwinfundv2(funds)
    setXwinstrategies(strategies)
    setXwinstakingv2(xwinstaking)
    setXwinbuddystakingv2(xwinbuddystaking)
  }

  const refreshAccount = async () => {
    // const web3_ = new Web3(globalProvider)
    // setupAccount(web3_)
  }


  return [{ xwinbuddystakingv2, xwinstakingv2, tokensv2, xwinfundv2, xwinstrategies, theSigner, theWeb3, web3static, explorerScan, ETHBalance, XWINBalance, USDTBalance, tokenBalances, globalProvider, appGlobalConfig }, refreshAccount, switchChain, changeNetworkConfig]
}

export default useNetworkContext
