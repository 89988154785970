import React, { useState, useEffect, useRef, useContext } from 'react'
// components
import { Typography, Button } from '../../../components/Wrappers/Wrappers'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import {
    Box,
    DialogContent,
    DialogActions,
    ButtonGroup,
    TextField,
    Card,
    Badge,
    CardContent,
} from '@material-ui/core'
import useStyles from '../styles'
import { AppContext } from '../../../context/AppProvider'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi/dist'
import { alreadyRegisterReferral } from '../../../utils/firestoreDataInteractor'
import { getReferralURL } from '../../../utils/helper'
import { toast } from 'react-toastify'
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import xWinUtils from '../../../xWinUtils'

export default function IamReferral(props) {
    const classes = useStyles()
    const [activated, setActivated] = React.useState(false)
    const [loadingTxn, setLoadingTxn] = React.useState(false)
    const [myReferralCode, setMyReferralCode] = useState("")
    const {
        web3static,
        appGlobalConfig
    } = useContext(AppContext)
    const { address} = useAccount()

    const { t, i18n } = useTranslation('fundDetails')
    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg, "top")
    }

    useEffect(() => {
        initCheckReferral()
    }, [address])

    const initCheckReferral = async () =>{

        if(appGlobalConfig == undefined) return
        if(address == undefined) return
        setLoadingTxn(true)
        const output = await alreadyRegisterReferral(address, appGlobalConfig)
        setActivated(output?.activated || false)
        setMyReferralCode(output?.activated ? output?.MyReferralCode : "" || "")
        setLoadingTxn(false)
    }

    const getSubHeader = () => {
        
        if(activated){
            return (
                <>
                <Typography className={classes.displayFont}>
                    {t('referral.join-referral')}
                </Typography>
                <DialogContent>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'center'}
                    >
                        <TextField
                            label={t('referral.my-code')}
                            margin="dense"
                            name="myReferralCode"
                            required
                            disabled={true}
                            value={myReferralCode}
                            fullWidth
                        />
                    </Box>
                </DialogContent>   
                <DialogActions>
                <ButtonGroup
                    size="small"
                    color="primary"
                    aria-label="outlined primary button group"
                >
                    {displayButton()}
                </ButtonGroup>
            </DialogActions>
                </>
            )
        }else{
            return (
                <>
                <Typography className={classes.displayFont}>
                    {t('referral.not-join-referral')}&nbsp; 
                    <a href="mailto:admin@xwin.finance">admin@xwin.finance</a>
                </Typography>
                </>
            )
        }
    }

    const displayBatch = () => {
        if (activated) {
            return (
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    badgeContent={t('referral.join')}
                    color="primary"
                    overlap="rectangle"
                ></Badge>
            )
        }
    }

    const copyAdressToClipboard = event  => {
        event.preventDefault()
        navigator.clipboard.writeText(getReferralURL(myReferralCode))
        showSnackBar('info', `Copied!`)
            
        // toast.success("Copied!", { autoClose: 10000, theme: "colored", style: { background: '#766852' }});
    
    }

    const displayButton = () => {

        if(activated){
            return (
                <Button
                    onClick={copyAdressToClipboard}
                    color="primary"
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                >
                    <Typography className={classes.displayFont}>
                        {t('referral.share-code')}
                    </Typography>
                </Button>
            )
        }else{
            return (
                <Button color="secondary" variant="contained" href="mailto:admin@xwin.finance">{t('referral.inquiry')}</Button>
            )
        }
    }

    return (
        <>
        {xWinUtils.getProgress(loadingTxn)}
        <SnackbarMessage ref={refsnackbar} />
        {displayBatch()}
            <Card className={classes.expansion}>
            <CardContent>
                <Typography
                    color="text"
                    colorBrightness={'hint'}
                    variant={'caption'}
                    style={{ marginRight: 5 }}
                    // noWrap
                >
                    {getSubHeader()}
                </Typography>
            </CardContent>
            
                     
            
            
            </Card>
        </> 
    )
}
