import React from 'react'
import {
    AccountBalance as AccountBalanceIcon,
    AllInclusive as AllInclusiveIcon,
    ConfirmationNumber as ConfirmationNumberIcon,
    History as HistoryIcon,
    Home as HomeIcon,
    Image as ImageIcon,
    InsertChart as InsertChartIcon,
    LocalAtm as LocalAtmIcon,
    People as PeopleIcon,
    SettingsInputAntennaOutlined as SettingsInputAntennaOutlinedIcon,
    Stars as StarsIcon,
    FavoriteBorderOutlined as FavoriteBorderOutlinedIcon,
    DashboardOutlined as DashboardOutlinedIcon,
    DonutSmallOutlined as DonutSmallOutlinedIcon,
    AccountBalanceOutlined as AccountBalanceOutlinedIcon,
    CollectionsBookmarkOutlined as CollectionsBookmarkOutlinedIcon,
    DragIndicatorOutlined as DragIndicatorOutlinedIcon,
} from '@material-ui/icons'
import xWinUtils from '../../xWinUtils'

const structure = [
    {
        id: 577,
        label: 'sidebar.title.general',
        defaultExpand: true,
        icon: <DashboardOutlinedIcon />,
        children: [
            {
                label: 'sidebar.children.dashboard',
                link: '/app/dashboard',
                icon: <></>,
            },
            {
                label: 'sidebar.children.markets',
                link: '/app/markets',
                icon: <></>,
            },
            {
                label: 'sidebar.children.leaderboard',
                link: '/app/leaderboard',
                icon: <></>,
            },
        ],
    },
    {
        id: 578,
        defaultExpand: false,
        label: 'sidebar.title.my-menu',
        icon: <FavoriteBorderOutlinedIcon />,
        children: [
            {
                label: 'My Exposure',
                link: '/app/exposure',
                icon: <></>,
            },
            {
                label: 'sidebar.children.my-vaults',
                link: '/app/vaults/privatevault',
                icon: <></>,
            },
            {
                label: 'sidebar.children.my-deposit',
                link: '/app/mymenu',
                icon: <></>,
            },
            {
                label: 'sidebar.children.my-referral',
                link: '/app/referral',
                icon: <></>,
            },
        ],
    },
    {
        id: 558,
        label: 'sidebar.title.robo',
        icon: <DonutSmallOutlinedIcon />,
        link: '/app/allocation',
    },
    {
        id: 868,
        defaultExpand: true,
        label: 'sidebar.title.strategies',
        icon: <StarsIcon />,
        children: [
            {
                label: 'sidebar.children.auto-trading',
                link: '/app/strategies/trading',
                icon: <></>,
            },
            {
                label: 'sidebar.children.single',
                link: '/app/strategies/singlebase',
                icon: <></>,
            },
            {
                label: 'sidebar.children.recents',
                link: '/app/vaults/allprivatevault',
                icon: <></>,
            },
        ],
    },

    {
        id: 478,
        defaultExpand: false,
        label: 'sidebar.title.staking',
        icon: <AccountBalanceOutlinedIcon />,
        children: [
            {
                label: 'sidebar.children.auto-compound',
                link: '/app/autolockstake',
                icon: <></>,
            },
            {
                label: 'sidebar.children.earn-xwin',
                link: '/app/farmingV2/all',
                icon: <></>,
            },
        ],
    },
    {
        id: 598,
        defaultExpand: false,
        label: 'sidebar.title.others',
        icon: <DragIndicatorOutlinedIcon />,
        children: [
            {
                label: 'sidebar.title.auditreport',
                icon: <>{xWinUtils.getIcons('certik')}</>,
                link:
                    'https://download.xwin.finance/docs/xWINFinance-Certik-Audit.pdf',
            },
            {
                label: 'sidebar.title.docs',
                icon: <CollectionsBookmarkOutlinedIcon />,
                link: 'https://doc.xwin.finance',
            },
            {
                label: 'sidebar.title.devdocs',
                icon: <CollectionsBookmarkOutlinedIcon />,
                link: 'https://developer.xwin.finance/',
            },
            {
                label: 'sidebar.title.legacy',
                icon: <HistoryIcon />,
                link: 'https://v1.xwin.finance',
            },
        ],
    },
]

export default structure
