import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => (
{
  root: {
    '& .red-cell': {
      color: theme.palette.secondary.dark,
    },
    '& .green-cell': {
      color: theme.palette.success.dark,
    },
    '& .bold-text': {
      fontWeight: 'bold',
    },
  },
  icon: {
    height: "32px",
    width: "32px",
    [theme.breakpoints.down('xs')]: {
      height: 25,
      width: 25,
    },
    zIndex: "30",
    borderRadius: "50px",
    justifyContent: "center",
    // background: "#FFFFFF",
    alignItems: "center",
    marginRight: "-5px",
  }
}))