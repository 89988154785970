import format from 'format-number'
import moment from 'moment'
import { BigNumber } from 'bignumber.js'
import appGlobalConfig from './configs/56/appGlobalConfig'
import millify from 'millify'
import { systemConfig } from './configs/systemConfig'
import { getGasPrice, convertToWei, getNetworkById } from './utils/helper'

const ETH_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
const BNB_ADDRESS = '0x0000000000000000000000000000000000000000'
const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000'

function roundTo(number, float) {
    if (number === 0) return '0'
    return parseFloat(number).toFixed(float)
}

function formatNumber(number) {
    if (number > 0) {
        return `+${number}`
    }

    return number
}

const xWinLib = {
    formatTVLNumberDollar(amount) {
        const formatter = new Intl.NumberFormat('en-US')
        const returnAmount = '$' + formatter.format(Math.ceil(amount))
        return returnAmount
    },

    formatNumberComma(amount) {
        const formatter = new Intl.NumberFormat('en-US')
        return formatter.format(amount)
    },

    ToBigNumber(toConvertNumber) {
        const xAmountBig = new BigNumber(toConvertNumber)

        return Math.floor(xAmountBig.toFixed()).toString()
    },

    GetETH_ADDRESS() {
        return ETH_ADDRESS
    },

    GetBNB_ADDRESS() {
        return BNB_ADDRESS
    },

    numDaysBetween(d1, d2) {
        let diff = d2 - d1
        var totalDays = Math.floor(diff / 60 / 60 / 24)
        return totalDays
    },

    numWeeksBetween(d1, d2) {
        let days = this.numDaysBetween(d1, d2)
        var weeks = Math.floor(days / 7)
        return weeks
    },

    getEndDate(duration) {
        return new Date(Date.now() + duration * 24 * 60 * 60 * 1000)
    },

    FormatDate(_date, dateformat = 'L') {
        if (_date === undefined) return ''
        return moment(new Date(_date._seconds * 1000)).format(dateformat)
    },

    FormatUnixDate(_date) {
        if (_date === undefined) return ''
        const timestamp = moment.unix(_date)
        return timestamp.format('YYYY-MM-DD HH:mm:ss')
    },

    CreateTargetAsync(xWinProtocol, FromAddress, xfundaddress, positions) {
        return new Promise((resolve, reject) => {
            const destAddress = []
            const aTokenAddress = []
            const chainlinkAddress = []
            const targetWgts = []

            positions.forEach(p => {
                destAddress.push(p.taddress)
                aTokenAddress.push(p.atokenaddress)
                chainlinkAddress.push(p.chainlinkaddress)
                targetWgts.push(roundTo(p.weight * 100, 0))
            })

            xWinProtocol.methods
                .CreateTarget(destAddress, targetWgts, xfundaddress)
                .send({
                    from: FromAddress,
                    value: 0,
                })
                .on('confirmation', (confirmationNumber, receipt) => {
                    resolve(receipt.transactionHash)
                })
                .on('error', (error, receipt) => {
                    if (receipt !== undefined) reject(receipt.transactionHash)
                })
                .catch(err => {
                    console.log('error: %j', err)
                    reject(err.message)
                })
        })
    },

    RebalanceAllInOne(
        xWinProtocol,
        xfundaddress,
        FromAddress,
        slippageAmt,
        positions
    ) {
        return new Promise((resolve, reject) => {
            const tradeParam = {
                xFundAddress: xfundaddress,
                amount: 0,
                priceImpactTolerance: slippageAmt * 100,
                deadline: Math.floor(Date.now() / 1000) + 60 * 15,
                returnInBase: true,
                referral: EMPTY_ADDRESS,
            }

            const destAddress = []
            const targetWgts = []

            positions.forEach(p => {
                destAddress.push(p.taddress)
                targetWgts.push(roundTo(p.weight * 100, 0))
            })

            xWinProtocol.methods
                .RebalanceAllInOne(tradeParam, destAddress, targetWgts)
                .send({
                    from: FromAddress,
                    value: 0,
                })
                .on('confirmation', (confirmationNumber, receipt) => {
                    resolve(receipt.transactionHash)
                })
                .on('error', (error, receipt) => {
                    if (receipt !== undefined) reject(receipt.transactionHash)
                })
                .catch(err => {
                    console.log('error: %j', err)
                    reject(err.message)
                })
        })
    },

    MoveNonBMAsync(
        xWinProtocol,
        xfundaddress,
        FromAddress,
        slippageAmt,
        tokenAddress
    ) {
        return new Promise((resolve, reject) => {
            xWinProtocol.methods
                .MoveNonIndexNameToBase(
                    xfundaddress,
                    tokenAddress,
                    Math.floor(Date.now() / 1000) + 60 * 15,
                    slippageAmt * 100
                )
                .send({
                    from: FromAddress,
                    value: 0,
                })
                .on('confirmation', (confirmationNumber, receipt) => {
                    resolve(receipt.transactionHash)
                })
                .on('error', (error, receipt) => {
                    if (receipt !== undefined) reject(receipt.transactionHash)
                })
                .catch(err => {
                    console.log('error: %j', err)
                    reject(err.message)
                })
        })
    },

    AddToMetamask(tokenAddress, tokenSymbol, decimals) {
        return new Promise((resolve, reject) => {
            window.ethereum
                .request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: tokenAddress,
                            symbol: tokenSymbol,
                            decimals,
                            // image: 'https://foo.io/token-image.svg',
                        },
                    },
                })
                .then(success => {
                    if (success) {
                        resolve(`${tokenSymbol} successfully added to wallet!`)
                    } else {
                        reject('Ops..! Please try again!')
                    }
                })
                .catch(err => {
                    console.log('error: %j', err)
                    reject('Ops..! Please try again!')
                })
        })
    },

    registerLuckyDrawAsync(protocol, account) {
        return new Promise((resolve, reject) => {
            const latestGasPrice = getGasPrice()
            protocol.methods
                .registerMe()
                .send({
                    from: account,
                    value: 0,
                    ...latestGasPrice,
                })
                .on('confirmation', (confirmationNumber, receipt) => {
                    resolve(receipt.transactionHash)
                })
                .on('error', error => {
                    reject(error.message)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    redeemLuckyDrawAsync(protocol, account, round) {
        return new Promise((resolve, reject) => {
            const latestGasPrice = getGasPrice()
            protocol.methods
                .claimPrizes(round)
                .send({
                    from: account,
                    value: 0,
                    ...latestGasPrice,
                })
                .on('confirmation', (confirmationNumber, receipt) => {
                    resolve(receipt.transactionHash)
                })
                .on('error', error => {
                    reject(error.message)
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    replaceString(template, data) {
        const pattern = /{\s*(\w+?)\s*}/g // {property}
        return template.replace(pattern, (_, token) => data[token] || '')
    },

    getLogoSVG(logoname) {
        return `/images/svg/${logoname}`
    },

    getLogo(logoname) {
        return `https://download.xwin.finance/images/${logoname}`
    },

    getIcon(logoname) {
        return `https://download.xwin.finance/images/${logoname}`
    },

    getBaseCcy(networkName) {
        return 'BNB'
    },

    displayBigNumber(_number) {
        return format({ prefix: ' $', suffix: '' })(
            millify(_number || 0, { precision: 1, lowercase: false })
        )
    },

    getIsJPYC(baseCcy) {
        return baseCcy?.toLowerCase() === 'jpyc'
    },

    getMarketCap(fundData, inUSD = true) {
        const marCap = inUSD
            ? fundData.unipriceInUSD * fundData.totalSupply
            : fundData.uniprice * fundData.totalSupply
        return format({ prefix: inUSD ? '$' : '¥', suffix: '' })(
            millify(marCap || 0, { precision: 1, lowercase: false })
        )
    },

    getMarketCapNoMilify(fundData, inUSD = true) {
        const marCap = inUSD
            ? fundData.unipriceInUSD * fundData.totalSupply
            : fundData.uniprice * fundData.totalSupply
        return format({ prefix: inUSD ? '$' : '¥', suffix: '' })(
            roundTo(marCap, 2)
        )
    },

    getNetwork(chainId) {
        let filterEnv = 'BNB Chain'
        switch (chainId) {
            case 1:
                filterEnv = 'Ethereum'
                break
            case 56:
                filterEnv = 'BNB Chain'
                break
            case 137:
                filterEnv = 'Polygon Chain'
                break
            case 42161:
                filterEnv = 'Arbitrum Chain'
                break
            default:
        }

        return filterEnv
    },

    getEtherLink(address, type) {
        const network = getNetworkById(systemConfig.chainId)
        return `${network.blockExplorerUrls[0]}/${type}/${address}`
    },

    getTokenWeight(tokenName, fundTotalValue) {
        return (tokenName.fundTokenValue / fundTotalValue) * 100
    },

    getTokenWeightExcludeETH(tokenName, fundTotalValue, ethToken) {
        return this.roundTo(
            (tokenName.fundTokenValue /
                (fundTotalValue - ethToken.fundTokenValue)) *
                100,
            2
        )
    },

    getTokenActiveWeight(tokenName, fundTotalValue) {
        const fundWgt = this.getTokenWeight(tokenName, fundTotalValue)
        const targetWgt = tokenName.targetweight / 100

        return targetWgt - fundWgt
    },

    getTokenName(tokensMaster, address) {
        return tokensMaster.find(
            x => x.address.toLowerCase() === address.toLowerCase()
        )?.name
    },

    getTokenAddress(tokensMaster, tokenname) {
        return tokensMaster.find(
            x => x.name.toLowerCase() === tokenname.toLowerCase()
        )?.address
    },

    roundToFormat(number, float) {
        if (number === 0) return ''
        return formatNumber(parseFloat(number).toFixed(float))
    },

    toPercent(number, float) {
        if (number === 0) return ''
        const percent = `${parseFloat(number * 100).toFixed(float)}%`

        return percent
    },

    toPercentNumOnly(number, float) {
        if (number === 0) return ''
        const percent = `${parseFloat(number * 100).toFixed(float)}`

        return percent
    },

    numberWithThousand(x) {
        return format({ prefix: '', suffix: 'k' })(xWinLib.roundTo(x / 1000, 0))
    },

    APRToAPY(value) {
        const apy = Math.pow(1 + value / 365, 365) - 1
        return format({ prefix: '', suffix: '' })(roundTo(apy * 100, 2))
    },

    APRToAPYNoFormat(value) {
        const apy = Math.pow(1 + value / 365, 365) - 1
        return roundTo(apy * 100, 2)
    },

    doShowFeeWarning(
        current,
        feeBlock,
        subsAmt,
        performanceFee,
        highWaterMarkPrice,
        uniprice,
        appGlobalConfig
    ) {
        const diff = feeBlock - current
        const days = diff / appGlobalConfig?.blockPerDay
        const estFee =
            uniprice < highWaterMarkPrice
                ? 0
                : ((uniprice - highWaterMarkPrice) * subsAmt * performanceFee) /
                  100
        const remain = subsAmt - estFee
        return {
            display: days < 3 && uniprice > highWaterMarkPrice,
            days: days,
            remain: remain,
            estFee: estFee,
        }
    },

    numberWithCommas(x) {
        return format({ prefix: appGlobalConfig.BaseCurrency, suffix: '' })(
            roundTo(x, 2)
        )
    },

    roundTo(number, float) {
        if (isNaN(number)) return 0

        if (number === 0) return 0
        return roundTo(number, float)
    },

    roundDown(number, float) {
        if (isNaN(number)) return 0

        if (number === 0) return 0

        float = float || 0
        return Math.floor(number * Math.pow(10, float)) / Math.pow(10, float)
    },

    compareValues(key, order = 'asc') {
        return function innerSort(a, b) {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0
            }

            const varA =
                typeof a[key] === 'string' ? a[key].toUpperCase() : a[key]

            const varB =
                typeof b[key] === 'string' ? b[key].toUpperCase() : b[key]

            let comparison = 0

            if (varA > varB) {
                comparison = 1
            } else if (varA < varB) {
                comparison = -1
            }

            return order === 'desc' ? comparison * -1 : comparison
        }
    },
}

export default xWinLib
