import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  progressSection: {
    marginBottom: theme.spacing(1)
  },
  expanded: { backgroundColor: theme.palette.background.light },
  progressTitle: {
    marginBottom: theme.spacing(2)
  },
  progress: {
    marginBottom: theme.spacing(1),
    backgroundColor: 'rgb(236, 236, 236)',
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main
  },
  progressBarPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  progressBarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  actionsIcon: {
    color: "#76767B"
  },
  avatar: {
    backgroundColor: "transparent",
    height: 56,
    width: 56
  },
  positiveNum: {
    textAlign: "right",
    // fontSize: 20,
    color: theme.palette.primary.main,
  },
  negativeNum: {
    textAlign: "right",
    fontSize: 20,
    color: theme.palette.secondary.dark,
  },
  snackbarWarning: {
    backgroundColor: theme.palette.warning.main,
  },
  snackbarSuccess: {
    backgroundColor: theme.palette.success.light,
    color: "black"
  },
  subTitle: {
    fontWeight: 100,
    color: theme.palette.primary.main,
  },
  selectPadding: {
    padding: 7,
    '& > svg': {
      right: 0
    }
  },
  hoverHighlight: {
    "&:hover": {
      boxShadow: theme.customShadows.widgetWide
    },
  },
}));
