import React, { useEffect, useContext, useState  } from "react";
import {
  Grid,
  Box,
} from "@material-ui/core";
import { Typography} from "../../../components/Wrappers/Wrappers";
import {AppContext}  from '../../../context/AppProvider';

// styles
import useStyles from "../styles";
import MyFarmValue from "./MyFarmValue";
import MyRewardValue from "./MyRewardValue";
import { GetBuddyFarmTotalValueByUser, GetMasterFarmTotalValueByUser } from "../../../utils/firestoreDataInteractor";
import { useTranslation } from "react-i18next";
import MyBuddyFarmValue from "./MyBuddyFarmValue";
import MyBuddyRewardValue from "./MyBuddyRewardValue";
import FarmlistBuddyV2 from "../../farmingBuddyV2/components/FarmlistBuddyV2";
import FarmlistV2 from "../../farmingV2/components/FarmlistV2";
import { useAccount } from "wagmi/dist";

export default function MyFarms(props) {
  var classes = useStyles();
  const { prices, selectedWallet, stakingPools, stakingBuddyPools, ...rest } = props;
  const { theWeb3, web3static, refreshAccount, theSigner } = useContext(AppContext);
  const { address, isConnecting, isDisconnected, isConnected } = useAccount()
  const [loading, setLoading] = React.useState(false);
  const [farmBuddyCollections, setFarmBuddyCollections] = useState([])
  const [farmCollections, setFarmCollections] = useState([])
  const [farmTotalValues, setFarmTotalValues] = useState('0')
  const [farmTotalPending, setFarmTotalPending] = useState('0')
  const [farmBuddyTotalValues, setFarmBuddyTotalValues] = useState('0')
  const [farmBuddyTotalShares, setFarmBuddyTotalShares] = useState('0')

  const { t, i18n } = useTranslation('myMenu')

  useEffect(() => {
    init()
  }, [address]);

  const init = async () => {

      if (address !== undefined) {
           
        setLoading(true)
        const userFarms = await GetBuddyFarmTotalValueByUser(address)
        const userMasterFarms = await GetMasterFarmTotalValueByUser(address)

        let buddyoutput = getBuddyFarmsData(userFarms)
        setFarmBuddyCollections(buddyoutput.farms || [])
        setFarmBuddyTotalValues(buddyoutput.totalamount || 0)
        setFarmBuddyTotalShares(buddyoutput.totalshares || 0)


        let farmoutput = getFarmsData(userMasterFarms)
        setFarmCollections(farmoutput.farms || [])
        setFarmTotalValues(farmoutput.totalamount || 0)
        setFarmTotalPending(farmoutput.totalpending || 0)


    } else {
        setFarmCollections([])
        setFarmTotalValues('0')
        setFarmTotalPending(0)
        setFarmBuddyTotalShares(0)
        setFarmBuddyTotalValues(0)
        setFarmBuddyCollections([])
    }
    setLoading(false)
        
  }

  const getFarmsData = (userFarms) => {
        
    let farms=[]
    userFarms.farmOwned.forEach(v => {
        let selected = stakingPools.find(
            x =>
                x.contractaddress.toLowerCase() ===
                v.contractaddress.toLowerCase()
        )
        farms.push({
            ...selected,
            ...v
        })
    });
    return {
        farms,
        totalamount : userFarms.totalamount,
        totalpending : userFarms.totalpending

    }
       
}

const getBuddyFarmsData = (userFarms) => {
    
    let farms=[]
    userFarms.farmOwned.forEach(v => {
        let selected = stakingBuddyPools.find(
            x =>
                x.rewardtoken.toLowerCase() ===
                v.contractaddress.toLowerCase()
        )
        farms.push({
            ...selected,
            ...v
        })
    });
    return {
        farms,
        totalamount : userFarms.totalamount,
        totalpending : userFarms.totalpending,
        totalshares: userFarms.totalshares

    }
}


  const displayFarmList = () => {
        
    if (farmCollections.length > 0) {
        return farmCollections.map(port => (
            <>
                <FarmlistV2
                    port={port}
                    theSigner={theSigner}
                    web3static={web3static}
                    refreshAccount={refreshAccount}
                    prices={prices}
                />
                <br />
            </>
        ))
    } else {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent={'center'}
            >
                <img
                    style={{ maxWidth: '250px' }}
                    alt={'no data'}
                    src={'/images/background/nodata.png'}
                />
            </Box>
        )
    }
}

const displayBuddyFarmList = () => {
        
  if (farmBuddyCollections.length > 0) {
      return farmBuddyCollections.map(port => (
          <>
              <FarmlistBuddyV2
                  port={port}
                  globalWeb3={theWeb3}
                  web3static={web3static}
                  selectedWallet={address}
                  refreshAccount={refreshAccount}
                  prices={prices}
              />
              <br />
          </>
      ))
  } else {
      return (
          <Box
              display="flex"
              alignItems="center"
              justifyContent={'center'}
          >
              <img
                  style={{ maxWidth: '250px' }}
                  alt={'no data'}
                  src={'/images/background/nodata.png'}
              />
          </Box>
      )
  }
}


  return (
    <Grid container spacing={2}>
        <Grid
            item
            lg={
                farmBuddyCollections?.length === 0
                    ? 9
                    : 6
            }
            sm={12}
            xs={12}
            className={classes.hoverHighlight}
        >
            <Grid item lg={12} sm={12} xs={12}>
                <MyFarmValue
                    farmTotalValues={farmTotalValues}
                    farmTotalPending={farmTotalPending}
                    farmCollections={farmCollections}
                    loading={loading}
                />
                <br />
            </Grid>
            <Box
                display="flex"
                alignItems="center"
                justifyContent={'left'}
            >
                <Typography
                    className={classes.positiveNum}
                >
                    {t('masterchef')}
                </Typography>
                <br />
            </Box>
            <Box
                display="flex"
                alignItems="center"
                justifyContent={'left'}
            >
                <MyRewardValue
                    address={address}
                    farmCollections={farmCollections}
                    farmTotalPending={farmTotalPending}
                    loading={loading}
                    parentCallback={init}
                />
            </Box>
            {farmCollections.length > 0 &&
                displayFarmList()}
        </Grid>
        {farmBuddyCollections?.length > 0 && (
            <Grid
                item
                lg={6}
                sm={12}
                xs={12}
                className={classes.hoverHighlight}
            >
                <Grid item lg={12} sm={12} xs={12}>
                    <MyBuddyFarmValue
                        farmBuddyTotalValues={
                            farmBuddyTotalValues
                        }
                        farmBuddyCollections={
                            farmBuddyCollections
                        }
                        loading={loading}
                    />
                    <br />
                </Grid>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'left'}
                >
                    <Typography
                        className={classes.positiveNum}
                    >
                        {t('buddychef')}
                    </Typography>
                    <br />
                </Box>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'left'}
                >
                    <MyBuddyRewardValue
                        farmBuddyCollections={
                            farmBuddyCollections
                        }
                        loading={loading}
                        theSigner={theSigner}
                        parentCallback={init}
                    />
                </Box>
                {farmBuddyTotalValues > 0 &&
                    displayBuddyFarmList()}
            </Grid>
        )}
    </Grid>
    
  );
}
