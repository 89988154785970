import React, { useState, useEffect, useRef, useContext } from 'react'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import format from 'format-number'
import 'react-toastify/dist/ReactToastify.css'
import { Typography, Button, Avatar } from '../../components/Wrappers/Wrappers'
import CancelIcon from '@material-ui/icons/Cancel'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import { getEstimateGasWithdraw, getEstimateSharesWithdraw, getPerformanceData as getPerformanceDataFund, RedeemAsync } from '../../utils/fundV2Interactor'
import { ExpandMore as ExpandMoreIcon, ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'

import {
    Box,
    CardHeader,
    IconButton,
    Dialog,
    DialogContent,
    DialogActions,
    ButtonGroup,
    TextField,
    InputAdornment,
    AccordionSummary,
    Accordion,
    AccordionDetails,
    Table,
    TableRow,
    TableCell,
    Card
} from '@material-ui/core'
// components
import useStyles from './styles'
import { AppContext } from '../../context/AppProvider'
import confetti from 'canvas-confetti'
import { useTranslation } from 'react-i18next'
import { GetFundV2EstimatedOutput, GetStrategyEstimatedOutput } from '../../utils/firestoreDataInteractor'
import { useAccount } from 'wagmi/dist'
import { jsNumberForAddress } from 'react-jazzicon'
import Jazzicon from 'react-jazzicon/dist/Jazzicon'

const redeemV2 = ({
    isFundV2,
    openModal,
    fundData,
    port,
    userData,
    parentCallback,
    reset,
}) => {
    const classes = useStyles()
    const [loading, setLoading] = React.useState(false)
    const [loadingEst, setLoadingEst] = React.useState(false)
    const [slippage, setSlippage] = React.useState(1)
    const [canRedeem, setCanRedeem] = React.useState(false)
    const [subsAmt, setsubsAmt] = useState('')
    const { t, i18n } = useTranslation('fundDetails')
    const [estimateOut, setEstimateOut] = useState({
        estFee: 0,
        estFeeBeforeDisc: 0,
        finalOut: 0,
        lockBenefitAmount: 0,
        lockDiscount: 0,
        origOut : 0,
        outBeforeDisc: 0,
        profit: 0,
        underlyingPerfFee: 0
    })
    const [origGet, setOrigGet] = useState({
        before: 0,
        after: 0,
    })
    
    const { theSigner, appGlobalConfig } = useContext(
        AppContext
    )
    const { address } = useAccount()
    const [estimateShares, setEstimateShares] = useState(0)
    
    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    useEffect(() => {
        setSlippage(fundData?.baseCcy?.toLowerCase() === "jpyc" ? 3: 1)
    }, [fundData])


    const handleClickAmt = ratio => async event => {
        event.preventDefault()

        if (ratio == 1) {
            setsubsAmt(userData.userfundbal)
            // calcEstimateShares(userData.userfundbal)
            await calcEstimateOutCheck(userData.userfundbal)
        } else {
            let redeemAmt = xWinLib.roundDown(ratio * userData.userfundbal, 18)
            if (redeemAmt > userData.userfundbal)
                redeemAmt = userData.userfundbal
            setsubsAmt(redeemAmt)
            // calcEstimateShares(redeemAmt)
            await calcEstimateOutCheck(redeemAmt)
        }
    }

    const handleClickClearAmt = () => event => {
        event.preventDefault()
        setsubsAmt(0)
        // setEligibleDiscount(false)
        setEstimateOut({
            estFee: 0,
            estFeeBeforeDisc: 0,
            finalOut: 0,
            lockBenefitAmount: 0,
            lockDiscount: 0,
            origOut : 0,
            outBeforeDisc: 0,
            profit: 0,
            underlyingPerfFee: 0
        })
    }

    const handleSlippageChange = () => async event => {
        if (event.target.value === '') return

        const val = parseFloat(event.target.value)
        if (val >= 0 && val !== undefined) {
            setSlippage(val)
        } else {
            setSlippage(1)
        }
    }

    const handleAmtChange = () => async event => {
        if (event.target.value === '') return

        const val = parseFloat(event.target.value)
        if (val >= 0 && val !== undefined) {
            setsubsAmt(val)
            await calcEstimateOutCheck(val)
            // calcEstimateShares(val)
        } else {
            setsubsAmt('')
            setEstimateOut({
                estFee: 0,
                estFeeBeforeDisc: 0,
                finalOut: 0,
                lockBenefitAmount: 0,
                lockDiscount: 0,
                origOut : 0,
                outBeforeDisc: 0,
                profit: 0,
                underlyingPerfFee: 0
            })
        }
    }

    const handleClickRedeem = async event => {
        event.preventDefault()

        if (subsAmt <= 0) {
            showSnackBar('error', `${t('redeemV2.error.withdraw-amount')}`)
            return
        }
        if (subsAmt > userData?.userfundbal) {
            showSnackBar('error', `${t('redeemV2.error.withdraw-amount-2')}`)
            return
        }
        await calcEstimateOutCheck(subsAmt);

        const outputEst = await getEstimateGasWithdraw(
            port?.contractaddress,
            subsAmt,
            theSigner,
            slippage);
        if(!outputEst.status){
            showSnackBar('error', outputEst.message)
            return;
        }

        setLoading(true)
        RedeemAsync(theSigner, port.contractaddress, address, subsAmt, slippage, outputEst.estimation)
            .then(res => {
                setLoading(false)
                setEstimateShares(0)
                setsubsAmt('')
                showSnackBar('success', res)
                confetti()
                parentCallback(true)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                showSnackBar('error', err)
                parentCallback(false)
            })
    }

    const calcEstimateOutForFundv2 = async amount => {
        if (address !== undefined && fundData?.baseToken !== undefined) {
            
            setLoadingEst(true)
            const shares = await getEstimateSharesWithdraw(
                fundData.contractaddress,
                amount, 
                theSigner,
                fundData.baseDecimal,
                slippage
            )
            setEstimateShares(shares)
            if(fundData?.performanceFee === 0 && shares > 0){
                setOrigGet({
                    before: shares,
                    after: shares,
                })    
                setCanRedeem(true)
                setLoadingEst(false)
                return
            }
            let performanceData = await GetFundV2EstimatedOutput(address, fundData?.contractaddress, amount, appGlobalConfig)
            setLoadingEst(false)
            if(shares > 0){
                const beforeAmt = shares - (performanceData.estFeeBeforeDisc - performanceData.estFee) 
                setOrigGet({
                    before: beforeAmt,
                    after: shares,
                })    
                setEstimateOut(performanceData)
                setCanRedeem(true)
            }else{
                setCanRedeem(false)
                showSnackBar('error', `${t('redeemV2.error.no-estimate')}`)
                return
            }
        }
    }

    const calcEstimateOutCheck = async amount => {
        
        if(isFundV2){
            calcEstimateOutForFundv2(amount)
        }else{
            calcEstimateOutForStrategy(amount)
        }
    }

    const calcEstimateOutForStrategy = async amount => {
        if (address !== undefined && fundData?.baseToken !== undefined && amount > 0) {

            setLoadingEst(true)
            const shares = await getEstimateSharesWithdraw(
                fundData.contractaddress,
                amount, 
                theSigner,
                fundData.baseDecimal,
                slippage
            )
            setEstimateShares(shares)

            let performanceData = await GetStrategyEstimatedOutput(address, fundData?.contractaddress, amount, appGlobalConfig)
            setLoadingEst(false)
            if(shares > 0){
                const beforeAmt = shares - (performanceData.estFeeBeforeDisc - performanceData.estFee) 
                setOrigGet({
                    before: beforeAmt,
                    after: shares,
                })    
                setEstimateOut(performanceData)
                setCanRedeem(true)
            }else{
                setCanRedeem(false)
                showSnackBar('error', `${t('redeemV2.error.no-estimate')}`)
                return
            }
            setCanRedeem(true)
        }
    }

    const getSubHeader = () => {
        return (
            <Typography color="secondary" className={classes.displayFont}>
                {format({ prefix: '', suffix: '' })(
                    xWinLib.roundTo(userData?.userfundbal, 14)
                )}
            </Typography>
        )
    }
     const handleSlippageAmt = slippage => event => {
         event.preventDefault()
         setSlippage(slippage)
     }

    const displayEst = () => {
        if (estimateOut.profit > 0 && estimateOut.lockDiscount > 0) {
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'right'}
                >
                    <Typography
                        color="text"
                        colorBrightness={'hint'}
                        variant={'caption'}
                        style={{ marginRight: 5 }}
                        // noWrap
                    >
                        {t('redeemV2.est-received')}
                    </Typography>
                    <img
                        src={xWinLib.getIcon(
                            fundData.baseCcy?.toLowerCase() + '.png'
                        )}
                        alt={fundData.baseCcy}
                        className={classes.avatarSmall}
                    />
                    <Typography
                        // variant={'body2'}
                        className={classes.negativeNum}
                        style={{ marginLeft: 5 }}
                    >
                        <s>
                            {format({ prefix: ' ', suffix: ' ' })(
                                xWinLib.roundDown(origGet.before, 4)
                                // xWinLib.roundDown(estimateOut.estOut, 4)
                            )}
                        </s>
                    </Typography>
                    <Typography
                        // variant={'body2'}
                        className={classes.displayFont}
                        style={{ marginLeft: 5 }}
                    >
                        &rarr;
                        {format({ prefix: ' ', suffix: ' ' })(
                            xWinLib.roundDown(
                                origGet.after,
                                4
                            )
                        )}
                    </Typography>
                    {/* {xWinUtils.displayToolTip("You have performance discount of " + estimateOut.lockDiscount)} */}
                </Box>
            )
        } else {
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'right'}
                >
                    <img
                        src={xWinLib.getIcon(
                            fundData.baseCcy?.toLowerCase() + '.png'
                        )}
                        alt={fundData.baseCcy}
                        className={classes.avatarSmall}
                    />
                    <Typography
                        // variant={'body2'}
                        className={classes.displayFont}
                        style={{ marginLeft: 5 }}
                    >
                        {format({ prefix: ' ', suffix: ' ' })(
                            xWinLib.roundDown(estimateShares, 4)
                        )}
                    </Typography>
                </Box>
            )
        }
    }

    const displayWithdrawWarning = () => {

        if(port?.type === "singleasset") return ""
        
        return (
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.hoverHighlight}
                >
                    <Typography
                        color="text"
                        colorBrightness={'hint'}
                        variant={'caption'}
                        style={{ marginRight: 5 }}
                        // noWrap
                    >
                        <ErrorOutlineIcon />
                        {t('redeemV2.slippage-title')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box className={classes.boxAmount2}>
                        <ButtonGroup size="small" color="secondary">
                            <Button
                                className={classes.customButton2}
                                fullWidth
                                color="secondary"
                                size="small"
                                variant="outlined"
                                onClick={handleSlippageAmt(0.5)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'0.5%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.customButton2}
                                fullWidth
                                color="secondary"
                                size="small"
                                variant="outlined"
                                onClick={handleSlippageAmt(1.0)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'1.0%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.customButton2}
                                fullWidth
                                color="secondary"
                                size="small"
                                variant="outlined"
                                onClick={handleSlippageAmt(1.5)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'1.5%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.customButton2}
                                fullWidth
                                color="secondary"
                                size="small"
                                variant="outlined"
                                onClick={handleSlippageAmt(2.0)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'2.0%'}
                                </Typography>
                            </Button>
                        </ButtonGroup>
                    </Box>
                    <TextField
                        type="number"
                        margin="dense"
                        name="slippage"
                        onChange={handleSlippageChange()}
                        required
                        // variant="outlined"
                        value={slippage}
                        fullWidth
                        InputProps={{
                            classes: {
                                input: classes.negativeNum,
                            },
                            style: { textAlign: 'right' },
                            step: 0.1,
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Typography className={classes.displayFont}>
                                        %
                                    </Typography>
                                </InputAdornment>
                            ),
                        }}
                    />
                </AccordionDetails>
                <AccordionDetails>
                    <Typography
                        color={'primary'}
                        colorBrightness={'hint'}
                        variant={'caption'}
                        style={{ marginRight: 5 }}
                    >
                        {t('redeemV2.slippage-desc')}
                    </Typography>
                </AccordionDetails>
            </Accordion>
        )
    }


    const displayPerformanceFee = () => {
        if (estimateOut.profit > 0 && estimateOut.lockDiscount > 0) {
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'right'}
                >
                    <img
                        src={xWinLib.getIcon(
                            fundData.baseCcy?.toLowerCase() + '.png'
                        )}
                        alt={fundData.baseCcy}
                        className={classes.avatarSmall}
                    />
                    <Typography
                        // variant={'body2'}
                        className={classes.negativeNum}
                        style={{ marginLeft: 5 }}
                    >
                        <s>
                            {format({ prefix: ' ', suffix: ' ' })(
                                xWinLib.roundDown(estimateOut.estFeeBeforeDisc, 4)
                            )}
                        </s>
                    </Typography>
                    <Typography
                        // variant={'body2'}
                        className={classes.displayFont}
                        style={{ marginLeft: 5 }}
                    >
                        &rarr;
                        {format({ prefix: ' ', suffix: ' ' })(
                            xWinLib.roundDown(estimateOut.estFee, 4)
                        )}
                    </Typography>
                </Box>
            )
        } else {
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'right'}
                >
                    <img
                        src={xWinLib.getIcon(
                            fundData.baseCcy?.toLowerCase() + '.png'
                        )}
                        alt={fundData.baseCcy}
                        className={classes.avatarSmall}
                    />
                    <Typography
                        // variant={'body2'}
                        className={classes.displayFont}
                        style={{ marginLeft: 5 }}
                    >
                        {format({ prefix: ' ', suffix: ' ' })(
                            xWinLib.roundDown(estimateOut.estFee, 4)
                        )}
                    </Typography>
                </Box>
            )
        }
    }

    const displayLogo = () => {

        if (fundData.isPrivate)
        {
            return (
                <Jazzicon
                    diameter={40}
                    seed={jsNumberForAddress(
                        port?.contractaddress || ""
                    )}
                />
            )
        }else{
            return (
                <Avatar
                    variant={'rounded'}
                    className={classes.avatar}
                    src={xWinLib.getIcon(port?.logo)}
                />
            )
        }
    }

    return (
        <div>
            <Dialog
                open={openModal}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                fullScreen={false}
                //maxWidth = {'md'}
            >
                {xWinUtils.getProgress(loading)}
                <SnackbarMessage ref={refsnackbar} />
                <Card className={classes.expansion}>
                <CardHeader
                    action={
                        <IconButton
                            className={classes.positiveNum}
                            onClick={reset}
                            aria-label="settings"
                        >
                            <CancelIcon />
                        </IconButton>
                    }
                    avatar={displayLogo()}
                    subheader={getSubHeader()}
                />
                <DialogContent>
                    <Box className={classes.boxAmount}>
                        <ButtonGroup size="small" color="secondary">
                            <Button
                                // className={classes.columnHide}
                                fullWidth
                                color="secondary"
                                size="small"
                                variant="outlined"
                                onClick={handleClickAmt(0.25)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'25%'}
                                </Typography>
                            </Button>
                            <Button
                                fullWidth
                                color="secondary"
                                size="small"
                                variant="outlined"
                                onClick={handleClickAmt(0.5)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'50%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.columnHide}
                                fullWidth
                                color="secondary"
                                size="small"
                                variant="outlined"
                                onClick={handleClickAmt(0.75)}
                            ><Typography className={classes.displayFont}>
                                {'75%'}
                                </Typography>
                            </Button>
                            <Button
                                className={classes.buttonGroup}
                                fullWidth
                                color="secondary"
                                size="small"
                                variant="outlined"
                                onClick={handleClickAmt(1)}
                            >
                                <Typography className={classes.displayFont}>
                                    {'100%'}
                                </Typography>
                            </Button>
                            <Button
                                fullWidth
                                color="secondary"
                                size="small"
                                variant="outlined"
                                onClick={handleClickClearAmt()}
                            >
                                <Typography className={classes.displayFont}>
                                    {t('redeemV2.clear')}
                                </Typography>
                            </Button>
                        </ButtonGroup>
                    </Box>
                </DialogContent>
                <DialogContent>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'center'}
                    >
                        <TextField
                            type="number"
                            margin="dense"
                            name="subsAmt"
                            onChange={handleAmtChange()}
                            required
                            variant="outlined"
                            value={subsAmt}
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.negativeNum,
                                },
                                style: { textAlign: 'right' },
                                step: '0.1',
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography className={classes.displayFont}>{port?.symbol}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </DialogContent>
                {displayWithdrawWarning()}
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        className={classes.hoverHighlight}
                    >
                        {displayEst()}
                    </AccordionSummary>
                    <AccordionDetails>
                            <Table size="small">
                                { appGlobalConfig?.chainId === "56" && (
                                    <TableRow>
                                    <TableCell width="30%" className={classes.tableHightlightPositive}>
                                        <Typography
                                            color="text"
                                            colorBrightness={'hint'}
                                            variant={'caption'}
                                            style={{ marginRight: 5 }}
                                            // noWrap
                                        >
                                            {t('redeemV2.discount')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="70%" className={classes.tableHightlightPositive}>
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            justifyContent={'right'}
                                        >
                                            <img
                                                src={xWinLib.getIcon(
                                                    fundData.baseCcy?.toLowerCase() + '.png'
                                                )}
                                                alt={fundData.baseCcy}
                                                className={classes.avatarSmall}
                                            />
                                            <Typography
                                                // variant={'body2'}
                                                className={classes.displayFont}
                                                style={{ marginLeft: 5 }}
                                            >
                                                {format({ prefix: ' ', suffix: '%' })(
                                                    xWinLib.roundDown(estimateOut.lockDiscount * 100, 2)
                                                )}
                                            </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                )}
                                
                                <TableRow>
                                    <TableCell width="30%" className={classes.tableHightlightPositive}>
                                        <Typography
                                            color="text"
                                            colorBrightness={'hint'}
                                            variant={'caption'}
                                            style={{ marginRight: 5 }}
                                            // noWrap
                                        >
                                             {t('redeemV2.est-performance')}
                                            
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="70%" className={classes.tableHightlightPositive}>
                                        {
                                        loadingEst ? (
                                            xWinUtils.getProgressLinear(loadingEst)
                                        ) : (
                                            displayPerformanceFee()
                                        )}
                                    </TableCell>
                                </TableRow>
                            </Table>
                    </AccordionDetails>
                </Accordion>
                <DialogActions>
                    <ButtonGroup
                        size="small"
                        color="primary"
                        aria-label="large outlined primary button group"
                    >
                        <Button
                            onClick={handleClickRedeem}
                            disabled={!canRedeem}      
                            color="secondary"
                            variant="contained"
                            startIcon={<AddCircleIcon />}
                        >
                            <Typography className={classes.displayFont}>
                                {t('redeemV2.withdraw')}
                            </Typography>
                        </Button>
                    </ButtonGroup>
                </DialogActions>
                </Card>
            </Dialog>
        </div>
    )
}

export default redeemV2
