import React, { useState, useEffect, useContext } from 'react'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import clsx from 'clsx'
import format from 'format-number'
import 'react-toastify/dist/ReactToastify.css'
import { Typography } from '../../components/Wrappers/Wrappers'
import {
    TableRow,
    Table,
    TableCell,
    TableBody,
    Card,
    DialogContent,
    Box,
} from '@material-ui/core'
import useStyles from './styles'
import { AppContext } from '../../context/AppProvider'
import _ from 'lodash'
import { getFundActivitiesByWalletOrContractAddr } from '../../utils/moralisInteractor'
// import appGlobalConfig from '../../configs/56/appGlobalConfig';
import { useTranslation } from 'react-i18next'
import { getExplorerScan } from '../../utils/helper'

export default function HistoryEvents(props) {
    const { id, showAll, account } = props
    const classes = useStyles()
    const { appGlobalConfig, explorerScan } = useContext(AppContext)

    const [loading, setLoading] = React.useState(false)
    const [newResults, setNewResults] = useState([])
    const { t, i18n } = useTranslation('fundDetails')

    useEffect(() => {
        getHistoryEvents()
    }, [id, account])

    const displayResult = () => {

        if (newResults.length === 0) {
            return (
                <TableRow hover>
                    <TableCell colSpan={6}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'center'}
                        >
                            <img
                                style={{ maxWidth: '250px' }}
                                alt={'no data'}
                                src={'/images/background/nodata.png'}
                            />
                        </Box>
                    </TableCell>
                </TableRow>
            )
        } else {
            return newResults?.map(x => (
                <TableRow hover>
                    <TableCell width="15%" className={clsx(classes.contentText, classes.columnHide)}>
                        <a
                            href={getExplorerScan(
                                x.contractaddress,
                                'address',
                                explorerScan
                            )}
                            target="_blank"
                        >
                            {xWinUtils.getAddressShort(x.contractaddress)}
                        </a>
                    </TableCell>
                    <TableCell width="15%" className={clsx(classes.contentText)}>
                        {xWinLib.roundTo(x.shares, 4)}
                    </TableCell>
                    <TableCell width="15%" className={clsx(classes.contentText, classes.columnHide)}>
                        <a
                            href={getExplorerScan(
                                x.useraddress,
                                'address',
                                explorerScan
                            )}
                            target="_blank"
                        >
                            {xWinUtils.getAddressShort(x.useraddress)}
                        </a>
                    </TableCell>
                    <TableCell width="15%" className={clsx(classes.contentText)}>{x.type}</TableCell>
                    <TableCell width="15%" className={clsx(classes.contentText, classes.columnHide)}>
                        {xWinLib.roundTo(x.amount, 4)}
                    </TableCell>
                    <TableCell width="15%" className={clsx(classes.contentText)}>
                        <a
                            href={getExplorerScan(
                                x.transaction_hash,
                                'tx',
                                explorerScan
                            )}
                            target="_blank"
                        >
                            {xWinUtils.getAddressShort(x.transaction_hash)}
                        </a>
                    </TableCell>
                </TableRow>
            ))
        }
    }

    const historyEvents = () => {
        return (
            <DialogContent>
                <Box display="flex" alignItems="left" justifyContent={'left'}>
                    <Typography variant="h5" className={classes.newsHeader}>
                        {t('historyEvents.title')}
                    </Typography>
                </Box>
                <br />
                <Box display="flex" alignItems="left" justifyContent={'left'}>
                    <Table size="small">
                        <TableBody>
                            <TableRow hover>
                                <TableCell
                                    width="15%"
                                    className={clsx(
                                        classes.contentText,
                                        classes.columnHide
                                    )}
                                >
                                    {t('historyEvents.contract')}
                                </TableCell>
                                <TableCell
                                    width="15%"
                                    className={clsx(classes.contentText)}
                                >
                                    {t('historyEvents.shares')}
                                </TableCell>
                                <TableCell
                                    width="15%"
                                    className={clsx(
                                        classes.contentText,
                                        classes.columnHide
                                    )}
                                >
                                    {t('historyEvents.wallet')}
                                </TableCell>
                                <TableCell
                                    width="15%"
                                    className={clsx(classes.contentText)}
                                >
                                    {t('historyEvents.activity')}
                                </TableCell>
                                <TableCell
                                    width="15%"
                                    className={clsx(
                                        classes.contentText,
                                        classes.columnHide
                                    )}
                                >
                                    {t('historyEvents.amount')}
                                </TableCell>
                                <TableCell
                                    width="15%"
                                    className={clsx(classes.contentText)}
                                >
                                    {t('historyEvents.hash')}
                                </TableCell>
                            </TableRow>

                            {displayResult()}
                        </TableBody>
                    </Table>
                </Box>
            </DialogContent>
        )
    }

    const getHistoryEvents = async () => {
        
        try {
            setLoading(true)
            let contractaddress =
                id === undefined ? appGlobalConfig.mainnetAddressZero : id
            let activities = await getFundActivitiesByWalletOrContractAddr(
                account,
                contractaddress,
                showAll,
                appGlobalConfig
            )
            
            _.sortBy(activities, a => a.block_number)
            setNewResults(activities)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setNewResults([])
        }
    }

    return (
        <Card>
            {historyEvents()}
            {xWinUtils.getProgressLinear(loading)}
        </Card>
    )
}
