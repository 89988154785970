import React, { useState, useEffect, useRef, useContext } from 'react'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import format from 'format-number'
import 'react-toastify/dist/ReactToastify.css'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'

import { Typography, Button } from '../../components/Wrappers/Wrappers'
import CancelIcon from '@material-ui/icons/Cancel'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { RebalanceAsync, canRebalance, getEstimateAsync } from '../../utils/fundV2Interactor'
import {getTargetNamesAddress,getTargetedWeight} from '../../utils/strategyV2Interactor.js'

import {
    TableRow,
    Table,
    TableCell,
    TableBody,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    ButtonGroup,
    TextField,
    InputAdornment,
    IconButton,
    Tooltip,
    Box,
    Card,
} from '@material-ui/core'
// components
import useStyles from './styles'
import { AppContext } from '../../context/AppProvider'
import { useTranslation } from 'react-i18next'

const RebalanceV2 = ({
    openModal,
    fundData,
    tokensMaster,
    selectedport,
    networkName,
    selectedWallet,
    reset,
}) => {
    const classes = useStyles()
    const [loading, setLoading] = React.useState(false)
    const [originalPosition, setOriginalPosition] = React.useState()
    const [positions, setPositions] = useState([])
    const [slippage, setSlippage] = React.useState(1)
    const { theSigner, web3static } = useContext(AppContext)
    const { t, i18n } = useTranslation('fundDetails')
    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    useEffect(() => {
        init()
    }, [fundData])

    const init = async () => {
        if(fundData.tokenNames === undefined){
                let p =[]
            if(selectedport?.template === "allocation"){
                 if(originalPosition){
                  return  setPositions(originalPosition)
                }
                const targetaddress = await getTargetNamesAddress(web3static, selectedport.contractaddress)

                targetaddress.forEach(async target => {
                const getThisTarget =  await getTargetedWeight(web3static,selectedport.contractaddress, target)

                let tkName = xWinLib.getTokenName(tokensMaster, target)
                p.push({
                    symbol: tkName,
                    weight: getThisTarget / 100,
                    taddress: target,
                })
               
                 setPositions(p)
                 setOriginalPosition(p)

            })
        }
            return
    }
        let p = []

        fundData.tokenNames.forEach(token => {
            if (token.address !== fundData.baseToken) {
                let tkName = xWinLib.getTokenName(tokensMaster, token.address)
                p.push({
                    symbol: tkName,
                    weight: token.targetweight / 100,
                    taddress: token.address,
                })
            }
        })
        setPositions(p)
    }

    const [state, setState] = useState({
        symbol: '',
        weight: 0,
    })
    const [errMsg, setErrmsg] = React.useState('')

    const [ticker, setTicker] = useState({
        symbol: '',
        weight: 0,
    })

    const getTotalWeight = () => {
        let total = 0
        positions.forEach(p => {
            total = total + parseFloat(p.weight)
        })
        return xWinLib.roundTo(total, 3)
    }

    const getTotalWeightExceptThis = pos => {
        let total = 0
        const filteredItems = positions.filter(t => t.symbol !== pos.symbol)
        filteredItems.forEach(p => {
            total = total + parseFloat(p.weight)
        })
        return total
    }

    const handleClickSameBM = event => {
        event.preventDefault()
        init()
    }

    const handleClickAdd = event => {
        event.preventDefault()

        let result = positions.filter(t => t.symbol === ticker.symbol)
        if (result.length > 0) {
            return
        }
        if (ticker.symbol === "" || state.weight === "" || parseFloat(state.weight) == 0) {
            return
        }
        setErrmsg('')
        let existingWgt = getTotalWeight()
        let totalweight = parseFloat(existingWgt) + parseFloat(state.weight)
        if (totalweight > 100) {
            setErrmsg(`${t('rebalanceV2.error.over-weight')}`)
            return
        }

        let p = []
        positions.forEach(element => {
            p.push({
                symbol: element.symbol,
                weight: element.weight,
                taddress: element.taddress,
            })
        })

        let tokenaddress = xWinLib.getTokenAddress(tokensMaster, ticker.symbol)

        p.push({
            symbol: ticker.symbol,
            weight: state.weight,
            taddress: tokenaddress,
        })
        setPositions(p)
    }

    const handleChange = name => event => {
        setState({
            ...state,
            [name]: parseFloat(event.target.value),
        })
    }

    const handleClickRebalanceAllInOne = async event => {
        event.preventDefault()

        let totalweight = getTotalWeight()
        if (parseFloat(totalweight) !== 100) {
            showSnackBar('error', `${t('rebalanceV2.error.total-weight')}`)
            return
        }

        if (!canRebalance(web3static, fundData.contractaddress)) {
            showSnackBar('error', `${t('rebalanceV2.error.cannot-rebalance')}`)
            return
        }
        
        setLoading(true)

        const outputEst = await getEstimateAsync(theSigner, fundData.contractaddress, positions, slippage)
        if(!outputEst.status){
            showSnackBar('error', outputEst.message)
            setLoading(false)
            return;
        }
        
        RebalanceAsync(theSigner, fundData.contractaddress, selectedWallet, positions, slippage, outputEst.estimation)
        .then(res => {
            setLoading(false)
            showSnackBar('success', res)
        })
        .catch(err => {
            setLoading(false)
            showSnackBar('error', err)
        })
    }

    const handleClickUpdate = symbol => event => {
        event.preventDefault()
        const selected = positions.find(t => t.symbol === symbol)
        var weight = prompt('Input Weight', selected.weight)
        if (weight == null) return

        let existingWgt = getTotalWeightExceptThis(selected)
        let totalweight = parseFloat(existingWgt) + parseFloat(weight)
        if (totalweight > 100) {
            setErrmsg(`${t('rebalanceV2.error.over-weight')}`)
            return
        }

        if (parseFloat(weight) == 0) {
            showSnackBar('error', "Token weight can not be 0")
            return
        }

        selected.weight = weight
        let p = []
        positions.forEach(element => {
            p.push({
                symbol: element.symbol,
                weight: element.weight,
                taddress: element.taddress,
            })
        })
        setPositions(p)
    }

    const handleClickDelete = symbol => event => {
        event.preventDefault()
        const filteredItems = positions.filter(t => t.symbol !== symbol)
        setPositions(filteredItems)
    }

    const handleSlippageChange = () => async event => {
        if (event.target.value === '') return

        const val = parseFloat(event.target.value)
        if (val >= 0 && val !== undefined) {
            setSlippage(val)
        } else {
            setSlippage(1)
        }
    }

    const getSetTarget = selectedport => {
        return (
        <>
            <DialogContent>
                <TextField
                        label="Slippage"
                        type="number"
                        margin="dense"
                        name="slippage"
                        onChange={handleSlippageChange()}
                        required
                        // variant="outlined"
                        value={slippage}
                        fullWidth
                        InputProps={{
                            classes: {
                                input: classes.positiveNum,
                            },
                            style: { textAlign: 'right' },
                            step: 0.1,
                            endAdornment: (
                                <InputAdornment position="start">
                                    <Typography className={classes.displayFont}>%</Typography>
                                </InputAdornment>
                            ),
                        }}
                    />
            </DialogContent>
            <DialogContent>
                <Box display="flex" alignItems="left" justifyContent={'left'}>
                    <Table size="small">
                        <TableBody>
                            {positions.map((p, i) => (
                                <TableRow hover>
                                    <TableCell
                                        width="15%"
                                        className={classes.columnHide}
                                    >
                                        <Typography className={classes.displayFont}>
                                            {xWinUtils.getIcons(p.symbol)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="20%">
                                        <Typography className={classes.displayFont}>
                                            {p.symbol}
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="35%">
                                        <Typography className={classes.displayFont}>
                                        {format({ prefix: '', suffix: '%' })(
                                            p.weight
                                        )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell width="15%">
                                        <ButtonGroup
                                            className={classes.customButton}
                                        >
                                            <Button
                                                // variant="contained"
                                                color="primary"
                                                // size="small"
                                                className={classes.customButton}
                                                startIcon={<EditIcon />}
                                                onClick={handleClickUpdate(
                                                    p.symbol
                                                )}
                                            ></Button>
                                            <Button
                                                // variant="contained"
                                                color="primary"
                                                // size="small"
                                                className={classes.customButton}
                                                startIcon={<DeleteIcon />}
                                                onClick={handleClickDelete(
                                                    p.symbol
                                                )}
                                            ></Button>
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow className={classes.tableRow}>
                                <TableCell
                                    width="15%"
                                    className={classes.columnHide}
                                >
                                    {/* {getIcons(ticker)} */}
                                </TableCell>
                                <TableCell width="20%">
                                    <Autocomplete
                                        id="symbol"
                                        style={{ width: '100%' }}
                                        name="symbol"
                                        options={xWinUtils.getSupportedSymbols(
                                            tokensMaster,
                                            networkName,
                                            selectedport
                                        )}
                                        getOptionLabel={option => option.symbol}
                                        onChange={(event, newValue) => {
                                            setTicker({
                                                symbol:
                                                    newValue == null
                                                        ? ''
                                                        : newValue.symbol,
                                            })
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Token"
                                                margin="dense"
                                            />
                                        )}
                                    />
                                </TableCell>
                                <TableCell width="35%">
                                    <TextField
                                        type="number"
                                        className={classes.inputText}
                                        margin="dense"
                                        name="weight"
                                        onChange={handleChange('weight')}
                                        required
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    %
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </TableCell>

                                <TableCell  width="15%">
                                    <ButtonGroup
                                        size="small"
                                        color="primary"
                                        aria-label="small outlined primary button group"
                                    >
                                        <Button
                                            // variant="contained"
                                            // color="primary"
                                            // size="small"
                                            className={classes.customButton}
                                            startIcon={<AddCircleIcon />}
                                            onClick={handleClickAdd}
                                        >
                                            {''}
                                        </Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </DialogContent>
        </>
        )
    }

    return (
        <Dialog
            open={openModal}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            fullScreen={true}
            maxWidth={'lg'}
        >
            <SnackbarMessage ref={refsnackbar} />
            <Card className={classes.expansion}>
            <CardHeader
                action={
                    <IconButton
                        className={classes.displayFont}
                        onClick={reset}
                        aria-label="settings"
                    >
                        <CancelIcon />
                    </IconButton>
                }
                title={<Typography className={classes.negativeNum}>{t('rebalanceV2.title')}</Typography>}
                
            />
            {xWinUtils.getProgress(loading)}
            {getSetTarget(selectedport)}
            <DialogActions>
                <Typography className={classes.negativeNum}>
                    {errMsg}
                </Typography>

                <ButtonGroup
                    size="small"
                    color="primary"
                    aria-label="small outlined primary button group"
                >
                    <Button
                        // className={classes.customButton}
                        onClick={handleClickSameBM}
                        color="secondary"
                        variant="outlined"
                        startIcon={<AddCircleIcon />}
                    >
                        <Typography className={classes.displayFont}>
                        {t('rebalanceV2.original')}
                        </Typography>
                    </Button>
                    <Button
                        // className={classes.customButton}
                        onClick={handleClickRebalanceAllInOne}
                        color="primary"
                        variant="outlined"
                        startIcon={<AddCircleIcon />}
                    >
                        <Typography className={classes.displayFont}>
                        {t('rebalanceV2.rebalance')}
                        </Typography>
                    </Button>
                </ButtonGroup>
            </DialogActions>
            </Card>

        </Dialog>
    )
}

export default RebalanceV2
