import React, { useState, useEffect, useContext } from 'react'
import xWinLib from '../../xWinLib'
import { AppContext } from '../../context/AppProvider'
import ReactInterval from 'react-interval'
import { convertFromWei } from '../../utils/helper'
import { Grid, Button, Container, Box } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { Favorite as FavoriteIcon } from '@material-ui/icons'

import ApexLineChart from './components/ApexLineChart'
import TokenStats from './components/TokenStats'
// styles
import useStyles from './styles'
// components
import Widget from '../../components/Widget'
import { Typography } from '../../components/Wrappers'
import { getTokenInfo } from '../../utils/erc20Interactor'
import TradingSignal from './components/TradingSignal'
import AssetSharpeRatio from './components/AssetSharpeRatio'
import { useTheme } from '@material-ui/styles'
import { getAllPrices } from '../../utils/xWinPriceMasterInteractor'
import MarketTrends from './components/MarketTrends'
import StrategyTVL from './components/StrategyTVL'
import {
    GetTATradingSignal,
    GetTokenPrices,
    GetTVLv1,
    GetTVLv2,
    GetWinStats,
    GetAllTvl
} from '../../utils/firestoreDataInteractor'
import { useTranslation } from 'react-i18next'
import { useAlertBannerState } from '../../context/AlertBannerContext'

const Dashboard = props => {
    const { portfolios, tokensMaster, xwinstrategies } = props
    var theme = useTheme()

    var classes = useStyles()
    const [mainData, setMainData] = React.useState([])
    const [overallStat, setOverallStat] = useState([])
    const [xwinStats, setXwinStats] = useState([])
    const [totalValueLock, setTotalValueLock] = React.useState(0)
    const [totalValueLockV2, setTotalValueLockV2] = React.useState(0)
    const [totalValueLockArb, setTotalValueLockArb] = React.useState(0)
    const [totalValueLockPolygon, setTotalValueLockPolygon] = React.useState(0)
    const [loading, setLoading] = React.useState(false)
    const [pickCategory, setPickCategory] = React.useState('tradingv2')
    const {
        appGlobalConfig,
        web3static,
    } = useContext(AppContext)
    // const { address, isConnecting, isDisconnected, isConnected } = useAccount()
    const [returnChoice, setReturnChoice] = React.useState(3)
    const [period, setPeriod] = React.useState(31)
    const [signals, setSignals] = React.useState([])
    const { t, i18n } = useTranslation('dashboard')

    useEffect(() => {
        fetchTokenPrice()
        fetchAllTvl()
    }, [portfolios])

    useEffect(() => {
        fetchSignal()
    }, [])

    useEffect(() => {
        fetchMarketData()
    }, [portfolios])

    useEffect(() => {
        fetchTVLv1()
    }, [])

    useEffect(() => {
        if(appGlobalConfig?.chainId === 56){
            loadWeb3()
        }else{
            setOverallStat({
                xwinPrice: "0",
                xwinPriceInBNB: "0",
                BNBPrice: "0",
                WBNBXWINPrice: "0",
                JPYUSD: "0",
                BabyLPPrice: "0",
                burnBal: "0",
            })
        }
    }, [portfolios, appGlobalConfig])

    const fetchAllTvl = async () => {
        const response = await GetAllTvl(appGlobalConfig)
        setTotalValueLockV2(response?.BNB)
        setTotalValueLockPolygon(response?.Polygon)
        setTotalValueLockArb(response?.ARB)
    }

    const loadWeb3 = async () => {
        try {
            if(appGlobalConfig === undefined) return

            setLoading(true)
                let totalTVL = await GetTVLv2(appGlobalConfig)
                setTotalValueLockV2(totalTVL)
                let prices = await getAllPrices(web3static)
                let burnInfo = await getTokenInfo(
                    web3static,
                    appGlobalConfig?.mainnetXWIN,
                    appGlobalConfig?.mainnetBurn
                )
                setOverallStat({
                    xwinPrice: prices?.xwinPriceInUSD  || "0",
                    xwinPriceInBNB: convertFromWei(
                        prices?.unipriceInBNB.toString() || "0"
                    ),
                    BNBPrice: convertFromWei(prices?.BNBUSDT.toString() || "0"),
                    WBNBXWINPrice: prices?.pcsLPPrice || "0",
                    JPYUSD: prices?.JPYUSD || "0",
                    BabyLPPrice: prices?.babyLPPrice || "0",
                    burnBal: burnInfo?.targetBal || "0",
                })
            // }
            setLoading(false)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
        setLoading(false)
    }

    const fetchMarketData = async () => {
        const promiseData = await Promise.all([GetWinStats()])
        let winStatsData = promiseData[0]
        setXwinStats(winStatsData)
    }

    const fetchTVLv1 = async () => {
        const promiseData = await Promise.all([GetTVLv1()])
        let tvlv1 = promiseData[0]
        setTotalValueLock(tvlv1)
    }

    const fetchSignal = async () => {
        const promiseData = await Promise.all([GetTATradingSignal()])
        let signalData = promiseData[0]
        setSignals(signalData)
    }

    const fetchTokenPrice = async () => {
        
        const promiseData = await Promise.all([
            GetTokenPrices(appGlobalConfig?.mainnetXWIN, appGlobalConfig),
        ])
        let priceData = promiseData[0]
        setMainData(priceData)
    }

    const AddToMetamask = async event => {
        setLoading(true)
        xWinLib
            .AddToMetamask(appGlobalConfig?.mainnetXWIN, xWinLib.getTokenName(
                tokensMaster,
                appGlobalConfig?.mainnetXWIN
            ), 18)
            .then(res => {
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    const refreshDisplayToken = () => {
        setPickCategory(pickCategory === 'tradingv2' ? 'indexv2' : 'tradingv2')
    }

    const handleClickPeriod = (event, choice) => {
        let duration = 30
        if (choice === 1) {
            duration = 2
        } else if (choice === 2) {
            duration = 8
        } else if (choice === 3) {
            duration = 31
        } else if (choice === 4) {
            duration = 366
        }
        setReturnChoice(choice)
        setPeriod(duration)
    }

    return (
        <Container>
            <ReactInterval
                timeout={20000}
                enabled={true}
                callback={() => refreshDisplayToken()}
            />

            <Grid container spacing={2}>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    
                >
                    <coingecko-coin-price-marquee-widget
                        coin-ids="bitcoin,ethereum,binancecoin,xwin-finance,pancakeswap-token"
                        currency="usd"
                        background-color={theme.palette.background.light}
                        locale="en"
                    ></coingecko-coin-price-marquee-widget>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={8} sm={12} xs={12}>
                    <TradingSignal signals={signals} />
                </Grid>
                <Grid item lg={4} sm={12} xs={12}>
                    <Box>
                        <MarketTrends />
                    </Box>
                    <Box className="pt-3">
                        <AssetSharpeRatio
                            tokensMaster={tokensMaster}
                            appGlobalConfig={appGlobalConfig}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item lg={12} sm={12} xs={12}>
                    <StrategyTVL
                        xwinstrategies={xwinstrategies}
                        web3static={web3static}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default Dashboard
