import React from 'react'

const AlertBannerContext = React.createContext()
const AlertBannerDispatchContext = React.createContext()


export default function AlertBanner({ children }) {
    const [isAlertOpen, setIsAlertOpen] = React.useState(true)


    return (
        <AlertBannerContext.Provider value={isAlertOpen}>
            <AlertBannerDispatchContext.Provider value={setIsAlertOpen}>
                {children}
            </AlertBannerDispatchContext.Provider>
        </AlertBannerContext.Provider>
    )
}


function useAlertBannerState() {
    var context = React.useContext(AlertBannerContext)
    if (context === undefined) {
        throw new Error(
            'useAlertBannerState must be used within a LayoutProvider'
        )
    }
    return context
}

function useAlertBannerDispatch() {
    var context = React.useContext(AlertBannerDispatchContext)
    if (context === undefined) {
        throw new Error(
            'useAlertBannerDispatch must be used within a LayoutProvider'
        )
    }
    return context
}

export {
    AlertBanner,
    useAlertBannerState,
    useAlertBannerDispatch,
    AlertBannerContext,
}
