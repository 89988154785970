import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => (
  {
    root: {
      "&:hover": {
        boxShadow: theme.customShadows.widgetWide
      },
      padding: theme.spacing(0),
    }, 
    expansion: {
      backgroundColor: theme.palette.background.default,
    },
    displayFont: {
      fontSize: '1.042rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      }
    },
    customButton: {
      fontSize: '1.042rem',
      padding: "none",
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
        padding: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.5rem',
        padding: "none",
      },
    },
    newsHeader: {
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: '#536DFE',
    },
    subTitle: {
      color: "#ffc247",
    },
    avatar: {
      height: 40,
      width: 40
    },
    columnHide: {
      [theme.breakpoints.down('xs')]: {
        display: "none"
      }
    },
    columnShow: {
      display: "none",
      [theme.breakpoints.down('xs')]: {
        display: "inline"
      }
    },
    notificationItem: {
      marginTop: theme.spacing(2),
    },
    snackbarWarning: {
      backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
      backgroundColor: theme.palette.success.main,
      color: "black"
    },
    badge: {
      margin: theme.spacing(2, 2, 0, 0),
    },
    positiveNum: {
      textAlign: "right",
      // fontSize: 15,
      color: theme.palette.primary.main,
      fontSize: '1.042rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      }
    },
    negativeNum: {
      fontSize: '1.042rem',
      color: theme.palette.secondary.dark,
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      }
    },
    tableContainer: {
      boxShadow: "none"
    },
    avatarSmall: {
      height: 20,
      width: 20,
      [theme.breakpoints.down('xs')]: {
        height: 15,
        width: 15,
      },
      zIndex: "3",
      borderRadius: "50px",
      justifyContent: "center",
      alignItems: "center",
    },
    displayFont: {
      fontSize: '1.042rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '0.75rem',
      }
    },
  }
))