import React, { useState, useEffect, useRef, useContext } from 'react'
import { useTheme } from '@material-ui/styles'
import xWinLib from '../../../xWinLib'
import ReactApexChart from 'react-apexcharts'
import TimerIcon from '@material-ui/icons/Timer'
import { Typography, Button } from '../../../components/Wrappers/Wrappers'
import { Box, Grid, TextField, InputAdornment } from '@material-ui/core'
// styles
import useStyles from '../styles'
import xWinUtils from '../../../xWinUtils'
import clsx from 'clsx';
import {
    getCompositionValues,
    getNextInvestmentBlock,
    getStableTargetName,
    getNextInvestmentAmount,
} from '../../../utils/xWinStableAlphaInteractor'
import { AppContext } from '../../../context/AppProvider'
import Dot from '../../../components/Sidebar/components/Dot'
import { useTranslation } from 'react-i18next'
import { getExplorerScan, getLayer1ExplorerScan } from '../../../utils/helper'

export default function StrategyDetails(props) {
    var classes = useStyles()
    var theme = useTheme()
    const { strategy, tokensMaster, myFundData } = props
    const {
        web3static,
        explorerScan,
        appGlobalConfig
    } = useContext(AppContext)
    const [compositionValues, setCompositionValues] = useState([])
    const [stableTargetName, setStableTargetName] = useState([])
    const [nextInvBlock, setNextInvBlock] = useState(0)
    const [nextInvAmount, setNextInvAmount] = useState(0)
    const [values, setValues] = useState({
        series2: [],
    })
    const [labels, setLabels] = useState([])
    const { t, i18n } = useTranslation('fundDetails')

    useEffect(() => {
        init()
    }, [strategy])

    let myPayLabel = []
    let seriesValue = []

    const init = async () => {
        try {
            const nextInvAmt = await getNextInvestmentAmount(
                web3static,
                strategy.contractaddress,
                myFundData
            )
            const nextInv = await getNextInvestmentBlock(
                web3static,
                strategy.contractaddress
            )
            const values = await getCompositionValues(web3static, strategy.contractaddress, strategy.decimal || 18, strategy.targetdecimal || 18)
            const names = await getStableTargetName(
                web3static,
                strategy.contractaddress,
                tokensMaster
            )
            setCompositionValues(values)
            setStableTargetName(names)
            setNextInvAmount(nextInvAmt.nextInvestAmount)
            setNextInvBlock(nextInv)
            let total = Number(values.stableValues) + Number(values.targetValues)
            let targetPerc = Number(values.targetValues) / Number(total)
            let stablePerc = Number(values.stableValues) / Number(total)
            myPayLabel.push(names.stakingCcy)
            myPayLabel.push(names.targetCcy)
            seriesValue.push(stablePerc)
            seriesValue.push(targetPerc)
            setLabels(myPayLabel)
            setValues({
                series2: seriesValue,
            })
        } catch (error) {
            console.log(error)            
        }
    }

    const themeOptions = theme => {
        return {
            labels: labels,
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: 'top',
            },
            chart: {
                foreColor: '#ccc',
                toolbar: {
                    show: false,
                },
            },
            responsive: [
                {
                    // breakpoint: 480,
                    // options: {
                    //   chart: {
                    //     width: 200
                    //   },
                    //   legend: {
                    //     position: "bottom"
                    //   }
                    // }
                },
            ],
            colors: [
                theme.palette.primary.main,
                theme.palette.secondary.main,
                theme.palette.warning.main,
                theme.palette.success.light,
                theme.palette.info.main,
            ],
        }
    }

    const goToExplorerScan = event => {

        let getExplorer = getExplorerScan(nextInvBlock, 'block/countdown', explorerScan)  
        if(appGlobalConfig.chainId === 42161){
            getExplorer = getLayer1ExplorerScan(nextInvBlock, 'block/countdown')
        }
        window.open(
            getExplorer,
            '_blank'
        )   
    }

    const displayDesc = () => {
        if (strategy.template.toLowerCase() === 'dca' && appGlobalConfig.chainId === 56) {
            return `${t('strategies.strategyDetails.dca-desc', {
                baseToken: stableTargetName.baseCcy?.toUpperCase(),
                targetToken: stableTargetName.targetCcy,
            })}`
        } else if (strategy.template.toLowerCase() === 'alpha' && appGlobalConfig.chainId === 56) {
            return `${t('strategies.strategyDetails.alpha-desc', {
                baseToken: stableTargetName.baseCcy?.toUpperCase(),
                targetToken: stableTargetName.targetCcy,
            })}`
        } else if (strategy.template.toLowerCase() === 'alpha' && appGlobalConfig.chainId === 42161) {
            return `${t('strategies.strategyDetails.alpha-desc-arb', {
                baseToken: stableTargetName.baseCcy?.toUpperCase(),
                targetToken: stableTargetName.targetCcy,
            })}`
        } else if (strategy.template.toLowerCase() === 'dca' && appGlobalConfig.chainId === 42161) {
            return `${t('strategies.strategyDetails.dca-desc-arb', {
                baseToken: stableTargetName.baseCcy?.toUpperCase(),
                targetToken: stableTargetName.targetCcy,
            })}`
        }  
        else {
            return ''
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid
                item
                lg={6}
                sm={12}
                xs={12}
                className={classes.hoverHighlight2}
            >
                <ReactApexChart
                    options={themeOptions(theme)}
                    series={values.series2}
                    type="donut"
                    height="250"
                    stroke={''}
                />
                <Typography
                    color="text"
                    colorBrightness={'hint'}
                    className={classes.displayFont}
                    // style={{ marginRight: 5 }}
                >
                    {displayDesc()}
                </Typography>
            </Grid>

            <Grid item lg={6} sm={12} xs={12} className={classes.cardpie}>
                <Grid item>
                    <Typography
                        className={clsx(classes.displayFont, "p-2")}
                        color="text"
                        colorBrightness={'hint'}
                    >
                        {t('strategies.strategyDetails.next-block')}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <TextField
                            value={nextInvBlock}
                            size="small"
                            variant="outlined"
                            onClick={goToExplorerScan}
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.positiveNum,
                                },
                                readOnly: true,
                                style: { textAlign: 'right' },
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <TimerIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.displayFont}>
                                        {t('strategies.strategyDetails.blocks')}
                                        </Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>

                <Grid item>
                    <Typography
                        className={clsx(classes.displayFont, "p-2")}
                        color="text"
                        colorBrightness={'hint'}
                    >
                          $ {t('strategies.strategyDetails.usdt-value', {
                                baseToken: "USD", //stableTargetName.baseCcy?.toUpperCase(),
                                stakingCcy: stableTargetName.stakingCcy
                            })}
                        {/* $ {t('strategies.strategyDetails.usdt-value')} */}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <TextField
                            value={xWinLib.displayBigNumber(
                                compositionValues.stableValues
                            )}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.positiveNum,
                                },
                                readOnly: true,
                                style: { textAlign: 'right' },
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.displayFont}>{xWinUtils.getIcons(
                                            stableTargetName.stakingCcy
                                        )}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Typography
                        className={clsx(classes.displayFont, "p-2")}
                        color="text"
                        colorBrightness={'hint'}
                    >
                        $ {t('strategies.strategyDetails.btcb-value', {
                                baseToken: "USD", //stableTargetName.baseCcy?.toUpperCase(),
                                targetToken: stableTargetName.targetCcy
                            })}
                        {/* $ {t('strategies.strategyDetails.btcb-value')} */}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <TextField
                            value={xWinLib.displayBigNumber(
                                compositionValues.targetValues
                            )}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.positiveNum,
                                },
                                readOnly: true,
                                style: { textAlign: 'right' },
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.displayFont}>{xWinUtils.getIcons(
                                            stableTargetName.targetCcy
                                        )}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Typography
                        className={clsx(classes.displayFont, "p-2")}
                        color="text"
                        colorBrightness={'hint'}
                    >
                        $ {t('strategies.strategyDetails.next-amount')}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <TextField
                            value={xWinLib.displayBigNumber(nextInvAmount)}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.positiveNum,
                                },
                                readOnly: true,
                                style: { textAlign: 'right' },
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.displayFont}>{xWinUtils.getIcons(
                                            stableTargetName.stakingCcy
                                        )}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}
