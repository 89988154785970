import React, { forwardRef, useImperativeHandle } from 'react'
// components
import { Typography, Button } from '../../components/Wrappers/Wrappers'

import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    DialogTitle,
} from '@material-ui/core'
import useStyles from './styles'
import { useTranslation } from 'react-i18next'

const termcondition = forwardRef((props, ref) => {
    const { className, ...rest } = props
    const classes = useStyles()
    const [openTnc, setOpenTnc] = React.useState(false)
    const [symbol, setSymbol] = React.useState('')
    const { t, i18n } = useTranslation('termcondition')

    const handleOpen = _symbol => {
        setOpenTnc(true)
        setSymbol(_symbol)
    }

    const handleCloseDisagree = () => {
        setOpenTnc(false)
        let itemKey = 'xwinv2-tnc' + symbol
        localStorage.setItem(itemKey, 'no')
        props.parentCallback(false)
    }

    const handleCloseAgree = () => {
        setOpenTnc(false)
        let itemKey = 'xwinv2-tnc' + symbol
        localStorage.setItem(itemKey, 'yes')
        props.parentCallback(true)
    }

    useImperativeHandle(ref, () => {
        return {
            handleOpen: handleOpen,
        }
    })

    return (
        <Dialog
            open={openTnc}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography className={classes.CardTitle}>
                    {t('title')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Typography
                        className={classes.subTitle}
                        variant={'caption'}
                    >
                        {t('paragraph-1')}
                        <br />
                        <br />
                        {t('paragraph-2')}
                        <br />
                        <br />
                        {t('click')}
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDisagree} color="primary">
                    {t('disagree')}
                </Button>
                <Button onClick={handleCloseAgree} color="primary" autoFocus>
                    {t('agree')}
                </Button>
            </DialogActions>
        </Dialog>
    )
})

export default termcondition
