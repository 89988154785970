const feeFAQ = {
    faqs: [
        {
            symbol: 'fee',
            env: 56,
            question: 'fee.question-platformfee',
            defaultExpanded: true,
            desc: 'fee.desc-platformfee',
        },
        {
            symbol: 'fee',
            env: 56,
            question: 'fee.question-strategyfee',
            desc: 'fee.desc-strategyfee',
        },
        {
            symbol: 'fee',
            env: 56,
            question: 'fee.question-underlying-fee',
            desc: 'fee.desc-underlying-fee',
        },
        {
            symbol: 'fee',
            env: 56,
            question: 'fee.question-performance-fee',
            desc: 'fee.desc-performance-fee',
        },
        {
            symbol: 'fee',
            env: 56,
            question: 'fee.question-performance-fee-diff-wallet',
            desc: 'fee.desc-performance-fee-diff-wallet',
        },
        {
            symbol: 'fee',
            env: 56,
            question: 'fee.question-hwm-shortperiod',
            desc: 'fee.desc-hwm-shortperiod',
        },
    ],
}

export default feeFAQ
