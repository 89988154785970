import React, { useState, useEffect, useRef, useContext } from 'react'
// components
import { Typography, Button } from '../../../components/Wrappers/Wrappers'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import {
    Box,
    DialogContent,
    DialogActions,
    ButtonGroup,
    TextField,
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core'
import useStyles from '../styles'
import { AppContext } from '../../../context/AppProvider'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi/dist'
import { alreadyRegisterReferral, registerRefferal, signinUser } from '../../../utils/firestoreDataInteractor'
import xWinUtils from '../../../xWinUtils'

export default function RegisterReferralDetail(props) {
    const { 
        refCode 
    } = props
    const classes = useStyles()
    const [loadingTxn, setLoadingTxn] = React.useState(false)
    const [alreadyRegister, setAlreadyRegister] = React.useState(false)
    const [validToken, setValidToken] = React.useState(false)
    const [accessToken, setAccessToken] = React.useState("")
    const [referralCode, setReferralCode] = useState(refCode)
    const {
        theSigner,
        appGlobalConfig
    } = useContext(AppContext)
    const { address, isConnected} = useAccount()

    const { t, i18n } = useTranslation('fundDetails')

    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    useEffect(() => {
        initCheckReferral()
    }, [address, appGlobalConfig])

    const initCheckReferral = async () =>{

        if(appGlobalConfig == undefined) return
        if(address == undefined) return
        setLoadingTxn(true)
        const output = await alreadyRegisterReferral(address, appGlobalConfig)
        const already = output !== ""
        setAlreadyRegister(already)
        setReferralCode(output?.ReferralCode || "")
        setLoadingTxn(false)
    }

    const handleClickRegister = async event => {
        event.preventDefault()

        if (referralCode === "") {
            showSnackBar('error', `${t('referral.referral-empty')}`)
            return
        }

        let JSONBody = {
            "WalletAddress": address,
            "ReferralCode": referralCode
          }

        setLoadingTxn(true)
        registerRefferal(
            accessToken,
            appGlobalConfig,
            JSONBody
        )
            .then(res => {
                setLoadingTxn(false)
                showSnackBar('info', res.message)
                setAlreadyRegister(res.status)
            })
            .catch(err => {
                setLoadingTxn(false)
                console.log(err)
                console.log(err.message)
            })
    }

    const handleClickSignIn = async event => {
        event.preventDefault()

        if (!isConnected) {
            showSnackBar('error', `${t('referral.referral-connect')}`)
            return
        }

        const msg = `I would like to sign in for user with address: ${address}`;
        let JSONBody = {
            "walletAddress": address,
            "signature": ""
          }
        let signature = await theSigner.signMessage(msg);
        JSONBody.signature = signature;
        setLoadingTxn(true)        
        await signinUser(
            JSONBody,
            appGlobalConfig
        )
            .then(res => {
                console.log(res, "res")
                setLoadingTxn(false)
                setValidToken(res.status)
                setAccessToken(res.accessToken)
            })
            .catch(err => {
                setLoadingTxn(false)
                setValidToken(false)
                setAccessToken("")
                console.log(err)
            })
    }

    

    const handleTextChange = event => {
        event.preventDefault()
        const referalValue = event.target.value
        if (referalValue !== "" && referalValue !== undefined) {
            setReferralCode(referalValue)
        } else {
            setReferralCode('')
        }
    }

    const displayButton = () => {

        if(alreadyRegister) return ""
        if(!validToken){
            return (
                    <Button
                        onClick={handleClickSignIn}
                        color="secondary"
                        variant="contained"
                        startIcon={<AddCircleIcon />}
                    >
                        <Typography className={classes.displayFont}>
                            {t('referral.sign-in')}
                        </Typography>
                    </Button>
            )
        }else{
            return (
                    <Button
                        onClick={handleClickRegister}
                        color="primary"
                        variant="contained"
                        startIcon={<AddCircleIcon />}
                    >
                        <Typography className={classes.displayFont}>
                            {t('referral.register')}
                        </Typography>
                    </Button>
            )
        }
        
                    
    }
    
    return (
        <>
            <SnackbarMessage ref={refsnackbar} />
            <Card className={classes.expansion}>
            {xWinUtils.getProgress(loadingTxn)}
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.hoverHighlight}
                >
                </AccordionSummary>
                <AccordionDetails>
                    <Typography
                        className={classes.displayFont}                                   
                        style={{ marginRight: 5 }}
                    >
                        {t('referral.referral-message')}
                        
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <DialogContent>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                >
                    <TextField
                        // label={t('referral.textfield-enter-referralCode')}
                        margin="dense"
                        name="referralCode"
                        onChange={handleTextChange}
                        required
                        disabled={alreadyRegister}
                        value={referralCode}
                        fullWidth
                    />
                </Box>
            </DialogContent>            
            
            <DialogActions>
                <ButtonGroup
                    size="small"
                    color="primary"
                    aria-label="outlined primary button group"
                >
                    {displayButton()}
                </ButtonGroup>
            </DialogActions>
            </Card>
        </> 
    )
}
