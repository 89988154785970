import React from "react";
import ApexCharts from "react-apexcharts";
import { useTheme } from "@material-ui/styles";
import xWinLib from '../../../xWinLib';



export default function ApexLineChart(props) {
  var theme = useTheme();
  const { fundData, mainData, period, priceReturns } = props;

  const getDateFormat = (_date) => {

    let year = _date.substring(0, 4)
    let month = _date.substring(4, 6)
    let day = _date.substring(6, 8)
    return new Date(year + "-" + month + "-" +  day)
  }

  let dateArr = []
  let dataArr = []
  let finalUPData  = []
  if(mainData !== null){

    const date = new Date();
    date.setDate(date.getDate() - period);
    Object.keys(mainData).forEach(function (key) {
      if(key !== "env"){
        const historydate = getDateFormat(key);
        if(historydate > date){
          dataArr.push(xWinLib.roundTo(mainData[key], 5))
          dateArr.push(getDateFormat(key).toString("yyyy/mm/dd"))  
        }
      }
    })
  }

  finalUPData.push({
    name: fundData.symbol,
    data: dataArr,
  })

  return (
    <ApexCharts
      options={themeOptions(theme, dateArr, dataArr, priceReturns)}
      series={finalUPData}
      type="area"
      height={300}
    />
  );
}

// ############################################################
function themeOptions(theme, dateArr, dataArr, priceReturns) {
  
  let minY = 0
  minY = dataArr.length > 0 ? Math.min(...dataArr) * (0.999) : 0;
  let maxY = 0
  maxY = dataArr.length > 0 ? Math.max(...dataArr) * (1.001) : 0;
  return {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: dateArr,
    },
    yaxis: {
      min: minY,
      max: maxY,
    },
    tooltip: {
      theme: false,
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    fill: {
      colors: priceReturns >= 0 ? [theme.palette.success.light, theme.palette.success.light]: [theme.palette.secondary.light, theme.palette.secondary.light],
    },
    colors: priceReturns >= 0 ? [theme.palette.success.main, theme.palette.success.light] : [theme.palette.secondary.main, theme.palette.secondary.light],
    chart: {
      foreColor: '#ccc',
      toolbar: {
        show: false,
      },
    },
    legend: {
      show: true,
      fontFamily: 'Poppins',
    },
  };
}
