import React, { useState, useEffect, useRef, useContext } from 'react'
import xWinLib from '../../../xWinLib'
import format from 'format-number'
import clsx from 'clsx'
import useStyles from '../styles'
import { Typography, Button } from '../../../components/Wrappers'
import Dot from '../../../components/Sidebar/components/Dot'
import xWinUtils from '../../../xWinUtils'
import SubscribeFarmV2 from '../../subscribefarmV2/subscribefarmV2'
import RedeemFarmV2 from '../../redeemfarmV2/redeemfarmV2'
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import ReactInterval from 'react-interval'
import confetti from 'canvas-confetti'
import millify from 'millify'
import { AppContext } from '../../../context/AppProvider'
import LinearProgress from '@mui/material/LinearProgress'
import { useTranslation } from 'react-i18next'

import {
    Grid,
    Box,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    CardActions,
    Chip,
    Avatar,
    Badge,
} from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import {
    getStakingAPR,
    GetUserBuddyStakingInfo,
    GetBuddyPoolInfo,
    DepositBuddyFarm,
} from '../../../utils/buddyChefInteractor'
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'


export default function FarmlistBuddyV2(props) {
    var classes = useStyles()
    const {
        prices,
        port,
        globalWeb3,
        web3static,
        selectedWallet,
        refreshAccount,
    } = props
    const { account, XWINBalance, theSigner } = useContext(AppContext)
    const { t, i18n } = useTranslation('farmingBuddy')
    const [loadingTxn, setLoadingTxn] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const [loadingAccount, setLoadingAccount] = React.useState(false)

    const [userData, setUserData] = useState({
        userfundbal: '0',
        userstakebal: '0',
        pendingXwin: '0',
    })
    const [poolInfo, setPoolInfo] = React.useState([])
    const [openDepositModal, setOpenDepositModal] = useState(false)
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false)
    const [endsIn, setEndsIn] = useState('')

    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    async function refreshAccountLocal() {
        if (selectedWallet !== undefined) {
            const userObj = await GetUserBuddyStakingInfo(
                web3static,
                selectedWallet,
                port.pid,
                port.address
            )
            setUserData({
                userfundbal: userObj.targetBal,
                userstakebal: userObj.amount,
                pendingXwin: userObj.pendingXwin,
            })
        } else {
            setUserData({
                userfundbal: '0',
                userstakebal: '0',
                pendingXwin: '0',
            })
        }
    }

    useEffect(() => {
        loadFundData()
    }, [port, prices])

    useEffect(() => {
        loadAccountData()
    }, [selectedWallet])

    const loadFundData = async () => {
        try {
            if (!web3static) return

            setLoading(true)
            const blockNumber = await web3static.eth.getBlockNumber()
            const pool = await GetBuddyPoolInfo(web3static, port, prices)
            setPoolInfo(pool)

            setEndsIn(pool.endPeriod - blockNumber.toString())
        } catch (error) {
            console.log(error)
            setLoading(false)
        } finally {
            setLoading(false)
        }
    }

    const loadAccountData = async () => {
        try {
            if (!web3static) return
            setLoadingAccount(true)
            await refreshAccountLocal()
        } catch (error) {
            console.log(error)
            setLoadingAccount(false)
        } finally {
            setLoadingAccount(false)
        }
    }

    const handleClickHarvest = event => {
        event.preventDefault()

        setLoadingTxn(true)
        DepositBuddyFarm(theSigner, port.pid, selectedWallet, 0)
            .then(res => {
                console.log(res)
                setLoadingTxn(false)
                showSnackBar('success', res)
                refreshAccount()
                refreshAccountLocal()
                confetti()
            })
            .catch(err => {
                setLoadingTxn(false)
                console.log(err)
                showSnackBar('error', err)
            })
    }

    const handleClickSubsFarm = event => {
        refreshAccountLocal()
        setOpenDepositModal(true)
    }

    const handleClickRedeemFarm = event => {
        refreshAccountLocal()
        setOpenWithdrawModal(true)
    }

    const handleCallback = refresh => {
        if (refresh) {
            confetti()
            refreshAccount()
            refreshAccountLocal()
        }
    }

    const getAPR = () => {
        if (poolInfo.length === 0 || endsIn <= 0) {
            return 0
        } else {
            return getStakingAPR(
                poolInfo,
                poolInfo.altTokenPrice,
                prices?.xwinPriceInUSD || 0
            )
        }
    }

    const displayBatch = () => {
        if (userData.userstakebal > 0) {
            return (
                <Badge
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    badgeContent={t('farmingBuddy.staked')}
                    color="primary"
                    overlap="rectangle"
                ></Badge>
            )
        }
    }

    const displayLogo = () => {

        if(port.type === "private"){
            return(
            <Jazzicon
                className={classes.icon2}
                diameter={40}
                seed={jsNumberForAddress(
                    port.contractaddress
                )}
            />
            )
        }else{
            return (
                <>
                <img
                    src={xWinLib.getIcon(port?.logo)}
                    alt={port?.logo}
                    className={classes.icon2}
                />
                <img
                    src={xWinLib.getIcon(port?.logo2)}
                    alt={port?.logo2}
                    className={classes.icon1}
                />
                </>
                )
        }
        
        
        
        
    }

    return (
        <div>
            <ReactInterval
                timeout={30000}
                enabled={true}
                callback={() => loadFundData()}
            />

            {xWinUtils.getProgress(loadingTxn)}
            <SnackbarMessage ref={refsnackbar} />

            <RedeemFarmV2
                userData={userData}
                port={port}
                selectedWallet={selectedWallet}
                openModal={openWithdrawModal}
                parentCallback={handleCallback}
                reset={() => {
                    setOpenWithdrawModal(false)
                    // refreshAccountLocal();
                }}
                buddyfarm={true}
            />

            <SubscribeFarmV2
                userData={userData}
                port={port}
                selectedWallet={selectedWallet}
                openModal={openDepositModal}
                parentCallback={handleCallback}
                reset={() => {
                    setOpenDepositModal(false)
                    handleCallback
                    // refreshAccountLocal();
                }}
                buddyfarm={true}
            />
            <ExpansionPanel className={classes.hoverHighlight}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{ expanded: classes.expanded }}
                >
                    <div className={clsx(classes.column3)}>
                        {displayBatch()}
                        <br />
                        {displayLogo()}
                    </div>
                    <div
                        className={clsx(
                            classes.column3,
                            classes.columnHide,
                            classes.helper1
                        )}
                    >
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginRight: 5 }}
                            // noWrap
                        >
                            {port.name}
                        </Typography>
                        <Typography
                            // variant={'body2'}
                            className={classes.subTitle}
                        >
                            {port.symbol}
                        </Typography>
                    </div>

                    <div className={clsx(classes.column3, classes.helper1)}>
                        <Grid item>
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                                // noWrap
                            >
                                {t('farmingBuddy.tvl')}
                            </Typography>
                            <Typography
                                // variant={'body2'}
                                className={classes.subTitle}
                            >
                                {xWinUtils.displayBalance(
                                    loading,
                                    xWinLib.displayBigNumber(poolInfo.TVLInUSD)
                                )}
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                                // noWrap
                            >
                                {t('farmingBuddy.apr')}
                            </Typography>
                            <Typography
                                // variant={'body2'}
                                className={classes.subTitle}
                            >
                                {xWinUtils.displayBalance(
                                    loading,
                                    format({ prefix: '', suffix: '%' })(
                                        getAPR() === 0
                                            ? 0
                                            : millify(getAPR() || 0, {
                                                  precision: 2,
                                                  lowercase: true,
                                              })
                                    )
                                )}
                            </Typography>
                        </Grid>
                    </div>

                    <div className={clsx(classes.column4, classes.helper1)}>
                        <Grid item>
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                            >
                                {t('farmingBuddy.ends')}
                            </Typography>
                            <Typography
                                // variant={'body2'}
                                className={classes.subTitle}
                            >
                                {endsIn > 0
                                    ? `${endsIn.toLocaleString()} ${t(
                                          'farmingBuddy.blocks'
                                      )}`
                                    : `${t('farmingBuddy.ended')}`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                                // noWrap
                            >
                                {t('farmingBuddy.available')}
                            </Typography>
                            <Typography
                                // variant={'body2'}
                                className={
                                    userData.userfundbal > 0
                                        ? classes.subSuccess
                                        : classes.subTitle
                                }
                            >
                                <Jazzicon
                                    // className={classes.icon2}
                                    diameter={20}
                                    seed={jsNumberForAddress(
                                        port.contractaddress
                                    )}
                                />
                                {/* <img
                                    src={xWinLib.getIcon(
                                        port.stakeSymbol?.toLowerCase() + '.png'
                                    )}
                                    alt={
                                        port.stakeSymbol?.toLowerCase() + '.png'
                                    }
                                    className={classes.avatarSmall}
                                /> */}
                                {xWinUtils.displayBalance(
                                    loadingAccount,
                                    format({ prefix: ' ', suffix: ` ` })(
                                        xWinLib.roundTo(userData.userfundbal, 2)
                                    )
                                )}
                            </Typography>
                        </Grid>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={classes.expansion}>
                    <div
                        className={clsx(classes.column2, classes.columnHide)}
                    />
                    <div className={clsx(classes.column6)}>
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginRight: 5 }}
                            // noWrap
                        >
                            {t('farmingBuddy.earned')}
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'left'}
                        >
                            {/* <Dot size='medium' color="warning" /> */}
                            <Typography
                                // variant={'body2'}
                                className={classes.subTitle}
                                style={{ marginLeft: 5 }}
                            >
                                <img
                                    src={xWinLib.getIcon(
                                        port?.earnSymbol?.toLowerCase() + '.png'
                                    )}
                                    alt={
                                        port?.earnSymbol?.toLowerCase() + '.png'
                                    }
                                    className={classes.avatarSmall}
                                />
                                {xWinUtils.displayBalance(
                                    loadingAccount,
                                    format({ prefix: ` `, suffix: ` ` })(
                                        xWinLib.roundTo(userData.pendingXwin, 5)
                                    )
                                )}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="left"
                            justifyContent={'left'}
                        >
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                            >
                                {xWinUtils.displayBalance(
                                    loadingAccount,
                                    format({ prefix: ' ~', suffix: ' USD' })(
                                        xWinLib.roundTo(
                                            userData.pendingXwin *
                                                prices?.xwinPriceInUSD || 0,
                                            1
                                        )
                                    )
                                )}
                            </Typography>
                        </Box>

                        <CardActions>
                            <Button
                                disabled={selectedWallet === '---'}
                                fullWidth
                                variant="outlined"
                                size="small"
                                color="primary"
                                onClick={handleClickHarvest}
                            >
                                {t('farmingBuddy.harvest')}
                            </Button>
                        </CardActions>
                    </div>
                    <div className={clsx(classes.column6)}>
                        <Typography
                            color="text"
                            colorBrightness={'hint'}
                            variant={'caption'}
                            style={{ marginRight: 5 }}
                            // noWrap
                        >
                            {t('farmingBuddy.my-staked')}:
                        </Typography>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'left'}
                        >
                            {/* <Dot size='medium' color="success" /> */}
                            <Typography
                                // variant={'body2'}
                                className={classes.subTitle}
                                style={{ marginLeft: 5 }}
                            >
                                <Jazzicon
                                    // className={classes.icon2}
                                    diameter={20}
                                    seed={jsNumberForAddress(
                                        port.contractaddress
                                    )}
                                />
                                {/* <img
                                    src={xWinLib.getIcon(
                                        port?.stakeSymbol?.toLowerCase() +
                                            '.png'
                                    )}
                                    alt={
                                        port?.stakeSymbol?.toLowerCase() +
                                        '.png'
                                    }
                                    className={classes.avatarSmall}
                                /> */}
                                {xWinUtils.displayBalance(
                                    loadingAccount,
                                    format({ prefix: ` `, suffix: `` })(
                                        xWinLib.roundTo(
                                            userData.userstakebal,
                                            5
                                        )
                                    )
                                )}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="left"
                            justifyContent={'left'}
                        >
                            <Typography
                                color="text"
                                colorBrightness={'hint'}
                                variant={'caption'}
                                style={{ marginRight: 5 }}
                            >
                                {xWinUtils.displayBalance(
                                    loadingAccount,
                                    format({ prefix: ' ~', suffix: ' USD' })(
                                        xWinLib.roundTo(
                                            userData.userstakebal *
                                                prices?.xwinPriceInUSD || 0,
                                            1
                                        )
                                    )
                                )}
                            </Typography>
                        </Box>

                        <CardActions>
                            <Button
                                disabled={
                                    selectedWallet === '---' || endsIn < 0
                                }
                                fullWidth
                                onClick={handleClickSubsFarm}
                                variant="outlined"
                                size="small"
                                color="primary"
                            >
                                +
                            </Button>
                            <Button
                                disabled={
                                    selectedWallet === '---' ||
                                    Number(userData.userstakebal) === 0
                                }
                                fullWidth
                                onClick={handleClickRedeemFarm}
                                variant="outlined"
                                size="small"
                                color="primary"
                            >
                                -
                            </Button>
                        </CardActions>
                    </div>

                    <div
                        className={clsx(classes.column3, classes.columnHide)}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    )
}
