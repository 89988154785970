import i18next from 'i18next'

const assetAllocationFAQ = {
    faqs: [
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-1',
            defaultExpanded: true,
            desc:
                '<div style="margin-left: auto; margin-right: auto;" data-ephox-embed-iri="https://xwinfinance.s3.amazonaws.com/XWIN+ROBO.mp4#t=2"><video style="width: 100%;" controls="controls" preload="metadata">   <source src="https://xwinfinance.s3.amazonaws.com/XWIN+ROBO.mp4#t=2" type="video/mp4">   Your browser does not support the video element. </video></div><div style="width: 100%; display: flex; flex-wrap: wrap;"><div style="flex: 1 1 0%; text-align: justify;">&nbsp;</div><div>&nbsp;</div></div>',
        },
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-2',
            desc: 'assetAllocationFaq.desc-2',
        },
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-3',
            desc: 'assetAllocationFaq.desc-3',
        },
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-4',
            desc: 'assetAllocationFaq.desc-4',
        },
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-5',
            desc: 'assetAllocationFaq.desc-5',
        },
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-6',
            desc: 'assetAllocationFaq.desc-6',
        },
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-7',
            desc: 'assetAllocationFaq.desc-7',
        },
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-8',
            desc: 'assetAllocationFaq.desc-8',
        },
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-9',
            desc: 'assetAllocationFaq.desc-9',
        },
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-10',
            desc: 'assetAllocationFaq.desc-10',
        },
        {
            symbol: 'assetallocation',
            env: 56,
            question: 'assetAllocationFaq.question-11',
            desc: 'assetAllocationFaq.desc-11',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-1',
            defaultExpanded: true,
            desc:
                '<div style="margin-left: auto; margin-right: auto;" data-ephox-embed-iri="https://xwinfinance.s3.amazonaws.com/XWIN+ROBO.mp4#t=2"><video style="width: 100%;" controls="controls" preload="metadata">   <source src="https://xwinfinance.s3.amazonaws.com/XWIN+ROBO.mp4#t=2" type="video/mp4">   Your browser does not support the video element. </video></div><div style="width: 100%; display: flex; flex-wrap: wrap;"><div style="flex: 1 1 0%; text-align: justify;">&nbsp;</div><div>&nbsp;</div></div>',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-2',
            desc: 'assetAllocationFaq.desc-2',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-3',
            desc: 'assetAllocationFaq.desc-3',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-4',
            desc: 'assetAllocationFaq.desc-4',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-5',
            desc: 'assetAllocationFaq.desc-5',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-6',
            desc: 'assetAllocationFaq.desc-6',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-7',
            desc: 'assetAllocationFaq.desc-7',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-8',
            desc: 'assetAllocationFaq.desc-8-arb',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-9',
            desc: 'assetAllocationFaq.desc-9',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-10',
            desc: 'assetAllocationFaq.desc-10',
        },
        {
            symbol: 'assetallocation',
            env: 42161,
            question: 'assetAllocationFaq.question-11',
            desc: 'assetAllocationFaq.desc-11',
        },
    ],
}

export default assetAllocationFAQ
