import React, { useState, useEffect, useContext } from 'react'
import ApexCharts from 'react-apexcharts'
import { useTheme } from '@material-ui/styles'
import xWinLib from '../../../xWinLib'
// import appGlobalConfig from '../../../configs/56/appGlobalConfig';
import { GetPerformanceCompare } from '../../../utils/firestoreDataInteractor'
import xWinUtils from '../../../xWinUtils'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Avatar, Box, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStyles from '../styles'
import { AppContext } from '../../../context/AppProvider'

export default function returnsCompareChart(props) {
    var theme = useTheme()
    const { fundData, period, tokensMaster } = props
    const [data, setData] = useState([])
    const [series, setSeries] = useState([])
    const [dateLabel, setDateLabel] = useState([])
    const [loading, setLoading] = React.useState(false)
    const [dropdown, setDropdown] = React.useState("")
    const [menuOptions, setMenuOptions] = React.useState([])
    
    const { t, i18n } = useTranslation('fundDetails')
    const classes = useStyles()
    const {
        appGlobalConfig
    } = useContext(AppContext)

    useEffect(() => {
        init(appGlobalConfig?.mainnetBTC)
    }, [appGlobalConfig])

    const init = async bmAddr => {
        setLoading(true)
        setDropdown(bmAddr)
        const options = getOptions(tokensMaster)
        setMenuOptions(getOptions(tokensMaster))
        const promiseData = await Promise.all([
            GetPerformanceCompare(fundData.contractaddress, bmAddr, appGlobalConfig),
        ])
        let seriesdata = promiseData[0]
        setData(seriesdata)
        constructData(seriesdata)
        setLoading(false)
    }

    const constructData = dt => {
        
        let arr = []
        let datesarr = []
        dt?.forEach(d => {
            let name = xWinLib.getTokenName(tokensMaster, d.name)
            if (name === undefined) {
                name = fundData.symbol
            }
            arr.push({
                name: name,
                data: d.data,
            })
        })

        dt[0]?.dates.forEach(d => {
            datesarr.push(d)
        })

        setSeries(arr)
        setDateLabel(datesarr)
    }

    const options = {
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 8],
        },
        xaxis: {
            type: 'datetime',
            categories: dateLabel,
        },
        tooltip: {
            theme: false,
            x: {
                format: 'dd/MM/yy HH:mm',
            },
        },
        fill: {
            colors: [theme.palette.primary.light, theme.palette.success.light],
        },
        colors: [theme.palette.primary.main, theme.palette.success.main],
        chart: {
            foreColor: '#ccc',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        legend: {
            show: true,
        },
    }

    const handleChangeDropdown = async event => {
        event.preventDefault()
        await init(event.target.value)
        setDropdown(event.target.value)
    }

    const getOptions = (tokensMaster) => {
        const types = []
        tokensMaster.forEach(s => {
            types.push({
                name: s.name,
                address: s.address
            })
        });
        return types
    }

    return (
        <>
            {/* <p> */}
                {xWinUtils.getProgressLinear(loading)}
                <Box
                    sx={{ p: 2, border: '1px dashed grey' }}
                    display="flex"
                    alignItems="center"
                    justifyContent={'right'}
                >
                    <Typography
                        color="text"
                        colorBrightness={'hint'}
                        variant={'body2'}
                        style={{ marginLeft: 5 }}
                        className={'p-2'}
                    >
                        {t('returnCompareCharts.benchmark')}
                    </Typography>
                    <FormControl
                        // sx={{ minWidth: 50 }}
                        size="small"
                        variant="standard"
                        style={{minWidth: 120}}
                    >
                        <Select
                            value={dropdown}
                            // autoWidth={true}
                            fullWidth
                            label="Benchmark"
                            onChange={handleChangeDropdown}
                            sx={{
                                color: theme.palette.type === 'dark' && 'white',
                                backgroundColor:
                                    theme.palette.type === 'dark' && '#2f2f36',
                                // paddingLeft: 1,
                            }}
                        >
                            {menuOptions?.map(s => {
                                    return (
                                        <MenuItem value={s.address}>
                                            <img
                                                src={xWinLib.getIcon(s.name.toLowerCase()+".png")}
                                                className={classes.smallavatar}
                                            />
                                                &nbsp;&nbsp;{s.name}
                                        </MenuItem>
                                    )
                                })}
                        </Select>
                    </FormControl>
                </Box>
            {/* </p> */}
            <ApexCharts
                options={options}
                series={series}
                type="line"
                height={400}
            />
        </>
    )
}
