import React, { useState, useEffect, useContext, useRef } from 'react'
import xWinUtils from '../../xWinUtils'
import FarmlistV2 from './components/FarmlistV2'
import { useParams } from 'react-router-dom'
import { getNetworkById } from '../../utils/helper'
import { AppContext } from '../../context/AppProvider'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import {
    Grid,
    Container,
    TextField as Input,
    Box,
    Button,
} from '@material-ui/core'
import { Typography } from '../../components/Wrappers/Wrappers'
import Widget from '../../components/Widget/Widget'
import useStyles from './styles'
import { systemConfig } from '../../configs/systemConfig'
import { getAllPrices } from '../../utils/xWinPriceMasterInteractor'
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/masterchefFAQ'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useNetwork } from 'wagmi'
import { withRouter } from 'react-router-dom'
import TokenStats from '../dashboard/components/TokenStats'
import ApexLineChart from '../dashboard/components/ApexLineChart'
import {
    GetWinStats,
    GetTokenPrices,
} from '../../utils/firestoreDataInteractor'
import xWinLib from '../../xWinLib'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { useTheme } from '@material-ui/styles'
import { Favorite as FavoriteIcon } from '@material-ui/icons'
import { getTokenInfo } from '../../utils/erc20Interactor'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

const FarmingV2 = props => {
    const classes = useStyles()
    var theme = useTheme()
    const { portfolios, fundtype, category } = props
    const [networkName, setNetworkName] = useState('')
    const [filterPortfolios, setFilterPortfolios] = useState([])
    const [
        filterPortfoliosByCategory,
        setFilterPortfoliosByCategory,
    ] = useState([])
    const [loading, setLoading] = React.useState(false)
    const [prices, setPrices] = useState([])
    const [overallStat, setOverallStat] = useState([])
    const refsnackbar = useRef(null)
    const [faqs, setFaqs] = useState([])
    const [value, setValue] = React.useState(0)
    const [xwinStats, setXwinStats] = useState([])
    const [returnChoice, setReturnChoice] = React.useState(3)
    const { t, i18n } = useTranslation('farmingV2')
    const {
        account,
        theWeb3,
        web3static,
        appGlobalConfig,
        refreshAccount,
        theSigner,
        tokensv2,
    } = useContext(AppContext)
    const { id } = useParams()
    const [mainData, setMainData] = React.useState([])
    const [period, setPeriod] = React.useState(31)

    useEffect(() => {
        fetchMarketData()
        loadWeb3()
        fetchTokenPrice()
    }, [portfolios, id])

    const loadWeb3 = async () => {
        if (web3static === undefined) return

        if (appGlobalConfig === undefined) return

        if (appGlobalConfig.chainId !== 56) {
            return props.history.push('/app/dashboard')
        }
        setLoading(true)
        let filterEnv = getNetworkById(systemConfig.chainId).env // xWinLib.getNetwork()
        setNetworkName(filterEnv)

        let allprices = await getAllPrices(web3static)
        let burnInfo = await getTokenInfo(
            web3static,
            appGlobalConfig?.mainnetXWIN,
            appGlobalConfig?.mainnetBurn
        )
        if (appGlobalConfig.chainId === 56) {
            setOverallStat({
                xwinPrice: allprices?.xwinPriceInUSD || '0',
                burnBal: burnInfo?.targetBal || '0',
            })
        }

        let filterport = []
        let searchExcludeDiscontinuePort
        if (filterEnv === undefined) {
            filterport = portfolios //.filter(x=>x.env.toLowerCase() === "bscmain")
            searchExcludeDiscontinuePort = filterport.filter(
                x => x.category !== 'discontinued'
            )
            setFilterPortfoliosByCategory(searchExcludeDiscontinuePort)
        } else {
            filterport = portfolios // .filter(x=>x.env.toLowerCase() === filterEnv.toLowerCase())
            if (id === 'all') {
                searchExcludeDiscontinuePort = filterport.filter(
                    x => x.category !== 'discontinued'
                )
                setFilterPortfoliosByCategory(searchExcludeDiscontinuePort)
            } else {
                let searchPort = filterport.filter(
                    x => x.contractaddress === id
                )
                setFilterPortfoliosByCategory(searchPort)
            }
        }

        let fqs = vaultfaqconfig.faqs.filter(
            x => x.env === appGlobalConfig?.chainId
        )
        setFaqs(fqs)

        setPrices(allprices)
        setFilterPortfolios(filterport)
        setLoading(false)
    }

    const displayNonV2Portfolios = () => {
        let funds = filterPortfoliosByCategory.filter(
            x => x.isxwinfund === false
        )
        return funds.map(port => (
            <Grid
                item
                lg={6}
                sm={12}
                xs={12}
                className={classes.hoverHighlight}
            >
                <FarmlistV2
                    port={port}
                    theSigner={theSigner}
                    globalWeb3={theWeb3}
                    web3static={web3static}
                    selectedWallet={account}
                    networkName={networkName}
                    refreshAccount={refreshAccount}
                    prices={prices}
                />
            </Grid>
        ))
    }

    const AddToMetamask = async event => {
        setLoading(true)
        xWinLib
            .AddToMetamask(
                appGlobalConfig?.mainnetXWIN,
                xWinLib.getTokenName(tokensv2, appGlobalConfig?.mainnetXWIN),
                18
            )
            .then(res => {
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }

    const fetchMarketData = async () => {
        const promiseData = await Promise.all([GetWinStats()])
        let winStatsData = promiseData[0]
        setXwinStats(winStatsData)
    }

    const handleClickPeriod = (event, choice) => {
        let duration = 30
        if (choice === 1) {
            duration = 2
        } else if (choice === 2) {
            duration = 8
        } else if (choice === 3) {
            duration = 31
        } else if (choice === 4) {
            duration = 366
        }
        setReturnChoice(choice)
        setPeriod(duration)
    }

    const fetchTokenPrice = async () => {
        if (appGlobalConfig?.chainId !== 56) return
        const promiseData = await Promise.all([
            GetTokenPrices(appGlobalConfig?.mainnetXWIN, appGlobalConfig),
        ])
        let priceData = promiseData[0]
        setMainData(priceData)
    }

    return (
        <Container>
            {xWinUtils.getProgress(loading)}
            <SnackbarMessage ref={refsnackbar} />
            <Grid container spacing={2}>
                <Grid
                    item
                    lg={4}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight2}
                >
                    <TokenStats
                        xwinStats={xwinStats}
                        overallStat={overallStat}
                    />
                </Grid>
                <Grid
                    item
                    lg={8}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight2}
                >
                    <Widget
                        bodyClass={classes.mainChartBody}
                        header={
                            <div className={classes.mainChartHeader}>
                                <Typography
                                    variant="h6"
                                    color="text"
                                    weight={'medium'}
                                    colorBrightness="secondary"
                                >
                                    {`${t('daily-price', {
                                        token: xWinLib.getTokenName(
                                            tokensv2,
                                            appGlobalConfig?.mainnetXWIN
                                        ),
                                    })}`}
                                </Typography>

                                <ToggleButtonGroup
                                    color={'primary'}
                                    size="small"
                                    value={returnChoice}
                                    exclusive
                                    onChange={handleClickPeriod}
                                >
                                    <ToggleButton
                                        value={2}
                                        style={{
                                            outlineColor:
                                                theme.palette.primary.main,
                                            outlineWidth: '1px',
                                            outlineStyle: 'solid',
                                            margin: '2px',
                                        }}
                                    >
                                        <Typography
                                            className={classes.displayFont}
                                        >
                                            1W
                                        </Typography>
                                    </ToggleButton>
                                    <ToggleButton
                                        value={3}
                                        style={{
                                            outlineColor:
                                                theme.palette.primary.main,
                                            outlineWidth: '1px',
                                            outlineStyle: 'solid',
                                            margin: '2px',
                                        }}
                                    >
                                        <Typography
                                            className={classes.displayFont}
                                        >
                                            1M
                                        </Typography>
                                    </ToggleButton>
                                    <ToggleButton
                                        value={4}
                                        style={{
                                            outlineColor:
                                                theme.palette.primary.main,
                                            outlineWidth: '1px',
                                            outlineStyle: 'solid',
                                            margin: '2px',
                                        }}
                                    >
                                        <Typography
                                            className={classes.displayFont}
                                        >
                                            1Y
                                        </Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <div className={classes.mainChartHeaderLabels}>
                                    <div
                                        className={classes.mainChartHeaderLabel}
                                    >
                                        <Button
                                            size="small"
                                            variant="contained"
                                            onClick={() => AddToMetamask()}
                                            color="secondary"
                                        >
                                            <Typography
                                                className={classes.displayFont}
                                            >
                                                <FavoriteIcon />
                                                {`${t('add-xwin', {
                                                    token: xWinLib.getTokenName(
                                                        tokensv2,
                                                        appGlobalConfig?.mainnetXWIN
                                                    ),
                                                })}`}
                                            </Typography>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        }
                    >
                        <ApexLineChart
                            appGlobalConfig={appGlobalConfig}
                            mainData={mainData}
                            period={period}
                            tokenName={xWinLib.getTokenName(
                                tokensv2,
                                appGlobalConfig?.mainnetXWIN
                            )}
                        />
                    </Widget>
                </Grid>
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight2}
                >
                    <TabPanel value={value} index={0}>
                        <br />
                        <Grid container spacing={2}>
                            {displayNonV2Portfolios()}
                        </Grid>
                    </TabPanel>
                </Grid>
            </Grid>
            <VaultFaq faqs={faqs} />
        </Container>
    )
}

export default withRouter(FarmingV2)
