import ERC20 from "../abi/BEP20.json";
import { getGasPrice, convertToWei, convertFromWei } from "../utils/helper";
import appGlobalConfig from '../configs/56/appGlobalConfig';
import { ethers } from "ethers";

export async function getTokenNameSymbol(web3, token) {
  const contract = new web3.eth.Contract(ERC20, token);

  const promiseData = await Promise.all([
    contract.methods.name().call(),
    contract.methods.symbol().call(),
  ]);
  let name = promiseData[0]
  let symbol = promiseData[1]
  return {
    name,
    symbol
  }
}

export async function getDecimal(web3, token) {
  const contract = new web3.eth.Contract(ERC20, token);

  const promiseData = await Promise.all([
    contract.methods.decimals().call(),
  ]);
  let decimals = promiseData[0]
  return Number(decimals)
}

export async function getTokenInfo(web3, token, targetaddress) {
  const contract = new web3.eth.Contract(ERC20, token);

  const promiseData = await Promise.all([
    contract.methods.decimals().call(),
    contract.methods.balanceOf(targetaddress).call()
  ]);
  let decimals = promiseData[0]
  let targetBal = promiseData[1]

  return {
    "decimals": decimals,
    "targetBal": convertFromWei(targetBal, decimals)
  };
}

export async function approveContract(signer, sourceToken, contractAddr, account) {
  
  return new Promise((resolve, reject) => {
    
    try {
      const latestGasPrice = getGasPrice(signer);
      const contract = new ethers.Contract(sourceToken, ERC20, signer);
        
      contract.approve(contractAddr, appGlobalConfig.MAXALLOWANCE, {
        from: account,
        value: 0,
        ...latestGasPrice
      })
      .then(txn => {
        txn.wait().then(receipt => {
          console.log(receipt);
          resolve(receipt.transactionHash);
        })
      })
      .catch(err => {
        console.log('error: %j', err);
        reject(err.message);
      })
    } catch (err) {
      console.log('error: %j', err);
      reject(err.message);
   }
  });
}

export async function IsTokenApproved(web3, amount, account, sourceToken, contractaddress) {
  if(amount === undefined) return false;
  if(parseFloat(amount) === NaN) return false;
  if(parseFloat(amount) === 0) return false;
  let decimal = await getDecimal(web3, sourceToken)
  const amountInWei = convertToWei(amount, decimal) // globalWeb3.utils.toWei(subsAmount.toString())
  const contract = new web3.eth.Contract(ERC20, sourceToken);
  let contractAllowance = await contract.methods
  .allowance(account, contractaddress)
  .call();
  return parseFloat(contractAllowance) >= parseFloat(amountInWei)  

}

