import React, { useState, useEffect, useRef, useContext } from 'react'
import xWinLib from '../../xWinLib'
import xWinUtils from '../../xWinUtils'
import { useParams } from 'react-router-dom'
import UnitPrice from './components/UnitPrice'
import format from 'format-number'
import { withRouter } from 'react-router-dom'
import ApexLineChart from './components/ApexLineChart'
import ReturnsCompareChart from './components/returnsCompareChart'
import TargetAllocation from './components/TargetAllocation'
import Strategies from '../strategies/Strategies'
import TermCondition from '../termcondition/termcondition'
import DepositV2 from '../depositV2/depositV2'
import RedeemV2 from '../redeemV2/redeemV2'
import { AppContext } from '../../context/AppProvider'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import BarChartIcon from '@material-ui/icons/BarChart'

import HistoryEvents from '../historyDetails/HistoryDetailV2'
import HistoryIcon from '@material-ui/icons/History'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ShareIcon from '@mui/icons-material/Share'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { TwitterShareButton } from 'react-share'
import TwitterIcon from '@mui/icons-material/Twitter'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck'
import clsx from 'clsx'
import VaultFaq from '../vaultfaq/VaultFaq'
import vaultfaqconfig from '../vaultfaq/faq/feeFAQ'

import {
    Grid,
    Box,
    CardContent,
    Card,
    CardHeader,
    TableRow,
    Table,
    TableCell,
    TableBody,
    Avatar,
    TableHead,
    Tooltip,
    ButtonGroup,
    Tabs,
    Tab,
    IconButton,
} from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import { Typography, Button } from '../../components/Wrappers/Wrappers'
import useStyles from './styles'
import PieChartIcon from '@material-ui/icons/PieChart'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PropTypes from 'prop-types'
import { GetFundDataAll, getFundExtraInfo } from '../../utils/fundV2Interactor'
import { getDecimal, getTokenInfo } from '../../utils/erc20Interactor'
import { useTheme } from '@material-ui/styles'
import RebalanceV2 from '../rebalanceV2/RebalanceV2'
import { GetTokenPrices } from '../../utils/firestoreDataInteractor'
import { useTranslation } from 'react-i18next'
import Jazzicon from 'react-jazzicon/dist/Jazzicon'
import { jsNumberForAddress } from 'react-jazzicon'
import { GetStrategiesDataAll, getTargetedWeight } from '../../utils/strategyV2Interactor'
import { useAccount } from 'wagmi/dist'
import { getPrivateFundByContractAddress } from '../../utils/moralisInteractor'
import {
    IsSupportedChain,
    formatChainId,
    getExplorerScan,
} from '../../utils/helper'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import AllocationStrategy from '../strategies/components/AllocationStrategy'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const FundDetailV2 = (props) => {
    var theme = useTheme()
    const classes = useStyles()
    const [mainData, setMainData] = React.useState([])
    const [priceData, setPriceData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [openWithdrawModal, setOpenWithdrawModal] = useState(false)
    const [openDepositModal, setOpenDepositModal] = useState(false)
    const [openRebModal, setOpenRebModal] = useState(false)
    const [returnChoice, setReturnChoice] = React.useState(2)
    const [period, setPeriod] = React.useState(8)
    const [priceReturns, setPriceReturns] = useState(0)
    const [isCopied, setIsCopied] = useState(false)
    const [durationTweet, setDurationTweet] = useState('Weekly')

    const windowWidth = window.innerWidth
    const breakpointWidth = theme.breakpoints.values.sm
    const isSmallScreen = windowWidth < breakpointWidth

    const [userData, setUserData] = useState({
        walletETHBalance: 0,
        selectedWallet: '',
        userfundbal: 0,
        referral: '',
    })
    const [fundData, setFundData] = useState([])
    const [relatedStrategyData, setRelatedStrategyData] = useState([])
    const routeParams = useParams()
    const [port, setPort] = useState([])
    const [fundtype, setFundtype] = useState([])
    const [underlyingNames, setUnderlyingNames] = useState([])

    const refTermCondition = useRef(null)

    const {
        appGlobalConfig,
        explorerScan,
        web3static,
        refreshAccount,
        theSigner,
        xwinstrategies,
        xwinfundv2,
        tokensv2,
        switchChain,
        changeNetworkConfig,
    } = useContext(AppContext)

    const { address } = useAccount()

    const [value, setValue] = React.useState(0)
    const { t, i18n } = useTranslation('fundDetails')
    const [faqs, setFaqs] = useState([])
    const refsnackbar = useRef(null)

    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    const formatUrlParams = (params) => {
        let extractedString = params.split('?')[0]
        return extractedString
    }

    const id = formatUrlParams(routeParams?.id)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        loadFundData()
    }, [id, address, xwinfundv2])

    useEffect(() => {
        calcReturns(priceData, period)
    }, [priceData])

    useEffect(() => {
        loadGraph()
    }, [id, xwinfundv2])

    useEffect(() => {
        loadStrategyData()
    }, [relatedStrategyData])

    useEffect(() => {
        if (props.location.state && props.location.state.quickInvestClicked) {
            setOpenDepositModal(true);
            props.location.state.quickInvestClicked = false;
        }
    }, [openDepositModal])

    const loadGraph = async () => {
        const promiseData = await Promise.all([
            GetTokenPrices(id, appGlobalConfig),
        ])
        let priceData = promiseData[0]
        setMainData(priceData)
        setPriceData(priceData)
    }

    const loadStrategyData = async () => {
        if (relatedStrategyData.length === 0) return

        const promises = relatedStrategyData.map(async t => {
            const promiseData = await Promise.all([
                await GetStrategiesDataAll(
                    web3static,
                    t.address,
                    xwinstrategies
                ),
            ])
            let underyingData = promiseData[0]
            const f = fundData.tokenNames.find((x) => x.address === t.address)
            underyingData.targetweight = f.targetweight
            underyingData.performanceFee = Number(underyingData.performanceFee) / 100
            underyingData.managerFee = Number(underyingData.managerFee) / 100
            return underyingData
        });
        const underlyingStrategies = await Promise.all(promises);
        setUnderlyingNames(underlyingStrategies)
    }

    const loadFundData = async () => {
        
        if (id === undefined) {
            return props.history.push('/app/dashboard')
        }
        if (web3static === undefined) return

        if (appGlobalConfig === undefined) return

        if (xwinfundv2 === undefined)  return

        if (await web3static.eth.getChainId() !== appGlobalConfig?.chainId) return

        if (routeParams?.chainId) {
            const cId = Number(formatChainId(routeParams?.chainId))
            if (IsSupportedChain(cId) && cId === appGlobalConfig?.chainId) {
                localStorage.setItem('chainId', cId)
            } else {
                await changeNetworkConfig(Number(cId))
            }
        }
        setLoading(true)
        let p = xwinfundv2?.find(
            (x) => x.contractaddress.toLowerCase() === id.toLowerCase()
        )

        let isPrivate = false
        if (p === undefined) {
            const privatevault = await getPrivateFundByContractAddress(
                id,
                appGlobalConfig
            )
            p = privatevault[0]
            setFundtype('v2index')
            setPort(p)
            isPrivate = true
        } else {
            setFundtype(p.fundtype)
            setPort(p)
        }
        let myFundData = await GetFundDataAll(web3static, id)

        if (myFundData === undefined) {
            const fetchId = localStorage.getItem('chainId')
            if (address !== undefined) {
                await switchChain(Number(formatChainId(routeParams?.chainId)))
            } else {
                await changeNetworkConfig(Number(fetchId))
            }
            return
        }
        setLoading(false)
        try {
            if (address !== undefined) {
                const promiseData = await Promise.all([
                    getTokenInfo(web3static, id, address),
                    getTokenInfo(web3static, myFundData.baseToken, address),
                ])
                let fundHolderData = promiseData[0]
                let myBaseCcyData = promiseData[1]
                setUserData({
                    userfundbal: fundHolderData.targetBal,
                    userbasebal: myBaseCcyData.targetBal,
                })
            }
            let tokenNames = []
            let targetaddressNew = []
            for (let i = 0; i < myFundData.targetaddress.length; i++) {
                targetaddressNew.push(myFundData.targetaddress[i])
            }

            const baseCcy = tokensv2.find(
                (x) =>
                    x.address.toLowerCase() ===
                    myFundData.baseToken.toLowerCase()
            )?.name
            const stablecoinUSD = tokensv2.find(
                (x) =>
                    x.address.toLowerCase() ===
                    myFundData.stablecoinUSDAddr.toLowerCase()
            )?.name
            let strategies = []

            // setLoading(false)
            for (let i = 0; i < targetaddressNew.length; i++) {
                let token = tokensv2.find(
                    (t) =>
                        t.address.toLowerCase() ===
                        targetaddressNew[i].toLowerCase()
                )
                const promiseData = await Promise.all([
                    getTargetedWeight(web3static, p.contractaddress, targetaddressNew[i]),
                ])
                let tartgetWeight = promiseData[0]
                tokenNames.push({
                    key: i,
                    address: targetaddressNew[i],
                    targetweight: tartgetWeight,
                })

                //get all relavant strategy address for strategy display later
                if (token?.type === 'strategy') {
                    strategies.push(token)
                }
            }

            const baseDecimal = await getDecimal(
                web3static,
                myFundData.baseToken
            )

            setFundData({
                ...fundData,
                baseCcy: baseCcy,
                baseDecimal: baseDecimal,
                baseToken: myFundData.baseToken,
                contractaddress: id,
                targetaddress: myFundData.targetaddress,
                fundvalue: myFundData.fundvalue.toString(),
                fundvalueInUSD: myFundData.fundvalueUSD.toString(),
                tokenNames: tokenNames,
                uniprice: myFundData.uniprice.toString(),
                totalSupply: myFundData.totalSupply.toString(),
                fundName: myFundData.fundName,
                fundmanager: myFundData.fundmanager,
                fundETHbalance: myFundData.fundETHbalance.toString(),
                symbol: myFundData.symbol,
                managerFee: myFundData.managerFee / 100,
                performanceFee: myFundData.performanceFee / 100,
                platformFee: myFundData.platformFee / 100,
                unipriceInUSD: myFundData.unipriceInUSD.toString(),
                stablecoinUSD: stablecoinUSD,
                marketCap: Number(
                    myFundData.unipriceInUSD.toString() *
                        myFundData.totalSupply.toString()
                ).toFixed(0),
                openForPublic: myFundData.openForPublic,
                isPrivate: isPrivate,
            })
            let fqs = vaultfaqconfig.faqs.filter(
                (x) => x.env === appGlobalConfig?.chainId || 56
            )
            setFaqs(fqs)
            setRelatedStrategyData(strategies)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
        setLoading(false)
    }

    const handleClickPeriod = (event, choice) => {
        let duration = 30
        if (choice === 1) {
            duration = 2
            setDurationTweet('Daily')
        } else if (choice === 2) {
            duration = 8
            setDurationTweet('Weekly')
        } else if (choice === 3) {
            duration = 31
            setDurationTweet('Monthly')
        } else if (choice === 4) {
            duration = 366
            setDurationTweet('Annualized')
        }
        setReturnChoice(choice)
        setPeriod(duration)
        calcReturns(priceData, duration)
    }

    const getDateFormat = (_date) => {
        let year = _date.substring(0, 4)
        let month = _date.substring(4, 6)
        let day = _date.substring(6, 8)
        return new Date(year + '-' + month + '-' + day)
    }

    const calcReturns = (data, duration) => {
        if (data === undefined || data === null) return
        if (data.length === 0) return
        if (data !== undefined) {
            let dataArr = []
            const date = new Date()
            date.setDate(date.getDate() - duration)
            Object.keys(mainData).forEach(function (key) {
                if (key !== 'env') {
                    const historydate = getDateFormat(key)
                    if (historydate > date) {
                        dataArr.push(xWinLib.roundTo(mainData[key], 7))
                    }
                }
            })
            let returns =
                Number(dataArr[dataArr.length - 1]) / Number(dataArr[0]) - 1
            setPriceReturns(returns * 100)
        }
    }

    const getFundDesc = () => {
        if (port === undefined) return
        if (fundData?.tokenNames === undefined) {
            return (
                <Card>
                    <CardContent className={classes.cards}>
                        {xWinUtils.getProgressLinear(true)}
                    </CardContent>
                </Card>
            )
        }
        if (!fundData.isPrivate) {
            return (
                <Card>
                    <CardContent className={classes.displayFont}>
                        <Typography
                            colorBrightness={'hint'}
                            className={classes.displayFont}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: t(port.desc, {
                                        targetname: port.symbol,
                                        protocol: port.source,
                                    }),
                                }}
                            />
                        </Typography>
                    </CardContent>
                </Card>
            )
        } else {
            return (
                <Card>
                    <CardContent className={classes.displayFont}>
                        <Typography
                            colorBrightness={'hint'}
                            className={classes.displayFont}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: `${t('message-template', {
                                        address: xWinUtils.getAddressShort(
                                            fundData.fundmanager
                                        ),
                                        openForPublic: fundData.openForPublic
                                            ? `${t('yes')}`
                                            : `${t('no')}`,
                                    })}`,
                                }}
                            />
                        </Typography>
                    </CardContent>
                </Card>
            )
        }
    }

    const handleClickBack = async (event) => {
        event.preventDefault()
        history.back()
    }

    const getSubHeader = () => {
        return (
            <div>
                <Typography color={'success'} className={classes.displayFont}>
                    {`${t('priceChart.balance')}` +
                        format({ prefix: ' ', suffix: '' })(
                            xWinLib.roundTo(userData.userfundbal, 4)
                        )}
                </Typography>
                <Typography
                    color="text"
                    colorBrightness={'hint'}
                    variant={'caption'}
                    className={classes.displayFont}
                    //style={{ marginRight: 5 }}
                >
                    <a
                        className={classes.link}
                        href={getExplorerScan(id, 'address', explorerScan)}
                        target="blank"
                    >
                        {t('priceChart.view-bsc')}
                    </a>
                </Typography>
            </div>
        )
    }

    const displayToggleButton = () => {
        return (
            <ToggleButtonGroup
                color={'primary'}
                size="small"
                value={returnChoice}
                exclusive
                onChange={handleClickPeriod}
            >
                <Box
                    className={classes.columnHide}
                    display="flex"
                    alignItems="center"
                    justifyContent={'right'}
                >
                    <Typography
                        variant={'body1'}
                        className={
                            priceReturns >= 0
                                ? classes.positiveNum
                                : classes.negativeNum
                        }
                    >
                        {priceReturns >= 0 ? (
                            <ArrowUpwardIcon />
                        ) : (
                            <ArrowDownwardIcon />
                        )}
                        {format({ prefix: '', suffix: '%' })(
                            xWinLib.roundTo(priceReturns, 2)
                        )}
                    </Typography>
                </Box>
                &nbsp;
                <ToggleButton
                    value={1}
                    style={{
                        outlineColor: theme.palette.primary.main,
                        outlineWidth: '1px',
                        outlineStyle: 'solid',
                        margin: '2px',
                    }}
                >
                    24H
                </ToggleButton>
                <ToggleButton
                    className={classes.columnHide}
                    value={2}
                    style={{
                        outlineColor: theme.palette.primary.main,
                        outlineWidth: '1px',
                        outlineStyle: 'solid',
                        margin: '2px',
                    }}
                >
                    1W
                </ToggleButton>
                <ToggleButton
                    value={3}
                    style={{
                        outlineColor: theme.palette.primary.main,
                        outlineWidth: '1px',
                        outlineStyle: 'solid',
                        margin: '2px',
                    }}
                >
                    1M
                </ToggleButton>
                <ToggleButton
                    value={4}
                    className={classes.columnHide}
                    style={{
                        outlineColor: theme.palette.primary.main,
                        outlineWidth: '1px',
                        outlineStyle: 'solid',
                        margin: '2px',
                    }}
                >
                    1Y
                </ToggleButton>
                <IconButton
                    className={classes.mainHeading}
                    onClick={handleClickBack}
                    aria-label="settings"
                >
                    <ArrowBackIcon />
                </IconButton>
            </ToggleButtonGroup>
        )
    }
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const copyAdressToClipboard = (account) => {
        navigator.clipboard.writeText(window.location.href)
        setIsCopied(true)
    }

    const displayLogo = () => {
        if (fundData.isPrivate) {
            return (
                <Jazzicon
                    diameter={isSmallScreen ? 40 : 85}
                    seed={jsNumberForAddress(port.contractaddress)}
                />
            )
        } else {
            return (
                <Avatar
                    variant={'rounded'}
                    className={classes.avatar}
                    src={xWinLib.getIcon(port.logo)}
                />
            )
        }
    }

    const getPriceChart = () => {
        if (port === undefined) return

        return (
            <Card>
                <CardHeader
                    action={displayToggleButton()}
                    avatar={displayLogo()}
                    title={
                        <Typography className={classes.primaryHeading}>
                            {port.name}
                            <Button
                                id="basic-button"
                                className={classes.columnHide}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <ShareIcon />
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleClose}>
                                    <TwitterShareButton
                                        url={`The ${durationTweet} return of ${
                                            port.name
                                        } is ${xWinLib.roundTo(
                                            priceReturns,
                                            2
                                        )}%  ! \nIt is an on-chain Defi vault deployed to ${xWinLib.getNetwork(
                                            appGlobalConfig?.chainId
                                        )}, powered by @xwinfinance.\n\nhttps://app.xwin.finance/app/funddetailv2/${
                                            port.contractaddress
                                        }/chainId=${appGlobalConfig?.chainId} `}
                                        target="_blank"
                                        windowWidth={window.innerWidth}
                                        windowHeight={window.innerHeight}
                                        openShareDialogOnClick={true}
                                    >
                                        <TwitterIcon size={32} round />
                                        {t('priceChart.share-twitter')}
                                    </TwitterShareButton>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        copyAdressToClipboard()
                                    }}
                                >
                                    {isCopied ? (
                                        <LibraryAddCheckIcon size={32} round />
                                    ) : (
                                        <LibraryAddIcon size={32} round />
                                    )}
                                    {t('priceChart.copy-link')}
                                </MenuItem>
                            </Menu>
                        </Typography>
                    }
                    subheader={getSubHeader()}
                    // titleTypographyProps={{ variant: 'h5' }}
                />
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                >
                    <ButtonGroup>
                        <Button
                            disabled={address === undefined}
                            color="primary"
                            variant="contained"
                            onClick={handleClickSubsDirect}
                            className={classes.customButton}
                        >
                            <Typography className={classes.displayFont}>
                                {t('priceChart.deposit')}
                            </Typography>
                        </Button>
                        <Button
                            disabled={
                                address === undefined ||
                                Number(userData.userfundbal) === 0
                            }
                            // size="medium"
                            color="secondary"
                            variant="contained"
                            onClick={handleClickRedeem}
                            className={classes.customButton}
                        >
                            <Typography className={classes.displayFont}>
                                {t('priceChart.redeem')}
                            </Typography>
                        </Button>
                        {address === fundData.fundmanager && (
                            <Button
                                disabled={address === undefined}
                                size="medium"
                                color="primary"
                                variant="outlined"
                                onClick={handleClickRebalance}
                            >
                                <Typography className={classes.displayFont}>
                                    {t('priceChart.rebalance')}
                                </Typography>
                            </Button>
                        )}
                    </ButtonGroup>
                </Box>
                <ApexLineChart
                    priceReturns={priceReturns}
                    mainData={mainData}
                    fundData={fundData}
                    period={period}
                />
            </Card>
        )
    }

    const handleClickSubsDirect = (event) => {
        if (
            port.showTnC &&
            !xWinUtils.getAgreeTermConditionByPortfolio(port.symbol)
        ) {
            if (refTermCondition.current != null)
                refTermCondition.current.handleOpen(port.symbol)
            return
        }
        setOpenDepositModal(true)
    }

    const handleClickRebalance = (event) => {
        setOpenRebModal(true)
    }

    const handleClickRedeem = (event) => {
        setOpenWithdrawModal(true)
    }

    const handleCallback = (refresh) => {
        if (refresh) {
            refreshAccount()
            loadFundData()
        }
    }

    const handleCallbackTermCondition = (refresh) => {
        if (refresh) setOpenDepositModal(true)
    }

    return (
        <Grid container spacing={2}>
            <SnackbarMessage ref={refsnackbar} />

            <TermCondition
                ref={refTermCondition}
                parentCallback={handleCallbackTermCondition}
            />

            <RedeemV2
                theSigner={theSigner}
                isFundV2={true}
                fundData={fundData}
                selectedWallet={address}
                openModal={openWithdrawModal}
                port={port}
                userData={userData}
                parentCallback={handleCallback}
                reset={() => {
                    setOpenWithdrawModal(false)
                }}
            />
            <DepositV2
                underlyingNames={underlyingNames}
                fundData={fundData}
                isFundv2={true}
                openModal={openDepositModal}
                port={port}
                userData={userData}
                parentCallback={handleCallback}
                reset={() => {
                    setOpenDepositModal(false)
                }}
            />
            <RebalanceV2
                fundData={fundData}
                tokensMaster={tokensv2}
                openModal={openRebModal}
                selectedport={port}
                // networkName={networkName}
                selectedWallet={address}
                parentCallback={handleCallback}
                reset={() => {
                    setOpenRebModal(false)
                }}
            />
            <Grid container spacing={3}>
                <Grid item lg={8} sm={12} xs={12}>
                    {getPriceChart()}
                </Grid>
                <Grid item lg={4} sm={12} xs={12}>
                    <UnitPrice
                        loading={loading}
                        fundData={fundData}
                        port={port}
                        account={address}
                        web3static={web3static}
                        xwinstrategies={xwinstrategies}
                        explorerScan={explorerScan}
                        appGlobalConfig={appGlobalConfig}
                        underlyingNames={underlyingNames}
                    />
                </Grid>
                <Grid
                    item
                    lg={12}
                    md={12}
                    xl={12}
                    xs={12}
                    className={classes.hoverHighlight}
                >
                    <Tabs
                        value={value}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        aria-label="basic tabs example"
                    >
                        <Tab
                            className={classes.contentText}
                            icon={<FileCopyIcon />}
                            iconPosition="start"
                            label={t('tabs.desc')}
                            {...a11yProps(1)}
                        />
                        <Tab
                            className={classes.contentText}
                            icon={<PieChartIcon />}
                            iconPosition="start"
                            label={t('tabs.allocation')}
                            {...a11yProps(0)}
                        />
                        <Tab
                            className={classes.contentText}
                            icon={<BarChartIcon />}
                            iconPosition="start"
                            label={t('tabs.returns')}
                            {...a11yProps(2)}
                        />
                        <Tab
                            className={classes.contentText}
                            icon={<HistoryIcon />}
                            iconPosition="start"
                            label={t('tabs.history')}
                            {...a11yProps(3)}
                        />
                        {relatedStrategyData.length > 0 && (
                            <Tab
                                className={classes.contentText}
                                icon={<PieChartIcon />}
                                iconPosition="start"
                                label={t('tabs.strategy')}
                                {...a11yProps(4)}
                            />
                        )}
                    </Tabs>
                    <TabPanel value={value} index={1}>
                        <Grid
                            item
                            lg={12}
                            sm={12}
                            xs={12}
                            className={classes.hoverHighlight2}
                        >
                            <AllocationStrategy
                                contractaddress={port?.contractaddress}
                                strategy={port}
                                tokensMaster={tokensv2}
                                myFundData={fundData}
                                xwinstrategies={xwinstrategies}
                            />
                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={0}>
                        {getFundDesc()}
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <ReturnsCompareChart
                            mainData={mainData}
                            fundData={fundData}
                            period={period}
                            tokensMaster={tokensv2}
                        />
                    </TabPanel>

                    {relatedStrategyData.length > 0 && (
                        <TabPanel value={value} index={4}>
                            <Grid
                                item
                                lg={12}
                                sm={12}
                                xs={12}
                                className={classes.hoverHighlight2}
                            >
                                <Strategies
                                    tokensMaster={tokensv2}
                                    relatedStrategyData={relatedStrategyData}
                                    xwinstrategies={xwinstrategies}
                                    myFundData={fundData}
                                />
                            </Grid>
                        </TabPanel>
                    )}
                    <TabPanel value={value} index={3}>
                        <Grid container spacing={2}>
                            <Grid item lg={12} sm={12} xs={12}>
                                <HistoryEvents
                                    account={address}
                                    id={id}
                                    showAll={true}
                                />
                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid>
                <Grid item lg={12} sm={12} xs={12}>
                    <VaultFaq faqs={faqs} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default withRouter(FundDetailV2)
