import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    root: {
        '&:hover': {
            boxShadow: theme.customShadows.widgetWide,
        },
    },
    expansion: {
        backgroundColor: theme.palette.background.default,
        color: 'white ',
    },
    newsHeader: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        color: '#536DFE',
    },
    subTitle: {
        color: '#ffc247',
    },
    displayFont: {
        fontSize: '1.042rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.75rem',
        },
    },
    hoverHighlight: {
        boxShadow: theme.customShadows.widgetWide,
        '&:hover': {
            boxShadow: theme.customShadows.widgetWide,
        },
    },
    avatar: {
        height: 40,
        width: 40,
    },
    columnHide: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    columnShow: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'inline',
        },
    },
    notificationItem: {
        marginTop: theme.spacing(2),
    },
    snackbarWarning: {
        backgroundColor: theme.palette.warning.main,
    },
    snackbarSuccess: {
        backgroundColor: theme.palette.success.main,
        color: 'black',
    },
    negativeNum: {
        textAlign: 'right',
        color: theme.palette.secondary.light,
        fontSize: '1.042rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.75rem',
        },
    },
    avatarSmall: {
        height: 20,
        width: 20,
        [theme.breakpoints.down('xs')]: {
            height: 15,
            width: 15,
        },
        zIndex: '3',
        borderRadius: '50px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boxAmount: {
        alignContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.5rem',
        },
    },
    boxAmount2: {
        alignContent: 'center',
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.5rem',
        },
    },
    customButton: {
        alignContent: 'center',
        fontSize: '1.042rem',
        padding: '3px 9px !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
            padding: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.5rem',
            padding: 'none',
        },
    },
    customButton2: {
        alignContent: 'center',
        fontSize: '1.042rem',
        padding: 'none',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.5rem',
            padding: 'none',
        },
    },
}))