import xWinLib from './xWinLib.js';
import appGlobalConfig from './configs/56/appGlobalConfig';
import xWinMaster from './abi/xWinMaster.json'
import xWinFundV5 from './abi/xWinFundV5.json'
import BEP20 from './abi/BEP20.json'
import xWinFarmV2 from './abi/xWinFarmV2.json'
import xWinStaking from './abi/xWinPrivateVault.json'

import React from "react";
import {
  LinearProgress,
  DialogContent,
  Dialog,
  Snackbar,
  SnackbarContent,
  Tooltip,
  Zoom,
  Typography
} from '@material-ui/core';

import { CircularProgress } from "./components/Wrappers";
import {
  Help as HelpIcon,
} from '@material-ui/icons';
import { getExplorerScan } from './utils/helper.js';


let Web3 = require('web3');

const xWinUtils = {

  getFundAllData: async function (myxWinFunds) {
    
    let myFundData = await myxWinFunds.methods.GetFundDataAll().call();
    let targetaddress = myFundData[1]
    let fundmanager = myFundData[2]
    let totalSupply = myFundData[3]
    let fundETHbalance = myFundData[4]
    let uniprice = myFundData[5]
    let fundvalue = myFundData[6]
    let fundName = myFundData[7]
    let symbol = myFundData[8]
    let managerFee = myFundData[9]
    let unipriceInUSD = myFundData[10]

    return {
      targetaddress,
      fundmanager,
      totalSupply,
      fundETHbalance,
      uniprice,
      fundvalue,
      fundName,
      symbol,
      managerFee,
      unipriceInUSD
    };
  },
  
  displayToolTip: function (msg) {

    return (
      <>
      <Tooltip
        TransitionComponent={Zoom}
        title={(
          <Typography
              color="text"
              colorBrightness={'hint'}
              variant={'caption'}
              style={{ marginLeft: 5 }}
              className={'p-2'}
          >
            <div dangerouslySetInnerHTML={{ __html: msg }} />
          </Typography>
        )}
        arrow
      >
        <HelpIcon />
      </Tooltip>
      &nbsp;
      </>
    )
  },

  getSnackBar: function (openMsg, msgStatus, responseMsg, fundData, _classname){
    
    return (
        <Snackbar 
          open={openMsg} autoHideDuration={10000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          TransitionComponent={"TransitionUp"}
          >
          <SnackbarContent
            aria-describedby="message-id2"
            className={_classname}
            //className={msgStatus=="error"? classes.snackbarWarning: classes.snackbarSuccess}
            message={
              <span id="message-id2">
                {msgStatus==="error"? responseMsg : <a href={getExplorerScan(responseMsg, "tx")} target="blank">{responseMsg}</a>}
              </span>
            }
          />
        </Snackbar>
    )
  },

  buyInPCS: function () {
		window.open("https://pancakeswap.finance/swap?outputCurrency=0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28", "_blank")
  },

  buyInBaby: function () {
		window.open("https://exchange.babyswap.finance/swap?outputCurrency=0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28", "_blank")
  },

  goAlpaca: function () {
		window.open("https://www.alpacafinance.org/", "_blank")
  },

  goCroxSwap: function () {
		window.open("https://www.croxswap.com/croxmas", "_blank")
  },

  goCoingecko: function () {
		window.open("https://www.coingecko.com/en/coins/xwin-finance", "_blank")
  },

  goCoinmarketcap: function () {
		window.open("https://coinmarketcap.com/currencies/xwin-finance/", "_blank")
  },

  goChainlink: function () {
		window.open("https://chain.link/", "_blank")
  },

  goBandProtocol: function () {
		window.open("https://bandprotocol.com/", "_blank")
  },

  goVulkania: function () {
		window.open("https://vulkania.io/coins/xwinfinance", "_blank")
  },

  goBscScan: function () {
		window.open("https://bscscan.com/token/0xd88ca08d8eec1e9e09562213ae83a7853ebb5d28", "_blank")
  },

  goP2P: function () {
		window.open("https://p2pb2b.io/trade/XWIN_USDT/", "_blank")
  },

  

  getProgress: function (loading) {
    
    if(!loading) return ""

    return (
      <Dialog
      open={loading}
      keepMounted
        >
          <DialogContent>
            <CircularProgress 
              color="primary" 
              size={50}
              thickness={4}
              style={{zIndex: "3",
              borderRadius: "50px",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "-20px",}}
            />
            <CircularProgress 
              color="secondary" 
              size={50}
              thickness={4}
              style={{zIndex: "3",
              borderRadius: "50px",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "-20px",}}
            />
            <CircularProgress 
              color="success" 
              size={50}
              thickness={4}
            />
          </DialogContent>
      </Dialog>
        )
  },

  getProgressLinear: function (loading) {
    
    if(!loading) return ""
    return (
          <LinearProgress 
            color="primary" 
            size={50}
            thickness={4}
          />
        )
  },

  getExchangeRate: async function (globalWeb3, from, to) {
    
    if(to === "WBNB") {
      to = "BNB"
    }
    let xWinMasterProtocol = new globalWeb3.eth.Contract(xWinMaster, appGlobalConfig.mainnetxWINMaster);
    let rate = await xWinMasterProtocol.methods.getPriceFromBand(from, to).call()
    rate = this.convertFromWei(globalWeb3, rate)
    return rate
  },

  getExchangeRateByAllAddress: async function (globalWeb3, from, to) {
    
    let xWinMasterProtocol = new globalWeb3.eth.Contract(xWinMaster, appGlobalConfig.mainnetxWINMaster);
    let rate = await xWinMasterProtocol.methods.getPriceByAllAddress(from, to).call()
    rate = this.convertFromWei(globalWeb3, rate)
    return rate
  },

  fetchJPYUSDRate:  async function() {
    
    return fetch(appGlobalConfig.apiGetJPYUSD)
        .then((response) => {
          if (response.ok) {
            return response.json().then(async data => {
              return parseFloat(data);
            });
          }
        })
        .catch(/* similar stuff here */);
  },


  getAllPrices: async function (globalWeb3) {
    
    let xWinMasterProtocol = new globalWeb3.eth.Contract(xWinMaster, appGlobalConfig.mainnetxWINMaster);
    let unipriceInBNB = await xWinMasterProtocol.methods.getPriceByAddress(appGlobalConfig.mainnetXWIN, "BNB").call()
    let BNBUSDT = await xWinMasterProtocol.methods.getPriceFromBand("BNB", "USDT").call()
    let JPYUSD = await this.fetchJPYUSDRate();

    let xwinPriceInUSD = parseFloat(this.convertFromWei(globalWeb3, unipriceInBNB.toString())) * parseFloat(this.convertFromWei(globalWeb3, BNBUSDT.toString()))
    let pcsLPPrice = await this.getLPCakePrice(globalWeb3, xwinPriceInUSD)
    let babyLPPrice = await this.getLPBabyPrice(globalWeb3, xwinPriceInUSD)
    return {
      xwinPriceInUSD,
      BNBUSDT,
      unipriceInBNB,
      pcsLPPrice,
      JPYUSD,
      babyLPPrice
    }
  },

  getXWINPrice: async function (version, globalWeb3) {
    
    let xWinMasterProtocol = this.getxWinMasterProtocol("", version, globalWeb3)
    let unipriceInBNB = await xWinMasterProtocol.methods.getPriceByAddress(appGlobalConfig.mainnetXWIN, "BNB").call()
    let BNBUSDT = await xWinMasterProtocol.methods.getPriceFromBand("BNB", "USDT").call()
    let JPYUSD = await this.fetchJPYUSDRate();
    let xwinPriceInUSD = this.convertFromWei(globalWeb3, unipriceInBNB.toString()) * this.convertFromWei(globalWeb3, BNBUSDT.toString())
    return {
      xwinPriceInUSD,
      BNBUSDT,
      unipriceInBNB,
      JPYUSD
    }
  },

  getLPCakePrice: async function (globalWeb3, xwinPriceInUSD) {
    
    let BEP20Protocol = new globalWeb3.eth.Contract(BEP20, appGlobalConfig.mainnetXWINBNBLPPoolV2);
    let xWINBEP20 = new globalWeb3.eth.Contract(BEP20, appGlobalConfig.mainnetXWIN);
    let xWINBalance = await xWINBEP20.methods.balanceOf(appGlobalConfig.mainnetXWINBNBLPPoolV2).call()
    let xWINBalanceInUSD = xWINBalance * xwinPriceInUSD
    let totalSupply = await BEP20Protocol.methods.totalSupply().call()
    let lpPrice = xWINBalanceInUSD * 2 / totalSupply
    return lpPrice

  },

  getLPBabyPrice: async function (globalWeb3, xwinPriceInUSD) {
    
    let BEP20Protocol = new globalWeb3.eth.Contract(BEP20, appGlobalConfig.mainnetXWINUSDTLPPool);
    let xWINBEP20 = new globalWeb3.eth.Contract(BEP20, appGlobalConfig.mainnetXWIN);
    let xWINBalance = await xWINBEP20.methods.balanceOf(appGlobalConfig.mainnetXWINUSDTLPPool).call()
    let xWINBalanceInUSD = xWINBalance * xwinPriceInUSD
    let totalSupply = await BEP20Protocol.methods.totalSupply().call()
    let lpPrice = xWINBalanceInUSD * 2 / totalSupply
    return lpPrice

  },

  _getTVLPrivate: async function (theWeb3, port){
    
    let sourceTokenInUSD = await this.getExchangeRateByAllAddress(theWeb3, port.sourceaddress, "0x55d398326f99059fF775485246999027B3197955")
    const myxWinStake = new theWeb3.eth.Contract(xWinStaking, port.contractaddress); 
    let totalSupply = await myxWinStake.methods.totalSupply().call() 
    let tVL = this.convertFromWei(globalWeb3, totalSupply.toString())
    return parseFloat(sourceTokenInUSD) * parseFloat(tVL) 
  },

  getAgreeTermCondition: function () {
    
    let xwintnc = localStorage.getItem("xwinv2-tnc")
    return xwintnc === "yes"
  },
  
  getAgreeTermConditionByPortfolio: function (_symbol) {
    
    let keyItem = "xwinv2-tnc" + _symbol
    let xwintnc = localStorage.getItem(keyItem)
    return xwintnc === "yes"
  },

  calcReturns: function (partialRate, blockDiff) {

    let n = blockDiff < 28800 ? 1 : 28800 / parseFloat(blockDiff) 
    let dailyRate = partialRate * n
    
    return {
        annualCompound: xWinLib.roundTo(dailyRate * 365 * 100, 2),
        dailyRate: xWinLib.roundTo(dailyRate * 100, 2)
    };
  },

  // getReferral: async function (xWinProtocol, selectedWallet) {
  //   return "0x0000000000000000000000000000000000000000"
  // },

  getAddressShort: function (selectedWallet) {

    if(selectedWallet === undefined) return ""
    let right5 = selectedWallet.substring(0, 4);
    let left5 = selectedWallet.substring(selectedWallet.length-3, selectedWallet.length);
    let combine = right5 + "...." + left5
    return combine
  },

  // getWalletAccount: async function (globalWeb3) {
  //   const accounts = await globalWeb3.eth.getAccounts()
  //   return accounts[0]
  // },

  displayBalance: function (loading, mybal) {

    if(loading) return ""
    return mybal 
  },

  getCountDown: function (toDateInUnix) {

    const minuteSeconds = 60;
    const hourSeconds = 3600;
    const daySeconds = 86400;

    const diff = toDateInUnix - (Date.now() / 1000)
    const stratTime = Date.now()  / 1000
    const endTime = stratTime + diff
    const remainingTime = endTime - stratTime
    const days = Math.ceil(remainingTime / daySeconds)
    const daysDuration = days * daySeconds

    const countdown = {
      "diff": diff,
      "stratTime": stratTime,
      "endTime" : stratTime + diff,
      "remainingTime": endTime - stratTime,
      "days" : Math.ceil(remainingTime / daySeconds),
      "daysDuration" :  days * daySeconds
    }
    return countdown
  },

  getImages: function (picture) {
    return <img alt={picture.toLowerCase()} src={"/images/charities/"+ picture.toLowerCase()} height="100" width={"100%"}/>
  },

  getIcons: function (symbol, noFileExt = true) {
    
    if(symbol === undefined || symbol === "") return ""
    let src = "https://download.xwin.finance/images/"+ symbol?.toLowerCase()
    if(noFileExt){
      src = src + ".png"
    }
    return <img height="32px" width="32px" alt={symbol?.toLowerCase()} src={src} />
  },

  getSupportedSymbols: function (tokensMaster, networkName, selectedport) {
    
    if(tokensMaster === undefined) return
    const symbols = []
    tokensMaster.forEach(token => {
      if(token.active){
        symbols.push({
          symbol: token.name
        })  
      }
    });
    return symbols
  },

  // getSupportedSymbols: function (tokensMaster, networkName, selectedport) {
    
  //   if(tokensMaster === undefined) return
  //   const symbols = []
  //   tokensMaster.forEach(token => {
  //     if(token.env.toLowerCase() === appGlobalConfig.env.toLowerCase()){
  //       if(token?.fundversion === selectedport?.fundversion){
  //         symbols.push({
  //           symbol: token.name
  //         })
  //       }else if(token?.fundversion === undefined){
  //         symbols.push({
  //           symbol: token.name
  //         })
  //       }
  //     }
  //   });
  //   return symbols
  // },

  getAddress: function (ticker, tokensMaster, networkName) {
    if(ticker === undefined) return ""
    let mainAddress = ""
    if(tokensMaster != null){
      let selected = tokensMaster.find(t => t.name === ticker.symbol && t.env === networkName.toString().toLowerCase())
      if(selected != null){
        mainAddress = selected.address
      } 
    }
    return {mainAddress}
  },

  getxWinMasterProtocol: function (networkName, version, theWeb3) {
    
    let xWinMasterProtocol
    if(networkName.toLowerCase() === "bsctest"){
      xWinMasterProtocol = new theWeb3.eth.Contract(xWinMaster, "0x8d3a15D3274C507ba4F309fAC42c0517B9825636");
    }else{
      xWinMasterProtocol = new theWeb3.eth.Contract(xWinMaster, "0x3e3EA5dA8eD28be5cdb9D5E95ad74B9A6cbc2196");
    }
    return xWinMasterProtocol
  },

  getMyPorts: function (filterport, wallet, fundprotocol, theWeb3) {
    
      let myPorts = []
      filterport.forEach(async port => {
        const myxWinFunds = new theWeb3.eth.Contract(fundprotocol, port.contractaddress);
        let userfundbal = await myxWinFunds.methods.balanceOf(wallet).call();
        if(parseFloat(userfundbal) > 0){
          myPorts.push(port)
        }
      });
      return myPorts
  },

  // getMyFarmPorts: function (filterport, wallet, fundprotocol, theWeb3) {
    
  //   let myPorts = []
  //   filterport.forEach(async port => {
  //     let xWinProtocol = new theWeb3.eth.Contract(fundprotocol, port.protocoladdress);
  //     let userInfo = await xWinProtocol.methods.userInfo(port.pid, wallet).call()
  //     if(parseFloat(userInfo?.amount) > 0){
  //       myPorts.push(port)
  //     }
  //   });
  //   return myPorts
  // },

  getIsApproved: async function (globalWeb3, subsAmount, wallet, port) {
    
    if(subsAmount === undefined) return false;
    if(parseFloat(subsAmount) === NaN) return false;
    if(parseFloat(subsAmount) === 0) return false;
    //if(isNaN(subsAmount)) return false;
    
    const subsAmtInWei = subsAmount * 10 **18 // globalWeb3.utils.toWei(subsAmount.toString())
    const sourceToken = new globalWeb3.eth.Contract(xWinFundV5, port.contractaddress);
    let contractAllowance = await sourceToken.methods
    .allowance(wallet, port.protocoladdress)
    .call();
    
    return parseFloat(contractAllowance) > parseFloat(subsAmtInWei)  

  },

  // getUserWalletInfoNew: async function (theWeb3, account) {
   
  //   // let selectedWallet = await this.getWalletAccount(globalWeb3)
  //   if(account !== "---"){
  //     let balance = await theWeb3.eth.getBalance(account)
  //     return {
  //       "walletETHBalance" :  this.convertFromWei(theWeb3, balance.toString()), 
  //       "selectedWallet" : account,
  //     }
  //   }else{
  //     return {
  //       "walletETHBalance" :  0,
  //       "selectedWallet" : "---",
  //     }
  //   }
  // },

  // getUserWalletInfo: async function (globalWeb3) {
   
  //   let selectedWallet = await this.getWalletAccount(globalWeb3)
  //   if(selectedWallet !== undefined){
  //     let balance = await globalWeb3.eth.getBalance(selectedWallet)
  //     return {
  //       "walletETHBalance" :  this.convertFromWei(globalWeb3, balance.toString()), 
  //       "selectedWallet" : selectedWallet,
  //     }
  //   }else{
  //     return {
  //       "walletETHBalance" :  0,
  //       "selectedWallet" : "",
  //     }
  //   }
  // },

  // getUserFundHolding: async function (globalWeb3, selectedWallet, contractaddress) {
   
  //   let myxWinFunds = new globalWeb3.eth.Contract(xWinFundV5, contractaddress);
  //   let userfundbal = "0"
  //   if(selectedWallet !== "---"){
  //     userfundbal = await myxWinFunds.methods.balanceOf(selectedWallet).call();
  //     userfundbal = this.convertFromWei(globalWeb3, userfundbal.toString())
  //   }
  //   return {
  //     "userfundbal" : userfundbal, 
  //     "xWinFund" : myxWinFunds,
  //   }
  // },

  // getUserFundFarmHolding: async function (globalWeb3, selectedWallet, contractaddress) {
   
  //   let myxWinFunds = new globalWeb3.eth.Contract(xWinFarmV2, contractaddress);
    
  //   let userfundbal = "0"
  //   if(selectedWallet !== "---"){
  //     userfundbal = await myxWinFunds.methods.balanceOf(selectedWallet).call();
  //     userfundbal = this.convertFromWei(globalWeb3, userfundbal.toString())
  //   }
    
  //   let owner = await myxWinFunds.methods.owner().call();
    
  //   let mylpBalance = 0
  //   let lpInfo 
  //   if(selectedWallet !== "---"){
  //     mylpBalance = await myxWinFunds.methods.getMyLPBalance().call({from: selectedWallet});
  //   }
  //   lpInfo = await myxWinFunds.methods.readUserInfo().call();

  //   let pendingCakeBal = await myxWinFunds.methods.getPendingCake().call();
  //   pendingCakeBal = this.convertFromWei(globalWeb3, pendingCakeBal.toString())
  //   let cakeBEP20 = new globalWeb3.eth.Contract(BEP20, "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82");
  //   let cakeBalance = await cakeBEP20.methods.balanceOf(contractaddress).call()
  //   cakeBalance = this.convertFromWei(globalWeb3, cakeBalance.toString())
  //   let totalpendingCakeBal = parseFloat(pendingCakeBal) + parseFloat(cakeBalance)
    
  //   return {
  //     "userfundbal" : userfundbal, 
  //     "xWinFund" : myxWinFunds,
  //     "mylpBalance": this.convertFromWei(globalWeb3, mylpBalance.toString()), 
  //     "lpBalance" : lpInfo === undefined ? 0 : this.convertFromWei(globalWeb3, lpInfo?.amount.toString()),
  //     "pendingCakeBal": totalpendingCakeBal,
  //     "owner" : owner
  //   }
  // },

  shouldDisable: function (fundtype) {

    if(fundtype==="liquidity" || fundtype==="liquidity2" || fundtype==="index" || fundtype==="trading") return true
    return false
  },

  isValidFundType: function (port)  {

    return (port.fundtype === "index" 
            || port.fundtype === "indexv2" 
            || port.fundtype === "liquidity" 
            || port.fundtype === "liquidity2" 
            || port.fundtype === "liquidityv2" 
            || port.fundtype === "trading"
            || port.fundtype === "tradingv2"
    )
  },

  convertFromWei: function (globalWeb3, value) {
    
    if(value === undefined) return "0"
    if(value === null) return "0"
    return value == "0"? "0" : globalWeb3.utils.fromWei(value)
  }

}

export default xWinUtils;
