import React, { useState, useEffect } from 'react'
import { Grid, Container, Box, Typography } from '@material-ui/core'
import UnrealizedPnL from './components/UnrealizedPnLV3'
import { GetUserPnL } from '../../utils/firestoreDataInteractor';
import xWinUtils from '../../xWinUtils';
import { useTranslation } from 'react-i18next'

export default function vaultPerformance(props) {
    const { account, portfolios, xwinstrategies, tokensMaster, appGlobalConfig } = props;
    const [profitLosses, setProfitLosses] = useState([])
    const [loading, setLoading] = React.useState(false)
    const { t, i18n } = useTranslation('myMenu')
    
    useEffect(() => {
        init()
    }, [account])

    const init = async () => {
        
        setLoading(true)
        const pnl = await GetUserPnL(account, appGlobalConfig)
        setProfitLosses(pnl.message || [])
        setLoading(false)
    }

    
    const displayPortfolios = () => {
        if (profitLosses?.length === 0) {
            return (
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={'center'}
                >
                    <img
                        style={{ maxWidth: '250px' }}
                        alt={'no data'}
                        src={'/images/background/nodata.png'}
                    />
                </Box>
            )
        }

        return profitLosses.map(port => (
            <UnrealizedPnL appGlobalConfig={appGlobalConfig} port={port} tokensMaster={tokensMaster} portfolios={portfolios} xwinstrategies={xwinstrategies} />
        ))
    }

    return (
        <Container>
            {xWinUtils.getProgressLinear(loading)}
            <Grid container spacing={3}>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    {displayPortfolios()}
                </Grid>
                <Grid item lg={12} sm={12} xl={12} xs={12}>
                    <Typography
                        color="text"
                        colorBrightness={'hint'}
                        variant={'caption'}
                        style={{ marginRight: 5 }}
                        //noWrap
                    >
                        {t('unrealizedPnL.disclaimer')}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
}
