import React, { useState, useEffect, useContext } from 'react'
import xWinLib from '../../xWinLib'
import { AppContext } from '../../context/AppProvider'
import { Grid, Button, Container } from '@material-ui/core'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import ApexLineChartTVL from './components/ApexLineChartTVL'
import ApexPieChartVault from './components/ApexPieChart'
// styles
import useStyles from './styles'
// components
import Widget from '../../components/Widget'
import { Typography } from '../../components/Wrappers'
import { useTheme } from '@material-ui/styles'
import {
    GetAllTvl,
    GetVaultInfo,
    GetHistoryTVL,
    GetTotalVault,
    GetRecommendedVaults,
} from '../../utils/firestoreDataInteractor'
import { useTranslation } from 'react-i18next'
import TotalTvl from './components/TotalTvl'
import Vault from './components/Vault'
import { useWeb3Modal } from '@web3modal/react'
import { useAccount } from 'wagmi'
import { Link as RouterLink } from 'react-router-dom'
import { Link } from '../../components/Wrappers/Wrappers'
import CreatePortV2 from '../createPortV2/CreatePortV2'

const MainMenu = props => {
    const { portfolios, tokensMaster } = props
    const { appGlobalConfig, tokensv2 } = useContext(AppContext)
    const { address, isConnected } = useAccount()
    const { open } = useWeb3Modal()

    var theme = useTheme()
    var classes = useStyles()

    const [recommendVaultAsset, setRecommendedVaultAsset] = React.useState([])
    const [recommendVaultWeight, setRecommendedVaultWeight] = React.useState([])
    const [allocatedports, setAllocatedports] = useState([])
    const [mainDataHistory, setMainDataHistory] = React.useState([])
    const [totalVault, setTotalVault] = React.useState(0)
    const [totalValueLock, setTotalValueLock] = React.useState(0)
    const [totalValueLockV2, setTotalValueLockV2] = React.useState(0)
    const [totalValueLockArb, setTotalValueLockArb] = React.useState(0)
    const [totalValueLockPolygon, setTotalValueLockPolygon] = React.useState(0)
    const [returnChoice, setReturnChoice] = React.useState(4)
    const [period, setPeriod] = React.useState(366)
    const { t, i18n } = useTranslation('mainMenu')
    const [rowsData, setRowsData] = React.useState([])
    const [openModal, setOpenModal] = useState(false)
    const [port, setPort] = useState([])
    const [returns, setReturns] = useState([])

    useEffect(() => {
        fetchHistoryTVL()
        fetchAllTvl()
        fetchTotalVault()
        handlePrebuiltVault()
    }, [portfolios])

    useEffect(() => {
        fetchVaultInfo()
    }, [appGlobalConfig])

    const fetchAllTvl = async () => {
        const response = await GetAllTvl(appGlobalConfig)
        setTotalValueLock(response?.Total)
        setTotalValueLockV2(response?.BNB)
        setTotalValueLockPolygon(response?.Polygon)
        setTotalValueLockArb(response?.ARB)
    }

    const fetchTotalVault = async () => {
        const response = await GetTotalVault(appGlobalConfig)
        setTotalVault(response?.TotalVault)
    }

    const fetchHistoryTVL = async () => {
        const promiseData = await Promise.all([GetHistoryTVL(appGlobalConfig)])
        let data = promiseData[0]
        setMainDataHistory(data)
    }

    const handleClickPeriod = (event, choice) => {
        let duration = 366
        if (choice === 2) {
            duration = 8
        } else if (choice === 3) {
            duration = 31
        } else if (choice === 4) {
            duration = 366
        }
        setReturnChoice(choice)
        setPeriod(duration)
    }

    const fetchVaultInfo = async () => {
        try {
            const promiseData = await Promise.all([
                GetVaultInfo(appGlobalConfig),
            ])
            let priceData = promiseData[0]
            loopVaultData(priceData)
        } catch (error) {}
    }

    const loopVaultData = async datas => {
        let allData = []
        if (datas) {
            datas.map(data => {
                const newData = {
                    id: data.ContractAddress,
                    fundName: data.FundName,
                    unitPrice: data.UnitPrice,
                    return: data.PerformanceReturn * 100,
                    fundSymbol: data.FundSymbol,
                    tvl: data.TotalValueLocked,
                    contractaddress: data.ContractAddress,
                }
                allData.push(newData)
            })
        }

        setRowsData(allData.slice(0, 4))
    }
    const selectChainTVL = () => {
        if (appGlobalConfig?.chainId === 56) {
            return totalValueLockV2
        } else if (appGlobalConfig?.chainId === 42161) {
            return totalValueLockArb
        } else {
            return totalValueLockPolygon
        }
    }

    const selectChainName = () => {
        if (appGlobalConfig?.chainId === 56) {
            return 'BNB'
        } else if (appGlobalConfig?.chainId === 42161) {
            return 'Arbitrum'
        } else {
            return 'Polygon'
        }
    }

    const handlePrebuiltVault = async () => {
        setPort({ template: 'recommended' })
        const promiseData = await Promise.all([
            GetRecommendedVaults(appGlobalConfig),
        ])
        let data = promiseData[0]
        const tokenNameArray = []
        const allocatedtemp = []
        if (data) {
            const newAsset = data?.Asset
            const newWeight = data?.Weight
            for (let i = 0; i < newAsset.length; i++) {
                const newObj = {
                    contractaddress: newAsset[i],
                    weight: newWeight[i],
                }

                let tkName = xWinLib.getTokenName(
                    tokensv2,
                    newObj.contractaddress
                )
                tokenNameArray.push(tkName)
                allocatedtemp.push({
                    name: tkName,
                    ratio: Number(newObj.weight * 100).toFixed(2),
                    contractaddress: newObj.contractaddress,
                })
            }
            setRecommendedVaultAsset(tokenNameArray)
            setRecommendedVaultWeight(data?.Weight)
            setAllocatedports(allocatedtemp)
        }
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item lg={12} sm={12} xs={12}>
                    <TotalTvl
                        TotalTVL={totalValueLock}
                        ChainTVL={selectChainTVL()}
                        ChainName={selectChainName()}
                        TotalVault={totalVault}
                    />
                </Grid>
                <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.investButton}
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            textDecoration: 'none', // Remove underline on hover
                            color: 'white', // Set the color for the button text on hover
                        },
                    }}
                >
                    {!isConnected ? (
                        <Button
                            onClick={() => open()}
                            color="primary"
                            variant="contained"
                        >
                            {`${t('quickInvest')}`}
                        </Button>
                    ) : (
                        <Button color="primary" variant="contained">
                            <Link
                                component={RouterLink}
                                to={{
                                    pathname:
                                        (appGlobalConfig?.chainId === 137
                                            ? '/app/strategydetailv2/'
                                            : '/app/funddetailV2/') +
                                        appGlobalConfig?.defaultVault,
                                    state: { quickInvestClicked: true },
                                }}
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                }}
                            >
                                {`${t('quickInvest')}`}
                            </Link>
                        </Button>
                    )}
                </Grid>

                {rowsData?.map(data => (
                    <Grid
                        item
                        lg={3}
                        md={6}
                        sm={6}
                        xs={12}
                    >
                        <Link
                            underline="none"
                            component={RouterLink}
                            to={{
                                pathname: `/app/strategydetailv2/${data.contractaddress}/chainId=${appGlobalConfig?.chainId}`,
                            }}
                        >
                            <Vault props={data} />
                        </Link>
                    </Grid>
                ))}
                <Grid
                    item
                    lg={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight1}
                >
                    <Widget
                        bodyClass={classes.mainChartBody}
                        header={
                            <div className={classes.mainChartHeader}>
                                <Typography
                                    variant="h6"
                                    color="text"
                                    weight={'medium'}
                                    colorBrightness="secondary"
                                >
                                    {`${t('TVL')}`}
                                </Typography>
                                <ToggleButtonGroup
                                    color={'primary'}
                                    size="small"
                                    value={returnChoice}
                                    exclusive
                                    onChange={handleClickPeriod}
                                >
                                    <ToggleButton
                                        value={2}
                                        style={{
                                            outlineColor:
                                                theme.palette.primary.main,
                                            outlineWidth: '1px',
                                            outlineStyle: 'solid',
                                            margin: '2px',
                                        }}
                                    >
                                        <Typography
                                            className={classes.displayFont}
                                        >
                                            1W
                                        </Typography>
                                    </ToggleButton>
                                    <ToggleButton
                                        value={3}
                                        style={{
                                            outlineColor:
                                                theme.palette.primary.main,
                                            outlineWidth: '1px',
                                            outlineStyle: 'solid',
                                            margin: '2px',
                                        }}
                                    >
                                        <Typography
                                            className={classes.displayFont}
                                        >
                                            1M
                                        </Typography>
                                    </ToggleButton>
                                    <ToggleButton
                                        value={4}
                                        style={{
                                            outlineColor:
                                                theme.palette.primary.main,
                                            outlineWidth: '1px',
                                            outlineStyle: 'solid',
                                            margin: '2px',
                                        }}
                                    >
                                        <Typography
                                            className={classes.displayFont}
                                        >
                                            1Y
                                        </Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>

                                <div className={classes.mainChartHeaderLabels}>
                                    <div
                                        className={classes.mainChartHeaderLabel}
                                    ></div>
                                </div>
                            </div>
                        }
                    >
                        <ApexLineChartTVL
                            appGlobalConfig={appGlobalConfig}
                            mainData={mainDataHistory}
                            period={period}
                        />
                    </Widget>
                </Grid>
                <Grid
                    item
                    lg={6}
                    ms={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight1}
                >
                    <Widget
                        bodyClass={classes.mainChartBody}
                        title={`${t('recommendTitle')}`}
                        disableWidgetMenu
                    >
                        <Typography
                            style={{
                                padding: 20,
                            }}
                        >
                            {`${t('roboadvisor')}`}
                        </Typography>
                        <div className={classes.recomendDiv}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={
                                    !isConnected
                                        ? () => open()
                                        : () => setOpenModal(true)
                                }
                                style={{ width: '100%' }}
                            >
                                {`${t('recommendForYou')}`}
                            </Button>

                            <Link
                                underline="none"
                                component={RouterLink}
                                to={{
                                    pathname: '/app/allocation',
                                }}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginTop: 20,
                                }}
                            >
                                <Button color="primary" variant="contained">
                                    {`${t('customizeVault')}`}
                                </Button>
                            </Link>
                        </div>
                    </Widget>
                </Grid>
                <Grid
                    item
                    lg={6}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight1}
                >
                    <Widget
                        bodyClass={classes.mainChartBody}
                        title={`${t('recommendVault')}`}
                        disableWidgetMenu
                    >
                        <ApexPieChartVault
                            tokenNames={recommendVaultAsset}
                            tokenWeight={recommendVaultWeight}
                        />
                    </Widget>
                </Grid>
                <CreatePortV2
                    tokensMaster={tokensv2}
                    openModal={openModal}
                    selectedport={port}
                    selectedWallet={address}
                    reset={() => {
                        setOpenModal(false)
                    }}
                    allocatedports={allocatedports}
                    returns={returns}
                />
            </Grid>
        </Container>
    )
}

export default MainMenu
