import { ethers } from "ethers";
import xWinFund from "../abi/v2/fundV2.json";
import xWinStrategy from "../abi/v2/xWinStrategies.json";
import { getGasPrice, convertToWei, convertFromWei } from "../utils/helper";
import xWinLib from '../xWinLib';
import { getDecimal } from "./erc20Interactor";


export async function getUnitPriceInUSD(web3, contractaddress) {
	const contract = new web3.eth.Contract(xWinFund, contractaddress);
	
  const promiseData = await Promise.all([
    contract.methods.getUnitPriceInUSD().call(),
  ]);
  let unitPriceInUSD = promiseData[0]
  return {
    "unitPriceInUSD": convertFromWei(unitPriceInUSD || 0, 18)
  };
}

export async function getFundExtraInfo(web3, contractaddress, targetaddress) {
	const contract = new web3.eth.Contract(xWinFund, contractaddress);
	
  const promiseData = await Promise.all([
    contract.methods.TargetWeight(targetaddress).call(),
    contract.methods.getTokenValues(targetaddress).call(),
    contract.methods.getBalance(targetaddress).call(),
    contract.methods.decimals().call()
  ]);
  let targetweight = promiseData[0]
  let fundTokenValue = promiseData[1]
  let AllBal = promiseData[2]
  let decimals = promiseData[3]
  
  return {
    "targetweight": targetweight,
    "fundTokenValue": convertFromWei(fundTokenValue || 0, decimals),
    "AllBal": convertFromWei(AllBal || 0, decimals),
  };
}

export async function getUnderlyingFee(web3, contractaddress) {
	
	const contract = new web3.eth.Contract(xWinStrategy, contractaddress);
	let performanceFee = await contract.methods.performanceFee().call();
	let strategyFee = await contract.methods.managerFee().call();
	return {
    "performanceFee": performanceFee || 0,
    "strategyFee": strategyFee || 0
  };
}

export async function getPerformanceData(web3, contractaddress, account, amount, theSigner) {
	
  const contractE = new ethers.Contract(
    contractaddress,
    xWinFund,
    theSigner
  )
  // let subsAmtInWei = amount
  const outAmtInWei = convertToWei(amount);
  
  let estimateReceived = await contractE.callStatic.withdraw(outAmtInWei);
  const contract = new web3.eth.Contract(xWinFund, contractaddress);
	let performanceMap = await contract.methods.performanceMap(account).call();
	return {
    "shares": convertFromWei(performanceMap.shares || 0),
    "avgPrice": convertFromWei(performanceMap.avgPrice || 0),
    "estimateReceived" : convertFromWei(estimateReceived || 0),
  };
}


export async function getMyPorts(web3, portfolios, account) {
  
  let myPorts = []
  
  const promises = portfolios.map(async port => {
    
    const data = await getPerformanceData(web3, port.contractaddress, account);
    if(parseFloat(data.shares) > 0){
      const funddata = await GetFundDataAll(web3, port.contractaddress);
      return {
        ...port,
        ...funddata,
        "shares" : data.shares,
        "avgPrice" : data.avgPrice
      }
    }
  });
  let ports = await Promise.all(promises);
  ports.forEach(p => {
    if(p !== undefined){
      myPorts.push(p);
    }
  });
  return myPorts;

}

export async function getEstimateShares(web3, contractaddress, subsAmtInWei, theSigner, slippage) {
  
  try {
    const contract = new ethers.Contract(
      contractaddress,
      xWinFund,
      theSigner
    )
    let estimateShares = 0;
    estimateShares = await contract.callStatic["deposit(uint256,uint32)"](subsAmtInWei, xWinLib.roundDown(slippage*100,0));
    return convertFromWei(estimateShares);
    
  } catch (error) {
    console.log(error)
    return 0
  }
}

export async function getEstimateGasDeposit(contractaddress, subsAmtInWei, theSigner, slippage) {
  
  try {
    const contract = new ethers.Contract(
      contractaddress,
      xWinFund,
      theSigner
    )
    
    let estimation = await contract.estimateGas["deposit(uint256,uint32)"](subsAmtInWei, Number(slippage)*100);
    estimation = xWinLib.roundTo(Number(estimation) * 1.2, 0) 
    return{
      status: true,
      estimation: estimation,
      message : "success"
    }
    
  } catch (error) {
    console.log(error)
    return {
      status: false,
      estimation: 0,
      message : "Gas estimation failed"
    }
  }
}

export async function getEstimateSharesWithdraw(contractaddress, amount, theSigner, basedecimal, slippage) {
  
  try {
    const subsAmtInWei = convertToWei(amount);
    const contract = new ethers.Contract(
      contractaddress,
      xWinFund,
      theSigner
    )
    let estimateShares = await contract.callStatic["withdraw(uint256,uint32)"](subsAmtInWei, slippage*100);    
    return convertFromWei(estimateShares, basedecimal);
  } catch (error) {
    console.log(error.message)
    return 0
  }
}

export async function getEstimateGasWithdraw(contractaddress, amount, theSigner, slippage) {
  
  try {
    const amtRedeemInWei = convertToWei(amount);
    const contract = new ethers.Contract(
      contractaddress,
      xWinFund,
      theSigner
    )
    let estimation = await contract.estimateGas["withdraw(uint256,uint32)"](amtRedeemInWei, Number(slippage)*100);
    estimation = xWinLib.roundTo(Number(estimation) * 1.2, 0) 
    return{
      status: true,
      estimation: estimation,
      message : "success"
    }
  } catch (error) {
    console.log(error)
    return {
      status: false,
      estimation: 0,
      message : "Gas estimation failed"
    }
  }
}

export async function GetFundDataAll(web3, contractaddress) {
  const contract = new web3.eth.Contract(xWinFund, contractaddress);
  try {
    const promiseData = await Promise.all([
      contract.methods.GetFundDataAll().call(),
      contract.methods.GetFundExtra().call(),
      contract.methods.decimals().call(),
      contract.methods.openForPublic().call(),
      contract.methods.stablecoinUSDAddr().call(),
    ]);
    let myFundData = promiseData[0]
    let feesData = promiseData[1]
    let decimals = promiseData[2]
    let openForPublic = promiseData[3]
    let stablecoinUSDAddr = promiseData[4]

    let targetaddress = myFundData.targetAddress; //_targetNamesAddress
    let fundmanager = feesData.mRebAddr; //_managerAddr
    let totalSupply = convertFromWei(myFundData.totalUnitB4, decimals); //totalUnitB4
    let fundETHbalance = convertFromWei(myFundData.baseBalance, decimals); //baseBalance
    let uniprice = convertFromWei(myFundData.unitprice, decimals); //unitprice
    let fundvalue = convertFromWei(myFundData.fundvalue, decimals); //fundvalue
    let fundvalueUSD = convertFromWei(myFundData.fundvalueUSD, decimals); //fundvalueUSD
    let fundName = myFundData.fundName; //fundName
    let symbol = myFundData.symbolName; //symbolName
    let unipriceInUSD = convertFromWei(myFundData.unitpriceUSD, decimals);
    let baseToken = myFundData.baseCcy === undefined ? myFundData.baseToken: myFundData.baseCcy; //baseToken 
    let managerFee = feesData.managementFee; //managementFee
    let performanceFee = feesData.performanceFee; // perfomanceFee
    let platformFee = feesData.platFee; //platformFee
    
    return {
      targetaddress,
      fundmanager,
      totalSupply,
      fundETHbalance,
      uniprice,
      fundvalue,
      fundvalueUSD,
      fundName,
      symbol,
      managerFee,
      performanceFee,
      platformFee,
      unipriceInUSD,
      baseToken,
      openForPublic,
      stablecoinUSDAddr
    };
  } catch (error) {
    console.log(contractaddress, "contractaddress")
    console.log(error)
  }
  
}

export async function validInvestor(web3, xfundaddress, account){

  const contract = new web3.eth.Contract(xWinFund, xfundaddress);
  const promiseData = await Promise.all([
    contract.methods.validInvestors(account).call(),
    contract.methods.openForPublic().call(),
  ]);
  let isValid = promiseData[0]
  let openForPublic = promiseData[1]
  if(openForPublic) return true;
  if(!openForPublic && isValid) return true;
  return false;
}

export async function SubscribeDirectAsync(signer, xfundaddress, account, subsAmt, decimal, slippage, estimation) {

  const contract = new ethers.Contract(xfundaddress, xWinFund, signer);
  const gasParam = getGasPrice(signer);
  const subsAmtInWei = convertToWei(subsAmt, decimal);
  return new Promise((resolve, reject) => {    
      contract["deposit(uint256,uint32)"](subsAmtInWei, Number(slippage)*100, {
        from: account,
        value: 0,
        gasLimit: estimation,
        ...gasParam
      })
      .then(txn => {
        txn.wait().then(receipt => {
          // console.log(receipt);
          resolve(receipt.transactionHash);
        })
      })
      .catch((err) => {
        console.log('error: %j', err.message);
        reject(err.code);
      });
  });
}

export async function RedeemAsync(signer, xfundaddress, account, amtRedeem, slippage, estimation) {
  
  const contract = new ethers.Contract(xfundaddress, xWinFund, signer);
  const gasParam = getGasPrice(signer);
  const amtRedeemInWei = convertToWei(amtRedeem.toString());
  
  return new Promise((resolve, reject) => {    
      contract["withdraw(uint256,uint32)"](amtRedeemInWei, Number(slippage)*100, {
        from: account,
        value: 0,
        gasLimit: estimation,
        ...gasParam
      })
      .then(txn => {
        txn.wait().then(receipt => {
          console.log(receipt);
          resolve(receipt.transactionHash);
        })
      })
      .catch(err => {
        console.log('error: %j', err);
        reject(err.code);
      })
  });
}

export async function RebalanceAsync(signer, xfundaddress, account, positions, slippage, estimation) {
  
  const destAddress = [];
  const targetWgts = [];

  positions.forEach((p) => {
    destAddress.push(p.taddress);
    targetWgts.push(xWinLib.roundTo(p.weight * 100, 0));
  });

  const contract = new ethers.Contract(xfundaddress, xWinFund, signer);
  const gasParam = getGasPrice(signer);
  
  return new Promise((resolve, reject) => {
    contract["Rebalance(address[],uint256[],uint32)"](destAddress, targetWgts, Number(slippage)*100, {
      from: account,
      value: 0,
      gasLimit: estimation,
      ...gasParam
    })
    .then(txn => {
      txn.wait().then(receipt => {
        console.log(receipt);
        resolve(receipt.transactionHash);
      })
    })
    .catch(err => {
      console.log('error: %j', err);
      reject(err.code);
    })
  });
}

export async function getEstimateAsync(signer, xfundaddress, positions, slippage) {
  
  try {
    const destAddress = [];
    const targetWgts = [];

    positions.forEach((p) => {
      destAddress.push(p.taddress);
      targetWgts.push(xWinLib.roundTo(p.weight * 100, 0));
    });
    const contract = new ethers.Contract(
      xfundaddress,
      xWinFund,
      signer
    )
    let estimation = await contract.estimateGas["Rebalance(address[],uint256[],uint32)"](destAddress, targetWgts, Number(slippage)*100);
    estimation = xWinLib.roundTo(Number(estimation) * 1.2, 0) 
    return{
      status: true,
      estimation: estimation,
      message : "success"
    }
    
  } catch (error) {
    console.log(error)
    return {
      status: false,
      estimation: 0,
      message : "Gas estimation failed"
    }
  }
}

export async function canRebalance(web3, contractaddress) {
  
  const contract = new web3.eth.Contract(xWinFund, contractaddress);
  let totalSupply = await contract.methods.getFundTotalSupply().call()
  return Number(totalSupply) > 0
}


