import MasterChef from "../abi/v2/xWinMasterChef.json";
import BuddyChef from "../abi/v2/xWinBuddyChef.json"
import xWinStrategies from "../abi/v2/xWinStrategies.json"

import appGlobalConfig from '../configs/56/appGlobalConfig';
import { getGasPrice, convertToWei, convertFromWei } from "../utils/helper";
import { getTokenInfo } from "./erc20Interactor";
import { GetFundDataAll } from "./fundV2Interactor";
import { getPrice } from "./xWinPriceMasterInteractor";
import { getPublicClient, getContract } from '@wagmi/core'
import { readContract } from '@wagmi/core'
import { ethers } from "ethers";


export async function GetPoolInfo(web3, port, prices) {

  try {
    
    if(port === undefined) {
      return{
        fundUnitPriceInUSD: "0",
        TVLInUSD: "0",
        xwinPerBlock: "0",
        totalAllocPoint: "0",
        lpTotalSupply: "0",
        allocPoint: "0",
        lpToken: "",
        endPeriod:"0"
      }
    } 
    const contract = new web3.eth.Contract(MasterChef, appGlobalConfig.mainnetMasterChef);
    
    const promiseData = await Promise.all([
      contract.methods.xwinPerBlock().call(),
      contract.methods.totalAllocPoint().call(),
      contract.methods.poolInfo(port.pid).call(),

    ]);
    let xwinPerBlock = promiseData[0]
    const totalAllocPoint = promiseData[1]
    const pool = promiseData[2]
    // const lptokenInfo = promiseData[3]

    xwinPerBlock = convertFromWei(xwinPerBlock);
    const lpTotalSupply =  convertFromWei(pool.totalDeposit) //lptokenInfo.targetBal;
    let fundUnitPriceInUSD = "0";
    if(port.isxwinfund){
      const contract2 = new web3.eth.Contract(xWinStrategies, port.contractaddress);
      const priceInUSD = await contract2.methods.getUnitPriceInUSD().call()
      fundUnitPriceInUSD = convertFromWei(priceInUSD);
    }
    else if(port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWINBNBLPPool.toLowerCase() 
      || port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWINBNBLPPoolV2.toLowerCase()
      || port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWINBNBLPPoolApeSwap.toLowerCase()
      ) 
    { 
      fundUnitPriceInUSD = prices.pcsLPPrice //await xWinUtils.getLPCakePrice(globalWeb3, prices.xwinPriceInUSD)
    }
    else if(port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWINUSDTLPPool.toLowerCase() 
      || port.contractaddress.toLowerCase() === appGlobalConfig.mainnetXWINUSDTAPELPPool.toLowerCase()
      ) 
    { 
      fundUnitPriceInUSD = prices.babyLPPrice //await xWinUtils.getLPCakePrice(globalWeb3, prices.xwinPriceInUSD)
    }
    else{
      fundUnitPriceInUSD = prices.xwinPriceInUSD; 
    }
    let TVLInUSD = Number(lpTotalSupply) * Number(fundUnitPriceInUSD);
    return{
      fundUnitPriceInUSD: fundUnitPriceInUSD,
      TVLInUSD: TVLInUSD,
      xwinPerBlock: xwinPerBlock,
      totalAllocPoint: totalAllocPoint,
      lpTotalSupply: lpTotalSupply,
      allocPoint: pool.allocPoint,
      lpToken: pool.lpToken,
      endPeriod : pool.endPeriod
    }      
  } catch (error) {
    console.log(port.contractaddress, "address")
    console.log(error)
  }
  return{
    fundUnitPriceInUSD: "0",
    TVLInUSD: "0",
    xwinPerBlock: "0",
    totalAllocPoint: "0",
    lpTotalSupply: "0",
    allocPoint: "0",
    lpToken: "",
    endPeriod:"0"
  }

}

export async function GetUserPendingXWIN(web3, account, pid) {

  const contract = new web3.eth.Contract(MasterChef, appGlobalConfig.mainnetMasterChef);
  
  const promiseData = await Promise.all([
    contract.methods.pendingRewards(pid, account).call(),
  ]);
  let pendingXwin = promiseData[0]

  return {
    "pendingXwin": convertFromWei(pendingXwin)
  };
  
}

export async function GetUserStakingInfo(web3, account, pid, contractaddress) {

  const contract = new web3.eth.Contract(MasterChef, appGlobalConfig.mainnetMasterChef);
  
  const promiseData = await Promise.all([
    getTokenInfo(web3, contractaddress, account),
    contract.methods.userInfo(pid, account).call(),
    contract.methods.pendingRewards(pid, account).call(),
  ]);
  let tokenInfo = promiseData[0]
  let userInfo = promiseData[1]
  let pendingXwin = promiseData[2]

  return {
    "amount": convertFromWei(userInfo.amount),
    "pendingXwin": convertFromWei(pendingXwin),
    "targetBal": tokenInfo.targetBal,
  };
  
}

export async function getMasterChefStakingAPR(web3, pid) {
  
  try {
    if(pid === undefined) return 0
    const contract = new web3.eth.Contract(MasterChef, appGlobalConfig.mainnetMasterChef);
    let apr  = await contract.methods.getAPR(pid).call();
    return Number(convertFromWei(apr)) / 10000
      
  } catch (error) {
    console.log(error)
    return 0    
  }
}

export async function getStakingAPR(poolInfo, tokenPrice, xwinPrice) {
  
  if (Number(tokenPrice) === 0 || Number(poolInfo.lpTotalSupply) <= 0 || Number(poolInfo.xwinPerBlock) === 0) {
    return '0';
  }
  const xwinReward = (Number(poolInfo.xwinPerBlock) * Number(poolInfo.allocPoint)) / Number(poolInfo.totalAllocPoint);
  if (Number(poolInfo.lpTotalSupply) <= 0 || xwinReward === undefined) {
    return '0';
  }
  const accCakePerShare = xwinReward / Number(poolInfo.lpTotalSupply);
  const proceeds = accCakePerShare * appGlobalConfig.blockPerDay * 365 * Number(xwinPrice);
  return Number((proceeds / Number(tokenPrice)) * 100).toFixed(2);
}

export async function HarvestAll(signer, account) {

  return new Promise((resolve, reject) => {    
    
    const contract = new ethers.Contract(appGlobalConfig.mainnetMasterChef, MasterChef, signer);
    const gasParam = getGasPrice(signer);
    contract.harvestAll({
      from: account,
      value: 0,
      ...gasParam
    })
    .then(txn => {
      txn.wait().then(receipt => {
        console.log(receipt);
        resolve(receipt.transactionHash);
      })
    })
    .catch((err) => {
      console.log('error: %j', err);
      reject(err.message);
    });
  });
}

export async function DepositFarm(signer, pid, account, subsAmt) {

  return new Promise((resolve, reject) => { 
    
      const gasParam = getGasPrice(signer);
      const contract = new ethers.Contract(appGlobalConfig.mainnetMasterChef, MasterChef, signer);
      const subsAmtInWei = convertToWei(subsAmt);
      
      contract.deposit(pid, subsAmtInWei, {
        ...gasParam,
        value: 0,
        from: account
      })
      .then(txn => {
        txn.wait().then(receipt => {
          console.log(receipt);
          resolve(receipt.transactionHash);
        })
      })
      .catch((err) => {
        console.log('error: %j', err);
        reject(err.message);
      });
    
  });
}


export async function WithdrawFarm(signer, pid, account, amtRedeem) {
  
  return new Promise((resolve, reject) => {
    
    const contract = new ethers.Contract(appGlobalConfig.mainnetMasterChef, MasterChef, signer);

    const gasParam = getGasPrice(signer);
    const amtRedeemInWei = convertToWei(amtRedeem.toString());
    contract.withdraw(pid, amtRedeemInWei, {
      from: account,
      value: 0,
      ...gasParam
    })
    .then(txn => {
      txn.wait().then(receipt => {
        console.log(receipt);
        resolve(receipt.transactionHash);
      })
    })
    .catch((err) => {
      console.log('error: %j', err);
      reject(err.message);
    });
  });
}

export async function getUserFarmAllInfo(web3, account) {

  try {

    if(appGlobalConfig.mainnetMasterChef === appGlobalConfig.mainnetAddressZero) return;
    
    const contract = new web3.eth.Contract(MasterChef, appGlobalConfig.mainnetMasterChef);
    let myFund  = await contract.methods.getMyFarmInfo(account).call();
    return myFund;
      
  } catch (error) {
    console.log(error)
  }
}

export async function 
getTotalFarmValue(web3) {

  try {

    if(appGlobalConfig.mainnetMasterChef === appGlobalConfig.mainnetAddressZero) return 0;
    
    const masterContract = new web3.eth.Contract(MasterChef, appGlobalConfig.mainnetMasterChef);
    const buddyContract = new web3.eth.Contract(BuddyChef, appGlobalConfig.mainnetBuddyChef);

    let masterPoolLength = await masterContract.methods.poolLength().call();
    let buddyPoolLength = await buddyContract.methods.poolLength().call();

    let ids = []
    let jds = []

    for (let i = 0; i < masterPoolLength; i++) {
      ids.push(i)
    }

    for (let j = 0; j < buddyPoolLength; j++) {
      jds.push(j)
    }

    const promises = ids.map(async i => {
        const poolInfo = await masterContract.methods.poolInfo(i).call()
        if(poolInfo.lpToken !== appGlobalConfig.mainnetLockStake){
          const supplyData = await getTokenInfo(web3, poolInfo.lpToken, appGlobalConfig.mainnetMasterChef)
          const price = await getPrice(web3, poolInfo.lpToken, appGlobalConfig.mainnetUSDT)
          return convertFromWei(price) * supplyData.targetBal  
        }else{
          return 0
        }
    });

    const promises2 = jds.map(async j => {
      const poolInfo = await buddyContract.methods.poolInfo(j).call()
      if(poolInfo.lpToken !== appGlobalConfig.mainnetLockStake){
        const supplyData = await getTokenInfo(web3, poolInfo.lpToken, appGlobalConfig.mainnetBuddyChef)
        const price = await getPrice(web3, poolInfo.lpToken, appGlobalConfig.mainnetUSDT)
        return convertFromWei(price) * supplyData.targetBal  
      }else{
        return 0
      }
  });

    
    
    const marketcapTVLMaster = await Promise.all(promises);
    const marketcapTVLBuddy = await Promise.all(promises2);

    const total = marketcapTVLMaster.reduce((sum, mc) => {
        return parseFloat(sum) + parseFloat(mc);
    }, 0);

    const total2 = marketcapTVLBuddy.reduce((sum, mc) => {
      return parseFloat(sum) + parseFloat(mc);
  }, 0);

    return total+total2
      
  } catch (error) {
    console.log(error)
    return 0
  }
}

