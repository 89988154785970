import React, { useState, useEffect, useRef, useContext } from 'react'
import { useTheme } from '@material-ui/styles'
import xWinLib from '../../../xWinLib'
import { Typography, Button } from '../../../components/Wrappers/Wrappers'
import { Box, Grid, TextField, InputAdornment, Card, Table, TableHead, TableRow, TableCell, TableBody, Tooltip } from '@material-ui/core'
// styles
import useStyles from '../styles'
import xWinUtils from '../../../xWinUtils'
import { AppContext } from '../../../context/AppProvider'
import { getStableTargetValues } from '../../../utils/xWinAutoTradingInteractor'
import format from 'format-number'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx';
import TargetAllocation from '../../funddetailV2/components/TargetAllocation'
import { getTokenInfo } from '../../../utils/erc20Interactor'
import { GetStrategiesDataAll, getFundExtraInfo, getTargetNamesAddress } from '../../../utils/strategyV2Interactor'
import { getExplorerScan } from '../../../utils/helper'

export default function AllocationStrategy(props) {
    var classes = useStyles()
    var theme = useTheme()
    const { strategy, myFundData, tokensMaster, xwinstrategies } = props
    const { web3static, explorerScan, appGlobalConfig } = useContext(AppContext)
    const [fundData, setFundData] = useState([])
    
    const { t, i18n } = useTranslation('fundDetails')

    useEffect(() => {
        init()
    }, [myFundData])

    const init = async () => {

        if(strategy.contractaddress === undefined) return
        if(myFundData.baseToken === undefined) return
        
        const targetaddress = await getTargetNamesAddress(web3static, strategy.contractaddress)
        let tokenNames = []
        let targetaddressNew = []
        let strategies = []
        let underlyingStrategies = []

        const baseCcy = tokensMaster.find(
            x =>
                x.address.toLowerCase() ===
                myFundData.baseToken?.toLowerCase()
        ).name

        for (let i = 0; i < targetaddress.length; i++) {
            targetaddressNew.push(targetaddress[i])
        }
        for (let i = 0; i < targetaddressNew.length; i++) {
            const promiseData = await Promise.all([
                getFundExtraInfo(web3static, strategy.contractaddress, targetaddressNew[i]),
                getTokenInfo(web3static, targetaddressNew[i], strategy.contractaddress),
            ])
            let fundExtraInfo = promiseData[0]
            let tokenInfo = promiseData[1]
            let diffBal = fundExtraInfo.AllBal - tokenInfo.targetBal
            let tname = ''
            let token = tokensMaster.find(
                t =>
                    t.address.toLowerCase() ===
                    targetaddressNew[i].toLowerCase()
            )
            if (token == undefined) {
                tname = ''
            } else {
                tname = token.name
            }

            let price =
                fundExtraInfo.fundTokenValue /
                (fundExtraInfo.AllBal === 0 ? 1 : fundExtraInfo.AllBal)
            tokenNames.push({
                key: i,
                address: targetaddressNew[i],
                targetweight: fundExtraInfo.targetweight,
                balance: tokenInfo.targetBal,
                fundTokenValue: fundExtraInfo.fundTokenValue,
                aTokenBal: diffBal.toString(),
                name: tname,
                price: price,
                decimals: tokenInfo.decimals,
            })

            //get all relavant strategy address for strategy display later
            if (token.type === 'strategy') {
                strategies.push(token)
                const underyingData = await GetStrategiesDataAll(web3static, targetaddressNew[i], xwinstrategies, appGlobalConfig)
                underlyingStrategies.push(underyingData)
            }
        }

        tokenNames.push({
            key: 999,
            address: myFundData.baseToken, 
            targetweight: 0,
            balance: 0, //fundData.fundETHbalance.toString(),
            fundTokenValue: 0, //fundData.fundETHbalance.toString(),
            aTokenBal: 0,
            name: baseCcy, 
            price: 1,
            decimals: 18,
        })
        setFundData({
            fundvalue: myFundData.fundvalue.toString(),
            tokenNames: tokenNames,
        })
    }

    const getActWgt = target => {
        const val = xWinLib.getTokenActiveWeight(target, fundData.fundvalue)
        if (val < 0) {
            return (
                <Typography className={clsx(classes.displayFont, classes.negativeNum)}>
                    {format({ prefix: '', suffix: '%' })(
                        xWinLib.roundTo(val, 2)
                    )}
                </Typography>
            )
        } else {
            return (
                <Typography className={clsx(classes.displayFont, classes.secondaryHeading)}>
                    {format({ prefix: '', suffix: '%' })(
                        xWinLib.roundTo(val, 2)
                    )}
                </Typography>
            )
        }
    }

    const getTargets = targets => {

        if (targets === undefined) return ''
        return (
            <Card>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell 
                                className={clsx(classes.displayFont, classes.columnHide)}
                            >
                                {t('allocation.token')}
                            </TableCell>
                            <TableCell className={clsx(classes.displayFont, classes.tablecell)}> {t('allocation.target')}</TableCell>
                            <TableCell className={clsx(classes.displayFont, classes.columnHide)}>
                                {t('allocation.fund')}
                            </TableCell>
                            <TableCell className={clsx(classes.displayFont, classes.tablecell)}>
                                {t('allocation.active')}
                            </TableCell>
                            <TableCell className={clsx(classes.displayFont, classes.tablecell)}>{t('allocation.quantity')}</TableCell>
                            <TableCell className={clsx(classes.displayFont, classes.tablecell)}>
                                {t('allocation.address')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(targets).map((key, i) => (
                            <TableRow hover key={i}>
                                <TableCell>
                                    <Tooltip
                                        title={xWinLib.getTokenName(
                                            tokensMaster,
                                            targets[i].address
                                        )}
                                        arrow={true}
                                    >
                                        {xWinUtils.getIcons(
                                            xWinLib.getTokenName(
                                                tokensMaster,
                                                targets[i].address
                                            )
                                        )}
                                    </Tooltip>
                                </TableCell>
                                <TableCell className={clsx(classes.displayFont, classes.columnHide)}>
                                    <Typography
                                        // variant="body1"
                                        className={classes.secondaryHeading}
                                    >
                                        {xWinLib.getTokenName(
                                            tokensMaster,
                                            targets[i].address
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell className={clsx(classes.displayFont)}>
                                    <Typography
                                        // variant="body1"
                                        className={clsx(classes.displayFont, classes.secondaryHeading)}
                                    >
                                        {format({ prefix: '', suffix: '%' })(
                                            xWinLib.roundTo(
                                                targets[i].targetweight / 100,
                                                2
                                            )
                                        )}
                                    </Typography>
                                </TableCell>
                                <TableCell
                                    align={'right'}
                                    className={clsx(classes.displayFont, classes.columnHide)}
                                >
                                    {format({ prefix: '', suffix: '%' })(
                                        xWinLib.roundTo(
                                            xWinLib.getTokenWeight(
                                                targets[i],
                                                fundData.fundvalue
                                            ),
                                            2
                                        )
                                    )}
                                </TableCell>
                                <TableCell
                                    align={'right'}
                                    className={clsx(classes.displayFont, classes.tablecell)}
                                >
                                    {getActWgt(targets[i])}
                                </TableCell>
                                <TableCell
                                    align="right"
                                    className={clsx(classes.displayFont, classes.secondaryHeading)}
                                >
                                    {format({ prefix: '', suffix: '' })(
                                        xWinLib.roundTo(targets[i].balance, 5)
                                    )}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    className={clsx(classes.displayFont, classes.tablecell)}
                                >
                                    <Typography
                                        className={clsx(classes.displayFont, classes.secondaryHeading)}
                                    >
                                        <a
                                            className={classes.link}
                                            href={getExplorerScan(
                                                targets[i].address,
                                                'address',
                                                explorerScan
                                            )}
                                            target="blank"
                                        >
                                            {t('allocation.view')}
                                        </a>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>
        )
    }

    const getFundTarget = () => {
        return (
            <Grid item lg={12} md={12} xl={12} xs={12}>
                {getTargets(fundData.tokenNames)}
            </Grid>
        )
    }

    const getPieChart = () => {
        return (
            <TargetAllocation
                fundData={fundData}
                tokensMaster={tokensMaster}
            />
        )
    }

    return (
        <Grid container spacing={2}>
            <Grid
                item
                lg={3}
                sm={12}
                xs={12}
                className={classes.hoverHighlight2}
            >
                {getPieChart()}
            </Grid>
            <Grid
                item
                lg={9}
                sm={12}
                xs={12}
                className={classes.cardpie}
            >
                {getFundTarget()}
            </Grid>
        </Grid>
    )
}
