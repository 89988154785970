import {
    Grid,
    Box,
    Card,
    CardContent,
} from '@material-ui/core'
import { Typography, Avatar } from '../../../components/Wrappers/Wrappers'
import React from 'react'
import useStyles from '../styles'
import { useTranslation } from 'react-i18next'
import xWinLib from '../../../xWinLib'
import CountUp from 'react-countup'

const TotalTvl = props => {
    const classes = useStyles()
    const { t, i18n } = useTranslation('mainMenu')
    const { TotalTVL, ChainTVL, ChainName, TotalVault } = props

    return (
            <Grid container spacing={2}>
                <Grid
                    item
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    // className={classes.container}
                >
                    <Card>
                        <CardContent>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'center'}
                            >
                                <Typography color="text" variant="h5">
                                    {`${t('totalTVL')}`}{" "}
                                    <img
                                        src={xWinLib.getIcon("bnb.png")}
                                        className={classes.icon1}
                                    />
                                    <img
                                        src={xWinLib.getIcon('arb.png')}
                                        className={classes.icon1}
                                    />
                                    <img
                                        src={xWinLib.getIcon('polygon.png')}
                                        className={classes.icon1}
                                    />
                                </Typography>
                                
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'center'}
                            >
                                <Typography className={classes.tvlNumber}>
                                    <CountUp
                                        separator=","
                                        decimals={0}
                                        decimal="."
                                        prefix="$"
                                        duration={1.75}
                                        end={TotalTVL}
                                    />
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    // className={classes.container}
                >
                    <Card>
                        <CardContent>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'center'}
                            >
                                <Typography color="text" variant="h5">
                                {ChainName}{" TVL"}{" "}
                                <img
                                    src={xWinLib.getIcon(ChainName.toLowerCase()+".png")}
                                    className={classes.icon1}
                                />
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'center'}
                            >
                                <Typography className={classes.tvlNumber}>
                                    <CountUp
                                        separator=","
                                        decimals={0}
                                        decimal="."
                                        prefix="$"
                                        duration={1.75}
                                        end={ChainTVL}
                                    />
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid
                    item
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    // className={classes.container}
                >
                    <Card>
                        <CardContent>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'center'}
                            >
                                <Typography color="text" variant="h5">
                                    {`${t('totalVault')}`}
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent={'center'}
                                paddingTop={0.6}
                            >
                                <Typography className={classes.tvlNumber}>
                                    <CountUp
                                        separator=","
                                        decimals={0}
                                        decimal="."
                                        prefix=""
                                        suffix={" "+`${t('vault')}`}
                                        duration={1.75}
                                        end={TotalVault}
                                    />
                                    
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
    )
}

export default TotalTvl
