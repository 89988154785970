import React, { useState, useEffect, useRef, useContext } from 'react'
// components
import { Typography, Button } from '../../../components/Wrappers/Wrappers'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import SnackbarMessage from '../../snackbarmessage/SnackbarMessage'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import format from 'format-number'
import confetti from 'canvas-confetti'

import {
    Box,
    DialogContent,
    DialogActions,
    ButtonGroup,
    TextField,
    Card,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    Grid,
} from '@material-ui/core'
import useStyles from '../styles'
import { AppContext } from '../../../context/AppProvider'
import { useTranslation } from 'react-i18next'
import { useAccount } from 'wagmi/dist'
import { alreadyRegisterReferral, registerRefferal, signinUser } from '../../../utils/firestoreDataInteractor'
import xWinUtils from '../../../xWinUtils'
import { DistributeFeeAsync, getManagerRatio, getSplitWalletTokens } from '../../../utils/splitFeeWalletInteractor'
import xWinLib from '../../../xWinLib'
import { jsNumberForAddress } from 'react-jazzicon'
import Jazzicon from 'react-jazzicon/dist/Jazzicon'

export default function MyEarning() {
    const classes = useStyles()
    const [loadingTxn, setLoadingTxn] = React.useState(false)
    const [fundTokens, setFundTokens] = useState([])
    const [splitInfo, setSplitInfo] = useState([])
    const [splitWalletAddr, setSplitWalletAddr] = useState("")
    const {
        tokensv2,
        xwinfundv2,
        web3static,
        theSigner,
        appGlobalConfig
    } = useContext(AppContext)
    const { address} = useAccount()

    const { t, i18n } = useTranslation('fundDetails')

    const refsnackbar = useRef(null)
    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    
    const handleClickDisplay = async event => {
        event.preventDefault()

        if (splitWalletAddr === "") {
            showSnackBar('error', `${t('referral.split-wallet-empty')}`)
            return
        }
        const tokensInfo = await getSplitWalletTokens(web3static, splitWalletAddr, appGlobalConfig, tokensv2)
        if(tokensInfo.length === 0){
            showSnackBar('error', `${t('referral.not-found')}`)
            setFundTokens([])
            setSplitInfo("")
            return
        }
        const info = await getManagerRatio(web3static, splitWalletAddr)

        let total = 0
        tokensInfo.forEach(token => {
            const found = info.managerAll.find(x=>x.managerAddress === address)
            if(found){
                const ratio = found.ratio[info.periodToUse] / 10000
                const myCommUnit = token.balance * Number(ratio)
                const myComm = token.balance * token.unitPriceUSD * Number(ratio)
                token.myComm = myComm
                token.myCommUnit = myCommUnit
                token.ratio = Number(ratio)
                total = total + myComm
            }
        });
        info.total = total
        setFundTokens(tokensInfo)
        setSplitInfo(info)
        
        
    }

    const handleClickDistribute = async event => {
        event.preventDefault()

        setLoadingTxn(true)

        DistributeFeeAsync(theSigner, splitWalletAddr, address)
            .then(res => {
                setLoadingTxn(false)
                showSnackBar('success', res)
                confetti()
            })
            .catch(err => {
                setLoadingTxn(false)
                console.log(err)
                showSnackBar('error', err)
            })
    }

    const handleTextChange = event => {
        event.preventDefault()
        const address = event.target.value
        if (address !== "" && address !== undefined) {
            setSplitWalletAddr(address)
        } else {
            setSplitWalletAddr('')
        }
    }

    const displayButton = () => {

        
        return (
                <Button
                    onClick={handleClickDisplay}
                    color="primary"
                    variant="contained"
                    fullWidth
                    startIcon={<AddCircleIcon />}
                >
                    <Typography className={classes.displayFont}>
                        {t('referral.display')}
                    </Typography>
                </Button>
        )
    
                    
    }

    const displayLogo = (contractaddress) =>{
   
        let found = tokensv2.find(x=>x.address.toLowerCase() === contractaddress.toLowerCase())
        if(found){
            return xWinUtils.getIcons(found.name)
        }
        found = xwinfundv2.find(x=>x.contractaddress.toLowerCase() === contractaddress.toLowerCase())
        if(found){
            return xWinUtils.getIcons(found.logo, false)
        }    
        return(
            <Jazzicon diameter={38} seed={jsNumberForAddress(contractaddress)}/>
        )
    }

    const displayResult = () => {
        
        if(fundTokens.length === 0) return
        
        return fundTokens.map(s => (
            <TableRow>
                <TableCell width={'15%'} className={classes.displayFont}>
                    {displayLogo(s.address)}
                </TableCell>
                <TableCell width={'20%'} className={classes.displayFont}>
                    {s.data.symbol}
                </TableCell>
                <TableCell width={'15%'} className={classes.displayFont}>
                    {format({ prefix: '', suffix: '%' })(
                            xWinLib.roundTo(s.ratio * 100, 2))}
                </TableCell>
                <TableCell width={'25%'} align='right' className={classes.displayFont}>
                    {xWinLib.roundTo(s.myCommUnit, 4)}
                </TableCell>
                <TableCell width={'25%'} align='right' className={classes.displayFont}>
                    {
                        format({ prefix: ' $', suffix: '' })(
                            xWinLib.roundTo(s.myComm, 2))
                    }&nbsp;
                    {xWinUtils.displayToolTip("Estimate value in USDT")}
                </TableCell>
            </TableRow>
        ))
    
    }
    return (
        <>
            <SnackbarMessage ref={refsnackbar} />
            <Card className={classes.expansion}>
                {xWinUtils.getProgress(loadingTxn)}
                
                <DialogContent>
                    <Grid container>
                        <Grid item lg={9} sm={12} xs={12}>
                            <TextField
                                label={t('referral.textfield-enter-splitwallet')}
                                margin="dense"
                                name="splitWalletAddr"
                                onChange={handleTextChange}
                                required
                                // disabled={alreadyRegister}
                                value={splitWalletAddr}
                                fullWidth
                            />
                        </Grid>
                        <Grid item lg={3} sm={12} xs={12}>
                            <ButtonGroup
                                size="small"
                                color="primary"
                                fullWidth
                            >
                                {displayButton()}
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </DialogContent>            
                
                {/* <DialogActions>
                    <ButtonGroup
                        size="small"
                        color="primary"
                        fullWidth
                        aria-label="outlined primary button group"
                    >
                        {displayButton()}
                    </ButtonGroup>
                </DialogActions> */}
                <DialogContent>
                    <TableContainer>
                        <Table>
                            {displayResult()}
                        </Table>
                    </TableContainer>
                </DialogContent>
                {fundTokens.length > 0 && (
                    <DialogActions>
                        <ButtonGroup
                            size="small"
                            color="primary"
                            fullWidth
                            aria-label="outlined primary button group"
                        >
                            <Button
                                onClick={handleClickDistribute}
                                color="secondary"
                                variant="contained"
                                fullWidth
                                startIcon={<AddCircleIcon />}
                            >
                                <Typography className={classes.displayFont}>
                                    {t('referral.distribute')}
                                </Typography>
                            </Button>
                        </ButtonGroup>
                    </DialogActions>
                )}
                
            </Card>
        </> 
    )
}
