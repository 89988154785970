import React, { useEffect, useRef, useContext } from 'react'
import { AppContext } from '../../context/AppProvider'
import useStyles from './styles'
import { Typography } from '../../components/Wrappers/Wrappers'
import CountUp from 'react-countup'
import { withRouter } from 'react-router-dom'
import { Grid, Container, Box, Button } from '@material-ui/core'
import ApexCharts from './components/ApexChart'
import DataTable from './components/DataTable'
import Widget from '../../components/Widget/Widget'
import { getExposure } from '../../utils/moralisInteractor'
import xWinUtils from '../../xWinUtils'
import SnackbarMessage from '../snackbarmessage/SnackbarMessage'
import { useAccount } from 'wagmi'

const exposurePage = props => {
    const classes = useStyles()
    const { appGlobalConfig } = useContext(AppContext)
    const { address } = useAccount()

    const [loading, setLoading] = React.useState(false)
    const [total, setTotal] = React.useState(0)
    const [exposureData, setExposureData] = React.useState([])
    const refsnackbar = useRef(null)

    const showSnackBar = (status, msg) => {
        refsnackbar.current.handleOpen(status, msg)
    }

    useEffect(() => {
        initData()
    }, [address, appGlobalConfig])

    useEffect(() => {
        calcTotal()
    }, [exposureData])

    const initData = async () => {
        if (address === undefined) {
            return props.history.push('/app/dashboard')
        }
        let storage = JSON.parse(localStorage.getItem('ExposureData'))

        // 7200 2 hours
        try {
            if (
                !storage ||
                storage.data.length === 0 ||
                storage.time + 720000 < now() ||
                storage.chainId !== appGlobalConfig?.chainId ||
                storage.walletAddress !== address
            ) {
                setLoading(true)
                await getTokenExposure()
            } else {
                setExposureData(storage.data)
            }
        } catch {
            setLoading(true)
            await getTokenExposure()
        }
    }

    function now() {
        return +new Date()
    }

    const getTokenExposure = async () => {
        try {
            const promiseData = await getExposure(appGlobalConfig, address)
            if (promiseData.length !== 0) {
                let data = []
                await promiseData.forEach(element => {
                    data.push({
                        Symbol: element.symbol,
                        Amount: element.tokenOwnedInUsdt,
                    })
                })
                let newList = data.sort((a, b) => b.Amount - a.Amount)
                setExposureData(newList)
                let object = {
                    time: now(),
                    chainId: appGlobalConfig?.chainId,
                    walletAddress: address,
                    data: newList,
                }
                localStorage.setItem('ExposureData', JSON.stringify(object))
                setLoading(false)
            } else {
                setLoading(false)
                setTotal(0)
                setExposureData(null)
            }
        } catch {
            setLoading(false)
            setTotal(0)
            setExposureData(null)
            showSnackBar('error', `Wallet not found`)
        }
    }

    const handleRefresh = async () => {
        setExposureData([])
        setLoading(true)
        await getTokenExposure()
    }

    const displayGraph = () => {
        if (exposureData !== null) {
            return (
                <Widget
                    bodyClass={classes.mainChartBody}
                    title={'Portfolio Exposure'}
                    disableWidgetMenu
                >
                    <ApexCharts mainData={exposureData}></ApexCharts>
                </Widget>
            )
        } else {
            return
        }
    }

    const displayTable = () => {
        if (exposureData !== null) {
            return <DataTable mainData={exposureData}></DataTable>
        } else {
            return
        }
    }

    const calcTotal = () => {
        if (exposureData !== null) {
            let sum = 0
            for (let index = 0; index < exposureData.length; index++) {
                sum += exposureData[index].Amount
            }
            setTotal(parseFloat(sum).toFixed(2))
        }
    }

    return (
        <Container>
            <SnackbarMessage ref={refsnackbar} />
            {xWinUtils.getProgress(loading)}
            <Grid container spacing={2}>
                <Grid item lg={12} sm={12} xs={12}>
                    <Widget>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'center'}
                        >
                            <Typography className={classes.totalNumber}>
                                <CountUp
                                    separator=","
                                    decimals={2}
                                    decimal="."
                                    prefix="Total: $"
                                    suffix=""
                                    duration={1.75}
                                    end={total}
                                />
                            </Typography>
                        </Box>
                    </Widget>
                </Grid>

                <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={classes.refreshButton}
                    style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        '&:hover': {
                            textDecoration: 'none', // Remove underline on hover
                            color: 'white', // Set the color for the button text on hover
                        },
                    }}
                >
                    <Button
                        onClick={handleRefresh}
                        color="primary"
                        variant="contained"
                    >
                        {'Refresh'}
                    </Button>
                </Grid>
                <Grid
                    item
                    lg={7}
                    ms={12}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight1}
                >
                    {displayGraph()}
                </Grid>
                <Grid
                    item
                    lg={5}
                    sm={12}
                    xs={12}
                    className={classes.hoverHighlight1}
                >
                    {displayTable()}
                </Grid>
            </Grid>
        </Container>
    )
}

export default withRouter(exposurePage)
