import React, { useState, useEffect, useContext } from 'react'
import xWinLib from '../../../xWinLib'
import format from 'format-number'
import xWinUtils from '../../../xWinUtils'
import moment from 'moment'
import {
    Box,
    Table,
    TableRow,
    TableCell,
    TableHead,
    TableContainer,
} from '@material-ui/core'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

// styles
import useStyles from '../styles'
import Widget from '../../../components/Widget/Widget'
import {
    GetSharpeRatio,
} from '../../../utils/firestoreDataInteractor'
import { useTranslation } from 'react-i18next'

export default function AssetSharpeRatio(props) {
    var classes = useStyles()
    const { tokensMaster, appGlobalConfig } = props
    const [expectedReturns3M, setExpectedReturns3M] = React.useState([])
    const [expectedReturns, setExpectedReturns] = React.useState([])
    const [expectedReturns12M, setExpectedReturns12M] = React.useState([])
    const [dropdown, setDropdown] = React.useState('6-months')
    const isDark = localStorage.getItem('theme')
    const { t, i18n } = useTranslation('dashboard')

    const yyyymm = moment(new Date())
        .endOf('month')
        .format('YYYYMM')

    useEffect(() => {
        fetchData()
    }, [dropdown, appGlobalConfig])

    const fetchData = async () => {
        const promiseData = await Promise.all([
            GetSharpeRatio('optimization-6m', yyyymm, appGlobalConfig),
            GetSharpeRatio('optimization-3m', yyyymm, appGlobalConfig),
        ])
        let o6m = promiseData[0]
        let o3m = promiseData[1]

        o6m.sort(xWinLib.compareValues('sharpeRatio', 'desc'))
        setExpectedReturns(o6m.slice(0, 10))

        o3m.sort(xWinLib.compareValues('sharpeRatio', 'desc'))
        setExpectedReturns3M(o3m.slice(0, 10))
    }

    const handleChangeDropdown = event => {
        setDropdown(event.target.value)
    }

    const displaySharpeRatio = dropdown => {
        let data
        if (dropdown === '3-months') {
            data = expectedReturns3M
        } else if (dropdown === '6-months') {
            data = expectedReturns
        } else {
            data = expectedReturns12M
        }
        
        let filter = []
        data.forEach(r => {
            const found = tokensMaster.find(x=>x.address.toLowerCase() === r.asset.toLowerCase())
            if(found !== undefined){
                filter.push(r)
            }
        });
        
        if (filter?.length > 0) {
            return data.map(r => (
                
                    <TableRow hover>
                    <TableCell width={'30%'}>
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent={'left'}
                        >
                            {xWinUtils.getIcons(
                                xWinLib.getTokenName(tokensMaster, r.asset)
                            )}
                        </Box>
                    </TableCell>
                    <TableCell className={classes.contentText}>
                        {xWinLib.getTokenName(tokensMaster, r.asset)}
                    </TableCell>
                    <TableCell width={'30%'} className={classes.positiveNum}>
                        {xWinLib.roundTo(r.sharpeRatio, 2)}
                    </TableCell>
                </TableRow>
                
                
            ))
        } else {
            ;<TableRow hover>
                <TableCell width={'100%'} colSpan="3">
                    {xWinUtils.getProgressLinear(true)}
                </TableCell>
            </TableRow>
        }
    }

    return (
        <Widget
            bodyClass={classes.mainChartBody}
            title={t('assetSharpeRatio.title')}
            disableWidgetMenu
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    backgroundColor: 'transparent',
                }}
            >
                <FormControl
                    sx={{ minWidth: 50 }}
                    size="small"
                    variant="standard"
                >
                    <Select
                        labelStyle={{ color: '#ff0000' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dropdown}
                        label="months"
                        onChange={handleChangeDropdown}
                        sx={{
                            color: isDark === 'dark' && 'white',
                            backgroundColor: isDark === 'dark' && '#2f2f36',
                            paddingLeft: 1,
                        }}
                    >
                        <MenuItem value="3-months">
                            {t('assetSharpeRatio.3-month')}
                        </MenuItem>
                        <MenuItem value="6-months">
                            {t('assetSharpeRatio.6-month')}
                        </MenuItem>
                        {/* <MenuItem value="12-months">
                            {t('assetSharpeRatio.12-month')}
                        </MenuItem> */}
                    </Select>
                </FormControl>
            </div>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className={classes.contentText}
                                width={'25%'}
                            ></TableCell>
                            <TableCell
                                className={classes.contentText}
                                width={'30%'}
                            >
                                {t('assetSharpeRatio.name')}
                            </TableCell>
                            <TableCell
                                className={classes.positiveNum}
                                width={'35%'}
                            >
                                {t('assetSharpeRatio.sharpe-ratio')}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {displaySharpeRatio(dropdown)}
                </Table>
            </TableContainer>
        </Widget>
    )
}
