const referralFAQ = {
    faqs: [
        {
            symbol: 'referral',
            env: 56,
            question: 'referral.question-what',
            defaultExpanded: true,
            desc: 'referral.desc-what',
        },
        {
            symbol: 'referral',
            env: 56,
            question: 'referral.question-how',
            desc: 'referral.desc-how',
        },
        {
            symbol: 'referral',
            env: 56,
            question: 'referral.question-howmuch',
            desc: 'referral.desc-howmuch',
        },
        {
            symbol: 'referral',
            env: 56,
            question: 'referral.question-howmuch2layer',
            desc: 'referral.desc-howmuch2layer',
        },
        {
            symbol: 'referral',
            env: 42161,
            question: 'referral.question-what',
            defaultExpanded: true,
            desc: 'referral.desc-what',
        },
        {
            symbol: 'referral',
            env: 42161,
            question: 'referral.question-how',
            desc: 'referral.desc-how',
        },
        {
            symbol: 'referral',
            env: 42161,
            question: 'referral.question-howmuch',
            desc: 'referral.desc-howmuch',
        },
        {
            symbol: 'referral',
            env: 42161,
            question: 'referral.question-howmuch2layer',
            desc: 'referral.desc-howmuch2layer',
        },
    ],
}

export default referralFAQ
