// import Web3 from "web3";
import xWinPriceMaster from "../abi/v2/xWinPriceMaster.json";
import appGlobalConfig from '../configs/56/appGlobalConfig';
import { convertFromWei } from "../utils/helper";
import xWinLib from '../xWinLib';
import xWinUtils from "../xWinUtils";

export async function getPrice(web3, from, to) {

  try {
    const contract = new web3.eth.Contract(xWinPriceMaster, appGlobalConfig.mainnetxWInPriceMaster);
    let rate = await contract.methods.getPrice(from, to).call();
    return rate;

  } catch (error) {
    console.log(error)
  }
}

export async function getAllPrices(web3) {

    try {
      if(appGlobalConfig.mainnetxWInPriceMaster === appGlobalConfig.mainnetXWINBNBLPPoolV2){
        return {
          xwinPriceInUSD : "1",
          BNBUSDT :  "1",
          unipriceInBNB :  "1",
          pcsLPPrice  : "1",
          JPYUSD  : "1",
          babyLPPrice  : "1"
        }
      }
      const contract = new web3.eth.Contract(xWinPriceMaster, appGlobalConfig.mainnetxWInPriceMaster);
      const promiseData = await Promise.all([
        contract.methods.getPrice(appGlobalConfig.mainnetXWIN, appGlobalConfig.mainnetUSDT).call(),
        contract.methods.getPrice(appGlobalConfig.mainnetWBNB, appGlobalConfig.mainnetUSDT).call(),
        contract.methods.getPrice(appGlobalConfig.mainnetXWINBNBLPPoolV2, appGlobalConfig.mainnetUSDT).call(),
        contract.methods.getPrice(appGlobalConfig.mainnetXWINUSDTAPELPPool, appGlobalConfig.mainnetUSDT).call(),
        xWinUtils.fetchJPYUSDRate(),
      ]);
  
    let XWINUSDTRates = promiseData[0]
    let BNBUSDTRates = promiseData[1]
    let XWINBNBLPRates = promiseData[2]
    let XWINUSDTLPRates = promiseData[3]
    let JPYUSDRates = promiseData[4]
      
    let unipriceInBNB = Number(convertFromWei(XWINUSDTRates)) / Number(convertFromWei(BNBUSDTRates))
    return {
        xwinPriceInUSD : convertFromWei(XWINUSDTRates),
        BNBUSDT : convertFromWei(BNBUSDTRates),
        unipriceInBNB : unipriceInBNB,
        pcsLPPrice : convertFromWei(XWINBNBLPRates),
        JPYUSD : JPYUSDRates,
        babyLPPrice : convertFromWei(XWINUSDTLPRates)
      }
        
    } catch (error) {
      console.log(error)
      return {
        xwinPriceInUSD : "1",
        BNBUSDT :  "1",
        unipriceInBNB :  "1",
        pcsLPPrice  : "1",
        JPYUSD  : "1",
        babyLPPrice  : "1"
      }
    }
  
}
