import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    notificationItem: {
        marginTop: theme.spacing(2),
    },
    hoverHighlight: {
        // minHeight: "22vh",
        '&:hover': {
            boxShadow: theme.customShadows.widget,
            borderRadius: '20px',
        },
    },
    progressSection: {
        marginBottom: theme.spacing(1),
    },
    progressTitle: {
        marginBottom: theme.spacing(2),
    },
    progress: {
        marginBottom: theme.spacing(1),
        backgroundColor: 'rgb(236, 236, 236)',
    },
    pieChartLegendWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginRight: theme.spacing(1),
    },
    legendItemContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(1),
        justifyContent: 'space-between',
        width: '100%',
    },
    fullHeightBody: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    alignStandaloneElement: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    tableWidget: {
        overflowX: 'auto',
    },
    progressBar: {
        backgroundColor: theme.palette.warning.main,
    },
    progressBarPrimary: {
        backgroundColor: theme.palette.primary.main,
    },
    progressBarWarning: {
        backgroundColor: theme.palette.warning.main,
    },
    performanceLegendWrapper: {
        display: 'flex',
        flexGrow: 1,
        alignItems: 'center',
        marginBottom: theme.spacing(1),
    },
    legendElement: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2),
    },
    legendElementText: {
        marginLeft: theme.spacing(1),
    },
    serverOverviewElement: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
    },
    serverOverviewElementText: {
        minWidth: 149,
        paddingRight: theme.spacing(2),
    },
    serverOverviewElementChartWrapper: {
        width: '100%',
    },
    mainChartBody: {
        overflowX: 'auto',
    },
    mainChartHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.only('xs')]: {
            flexWrap: 'wrap',
        },
    },
    mainChartHeaderLabels: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.only('xs')]: {
            order: 3,
            width: '100%',
            justifyContent: 'center',
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(2),
        },
    },
    mainChartHeaderLabel: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(3),
    },
    mainChartSelectRoot: {
        borderColor: theme.palette.text.hint + '80 !important',
    },
    mainChartSelect: {
        padding: 10,
        paddingRight: 25,
    },
    mainChartLegentElement: {
        fontSize: '14px !important',
        marginLeft: theme.spacing(1),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    fixIconRight: {
        '& .MuiSelect-icon': {
            right: 4,
        },
    },
    actionsIcon: {
        color: '#76767B',
    },
    avatar: {
        backgroundColor: 'transparent',
        height: 50,
        width: 50,
        [theme.breakpoints.down('xs')]: {
            height: 25,
            width: 25,
        },
    },
    positiveNum: {
        // fontWeight: 100,
        // color: theme.palette.success.dark,
        textAlign: 'right',
        fontSize: '1.042rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.75rem',
        },
    },
    contentText: {
        fontSize: '1.042rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.75rem',
        },
    },
    negativeNum: {
        fontWeight: 100,
        color: theme.palette.secondary.dark,
    },
    columnHide: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    columnShow: {
        display: 'none',
        [theme.breakpoints.down('xs')]: {
            display: 'inline',
            fontSize: '0.65rem',
        },
    },
    displayFont: {
        fontSize: '1.042rem',
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.75rem',
        },
    },

    bannerAlert: {
        [theme.breakpoints.down('md')]: {
            marginTop: '4rem',
        },
    },
}))
