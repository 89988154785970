import React, { useState, useEffect, useRef, useContext } from 'react'
import { useTheme } from '@material-ui/styles'
import xWinLib from '../../../xWinLib'
import ReactApexChart from 'react-apexcharts'
import { Typography, Button } from '../../../components/Wrappers/Wrappers'
import { Box, Grid, TextField, InputAdornment } from '@material-ui/core'
// styles
import useStyles from '../styles'
import xWinUtils from '../../../xWinUtils'
import { AppContext } from '../../../context/AppProvider'
import { getStableTargetValues } from '../../../utils/xWinAutoTradingInteractor'
import appGlobalConfig from '../../../configs/56/appGlobalConfig';
import { getPrice } from '../../../utils/xWinPriceMasterInteractor'
import { convertFromWei } from '../../../utils/helper'
import format from 'format-number'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx';

export default function AutoTradingStrategy(props) {
    var classes = useStyles()
    var theme = useTheme()
    const { strategy, tokensMaster } = props
    const { web3static } = useContext(AppContext)
    const [values, setValues] = useState({
        series2: [],
        labels: [],
    })
    const [fundInfo, setFundInfo] = useState({
        stableValues: '0',
        targetValues: '0',
        targetToken: appGlobalConfig.mainnetAddressZero,
        stablecoin: appGlobalConfig.mainnetAddressZero,
        targetname: 'btc',
        baseccy: 'usdt',
    })
    const { t, i18n } = useTranslation('fundDetails')

    useEffect(() => {
        init()
    }, [strategy])

    let myPayLabel = []
    let seriesValue = []

    const init = async () => {
        const strategyInfo = await getStableTargetValues(
            web3static,
            strategy.contractaddress
        )
        let price = await getPrice(
            web3static,
            strategyInfo.targetToken,
            strategyInfo.stablecoin
        )
        let targetInBaseCcy =
            Number(strategyInfo.targetValues) * convertFromWei(price)
        let targetToken = tokensMaster.find(
            x =>
                x.address.toLowerCase() ===
                strategyInfo.targetToken.toLowerCase()
        )
        let stableToken = tokensMaster.find(
            x =>
                x.address.toLowerCase() ===
                strategyInfo.stablecoin.toLowerCase()
        )
        let total =
            Number(strategyInfo.stableValues) +
            Number(targetInBaseCcy)
        let targetPerc = Number(targetInBaseCcy) / Number(total)
        let stablePerc = Number(strategyInfo.stableValues) / Number(total)
        myPayLabel.push(stableToken.name)
        myPayLabel.push(targetToken.name)
        seriesValue.push(stablePerc)
        seriesValue.push(targetPerc)
        setValues({
            series2: seriesValue,
            labels: myPayLabel,
        })

        setFundInfo({
            ...strategyInfo,
            targetInBaseCcy: targetInBaseCcy,
            targetname: targetToken.name,
            baseccy: stableToken.name,
        })
    }

    const themeOptions = theme => {
        return {
            labels: values.labels,
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: 'bottom',
            },
            chart: {
                foreColor: '#ccc',
                toolbar: {
                    show: false,
                },
            },
            responsive: [
                {
                    // breakpoint: 480,
                    // options: {
                    //   chart: {
                    //     width: 200
                    //   },
                    //   legend: {
                    //     position: "bottom"
                    //   }
                    // }
                },
            ],
            colors: [
                theme.palette.primary.main,
                theme.palette.secondary.main,
                theme.palette.warning.main,
                theme.palette.success.light,
                theme.palette.info.main,
            ],
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid
                item
                lg={5}
                sm={12}
                xs={12}
                className={classes.hoverHighlight2}
            >
                <ReactApexChart
                    options={themeOptions(theme)}
                    series={values.series2}
                    type="donut"
                    height="350"
                    stroke={''}
                />
            </Grid>
            <Grid item lg={7} sm={12} xs={12} className={classes.cardpie}>
                <Grid item>
                    <Typography
                        className={clsx(classes.displayFont, "p-2")}
                        color="text"
                        colorBrightness={'hint'}
                    >
                        {t('strategies.autoTrading.usdt-value', {
                            baseToken: fundInfo.baseccy,
                        })}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <TextField
                            value={xWinLib.displayBigNumber(
                                fundInfo.stableValues
                            )}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.positiveNum,
                                },
                                readOnly: true,
                                style: { textAlign: 'right' },
                                startAdornment: (
                                    <InputAdornment position="end">
                                        {xWinUtils.getIcons(fundInfo.baseccy)}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.displayFont}>{fundInfo.baseccy}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <Typography
                        className={clsx(classes.displayFont, "p-2")}
                        color="text"
                        colorBrightness={'hint'}
                    >
                        {t('strategies.autoTrading.btc-value', {
                            baseToken: fundInfo.baseccy,
                        })}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <TextField
                            value={xWinLib.displayBigNumber(
                                fundInfo.targetInBaseCcy
                            )}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.positiveNum,
                                },
                                readOnly: true,
                                style: { textAlign: 'right' },
                                startAdornment: (
                                    <InputAdornment position="end">
                                        {xWinUtils.getIcons(
                                            fundInfo.targetname
                                        )}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.displayFont}>{fundInfo.baseccy}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item>
                    <br />
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent={'right'}
                    >
                        <TextField
                            value={format({ prefix: '$ ', suffix: '' })(
                                xWinLib.roundTo(
                                    fundInfo.targetInBaseCcy > 0
                                        ? fundInfo.stopLossPrice
                                        : 0,
                                    2
                                )
                            )}
                            size="small"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.positiveNum,
                                },
                                readOnly: true,
                                style: { textAlign: 'right' },
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.displayFont}>{t('strategies.autoTrading.stoploss')}</Typography>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className={classes.displayFont}>{fundInfo.baseccy}</Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    )
}
