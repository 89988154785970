import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@material-ui/styles'
import App from './components/App'
import * as serviceWorker from './serviceWorker'
import { LayoutProvider } from './context/LayoutContext'
import {
    ThemeProvider as ThemeChangeProvider,
    ThemeStateContext,
} from './context/ThemeContext'
import { AlertBannerContext, AlertBanner } from './context/AlertBannerContext'
import { CssBaseline } from '@material-ui/core'
import AppProvider from './context/AppProvider'
import config from '../src/config'
import './i18n'
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig, Provider } from 'wagmi'
// import { arbitrum, mainnet, polygon } from 'wagmi/chains'
import { publicProvider } from 'wagmi/providers/public'

axios.defaults.baseURL = config.baseURLApi
axios.defaults.headers.common['Content-Type'] = 'application/json'

const bsc = {
    id: 56,
    name: "BNB",
    network: "bsc",
    nativeCurrency: {
        decimals: 18,
        name: "BNB",
        symbol: "BNB",
    },
    rpcUrls: {
        default: {
            http: ["https://bsc-dataseed.binance.org/"],
        },
        public: {
            http: ["https://bsc-dataseed.binance.org/"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "BscScan",
            url: "https://bscscan.com",
        },
        default: {
            name: "BscScan",
            url: "https://bscscan.com",
        },
    },
    contracts: {
        multicall3: {
            address: "0xca11bde05977b3631167028862be2a173976ca11",
            blockCreated: 15921452,
        },
    },
};


const localhost = {
    id: 31337,
    name: "Localhost",
    network: "localhost",
    nativeCurrency: {
        decimals: 18,
        name: "Polygon",
        symbol: "MATIC",
    },
    rpcUrls: {
        default: {
            http: ["http://127.0.0.1:8545"],
        },
        public: {
            http: ["http://127.0.0.1:8545"],
        },
    },
}

const arbitrum = {
    id: 42161,
    name: "Arbitrum",
    network: "arbitrum",
    nativeCurrency: {
        decimals: 18,
        name: "Ether",
        symbol: "ETH",
    },
    rpcUrls: {
        default: {
            http: ["https://arbitrum.public-rpc.com"],
        },
        public: {
            http: ["https://arbitrum.public-rpc.com"],
        },
    },
}

const polygon = {
    id: 137,
    name: "Polygon",
    network: "polygon",
    nativeCurrency: {
        decimals: 18,
        name: "MATIC",
        symbol: "MATIC",
    },
    rpcUrls: {
        default: {
            http: ["https://polygon-rpc.com/"],
        },
        public: {
            http: ["https://polygon-rpc.com/"],
        },
    },
}

const chains = [bsc, arbitrum, polygon]
const projectId = 'aa33d4e82a40c77a9a52f8967397abac'
const { publicClient, provider, webSocketProvider } = configureChains(chains, [w3mProvider({ projectId }), publicProvider()])
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
  provider
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)


ReactDOM.render(
    <Suspense fallback={null}>
        <WagmiConfig config={wagmiConfig}>
            <AppProvider>
                <LayoutProvider>
                    <AlertBanner>
                        <ThemeChangeProvider>
                            <ThemeStateContext.Consumer>
                                {theme => (
                                    <ThemeProvider theme={theme}>
                                        <CssBaseline />
                                        <Web3Modal
                                            projectId={projectId}
                                            ethereumClient={ethereumClient}
                                        />
                                        <App />
                                    </ThemeProvider>
                                )}
                            </ThemeStateContext.Consumer>
                        </ThemeChangeProvider>
                    </AlertBanner>
                </LayoutProvider>
            </AppProvider>
        </WagmiConfig>
    </Suspense>,
    document.getElementById('root')
)

serviceWorker.unregister()
