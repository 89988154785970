import React, { useEffect, useContext, useState  } from "react";

import {
  Grid,
  Box,
} from "@material-ui/core";
import {AppContext}  from '../../../context/AppProvider';

// styles
import useStyles from "../styles";
import { GetStrategyTotalValueByUser } from "../../../utils/firestoreDataInteractor";
import { useTranslation } from "react-i18next";
import StrategieslistV2 from "../../strategiesV2/components/StrategieslistV2";
import MyVaultValue from "./MyVaultValue";
import { GetVenusData } from "../../../utils/strategyV2Interactor";
import { useAccount } from "wagmi/dist";

export default function MyStrategies(props) {
  var classes = useStyles();
  const { prices, selectedWallet, stakingPools, xwinstrategies, stakingBuddyPools, tokensMaster, portfolios, ...rest } = props;
  const { theWeb3, web3static, refreshAccount, ETHBalance, appGlobalConfig } = useContext(AppContext);
  const { address, isConnecting, isDisconnected, isConnected } = useAccount()
  const [venusData, setVenusData] = useState([])
  const [loading, setLoading] = React.useState(false);
  const [strategyCollections, setStrategyCollections] = useState([])
  const [strategyTotalValues, setStrategyTotalValues] = useState('0')
  const { t, i18n } = useTranslation('myMenu')

  useEffect(() => {
    init()
  }, [address]);

  const init = async () => {

      if (address !== undefined) {
           
        setLoading(true)
        const userStrategies = await GetStrategyTotalValueByUser(address, appGlobalConfig)

        let sOutput = getStrategyVaultsData(userStrategies);
        setStrategyCollections(sOutput.vaults || [])
        setStrategyTotalValues(sOutput.totalAmount)
        let venusDt = await GetVenusData()
        setVenusData(venusDt)

    } else {
        setStrategyCollections([])
        setStrategyTotalValues(0)

    }
    setLoading(false)
        
  }

  const getStrategyVaultsData = (userVaults) => {
        
    let vaults=[]
    userVaults.fundOwned.forEach(v => {
        let selected = xwinstrategies.find(
            x =>
                x.contractaddress.toLowerCase() ===
                v.contractaddress.toLowerCase()
        )
        vaults.push({
            ...selected,
            ...v
        })
    });
    return {
        vaults,
        totalAmount : userVaults.totalAmount
    }
        
}


  const displayList = () => {
        
    if (strategyCollections.length > 0) {
        return strategyCollections.map(port => (
            <>
                <StrategieslistV2
                    showBalWhenSmall={false}
                    port={port}
                    portfolios={portfolios}
                    tokensMaster={tokensMaster}
                    globalWeb3={theWeb3}
                    web3static={web3static}
                    selectedWallet={address}
                    xwinstrategies={xwinstrategies}
                    ETHBalance={ETHBalance}
                    refreshAccount={refreshAccount}
                    venusData={venusData}
                />
                <br/>
            </>
        ))
    } else {
        return (
            <Box
                display="flex"
                alignItems="center"
                justifyContent={'center'}
            >
                <img
                    style={{ maxWidth: '250px' }}
                    alt={'no data'}
                    src={'/images/background/nodata.png'}
                />
            </Box>
        )
    }
}

  return (
        <Grid container spacing={2}>
        <Grid
            item
            lg={
                strategyCollections?.length == 0
                    ? 12
                    : strategyCollections?.length > 4
                    ? 12
                    : 6
            }
            sm={12}
            xs={12}
            className={classes.hoverHighlight}
        >
            <MyVaultValue
                vaultTotalValues={strategyTotalValues || 0}
                vaultCollections={
                    strategyCollections || []
                }
                loading={loading}
            />
        </Grid>
        {strategyCollections?.length > 0 && (
            <Grid
                item
                lg={
                    strategyCollections?.length > 4
                        ? 12
                        : 6
                }
                sm={12}
                xs={12}
                className={classes.hoverHighlight}
            >
                {displayList()}
            </Grid>
        )}
    </Grid>
    
  );
}
