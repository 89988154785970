import appGlobalConfig from '../configs/56/appGlobalConfig';

export async function getPrivateFundsBySearchKey(searchkey, appGlobalConfig) {

  if(appGlobalConfig === undefined) return [];
  let api = appGlobalConfig.apiGetPrivateFundsBySearchKey + "?searchkey="+searchkey
  let output = await (await fetch(api)).json();
  return output.message;
}

export async function getLatestPrivateFunds(appGlobalConfig) {

  if(appGlobalConfig === undefined) return [];
  let api = appGlobalConfig.apiGetLatestPrivateFunds
  let output = await (await fetch(api)).json();
  return output.message;
}

export async function getExposure(appGlobalConfig, address) {

  if(appGlobalConfig === undefined) return [];
  let api = appGlobalConfig.apiGetExposure + address;
  let output = await (await fetch(api)).json();
  return output.message;
}
  
export async function getPrivateFunds(account, appGlobalConfig) {

  if(appGlobalConfig === undefined) return [];
  let api = appGlobalConfig.apiGetPrivateFunds + "?account="+account
  let output = await (await fetch(api)).json();
  return output.message;

}

export async function getFundActivitiesByWalletOrContractAddr(account, contractaddress, showAll = false, appGlobalConfig) {

  try {
    if(appGlobalConfig === undefined) return [];
    let api = appGlobalConfig.apiGetFundActivitiesByWalletOrContractAddr + "?account="+account+"&contractaddress="+contractaddress+"&showAll="+showAll
    let output = await (await fetch(api)).json();
    return output.message;      
  } catch (error) {
    return []
  }

}

export async function getPrivateFundByContractAddress(address, appGlobalConfig) {

  if(appGlobalConfig === undefined) return [];

  let api = appGlobalConfig.apiGetPrivateFundsByContractAddress + "?contractaddress="+address
  let output = await (await fetch(api)).json();
  return output.message;

}
